<template>
  <div class="thumbnails-list">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThumbnailsList',
  setup () {
    return {}
  }
})
</script>

<style scoped lang="less">
  .thumbnails-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 8px 8px;
  }
</style>
