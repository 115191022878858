<script>
import { computed, defineComponent, onMounted, onUnmounted } from 'vue'
import { SESSION_TIME } from '@/constants'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'AbsenceHandler',
  setup () {
    const store = useStore()
    const authStatus = computed(() => store.getters['auth/authStatus'])
    const windowBlurTime = computed(() => store.getters['auth/windowBlurTime'])

    onMounted(() => {
      window.addEventListener('focus', handleWindowFocus, false)
      window.addEventListener('blur', handleWindowBlur, false)
    })

    onUnmounted(() => {
      window.removeEventListener('focus', handleWindowFocus, false)
      window.removeEventListener('blur', handleWindowBlur, false)
    })

    const handleWindowBlur = () => {
      if (authStatus.value) {
        store.commit('auth/SET_WINDOW_BLUR_TIME', new Date().getTime())
      }
    }

    const handleWindowFocus = () => {
      if (!authStatus.value) return
      if (authStatus.value && !windowBlurTime.value) return
      const currentTime = new Date().getTime()
      if (currentTime - windowBlurTime.value > SESSION_TIME) {
        store.dispatch('auth/checkIfSessionIsActive')
      }
    }

    return {}
  }
})
</script>

<template>
  <template />
</template>
