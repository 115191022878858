<script setup>

import { onMounted, ref, watch } from 'vue'

const player = ref(null)
const playbackStarted = ref(false)
const loaded = ref(false)

onMounted(()=>{
  loadYouTubeAPI()
})

const emit = defineEmits(['ended'])

function loadYouTubeAPI() {
  if (window.YT && window.YT.Player) {
    // Если API уже загружен, инициализируем плеер сразу
    initPlayer()
  } else {
    // Если API еще не загружен, загружаем его
    let tag = document.createElement('script')
    tag.src = "https://www.youtube.com/iframe_api"
    let firstScriptTag = document.getElementsByTagName('script')[0]
    if (!firstScriptTag) {
      document.head.appendChild(tag)
    } else {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }

    // Объявляем глобальную функцию, которая будет вызвана API
    window.onYouTubeIframeAPIReady = () => {
      initPlayer()
    }
  }
}

function initPlayer() {
  player.value = new YT.Player('youtube-video', {
    events: {
      'onReady': onPlayerReady,
      'onStateChange': onPlayerStateChange
    }
  })
}

function onPlayerReady() {
  loaded.value = true
}

function onPlayerStateChange(event) {
  if (event.data === 0) {
    emit('ended')
    console.log('ended')
  }
}


function playVideo() {
  player.value.playVideo()
  playbackStarted.value = true
}
</script>

<template>
  <div style="display: flex; height: 100%; width: 100%; position: relative;">
    <iframe
      id="youtube-video"
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/gwCkJ_1CO3c?enablejsapi=1"
      title="Digital Signage for Apple TV - Kitcast - What is Digital Signage?"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    />
    <div
      v-if="loaded && !playbackStarted"
      style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; align-items: center; justify-content: center;"
    >
      <a-button
        size="large"
        type="primary"
        @click="playVideo"
      >
        {{ $t('components.onboardingService.modalButtonText') }}
      </a-button>
    </div>
  </div>
</template>


