<script setup>

import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const clarityLoaded = ref(false)
const userId = computed(() => store.getters['auth/userId'])

onMounted(() => {
  if (!window.clarity) {
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "nqsf6o3bvu");
  }
  clarityLoaded.value = true
})

watch([userId, clarityLoaded], ([id, loaded]) => {
  if (loaded && id) {
    window.clarity && window.clarity("identify", id)
  }
})


</script>

<template>
  <div />
</template>

<style scoped lang="less">

</style>
