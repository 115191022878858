<template>
  <icon>
    <template #component>
      <svg
        width="52"
        height="56"
        viewBox="0 0 52 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_81388_106489)">
          <rect
            width="51"
            height="56"
            transform="translate(0.5)"
            fill="white"
          />
          <path
            d="M51.5 0L0.5 10V46L51.5 56V0Z"
            fill="url(#paint0_linear_81388_106489)"
          />
          <rect
            x="15.5"
            y="18"
            width="20"
            height="20"
            rx="10"
            fill="white"
          />
          <path
            d="M21.7383 32H23.1152L23.8828 29.7148H27.1172L27.8789 32H29.2617L26.209 23.5449H24.791L21.7383 32ZM25.4473 25.0273H25.5469L26.7656 28.6602H24.2344L25.4473 25.0273Z"
            fill="black"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_81388_106489"
            x1="26.5"
            y1="0"
            x2="26.5"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop
              offset="1"
              stop-color="#656565"
            />
          </linearGradient>
          <clipPath id="clip0_81388_106489">
            <rect
              width="51"
              height="56"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'FlipLeftTransition',
  components: {
    Icon
  }
})
</script>
