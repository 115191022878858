<template>
  <div
    ref="animationContainer"
    class="animation-preview"
  />
</template>
<script>
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import bodymovin from 'bodymovin'

export default defineComponent({
  name: 'AnimationPreview',
  props: {
    animation: Object
  },
  setup (props) {

    const animationContainer = ref()
    const animationHolder = ref()

    onMounted(() => {
      props.animation && startAnimation(props.animation)
    })

    onUnmounted(() => {
      animationHolder.value?.destroy()
    })

    watch(() => props.animation, (value) => {
      restartAnimation()
    })

    const restartAnimation = () => {
      animationHolder.value?.destroy()
      startAnimation(props.animation)
    }

    const startAnimation = (animation) => {
      const autoplay = true
      animationHolder.value = bodymovin.loadAnimation({
        wrapper: animationContainer.value,
        loop: true,
        autoplay: autoplay,
        animationData: animation
      })
    }

    return {
      animationContainer
    }
  }
})
</script>

<style lang="less" scoped>
.animation-preview {
  position: absolute;
}
</style>
