export default [
  {
    rows: [
      {
        height: 6,
        columns: [
          {
            width: 6,
            elements: [
              {
                type: 'text',
                alignment: 'bottomRight',
                fontColor: '#dcdcdc',
                text: 'Heading',
              }
            ]
          }
        ]
      },
      {},
      {}
    ]
  },
  {
    rows: [
      {
        height: 6,
        columns: [
          {
            width: 6,
            elements: [{
              type: 'text',
              alignment: 'middleCenter',
              text: 'Heading',
            }]
          }
        ]
      },
    ]
  },
  {
    rows: [
      {
        height: 6,
        columns: [{
          width: 6,
          elements: [{
            type: 'text',
            alignment: 'bottomCenter',
            text: 'Heading',
          }]
        },
        {
          width: 6,
          elements: [{
            type: 'media',
            alignment: 'center',
            objectFit: 'fit',
            mediaType: 'image',
            url: 'https://picsum.photos/200/300/?random'
          }]
        }]
      },
    ]
  },
  {
    rows: [
      {
        height: 6,
        columns: [{
          width: 8,
          elements: [{
            type: 'text',
            alignment: 'topLeft',
            text: 'Heading',
          }]
        },
          {
            width: 4,
            elements: [{
              type: 'media',
              alignment: 'center',
              objectFit: 'fit',
              mediaType: 'image',
              url: 'https://picsum.photos/200/300/?random'
            }]
          }]
      },
    ]
  },
  {
    rows: [
      {
        height: 6,
        columns: [{
          width: 6,
          elements: [{
            type: 'text',
            alignment: 'topLeft',
            itext: 'Heading'
          },
            {
              type: 'media',
              alignment: 'center',
              objectFit: 'crop',
              mediaType: 'image',
              url: 'https://picsum.photos/200/300/?random'
            }]
        }]
      },
    ]
  },
  {
    rows: [
      {
        height: 6,
        columns: [{
          width: 4,
          elements: [
            {
              type: 'media',
              alignment: 'center',
              objectFit: 'fit',
            }]
        },
          {
            width: 4,
            elements: [
              {
                type: 'media',
                alignment: 'center',
                objectFit: 'fit',
              }]
          },
          {
            width: 4,
            elements: [
              {
                type: 'media',
                alignment: 'center',
                objectFit: 'fit',
                mediaType: 'image',
                url: 'https://picsum.photos/200/300/?random'
              }]
          }]
      },
    ]
  },
  {
    rows: [
      {
        height: 6,
        columns: [{
          width: 6,
          elements: [
            {
              type: 'media',
              alignment: 'center',
              objectFit: 'fit',
              mediaType: 'image',
              url: 'https://picsum.photos/200/300/?random'
            }]
        }]
      },
    ]
  }
]
