<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const eduTierApplication = computed(() => store.getters['workspace/eduTierApplication'])
const eduApplicationAvailable = computed(() => eduTierApplication.value?.available)
const eduApplicationApplied = computed(()=> eduTierApplication.value?.applied)

const showEduApplicationModal  = () =>{
  store.dispatch('openEDUAppliance')
}

</script>

<template>
  <div
    v-if="eduApplicationAvailable"
  >
    <i18n-t
      v-if="!eduApplicationApplied"
      keypath="components.EDUApplicationString.eduApplicationText"
      tag="label"
      for="components.EDUApplicationString.eduApplicationLink"
    >
      <a-typography-link
        class="underline-link"
        @click="showEduApplicationModal"
      >
        {{ $t('components.EDUApplicationString.eduApplicationLink') }}
      </a-typography-link>
    </i18n-t>
    <a-typography-text
      v-else
      style="color: var(--ant-primary-color);"
    >
      {{ $t('components.EDUApplicationString.pendingText') }}
    </a-typography-text>
  </div>
</template>

<style scoped lang="less">

</style>
