<script setup>
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'

const props = defineProps({
  src: String,
  open: Boolean,
  closeDelay: {
    type: Number,
    default: 2000
  }
})

const emit = defineEmits(['cancel', 'submit', 'close'])

const MIN_HEIGHT = 100
const MODAL_WIDTH = 800

const tallyIframeRef = ref(null)
const frameWrapperRef = ref(null)
const tallyFormLoaded = ref(false)
const containerHeight = ref(MIN_HEIGHT)

let resizeTimeout
const resizeObserver = new ResizeObserver(entries => {
  clearTimeout(resizeTimeout)
  resizeTimeout = setTimeout(() => {
    for (let entry of entries) {
      containerHeight.value = entry.contentRect.height
    }
  }, 100)
})


onMounted(()=>{
  window.Tally?.loadEmbeds()
})

onUnmounted(() => {
  resizeObserver.disconnect()
  window.removeEventListener('message', handleFormSubmitted)
  tallyIframeRef.value?.removeEventListener('load', handleIframeLoaded)
})

const handleIframeLoaded = () => {
  tallyFormLoaded.value = true
  window.addEventListener('message', handleFormSubmitted)
}

const handleFormSubmitted = (e) => {
  if (e?.data?.includes && e?.data?.includes('Tally.FormSubmitted')) {
    emit('submit')
    setTimeout(() => {
      handleClose()
    }, props.closeDelay)
  }
}

const handleClose = () => {
  closeModal()
  emit('close')
}

const handleCancel = () => {
  closeModal()
  emit('cancel')
}

const closeModal = () => {
  tallyFormLoaded.value = false
  window.removeEventListener('message', handleFormSubmitted)
  tallyIframeRef.value?.removeEventListener('load', handleIframeLoaded)
}

const resetIframeForm = () => {
  const iframe = tallyIframeRef.value
  if (iframe) {
    iframe.src = props.src
  }
}

watchEffect(() => {
  if (props.open) {
    if (frameWrapperRef.value) {
      resizeObserver.observe(frameWrapperRef.value)
    }
    if (tallyIframeRef.value) {
      resetIframeForm()
      tallyIframeRef.value.addEventListener('load', handleIframeLoaded)
    }
  } else {
    resizeObserver.disconnect()
    containerHeight.value = MIN_HEIGHT
  }
})

</script>

<template>
  <a-modal
    destroy-on-close
    :open="open"
    :footer="null"
    :width="MODAL_WIDTH"
    :closable="false"
    :wrap-class-name="['close-to-top-modal']"
    @cancel="handleCancel"
  >
    <a-spin
      :spinning="!tallyFormLoaded"
    >
      <div
        class="frame-container"
        :style="{height: `${containerHeight}px`}"
      >
        <div ref="frameWrapperRef">
          <iframe
            v-show="tallyFormLoaded"
            id="tally-add-widget-iframe"
            ref="tallyIframeRef"
            :src="src"
            style="height: 100%; width: 100%;"
          />
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<style scoped lang="less">
.frame-container {
  overflow: hidden;
  min-height: 200px;
  transition: all .2s ease;
}
</style>
