<template>
  <ResizableSidebar
    :collapsed="collapsed"
    :trigger="null"
    collapsible
    :collapsed-width="0"
    theme="dark"
    :class="{'border-r':!collapsed}"
    class="groups-sidebar dark"
    :width="250"
    :max-width="400"
    :min-width="250"
  >
    <a-layout class="full-height dark">
      <MoveToOtherGroupModal
        v-model:visible="showMoveToOtherGroupModal"
        :title="$t('components.groupsSidebar.moveToOtherGroupTitle', {groupName:editGroupState.name})"
        :max-groups-depth="maxDepth"
        :current-group-depth="editGroupState.maxGroupDepth"
        :exclude-group-id="editGroupState.groupId"
        :non-selectable-parent-group-id="editGroupState.parentGroupId"
        :ignore-smart-groups="true"
        @select="setParentGroupId"
        @close="resetGroupForm"
      />
      <CreateEditGroupModal
        :current-group-name="editGroupState.name"
        :current-group-language="editGroupState.language"
        :current-parent-group-id="editGroupState.parentGroupId"
        :exclude-group-id="editGroupState.groupId"
        :is-smart-group="editGroupState.type === SMART_GROUP_TYPE"
        :visible="showCreateGroupModal"
        :nested-groups-enabled="nestedGroupsEnabled"
        @cancel="resetGroupForm"
        @update="onGroupEdit"
        @create="onGroupEdit"
      />
      <a-layout-header class="logo-container">
        <div class="logo">
          <img
            :src="workspaceLogo || require( `@/assets/kitcast-logo.svg`)"
            alt=""
          >
        </div>
      </a-layout-header>
      <a-layout-content
        style="display: flex; flex-direction: column; padding: 12px 16px 0 16px; overflow-x: auto;"
      >
        <a-input-search
          v-model:value="searchValue"
          :placeholder="$t('components.groupsSidebar.filterPlaceholder')"
          class="groups-filter"
        />
        <small class="menu-block-title">
          {{ $t('components.groupsSidebar.standardGroupsTitle') }}
        </small>
        <a-menu
          v-if="!isManager"
          theme="dark"
          class="groups-add"
          :selectable="false"
        >
          <a-menu-item
            key="1"
            @click="startGroupCreate(STANDARD_GROUP_TYPE)"
          >
            <template #icon>
              <PlusCircleOutlined />
            </template>
            {{ $t('components.groupsSidebar.addStandardGroup') }}
          </a-menu-item>
        </a-menu>
        <a-spin
          :spinning="availableStandardGroupsLoading"
          :delay="150"
        >
          <a-tree
            v-if="show"
            :selected-keys="selectedGroup"
            :auto-expand-parent="autoExpandParent"
            :expanded-keys="expandedKeys"
            :tree-data="standardGroupsTreeToShow"
            show-icon
            class="groups-sidebar-tree"
            @expand="onExpand"
            @select="onGroupSelect"
          >
            <template #icon>
              <InboxOutlined />
            </template>
            <template
              #title="{ title, key, parentGroupId, depth, language, first, maxGroupDepth }"
            >
              <div
                :ref="first ? 'groupsTreeRef' : null"
                style="position: absolute; width: 100%; height: 100%; left: 0; z-index: -1;"
              />
              <a-tooltip
                placement="top"
                :mouse-enter-delay=".8"
              >
                <template #title>
                  {{ title }}
                </template>
                <span v-if="title?.indexOf(searchValue) > -1">
                  {{ title.substr(0, title.indexOf(searchValue)) }}
                  <span class="search-value">{{ searchValue }}</span>
                  {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
                </span>
                <span v-else>{{ title }}</span>
              </a-tooltip>
              <a-dropdown
                :trigger="['click']"
                placement="bottomLeft"
              >
                <a-typography-link
                  style="padding-right: 6px;padding-left: 4px; flex-shrink: 0"
                  @click.prevent.stop
                >
                  <SettingOutlined />
                </a-typography-link>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <template #icon>
                        <EditOutlined />
                      </template>
                      <a @click="startGroupEdit({groupId: key, name: title, parentGroupId, language, type: STANDARD_GROUP_TYPE})">{{ $t('components.groupsSidebar.edit') }}</a>
                    </a-menu-item>
                    <a-menu-item v-if="!isManager && (depth < maxDepth && nestedGroupsEnabled)">
                      <template #icon>
                        <FolderAddOutlined />
                      </template>
                      <a @click="startSubGroupCreate({parentGroupId: key})">{{ $t('components.groupsSidebar.addSubGroup') }}</a>
                    </a-menu-item>
                    <a-menu-item>
                      <template #icon>
                        <RobotOutlined />
                      </template>
                      <a-tooltip
                        :title="$t('components.groupsSidebar.copyMDMTooltipTitle')"
                        placement="right"
                      >
                        <a @click="copyMDMConfigToClipboard({groupId: key})">{{ $t('components.groupsSidebar.copyMDM') }}</a>
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item v-if="groupsAreMovable && maxDepth > maxGroupDepth && parentGroupId">
                      <template #icon>
                        <ExportOutlined />
                      </template>
                      <a @click="startMoveGroup({groupId: key, name: title, parentGroupId})">{{ $t('components.groupsSidebar.move') }}</a>
                    </a-menu-item>
                    <template v-if="parentGroupId || standardGroupsTree?.length !== 1">
                      <a-menu-divider />
                      <a-popconfirm
                        :title="$t('components.groupsSidebar.deleteGroupPopConfirmTitle')"
                        :ok-text="$t('components.groupsSidebar.deleteGroupPopConfirmOkText')"
                        :cancel-text="$t('components.groupsSidebar.deleteGroupPopConfirmCancelText')"
                        placement="bottom"
                        @confirm="onGroupDelete({groupId: key, type: STANDARD_GROUP_TYPE})"
                      >
                        <a-menu-item>
                          <template #icon>
                            <DeleteOutlined />
                          </template>
                          <a>{{ $t('components.groupsSidebar.delete') }}</a>
                        </a-menu-item>
                      </a-popconfirm>
                    </template>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-tree>
        </a-spin>
        <template v-if="smartGroupsEnabled">
          <small class="menu-block-title">
            {{ $t('components.groupsSidebar.smartGroupsTitle') }}
          </small>
          <a-menu
            v-if="!isManager"
            theme="dark"
            class="groups-add"
            :selectable="false"
          >
            <a-menu-item
              key="1"
              @click="startGroupCreate(SMART_GROUP_TYPE)"
            >
              <template #icon>
                <PlusCircleOutlined />
              </template>
              {{ $t('components.groupsSidebar.addSmartGroup') }}
            </a-menu-item>
          </a-menu>
          <a-spin
            :spinning="availableSmartGroupsLoading"
            :delay="150"
          >
            <a-tree
              v-if="show"
              :selected-keys="selectedGroup"
              :tree-data="smartGroupsTree"
              show-icon
              class="groups-sidebar-tree"
              @select="onGroupSelect"
            >
              <template #icon>
                <BrainOutlined />
              </template>
              <template
                #title="{ title, key, parentGroupId }"
              >
                <a-tooltip
                  placement="top"
                  :mouse-enter-delay=".8"
                >
                  <template #title>
                    {{ title }}
                  </template>
                  <span v-if="title?.indexOf(searchValue) > -1">
                    {{ title.substr(0, title.indexOf(searchValue)) }}
                    <span class="search-value">{{ searchValue }}</span>
                    {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
                  </span>
                  <span v-else>{{ title }}</span>
                </a-tooltip>
                <a-dropdown
                  :trigger="['click']"
                  placement="bottomLeft"
                >
                  <a-typography-link
                    style="padding-right: 6px;padding-left: 4px; flex-shrink: 0"
                    @click.prevent.stop
                  >
                    <SettingOutlined />
                  </a-typography-link>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <template #icon>
                          <EditOutlined />
                        </template>
                        <a @click="startGroupEdit({groupId: key, name: title, parentGroupId, type: SMART_GROUP_TYPE})">{{ $t('components.groupsSidebar.edit') }}</a>
                      </a-menu-item>
                      <a-menu-divider />
                      <a-popconfirm
                        :title="$t('components.groupsSidebar.deleteGroupPopConfirmTitle')"
                        :ok-text="$t('components.groupsSidebar.deleteGroupPopConfirmOkText')"
                        :cancel-text="$t('components.groupsSidebar.deleteGroupPopConfirmCancelText')"
                        placement="bottom"
                        @confirm="onGroupDelete({groupId: key, type: SMART_GROUP_TYPE})"
                      >
                        <a-menu-item>
                          <template #icon>
                            <DeleteOutlined />
                          </template>
                          <a>{{ $t('components.groupsSidebar.delete') }}</a>
                        </a-menu-item>
                      </a-popconfirm>
                    </a-menu>
                  </template>
                </a-dropdown>
              </template>
            </a-tree>
          </a-spin>
        </template>
        <div class="spacer" />
      </a-layout-content>
    </a-layout>
  </ResizableSidebar>
</template>

<script>
import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  FolderAddOutlined,
  InboxOutlined,
  PlusCircleOutlined,
  RobotOutlined,
  SettingOutlined
} from '@ant-design/icons-vue'
import { computed, defineComponent, inject, onMounted, reactive, ref, toRef, watch } from 'vue'
import { useStore } from 'vuex'
import { error, success } from '@/utils'
import ResizableSidebar from '@/components/ResizableSidebar'
import MoveToOtherGroupModal from '@/components/inputModals/MoveToOtherGroupModal.vue'
import CreateEditGroupModal from '@/components/inputModals/CreateEditGroupModal.vue'
import { MAX_GROUPS_DEPTH, SMART_GROUP_TYPE, STANDARD_GROUP_TYPE } from '@/constants'
import { useI18n } from 'vue-i18n'
import BrainOutlined from '@/components/icons/BrainOutlined.vue'
import { useRouter } from 'vue-router'

function findNodeByGroupId(tree, groupId) {
  if (tree.groupId === groupId) {
    return tree
  }

  if (tree.children && tree.children.length > 0) {
    for (let child of tree.children) {
      const result = findNodeByGroupId(child, groupId)
      if (result) {
        return result
      }
    }
  }
  return null
}

export default defineComponent({
    name: 'GroupsSidebar',
  components: {
    BrainOutlined,
    CreateEditGroupModal,
    MoveToOtherGroupModal,
    ResizableSidebar,
    PlusCircleOutlined,
    SettingOutlined,
    EditOutlined,
    ExportOutlined,
    DeleteOutlined,
    FolderAddOutlined,
    InboxOutlined,
    RobotOutlined
  },
  props: {
    group: {
      type: String,
      default: null
    },
    show: Boolean
  },
  emits: ['group-change'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    const group = toRef(props, 'group')
    const expandedKeys = ref([])
    const searchValue = ref('')
    const selectedGroup = ref(props.group ? [props.group] : [])
    const autoExpandParent = ref(true)
    const showCreateGroupModal = ref(false)
    const showMoveToOtherGroupModal = ref(false)
    const groupsTreeRef = ref(null)
    const onboardingService = inject('onboardingService')
    const isManager = computed(() => store.getters['workspace/isManager'])
    const collapsed = computed(() => store.getters['groupSidebarCollapsed'])
    const availableGroupsLoaded = computed(() => store.getters['groups/availableGroupsLoaded'])
    const availableGroups = computed(() => store.getters['groups/availableGroups'])
    const availableStandardGroups = computed(() => store.getters['groups/availableStandardGroups'])
    const availableStandardGroupsLoading = computed(() => store.getters['groups/availableStandardGroupsLoading'])
    const availableSmartGroups = computed(() => store.getters['groups/availableSmartGroups'])
    const availableSmartGroupsLoading = computed(() => store.getters['groups/availableSmartGroupsLoading'])
    const smartGroupsEnabled = computed(() => store.getters['workspace/smartGroupsEnabled'])
    const nestedGroupsEnabled = computed(() => store.getters['workspace/nestedGroupsEnabled'])
    const workspaceLogo = computed(() => store.getters['workspace/workspaceLogo'] || store.getters['auth/logo'])
    const authStatus = computed(() => store.getters['auth/authStatus'])
    const standardGroupsTree = computed(() => store.getters['groups/availableStandardGroupsTree']({ setDepth: true }))
    const standardGroupsTreeToShow = computed(() => {
      const mirror = JSON.parse(JSON.stringify(standardGroupsTree.value))
      return availableStandardGroupsLoading.value ? mirror : standardGroupsTree.value
    })
    const smartGroupsTree = computed(() => store.getters['groups/availableSmartGroupsTree']({ setDepth: true }))
    const groupsTreeAvailableForSubGroups = computed(() => store.getters['groups/availableStandardGroupsTree']({ maxGroupsDepth: MAX_GROUPS_DEPTH }))
    const groupsAreMovable = computed(() => !!availableStandardGroups.value?.length && nestedGroupsEnabled.value)
    const editGroupState = reactive({
      name: '',
      parentGroupId: null,
      language: null,
      groupId: null,
      type: null
    })

    onMounted(async () => {
      availableGroupsLoaded.value && onGroupsFetched()
    })

    const onExpand = keys => {
      expandedKeys.value = keys
      autoExpandParent.value = false
    }

    const getParentGroupId = () => {
      const [selectedGroupId] = selectedGroup.value
      const { parentGroupId } = availableGroups.value?.find(({ id }) => id === selectedGroupId) || {}
      return parentGroupId
    }

    const onGroupSelect = (data, { node }) => {
      selectedGroup.value = [node.key]
      if (!expandedKeys.value.some(k => k === node.key)) {
        expandedKeys.value = [...expandedKeys.value, node.key]
      }
      emit('group-change', node.key)
    }

    const startGroupCreate = (type) => {
      editGroupState.type = type
      showCreateGroupModal.value = true
    }

    const startGroupEdit = ({ groupId, name, parentGroupId, type, language }) => {
      editGroupState.name = name
      editGroupState.language = language || null
      editGroupState.groupId = groupId
      editGroupState.parentGroupId = parentGroupId
      editGroupState.type = type
      showCreateGroupModal.value = true
    }

    const startSubGroupCreate = ({ parentGroupId }) => {
      editGroupState.parentGroupId = parentGroupId
      editGroupState.type = STANDARD_GROUP_TYPE
      showCreateGroupModal.value = true
    }

    const startMoveGroup = ({ groupId, name, parentGroupId }) => {
      editGroupState.groupId = groupId
      const treeNode = findNodeByGroupId({children:standardGroupsTree.value}, groupId)
      editGroupState.name = name
      editGroupState.maxGroupDepth = treeNode.maxGroupDepth
      editGroupState.parentGroupId = parentGroupId
      showMoveToOtherGroupModal.value = true
    }

    const setParentGroupId = (parentGroupId) => {
      editGroupState.parentGroupId = parentGroupId
      editGroupState.type = STANDARD_GROUP_TYPE
      onGroupEdit({parentGroupId})
    }

    const resetGroupForm = () => {
      showCreateGroupModal.value = false
      editGroupState.name = ''
      editGroupState.groupId = null
      editGroupState.parentGroupId = null
      editGroupState.maxGroupDepth = 0
      editGroupState.type = null
      editGroupState.language = null
    }

    const onGroupCreated = (group) => {
      const { id, parentGroupId } = group
      selectedGroup.value = [id]
      if (parentGroupId) {
        expandedKeys.value = [parentGroupId]
        autoExpandParent.value = true
      }
      resetGroupForm()
      emit('group-change', id)
    }

    const onGroupEdit = ({groupName: name, parentGroupId, language}) => {
      const { groupId, type } = editGroupState
      if (!name && !parentGroupId) return
      const input = {
        ...(name ? { name } : null),
        ...(parentGroupId ? { parentGroupId: parentGroupId === 'ROOT' ? null : parentGroupId } : {}),
        language
      }
      if (groupId) {
        return store.dispatch('groups/updateGroup', { id: groupId, input, type })
          .then(() => {
            resetGroupForm()
          }).catch(e => {
            error(e.message)
          })
      }
      store.dispatch('groups/createGroup', {
      input: {
        ...input,
        ...{
          defaultPlaylist: {
            name: t(`components.groupsSidebar.${type === STANDARD_GROUP_TYPE
                ? 'defaultStandardGroupPlaylistName'
                : 'defaultSmartGroupPlaylistName'
            }`)
          }
        }
      }, type }).then(onGroupCreated).catch(e => {
        error(e.message)
      })
    }

    const onGroupDelete = ({ groupId, type }) => {
      const [selectedGroupId] = selectedGroup.value
      const closestGroupId = getClosestGroupId(groupId, type)
      expandedKeys.value = []
      store.dispatch('groups/deleteGroup', { id: groupId, type }).then(() => {
        if (selectedGroupId === groupId || !isGroupExist(selectedGroupId)) {
          emit('group-change', closestGroupId)
          selectedGroup.value = [closestGroupId]
        }
        resetGroupForm()
      })
    }

    const isGroupExist = (groupId) => {
      return availableGroups.value.some(({ id }) => id === groupId)
    }

    const getClosestGroupId = (groupId, type) => {
      const availableGroups = type === STANDARD_GROUP_TYPE
          ? availableStandardGroups
          : availableSmartGroups.value?.length > 1
              ? availableSmartGroups
              : availableStandardGroups

      const groupIndex = availableGroups.value.findIndex(({ id }) => id === groupId)
      const group = availableGroups.value[groupIndex]

      if (!group) return null

      const parentGroupId = group?.parentGroupId
      const groupsOnLevel = availableGroups.value.filter(({ parentGroupId: pid }) => pid === parentGroupId)

      if (groupsOnLevel.length > 1) {
        const neighboringGroup = groupsOnLevel.find(({ id }) => id !== groupId)
        return neighboringGroup?.id || null
      }

      if (parentGroupId) {
        return parentGroupId
      }

      const rootGroups = availableGroups.value.filter(({ parentGroupId: pid }) => !pid)
      const neighboringRootGroup = rootGroups.find(({ id }) => id !== groupId)
      return neighboringRootGroup?.id || null
    }

    const onGroupsFetched = () => {
      const isUsersGroup = !!standardGroupsTree.value.some(({ key }) => selectedGroup.value[0] === key) ||
          !!smartGroupsTree.value.some(({ key }) => selectedGroup.value[0] === key)

      if (!selectedGroup.value.length || !isUsersGroup) {
        const group = standardGroupsTree.value.length
            ? standardGroupsTree.value[0].key
            : smartGroupsTree.value.length
                ? standardGroupsTree.value[0].key
                : ''
        selectedGroup.value = [props.group || group]
      }
      emit('group-change', selectedGroup.value[0])

      const parentGroupId = getParentGroupId()
      if (parentGroupId && !expandedKeys.value.includes(parentGroupId)) {
        autoExpandParent.value = true
        expandedKeys.value = [parentGroupId]
      }
      onboardingService.setLoadable('changeScreenName', true)
      onboardingService.setLoadable('groupsSidebar', true)
    }

    const copyMDMConfigToClipboard = ({groupId}) => {
      store.dispatch('groups/copyGroupJAMFSettingsToClipboard', {groupId, cb: ()=>{
          success('Copied')
        }})
    }

    watch(searchValue, value => {
      expandedKeys.value = availableGroups.value
        ?.filter(item => item.name.indexOf(value) > -1)
        .map(({ parentGroupId }) => parentGroupId)
        .filter(Boolean)
      searchValue.value = value
      autoExpandParent.value = true
    })

    watch(group, newValue => {
      if (newValue) {
        selectedGroup.value = [newValue]
      } else {
        authStatus.value && onGroupsFetched()
      }
    })

    watch(availableGroupsLoaded, async (value) => {
      if (value) {
        onGroupsFetched()
      }
    })

    watch(()=> groupsTreeRef.value, (value) => {
      if (value) {
        onboardingService.setRef(value, 'groupsSidebar', () => {
          router.push({name: 'Groups'})
        }, () => {
          router.push({name: 'DevicesPage'})
        })
      }
    })

    return {
      collapsed,
      standardGroupsTreeToShow,
      availableStandardGroupsLoading,
      availableSmartGroupsLoading,
      availableGroups,
      expandedKeys,
      groupsAreMovable,
      searchValue,
      autoExpandParent,
      workspaceLogo,
      maxDepth: MAX_GROUPS_DEPTH,
      standardGroupsTree,
      smartGroupsTree,
      groupsTreeAvailableForSubGroups,
      selectedGroup,
      showCreateGroupModal,
      showMoveToOtherGroupModal,
      editGroupState,
      nestedGroupsEnabled,
      smartGroupsEnabled,
      STANDARD_GROUP_TYPE,
      SMART_GROUP_TYPE,
      isManager,
      groupsTreeRef,
      resetGroupForm,
      startGroupCreate,
      startGroupEdit,
      startSubGroupCreate,
      startMoveGroup,
      setParentGroupId,
      onExpand,
      onGroupEdit,
      onGroupSelect,
      onGroupDelete,
      copyMDMConfigToClipboard
    }
  }

})
</script>
<style lang="less">
@import "../styles/variables.less";

.groups-sidebar {
  .menu-block-title {
    color: rgba(255, 255, 255, 0.65);
    display: inline-block;
    margin-top: 8px;
    padding-bottom: 4px;
  }
  .ant-input-search {
    input {
      background-color: transparent;
      border-color: @menu-dark-item-active-bg;
      color: @menu-item-color;
      &::placeholder {
        color: @menu-item-color;
      }
    }

    .ant-input-group-addon {
      button {
        background: transparent;
        border-color: @menu-dark-item-active-bg;
        border-left-color: transparent !important;
        color: @menu-item-color !important;
      }

      background: transparent;
      border-color: @menu-dark-item-active-bg;
    }
    .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
      border-color: @menu-dark-item-active-bg;
    }
  }
  .logo-container {
    margin: 0 16px;
    padding: 0;
    border-bottom: solid 1px rgba(184, 184, 184, 0.2);
    background-color: transparent;
    .logo {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        max-width: 80px;
        max-height: 52px;
      }
    }
  }
  .ant-layout-footer {
    padding: 16px;

  }

  .groups-add {
    .ant-menu-item {
      padding: 0 6px;
      margin-top: -6px;
    }
  }

  .groups-sidebar-tree {
    .ant-tree-treenode-motion {
      width: 100%;
    }
    .ant-tree-treenode {
      width: 100%;
      padding-right: 8px;
      .ant-tree-node-content-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        .ant-tree-title {
          flex: 1;
          justify-content: space-between;
          align-items: center;
          display: flex;
          .anticon {
            visibility: hidden;
          }
          &:hover {
            .anticon {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}
.search-value {
  color: var(--ant-primary-color);
}

</style>
