<script>
import { defineComponent, inject } from 'vue'
import { PictureFilled } from '@ant-design/icons-vue'
import predefinedLayouts from '@/components/cEngineEditor/predefinedLayouts'
import DropdownComponent from '@/components/cEngineEditor/DropdownComponent.vue'

export default defineComponent({
  name: 'LayoutPickerDropdown',
  components: {
    DropdownComponent,
    PictureFilled
  },
  props: {},
  emits: ['add-page', 'close'],
  setup (_, {emit}) {
    const service = inject('cEngineService')

    const closeDropdown = () => {
      emit('close')
    }
    const addPage = (layout) => {
      service.addPage(layout)
      closeDropdown()
    }


    return {
      pagesTemplates: predefinedLayouts,
      addPage,
      closeDropdown
    }
  }
})

</script>

<template>
  <div class="layout-picker-dropdown">
    <DropdownComponent
      title="Choose a layout"
      @close="closeDropdown"
    >
      <template #content>
        <div class="layout-list">
          <div
            v-for="(layout, index) in pagesTemplates"
            :key="index"
            class="layout"
            @click="addPage(layout)"
          >
            <div
              v-for="(row, rowIndex) in layout.rows"
              :key="rowIndex"
              class="layout-row"
            >
              <div
                v-for="(column, columnIndex) in row.columns"
                :key="columnIndex"
                class="layout-column"
                :style="{flex: column.width}"
              >
                <template
                  v-for="(element, elementIndex) in column.elements"
                  :key="elementIndex"
                >
                  <template v-if="element.type === 'text'">
                    <div
                      class="text"
                      :class="element.alignment"
                    >
                      <div
                        v-for="(item, itemIndex) in element.items"
                        :key="itemIndex"
                        :class="item.style"
                      >
                        {{ item.text }}
                      </div>
                    </div>
                  </template>
                  <template v-if="element.type === 'media'">
                    <div class="background-icon">
                      <PictureFilled />
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DropdownComponent>
  </div>
</template>

<style scoped lang="less">
.layout-picker-dropdown {
  .layout-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    .layout {
      aspect-ratio: 1.77/1;
      color: #000;
      cursor: pointer;
      display: flex;
      gap: 2px;
      .layout-row {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 2px;
        .layout-column {
          flex: 1;
          padding: 4px;
          border-radius: 4px;
          background: linear-gradient(0deg, rgba(105, 100, 100, 0.1), rgba(105, 100, 100, 0.1)),
          linear-gradient(0deg, #FFFFFF, #FFFFFF);
          width: 100%;
          height: 100%;
          line-height: 1.2;
          position: relative;
          .text {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            &.center {
              align-items: center;
              justify-content: center;
            }

            .heading {
              font-size: 7px;
              font-weight: bold;
            }

            .paragraph {
              font-size: 7px;
              opacity: .5;
            }
          }

          .background-icon {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            z-index: 1;
            font-size: 16px;
          }
        }
      }

      &:hover {
        .layout-row .layout-column {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
