<script setup>
import moment from 'moment'
import { useStore } from 'vuex'
import { computed } from 'vue'
const store = useStore()

const freeDevicesCoupon = computed(() => store.getters['subscription/freeDevicesCoupon'])
const freeDevicesNumber = computed(()=> freeDevicesCoupon.value?.freeDevicesCount)
const deactivationDate = computed(()=> moment(freeDevicesCoupon.value?.deactivationAt).format('MMM D YYYY'))
const deactivationDateToday = computed(()=> moment(freeDevicesCoupon.value?.deactivationAt).isSame(moment(), 'day'))
</script>

<template>
  <a-typography-text
    v-if="freeDevicesCoupon"
    type="secondary"
  >
    <template v-if="!deactivationDateToday">
      {{ $t('components.subscriptionSettingsInfoCardFreeDevices.freeDevices', {count: freeDevicesNumber, date: deactivationDate}, freeDevicesNumber) }}
    </template>
    <template v-else>
      {{ $t('components.subscriptionSettingsInfoCardFreeDevices.freeDevicesExpToday', {count: freeDevicesNumber}, freeDevicesNumber) }}
    </template>
  </a-typography-text>
</template>

<style scoped lang="less">

</style>
