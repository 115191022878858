<script setup>

import dayjs from 'dayjs'
import { computed, ref, watchEffect } from 'vue'

const props = defineProps({
  timeFrom: String,
  timeTo: String,
  isAmPm: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update'])

// const formatEventTimeToPickerTime = (from, to) => {
//   if (!from || !to) return []
//   const fromTime = dayjs(from+':00', 'HH:mm:ss')
//   const toTime = dayjs(to+':00', 'HH:mm:ss')
//   return [fromTime, toTime]
// }

// Helper function to format time correctly based on 12-hour or 24-hour format
const formatEventTimeToPickerTime = (from, to, isAmPm) => {
  if (!from || !to) return []

  // Use the correct format based on the isAmPm flag
  const timeFormat = isAmPm ? 'hh:mm A' : 'HH:mm'
  const fromTime = dayjs(from+':00', 'HH:mm:ss')
  const toTime = dayjs(to+':00', 'HH:mm:ss')

  return [fromTime, toTime]
}

const eventTimePickerFormat = ref([])

const setEventTime = (timeFrom, timeTo, isAmPm) => {
  return formatEventTimeToPickerTime(timeFrom, timeTo, isAmPm)
}

const handleChange = (value) => {
  eventTimePickerFormat.value = value
  const timeFrom = value[0].format('HH:mm')
  const timeTo = value[1].format('HH:mm')
  emit('update', { timeFrom, timeTo})
}

watchEffect(() => {
  if (props.timeFrom && props.timeTo) {
    eventTimePickerFormat.value = setEventTime(props.timeFrom, props.timeTo, props.isAmPm)
  }
})

</script>

<template>
  <a-time-range-picker
    v-model:value="eventTimePickerFormat"
    :style="{width: `${isAmPm ? 200 : 160 }px`, marginTop: '4px'}"
    :allow-clear="false"
    :use12-hours="isAmPm"
    type="time"
    :format="isAmPm ? 'hh:mm A' : 'HH:mm'"
    @change="handleChange"
  />
</template>

<style scoped lang="less">

</style>
