<script setup>
import InfoHeader from '@/components/InfoHeader.vue'
import { useStore } from 'vuex'
const store = useStore()
const openUpgradeSubscriptionModal = () => {
  store.dispatch('handleUpdateSubscription')
}
</script>

<template>
  <InfoHeader type="danger">
    <i18n-t
      keypath="components.inactiveSubscriptionHeader.subscriptionSuspendedText"
      tag="label"
      for="components.inactiveSubscriptionHeader.subscriptionSuspendedLink"
    >
      <a
        class="upgrade-link"
        @click="openUpgradeSubscriptionModal"
      >{{ $t('components.inactiveSubscriptionHeader.subscriptionSuspendedLink') }}</a>
    </i18n-t>
  </InfoHeader>
</template>

<style scoped lang="less">
.upgrade-link{
  color: white;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
</style>
