<script>
import { computed, defineComponent, inject, ref } from 'vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'PagesSidebar',
  components: { draggable },
  setup () {

    const service = inject('cEngineService')
    const pages = ref(service.pages)
    const activePageIndex = computed(()=>service.activePageIndex.value)
    const onOrderChange = (event) => {
      service.changePagesOrder(event.moved?.newIndex, event.moved?.oldIndex)
    }

    const onPageDelete = (index) => {
      service.deletePage(index)
    }

    const onPageSelect = (index) => {
      service.selectPage(index)
    }

    return {
      pages,
      activePageIndex,
      onOrderChange,
      onPageDelete,
      onPageSelect
    }
  }
})

</script>

<template>
  <draggable
    :list="pages"
    class="pages"
    tag="div"
    ghost-class="ghost"
    :item-key="page => page.id"
    @change="onOrderChange"
  >
    <template #item="{ element: page, index }">
      <div
        :key="page.id"
        class="page"
        :class="{active: activePageIndex === index}"
        @click="onPageSelect(index)"
      >
        <a-dropdown :trigger="['contextmenu']">
          <div>
            <div
              class="number"
            >
              {{ index + 1 }}
            </div>
            <div class="icon" />
          </div>
          <template #overlay>
            <a-menu>
              <a-menu-item
                key="1"
                @click="onPageDelete(index)"
              >
                Delete page
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </template>
  </draggable>
</template>

<style scoped lang="less">
.pages {
  flex: 1;
  padding: 32px 24px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .page {
    cursor: pointer;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    gap: 8px;
    .ant-dropdown-trigger {
      display: flex;
      width: 100%;
      height: 100%;
      .number {
        font-size: 14px;
        width: 20px;
      }
      .icon {
        flex: 1;
        background-color: #4F4F4F;
        border-radius: 8px;
        height: 42px;
      }
    }
    &:hover, &.active {
      background-color: #f5f5f5;
      .ant-dropdown-trigger {
        .number {
          font-weight: bold;
        }
        .icon {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
