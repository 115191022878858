<template>
  <div
    class="thumbnails-list-item"
    :class="{selectable}"
    @click="$emit('onSelect')"
  >
    <div class="thumbnails-image-wrapper">
      <a-image
        v-if="thumbnail"
        :preview="false"
        :src="thumbnail"
      />
      <div
        v-else-if="isUploading"
        class="thumbnail-placeholder"
      >
        <SyncOutlined spin />
      </div>
      <div
        v-else
        class="thumbnail-placeholder"
      >
        <PictureOutlined />
      </div>
      <div
        v-if="!!onMediaRemove"
        class="remove-item"
        @click="onMediaRemove()"
      >
        <a-tooltip title="Remove">
          <a-button
            shape="circle"
            size="small"
            type="default"
          >
            <template #icon>
              <DeleteOutlined />
            </template>
          </a-button>
        </a-tooltip>
      </div>
    </div>
    <a-tooltip :title="fullName">
      <div class="thumbnail-name">
        <span class="thumbnail-name-ellipsis">
          {{ name }}
        </span>
        <span
          v-if="extension"
          class="thumbnail-name-ext"
        >
          .{{ extension }}
        </span>
      </div>
      <div
        v-if="uploading"
        class="thumbnail-progress"
      >
        {{ progress }}%
      </div>
    </a-tooltip>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThumbnailsItem',
  props: {
    thumbnail: String,
    isUploading: Boolean,
    noPreview: Boolean,
    selectable: Boolean,
    onMediaRemove: Function,
    fullName: String,
    name: String,
    extension: String,
    uploading: Boolean,
    progress: String
  },
  emits: ['onSelect'],
  setup () {
    return {}
  }
})
</script>

<style lang="less" scoped>
  .thumbnails-list-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &.selectable:hover {
      .thumbnails-image-wrapper {
        border-color: var(--ant-primary-color);
      }
    }

    .thumbnails-image-wrapper {
      height: 60px;
      border: solid 2px #f0f0f0;
      border-radius: 3px;
      background-color: #fff;
      overflow: hidden;

      .thumbnail-placeholder {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    .thumbnail-name {
      display: flex;

      .thumbnail-name-ellipsis {
        flex: 0 1 auto;
        flex-shrink: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .thumbnail-name-ext {
        flex: 1 0 auto;
      }
    }

    &[draggable="true"] {
      cursor: grabbing;

      :deep(.ant-image) {
        cursor: grab;
        .ant-image-mask {
          cursor: grabbing;
        }
      }
    }

    &:hover {
      .remove-item {
        display: block;

        &:hover {
        }
      }
    }

    .remove-item {
      cursor: pointer;
      position: absolute;
      right: 4px;
      top: 4px;
      display: none;
    }

    :deep(.ant-image) {
      display: flex;
      justify-content: center;
      background-color: #fff;

      :deep(.ant-image-mask) {
        cursor: grab;
      }

      :deep(.ant-image-img) {
        max-height: 60px;
        object-fit: cover;
      }
    }
  }
</style>
