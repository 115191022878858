import ApolloClient from '@/apollo'
import {
  LIST_EXTERNAL_API_KEYS
} from '@/graphql/queries'
import {
  CREATE_EXTERNAL_API_KEY,
  DELETE_EXTERNAL_API_KEY, RESOLVE_EXTERNAL_API_KEY_VALUE, UPDATE_EXTERNAL_API_KEY
} from '@/graphql/mutations'
import { handleError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    apiKeysList: [],
    apiKeysLoading: false
  },
  actions: {
    async getApiKeysList ({ commit, state }) {
      state.apiKeysLoading = true
      try {
        const { data: { listExternalApiKeys } } = await ApolloClient.query({ query: LIST_EXTERNAL_API_KEYS, fetchPolicy: 'no-cache' })
        commit('SET_API_KEYS_LIST', listExternalApiKeys)
      } catch (e) {
        handleError(e)
      } finally {
        state.apiKeysLoading = false
      }
    },
    async createApiKey ({ dispatch, state }, { name }) {
      state.apiKeysLoading = true
      try {
        await ApolloClient.mutate({ mutation: CREATE_EXTERNAL_API_KEY, variables: { input: { name } } })
        dispatch('getApiKeysList')
      } catch (e) {
        state.apiKeysLoading = false
        handleError(e)
      }
    },
    async resolveApiKeyValue ({ dispatch, state }, { id }) {
      state.apiKeysLoading = true
      try {
        const { data: { resolveExternalApiKeyValue } } = await ApolloClient.mutate({ mutation: RESOLVE_EXTERNAL_API_KEY_VALUE, variables: { externalApiKeyId: id } })
        return resolveExternalApiKeyValue
      } catch (e) {
        handleError(e)
      } finally {
        state.apiKeysLoading = false
      }
    },
    async deleteApiKey ({ dispatch, state }, { id }) {
      state.apiKeysLoading = true
      try {
        await ApolloClient.mutate({ mutation: DELETE_EXTERNAL_API_KEY, variables: { id } })
        dispatch('getApiKeysList')
      } catch (e) {
        state.apiKeysLoading = false
        handleError(e)
      }
    },
    async updateApiKey ({ dispatch, state }, { id, name }) {
      state.apiKeysLoading = true
      try {
        await ApolloClient.mutate({ mutation: UPDATE_EXTERNAL_API_KEY, variables: { id, input: { name } } })
        dispatch('getApiKeysList')
      } catch (e) {
        state.apiKeysLoading = false
        handleError(e)
      }
    }
  },
  getters: {
    apiKeysList: state => state.apiKeysList,
    apiKeysLoading: state => state.apiKeysLoading
  },
  mutations: {
    SET_API_KEYS_LIST (state, apiKeysList) {
      state.apiKeysList = apiKeysList
    },
  }
}
