<script>
import { defineComponent, inject, ref } from 'vue'
import { TextElement } from '@/components/cEngineEditor/helpers'
import RichText from '@/components/richText/RichText.vue'

export default defineComponent({
  name: 'PageEditorTextElement',
  components: {
    RichText
  },
  props: {
    element: {
      type: TextElement,
      default: () => null
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const service = inject('cEngineService')
    const selectedItem = service.activeItem
    const editorRef = ref(null)

    const handleClick = () =>{
      service.selectElement(props.element)
    }

    const handleUpdate = (e) => {
      console.log(editorRef.value?.editor)
    }

    const setContent = () => {
      if (editorRef.value) {
        editorRef.value.editor.commands.setContent({
          type: "doc",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "Example Text",
                },
              ],
            },
          ],
        });
      }
    }

    return {
      editorRef,
      selectedItem,
      handleUpdate,
      handleClick,
      setContent
    }
  }
})
</script>

<template>
  <div
    class="text-element"
    :class="{[element.alignment]: true, active: isActive}"
    :style="{['--fc']: element.fontColor}"
  >
    <div class="text-element-wrapper">
      <RichText
        ref="editorRef"
        :on-update="handleUpdate"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<style scoped lang="less">
.text-element {
  --fc: #000;
  width: 100%;
  height: 100%;
  padding: 12px;
  z-index: 100;
  color: var(--fc);
  .text-element-wrapper {
    padding: 12px;
    border-radius: 16px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .text-row {
      &.heading {
        font-size: 32px;
        font-weight: bold;
      }
      &.paragraph {
        font-size: 16px;
        font-weight: normal;
      }
      &.selected {
        border-radius: 4px;
        outline-offset: 4px;
        outline: 2px solid var(--ant-primary-color);

      }
    }
  }
  &.active {
    .text-element-wrapper {
      box-shadow: 0 0 2px 1px var(--ant-primary-color);
    }
  }
  &.topLeft {
    .text-element-wrapper {
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
    }
  }
  &.topCenter {
    .text-element-wrapper {
      align-items: center;
      justify-content: flex-start;
      text-align: center;
    }
  }
  &.topRight {
    .text-element-wrapper {
      align-items: flex-end;
      justify-content: flex-start;
      text-align: right;
    }
  }
  &.middleLeft {
    .text-element-wrapper {
      align-items: flex-start;
      justify-content: center;
      text-align: left;
    }
  }
  &.middleCenter {
    .text-element-wrapper {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  &.middleRight {
    .text-element-wrapper {
      align-items: flex-end;
      justify-content: center;
      text-align: right;
    }
  }
  &.bottomLeft {
    .text-element-wrapper {
      align-items: flex-start;
      justify-content: flex-end;
      text-align: left;
    }
  }
  &.bottomCenter {
    .text-element-wrapper {
      align-items: center;
      justify-content: flex-end;
      text-align: center;
    }
  }
  &.bottomRight {
    .text-element-wrapper {
      align-items: flex-end;
      justify-content: flex-end;
      text-align: right;
    }
  }

}

</style>
