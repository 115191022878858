<template>
  <a-button
    :disabled="!parentDirectoryMediaId"
    :style="{margin: padded ? '0 0 0 16px': '0 0 16px 0'}"
    @click="goBack"
  >
    <template #icon>
      <RollbackOutlined />
    </template>
    {{ $t('components.mediaTable.backButtonText') }}
  </a-button>
  <MediaTableExpirationModal
    v-model:open="showExpirationModal"
    :options="expirationModalSettings.autoDeleteOptions"
    :media-ids="expirationModalSettings.mediaIds"
    @save="updateMediaExpirationOptions"
  />
  <a-table
    class="storage-table"
    size="small"
    :scroll="{ x: 600, y: 500 }"
    :loading="loading"
    :class="{'table-padded':padded}"
    :data-source="tableMediaList"
    :columns="columns"
    :row-selection="rowSelection"
    :custom-row="customRow"
    :row-class-name="rowClassName"
    @change="handleTableChange"
    @resize-column="handleResizeColumn"
  >
    <template #headerCell="{ column }">
      <template v-if="column.key">
        {{ $t(`components.mediaTable.${column.key}`) }}
      </template>
    </template>
    <template #bodyCell="{ column, record, text }">
      <template v-if="column.dataIndex === 'actions' && record.mimetype !== 'BACK'">
        <div class="actions">
          <a-tooltip>
            <template #title>
              {{ $t('components.mediaTable.expirationSettingsTooltipTitle') }}
            </template>
            <a-button
              @click.stop="openExpirationModal({ mediaId: record.id, autoDeleteOptions: record.autoDeleteOptions })"
            >
              <template #icon>
                <CalendarOutlined />
              </template>
            </a-button>
          </a-tooltip>
          <a-tooltip v-if="showMove">
            <template #title>
              {{ $t('components.mediaTable.moveTooltipTitle') }}
            </template>
            <a-button
              @click.stop="handleFileMove(record.id)"
            >
              <template #icon>
                <ExportOutlined />
              </template>
            </a-button>
          </a-tooltip>
          <a-tooltip>
            <template #title>
              {{ $t('components.mediaTable.deleteTooltipTitle') }}
            </template>
            <a-popconfirm
              placement="left"
              :title="$t(`components.mediaTable.${record.isFolder ? 'popConfirmDeleteFolder' : 'popConfirmDeleteFile'}`)"
              :ok-text="$t('components.mediaTable.popConfirmOkText')"
              :cancel-text="$t('components.mediaTable.popConfirmCancelText')"
              @confirm="deleteFile(record.id)"
            >
              <a-button @click.stop>
                <template #icon>
                  <DeleteOutlined style="font-size: 12px" />
                </template>
              </a-button>
            </a-popconfirm>
          </a-tooltip>
        </div>
      </template>
      <template v-if="column.dataIndex === 'createdAt'">
        {{ formatDate(text) }}
      </template>
      <template v-if="column.dataIndex === 'autoDeleteOptions'">
        {{ text?.deleteAt ? formatDayJSDate(text.deleteAt) : $t('components.mediaTable.noExpiration') }}
      </template>
      <template v-if="column.dataIndex === 'size'">
        <span v-if="record.progress !== null">{{ text ? `${formatFileSize(text)} - ` : '' }} {{ record.progress }}%</span>
        <span v-else-if="!text && !record.isFolder">Processing</span>
        <span v-else>{{ formatFileSize(text) }}</span>
      </template>
      <template v-if="column.dataIndex === 'name'">
        <template v-if="record.isFolder">
          <a
            class="table-link"
            @click.prevent.stop="openFolder(record.id)"
          >
            {{ text }}
          </a>
        </template>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <template v-if="column.dataIndex === 'url'">
        <a-image
          v-if="!record.isFolder && record.hasThumbnail"
          :key="mediaRetryState[record.id]?.key || record.id"
          :height="40"
          :src="record.thumbnail"
          :preview="{src: record.source}"
          @error="handleImageError(record, 5)"
          @click.stop.prevent
        />
        <a-spin
          v-else-if="!record.isFolder && !record.hasThumbnail"
          spinning
        >
          <div class="img-spacer" />
        </a-spin>
        <div
          v-else
          class="img-spacer"
        />
      </template>
      <template v-if="column.dataIndex === 'mimetype'">
        <span />
        <a-tooltip v-if="record.mimetype === 'DIRECTORY'">
          <template #title>
            Go to folder
          </template>
          <FolderFilled
            style="font-size: 18px; cursor: pointer"
            @click.prevent.stop="openFolder(record.id)"
          />
        </a-tooltip>
        <PictureOutlined
          v-else-if="record.isImage"
          style="font-size: 18px;"
        />
        <YoutubeOutlined
          v-else-if="record.isVideo"
          style="font-size: 18px;"
        />
        <FileTextOutlined
          v-else-if="record.isDocument"
          style="font-size: 18px;"
        />
      </template>
    </template>
  </a-table>
</template>

<script>
import { computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { error, formatDate, formatFileSize, success } from '@/utils'
import {
  DeleteOutlined,
  ExportOutlined,
  FileTextOutlined,
  FolderFilled,
  PictureOutlined,
  RollbackOutlined,
  YoutubeOutlined,
  CalendarOutlined
} from '@ant-design/icons-vue'
import { ACCEPTED_MIME_TYPES, MEDIA_TYPES } from '@/constants'
import { useStore } from 'vuex'
import { SlideAsset } from '@/helpers/Slides'
import MediaTableExpirationModal from '@/components/tables/mediaTable/MediaTableExpirationModal.vue'
import dayjs from 'dayjs'

const SOURCE_TYPE = 'WORKSPACE_MEDIA'

export default defineComponent({
  name: 'MediaTable',
  components: {
    MediaTableExpirationModal,
    FolderFilled,
    RollbackOutlined,
    DeleteOutlined,
    PictureOutlined,
    YoutubeOutlined,
    FileTextOutlined,
    ExportOutlined,
    CalendarOutlined
  },
  props: {
    padded: Boolean,
    filter: Object,
    visible: Boolean,
    showMove: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: String,
      default: null
    },
    selection: {
      type: String,
      validator (value) {
        return ['single', 'multiple', 'none'].indexOf(value) !== -1
      },
      default: 'multiple'
    },
    folderSelectDisabled: Boolean,
    excludeHeadings: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      validator (value) {
        return value.every(t =>MEDIA_TYPES.includes(t))
      }
    }
  },
  emits: ['select', 'move', 'change-folder', 'update:parent-id'],
  setup (props, { emit }) {
    const store = useStore()
    const currentPath = ref([])
    const folderNameInputRef = ref()
    const showExpirationModal = ref(false)
    const expirationModalSettings = reactive({
      mediaIds: null,
      autoDeleteOptions: null
    })
    const sortedInfo = ref({columnKey: null, order: null})
    const currentGroupId = computed(() => store.getters['groups/currentGroupId'])
    const selectedMediaIds = computed(() => store.getters['media/selectedMediaIds'])
    const subscribed = computed(() => store.getters['media/subscribed'])
    const parentDirectoryMediaId = computed(() => store.getters['media/parentDirectoryMediaId'])
    const uploadingQueue = computed(() => store.getters['media/uploadingQueue'])
    const mediaList = computed(() => store.getters['media/mediaList'])
    const loading = computed(() => store.getters['media/mediaListLoading'])
    const selectedKeys = ref(selectedMediaIds.value || [])
    const supportedTypes = ref(props.types ? [...props.types, 'DIRECTORY'] : [...MEDIA_TYPES])
    const mediaRetryState = reactive({})
    const { deleteMedia, updateMediaDeleteOptions } = inject('mediaService')

    const tableMediaList = computed(() => {
      return mediaList.value?.filter(m => !m.tag)
        .filter(({ mimetype }) => supportedTypes.value?.includes(mimetype.split('_')[0]) ?? true)
        .filter(m => props.filter ? m[props.filter?.dataIndex].toLowerCase().includes(props.filter?.value.toLowerCase()) : true)
        .map(({ mimetype, generatedMedia, createdAt, type, size, id, isUploaded, ...other }) => {
          const isFolder = type === 'DIRECTORY'
          let progress = null
          if (uploadingQueue.value?.[id]) {
            progress = uploadingQueue.value[id].progress
            size = uploadingQueue.value[id].size
          }
          const thumbnailUrl = generatedMedia?.find(({ tag }) => tag === 'thumbnail')
          const thumbnail = thumbnailUrl?.url || null
          const isImage = mimetype.startsWith('IMAGE')
          const isVideo = mimetype.startsWith('VIDEO')
          const isDocument = mimetype.startsWith('DOCUMENT')
          const source = isImage ? generatedMedia?.find(({ tag }) => tag === 'content')?.url : thumbnail
          const createdAtSeconds = +moment(createdAt)
          const hasThumbnail = isFolder || (!!thumbnail && isUploaded)
          const disabled = isFolder && props.folderSelectDisabled
          return { key: id, type, createdAtSeconds, disabled, isFolder, size, progress, thumbnail, source, hasThumbnail, generatedMedia, createdAt, id, mimetype, isImage, isVideo, isDocument, ...other }
        }).sort((a, b) => {
          if (a.isFolder && !b.isFolder) return -1
          if (!a.isFolder && b.isFolder) return 1
          return a.name.localeCompare(b.name)
        })
    })

    const hasAllThumbnails = computed(() => {
      return tableMediaList.value?.every(({ hasThumbnail }) => hasThumbnail)
    })

    const rowSelection = computed(() => {
      if (props.selection === 'multiple') {
        return {
          onChange: onSelectChange,
          selectedRowKeys: selectedKeys.value,
          getCheckboxProps: record => ({
            disabled: record.disabled
          })
        }
      }
      return null
    })

    const columns = computed(() => {
      const sorted = sortedInfo.value || {columnKey: null, order: null}
      return [{
        title: '',
        dataIndex: 'mimetype',
        align: 'center',
        width: 32,
        minWidth: 32,
        maxWidth: 32,
        fixed: 'left'
      }, {
        title: 'File Name',
        dataIndex: 'name',
        key: "name",
        resizable: true,
        ellipsis: true,
        width: 350,
        maxWidth: 800,
        fixed: 'left',
        sorter: {
          compare: (a, b) => {
            return a.name.localeCompare(b.name);
          }
        },
        sortOrder: sorted.columnKey === 'name' && sorted.order
      }, {
        title: 'Created Date',
        dataIndex: 'createdAt',
        key: "createdAt",
        width: 200,
        minWidth: 100,
        maxWidth: 400,
        sorter: {
          compare: (a, b) => a.createdAtSeconds - b.createdAtSeconds
        },
        defaultSortOrder: 'descend',
        sortOrder: sorted.columnKey === 'createdAt' && sorted.order
      }, {
        title: 'Preview',
        key: "url",
        dataIndex: 'url',
        width: 100,
        minWidth: 100,
        maxWidth: 110
      }, {
        title: 'Expires',
        key: "autoDeleteOptions",
        dataIndex: 'autoDeleteOptions',
        width: 100,
        minWidth: 100,
        maxWidth: 110
      }, {
        title: 'Size',
        dataIndex: 'size',
        key: "size",
        width: 120,
        minWidth: 120,
        maxWidth: 300,
        sorter: {
          compare: (a, b) => (a.size || 0) - (b.size || 0)
        },
        sortOrder: sorted.columnKey === 'size' && sorted.order
      }, {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        width: 120,
        minWidth: 120,
        maxWidth: 120
      }].filter(c => !props.excludeHeadings.includes(c.dataIndex))
    })

    onMounted(() => {
      getMediaList()
    })

    onUnmounted(() => {
      unsubscribeFromMediaList()
    })

    const getMediaList = async () => {
      return store.dispatch('media/getMediaList', props.groupId)
    }

    const openExpirationModal = ({ mediaId, autoDeleteOptions }) => {
      expirationModalSettings.mediaIds = [mediaId]
      expirationModalSettings.autoDeleteOptions = autoDeleteOptions
      showExpirationModal.value = true
    }

    const closeExpirationModal = () => {
      expirationModalSettings.mediaIds = null
      expirationModalSettings.autoDeleteOptions = null
      showExpirationModal.value = false
    }

    const updateMediaExpirationOptions = ({ mediaIds, autoDeleteOptions }) => {
      updateMediaDeleteOptions({ ids: mediaIds, autoDeleteOptions }).then(()=>{
        closeExpirationModal()
        success()
      })
      .catch(e => {
        error(e.message)
      })
    }

    const openFolder = (id) => {
      currentPath.value.push(id)
      emit('change-folder')
      unsubscribeFromMediaList()
      setParentDirectoryMediaId(id)
    }

    const goBack = () => {
      currentPath.value.pop()
      unsubscribeFromMediaList()
      setParentDirectoryMediaId()
      emit('change-folder')
    }

    const setParentDirectoryMediaId = () => {
      store.commit('media/SET_PARENT_DIRECTORY_MEDIA_ID', currentPath.value.at(-1) || null)
    }

    const deleteFile = (id) => {
      deleteMedia(
        { payload: { id },
          success: () => {
            store.commit('media/REMOVE_SELECTED_MEDIA_BY_ID', id)
            success()
          },
          error
        })
    }

    const onSelectChange = (selection) => {
      selection = selection.filter(id=>{
        const media = tableMediaList.value.find(m => m.id === id)
        return !media.isVideo || (media.size && media.progress === null)
      })
      selectedKeys.value = cloneDeep(selection)
      selectMedia(selection)
    }

    const selectMedia = (selection = []) => {
      const selected = selection?.map(id => {
        const { thumbnail, name, metadata, source } = tableMediaList.value.find(m => m.id === id) || {}
        return new SlideAsset({
          id,
          name,
          thumbnail,
          metadata,
          source,
          mediaReference: {
            sourceType: SOURCE_TYPE,
            workspaceMediaId: id
          }
        })
      })
      store.commit('media/SET_SELECTED_MEDIA', selected)
    }

    const customRow = (record) => {
      if (props.selection === 'single') {
        return {
          onClick: () => {
            if (record.isFolder || (record.isVideo && (!record.size || record.progress !== null))) return
            selectedKeys.value = [record.id]
            selectMedia([record.id])
          }
        }
      } else if (props.selection === 'multiple') {
        return {
          onClick: () => {
            if (record.isFolder || (record.isVideo && (!record.size || record.progress !== null))) return
            if (!selectedKeys.value?.includes(record.id)) {
              selectedKeys.value.push(record.id)
            } else {
              selectedKeys.value = selectedKeys.value.filter(r => r !== record.id)
            }
            selectMedia(cloneDeep(selectedKeys.value))
          }
        }
      }
      return null
    }

    const rowClassName = (record) => {
      const className = (props.selection === 'single' || props.selection === 'multiple') && !record.isFolder ? 'selectable ' : ''
      return selectedKeys.value?.includes(record.id) ? className + 'ant-table-row-selected selected' : className
    }

    const subscribeToMediaList = () => {
      if (subscribed.value) return
      return store.dispatch('media/subscribeToMediaList', parentDirectoryMediaId.value)
    }

    const unsubscribeFromMediaList = () => {
      if (!subscribed.value) return
      return store.commit('media/UNSUBSCRIBE_FROM_MEDIA')
    }

    const setTableSortByCreateDate = () => {
      sortedInfo.value = {
        order: 'descend',
        columnKey: 'createdAt',
      };
    }

    const formatDayJSDate = (date) => {
      return dayjs(date).utc().format('MMM DD YYYY')
      // return dayjs(date).format('MMM DD YYYY HH:mm')
    }

    const handleFileMove = (id) => {
      selectMedia([id])
      emit('move')
    }

    const handleTableChange = (pagination, filters, sorter) => {
      sortedInfo.value = sorter.order ? sorter : { columnKey: null, order: null };
    }

    const handleImageError = (record, maxRetries = 3, delay = 500) => {
      if (!mediaRetryState[record.id]) {
        mediaRetryState[record.id] = {
          retryCount: 0,
          key: record.id
        }
      }
      const state = mediaRetryState[record.id];
      if (state.retryCount < maxRetries) {
        state.retryCount += 1;
        setTimeout(() => {
          state.key = `${record.id}-${state.retryCount}`
        }, delay * state.retryCount)
      }
    }

    watch(hasAllThumbnails, (value) => {
      if (!value) {
        subscribeToMediaList()
      } else {
        unsubscribeFromMediaList()
      }
    })

    watch(loading, (value) => {
      !value && !hasAllThumbnails.value && !subscribed.value && subscribeToMediaList()
    })

    watch(currentGroupId, () => {
      currentPath.value = []
    })

    watch(selectedMediaIds, (mediaIds) => {
      selectedKeys.value = mediaIds
    })

    watch(parentDirectoryMediaId, () => {
      getMediaList()
    })

    watch(() => props.groupId, () => {
      selectedKeys.value = []
      unsubscribeFromMediaList()
      getMediaList()
    })

    return {
      columns,
      ACCEPTED_MIME_TYPES,
      selectedKeys,
      tableMediaList,
      folderNameInputRef,
      loading,
      rowSelection,
      uploadingQueue,
      parentDirectoryMediaId,
      mediaRetryState,
      showExpirationModal,
      expirationModalSettings,
      rowClassName,
      customRow,
      openFolder,
      deleteFile,
      goBack,
      formatDate,
      formatDayJSDate,
      formatFileSize,
      onSelectChange,
      setTableSortByCreateDate,
      openExpirationModal,
      closeExpirationModal,
      updateMediaExpirationOptions,
      handleFileMove,
      handleTableChange,
      handleImageError,
      handleResizeColumn: (w, col) => {
        col.width = w
      }
    }
  },
  computed: {
    dayjs () {
      return dayjs
    }
  }

})
</script>

<style lang="less">
@import "../../styles/variables.less";
.actions {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  > span {
    cursor: pointer;
  }
}
.storage-table {
  .ant-image {
    width: 100%;
    cursor: pointer;
    .ant-image-img {
      height: inherit;
      object-fit: cover;
    }
  }
  .ant-table-row  {
    &.selectable {
      cursor: pointer;
    }
    &.selected {
      .ant-table-cell {
        background-color: var(--ant-primary-1);
      }
    }
  }
  .table-link {
    color: @text-color;
  }
  .img-spacer {
    height: 40px;
  }
}

</style>
