import { ApolloClient, from, InMemoryCache, split } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import store from '@/store'
import { setContext } from '@apollo/client/link/context'



const getAccessToken = () => {
  const token = store.getters['auth/token']
  return token ? `Bearer ${token}` : ''
}

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) {
    if (networkError.statusCode === 401) {
      store.dispatch('auth/resetUser')
    }
    if (networkError.statusCode === 400) {
      console.log('Something happened')
    }
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, path }) => {
      if (extensions?.code === 'BAD_TOKEN' && !path.includes('logoutUser')) {
        store.dispatch('auth/resetUser')
      }
      // else if (ERROR_CODES.includes(extensions?.code)) {
      //   error(i18n.global.t(`errors.codes.${extensions?.code}`))
      // }
    })
  }
})

// HTTP connection to the API
const httpLink = createUploadLink({
  uri: process.env.VUE_APP_API_URL,
  credentials: 'include'
});

let wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.VUE_APP_API_WS_URL,
    connectionParams: () => ({
      authorization: getAccessToken()
    }),
    reconnect: true
  }),
);

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' &&
      operation === 'subscription'
  },
  wsLink,
  httpLink
)

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getAccessToken()
    }
  }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, link]),
  cache
})

// TODO recreate apollo Client after logout

function recreateWsLink() {
  // Закрыть текущее соединение, если оно существует
  if (wsLink.subscriptionClient) {
    wsLink.subscriptionClient.close();
  }

  // Создать новый экземпляр GraphQLWsLink с новыми параметрами авторизации
  wsLink = new GraphQLWsLink(createClient({
    url: process.env.VUE_APP_API_WS_URL,
    connectionParams: () => ({
      authorization: getAccessToken()
    }),
    reconnect: true,
  }));

  updateApolloClientLink();
}

function updateApolloClientLink() {
  const newLink = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  );
  apolloClient.setLink(from([authLink, errorLink, newLink]));
}

export default apolloClient

export { recreateWsLink }
