<template>
  <icon>
    <template #component>
      <svg
        width="100px"
        height="100px"
        viewBox="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Cabinet-directions"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Directory/3x-Outline"
            transform="translate(-1670.000000, -410.000000)"
          >
            <g
              id="Directory-Cell-/-1-+-Description-Copy"
              transform="translate(100.000000, 360.000000)"
            >
              <g
                id="Arrow"
                transform="translate(1420.000000, 0.000000)"
              >
                <g
                  id="Icons/Arrow"
                  transform="translate(150.000000, 50.000000)"
                  fill="#FFFFFF"
                >
                  <g>
                    <polygon
                      id="Shape"
                      fill-rule="nonzero"
                      points="44.859375 58 66 78 46 78 18 50 46 22 66 22 44.859375 42 82 42 82 58"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'DirectionArrow',
  components: {
    Icon
  }
})
</script>
