<script setup>
import { computed, nextTick, ref, toRaw, watch } from 'vue'
import { CopyOutlined } from '@ant-design/icons-vue'
import { Slide } from '@/helpers/Slides'
import { success } from '@/utils'
const props = defineProps({
  slideObj: Slide,
  visible: Boolean
})

const emit = defineEmits(['update', 'update:visible'])
let slide
const slideName = ref('')
const tags = ref([])
const opened = computed(()=>props.visible)
const nameInputRef = ref(null)

const handleOk = () => {
  slide?.setTags(tags.value || null)
  slide?.setName(slideName.value || null)
  emit('update:visible', false)
  emit('update', slide.getDtoWithoutWrapper())
  resetModal()
}
const options = [
  'Sales promotion',
  'Restaurants',
  'School, K-12, EDU, Higher education',
  'Sports',
  'Active life',
  'Automotive',
  'Shopping',
  'Real estate',
  'Emergency',
  'Menu',
  'Health and Wellness',
  'Nightlife',
  'Corporate internal communications',
  'Hotels and travels',
  'Banks and financial services'
].map((tag) => ({ value: tag }))


const handleCancel = () => {
  emit('update:visible', false)
  resetModal()
}

const onTagsChange = (value) => {
  tags.value = value
}
const onTagsPaste = (evt) => {
  evt.preventDefault()
  const possibleTags = evt.clipboardData.getData('text/plain').split('|')
  tags.value = [...tags.value, ...possibleTags]
}

const resetModal = () => {
  slide = null
  slideName.value = ''
  tags.value = []
}

const copyTags = () => {
  if (!tags.value.length) return
  navigator.clipboard.writeText(tags.value.join('|'))
  success('Tags copied to clipboard')
}


watch(() => props.visible, (visible) => {
  if (!visible) return
  slide = props.slideObj
  tags.value = slide.tags || []
  slideName.value = slide.name
  nextTick(() => {
    nameInputRef.value?.focus()
  })
})

</script>

<template>
  <a-modal
    v-model:open="opened"
    :title="$t('components.slideInfoEditModal.title')"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      layout="vertical"
    >
      <a-form-item
        :label="$t('components.slideInfoEditModal.slideNameLabel')"
        name="name"
      >
        <a-input
          ref="nameInputRef"
          v-model:value="slideName"
          :placeholder="$t(`components.slideInfoEditModal.slideNamePlaceholder`)"
        />
      </a-form-item>
      <a-form-item
        :label="$t('components.slideInfoEditModal.tagsLabel')"
        name="name"
      >
        <div style="display: flex; gap: 8px;">
          <a-select
            mode="tags"
            :placeholder="$t(`components.slideInfoEditModal.tagsPlaceholder`)"
            option-label-prop="label"
            style="width: 100%;"
            max-tag-count="responsive"
            :value="tags"
            :options="options"
            @change="onTagsChange"
            @paste="onTagsPaste"
          />
          <a-tooltip :title="$t(`components.slideInfoEditModal.copyTags`)">
            <a-button @click="copyTags">
              <template #icon>
                <CopyOutlined />
              </template>
            </a-button>
          </a-tooltip>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<style scoped lang="less">

</style>
