import { i18n } from '@/i18n'

const ERROR_CODES = [
  "MANUALLY_FROZEN",
  "SUBSCRIPTION_UNPAID",
  "SUBSCRIPTION_TRIALING_ENDED",
  "FROZEN_TOO_MANY_DEVICES",
  "FROZEN_UNKNOWN",
  "FORBIDDEN",
  "BAD_REQUEST"
]

export function handleError(e, shouldRethrow = true) {
  const { graphQLErrors } = e

  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (ERROR_CODES.includes(extensions?.code)) {
        e.message = i18n.global.t(`errors.codes.${extensions?.code}`)
      }
    })
  }

  throw e
}
