<template>
  <MediaService>
    <template
      v-if="authStatus"
    >
      <OnboardingService>
        <EDUApplianceModal />
        <UpdateUserInfoModal />
        <LivePreviewModal />
        <AbsenceHandler />
        <SocialHandler />
        <InvitesHandler :invite-token="$route?.query?.inviteToken" />
        <EmailValidationHandler :email-confirmed="!!$route?.query?.['email-confirmed']" />
        <PaymentMethodUpdateHandler :success="!!$route?.query?.['payment-method-update-success']" />
        <MediaModal />
        <DevicesModal />
        <CreateWorkspaceModal />
        <GlobalSettingsModal />
        <UpdateSubscriptionModal
          v-if="workspaceLoaded && (isOwner || isAdmin) && !isEnterprize"
          :success="paymentSuccessInfo"
        />
        <UpdatePaymentMethodModal v-if="isAdmin || isOwner" />
        <CollaborationModal />
        <AuthorizedAccountsModal />
        <ContactUsModal />
        <a-layout class="full-height">
          <ForcedPlaylist
            v-if="forcedPlaylist && !groupIsLoading"
            :forced-playlist="forcedPlaylist"
            :group-id="groupId"
            :playlist-id="playlistId"
          />
          <ConditionalHeaders />
          <a-layout>
            <RootSidebar
              :notifications-visible="notificationsVisible"
              @notifications="notificationsVisible = !notificationsVisible"
            />
            <NotificationsSider v-model:visible="notificationsVisible" />
            <router-view />
          </a-layout>
        </a-layout>
      </onboardingservice>
    </template>
  </MediaService>
</template>
<script>
import { computed, createVNode, defineComponent, onMounted, ref, watch, watchEffect } from 'vue'
import RootSidebar from '@/components/rootSidebar/RootSidebar.vue'
import NotificationsSider from '@/components/siders/NotificationsSider.vue'
import { useStore } from 'vuex'
import GlobalSettingsModal from '@/components/globalSettings/GlobalSettingsModal.vue'
import { Modal } from 'ant-design-vue'
import { DEFAULT_ACCENT_COLOR } from '@/constants'
import CollaborationModal from '@/components/rootModals/collaborationModal/CollaborationModal.vue'
import { useRoute, useRouter } from 'vue-router'
import MediaService from '@/components/MediaService'
import MediaModal from '@/components/rootModals/MediaModal.vue'
import DevicesModal from '@/components/rootModals/DevicesModal.vue'
import UpdateSubscriptionModal from '@/components/updateSubscriptionModal/UpdateSubscriptionModal.vue'
import ForcedPlaylist from '@/components/ForcedPlaylist'
import UpdatePaymentMethodModal from '@/components/inputModals/UpdatePaymentMethodModal.vue'
import ContactUsModal from '@/components/ContactUsModal'
import LivePreviewModal from '@/components/LivePreviewModal'
import AbsenceHandler from '@/components/handlers/AbsenceHandler.vue'
import SocialHandler from '@/components/handlers/SocialHandler.vue'
import InvitesHandler from '@/components/handlers/InvitesHandler.vue'
import EmailValidationHandler from '@/components/handlers/EmailValidationHandler.vue'
import PaymentMethodUpdateHandler from '@/components/handlers/PaymentMethodUpdateHandler.vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment/moment'
import AuthorizedAccountsModal from '@/components/rootModals/AuthorizedAccountsModal.vue'
import CreateWorkspaceModal from '@/components/inputModals/CreateWorkspaceModal.vue'
import UpdateUserInfoModal from '@/components/inputModals/UpdateUserInfoModal.vue'
import OnboardingService from '@/components/onboarding/OnboardingService.vue'
import ConditionalHeaders from '@/components/conditionalHeaders/ConditionalHeaders.vue'
import EDUApplianceModal from '@/components/EDUApplianceModal.vue'
import { cloneDeep } from 'lodash'

export default defineComponent({
  components: {
    EDUApplianceModal,
    ConditionalHeaders,
    OnboardingService,
    UpdateUserInfoModal,
    CreateWorkspaceModal,
    AuthorizedAccountsModal,
    PaymentMethodUpdateHandler,
    EmailValidationHandler,
    InvitesHandler,
    SocialHandler,
    AbsenceHandler,
    LivePreviewModal,
    ContactUsModal,
    UpdatePaymentMethodModal,
    ForcedPlaylist,
    UpdateSubscriptionModal,
    DevicesModal,
    MediaModal,
    MediaService,
    CollaborationModal,
    GlobalSettingsModal,
    NotificationsSider,
    RootSidebar
  },
  props: {
    groupId: String,
    playlistId: String,
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const paymentSuccessInfo = ref(null)
    const notificationsVisible = ref(false)
    const showCreateWorkspaceModal = ref(false)
    const renewalModalShown = ref(!!localStorage.getItem('renewal-modal-shown') || false)
    const workspaceFavicon = computed(() => store.getters['workspace/workspaceFavicon'] || store.getters['auth/favicon'])
    const changingWorkspace = computed(() => store.getters['workspace/changingWorkspace'])
    const accentColor = computed(() => store.getters['workspace/workspaceAccentColor'])
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
    const isEnterprize = computed(() => store.getters['workspace/isEnterprize'])
    const isOwner = computed(() => store.getters['workspace/isOwner'])
    const isAdmin = computed(() => store.getters['workspace/isAdmin'])
    const workspaceLoaded = computed(() => store.getters['workspace/workspaceLoaded'])
    const defaultAccentColor = computed(() => store.getters['auth/accentColor'])
    const forcedPlaylist = computed(() => store.getters['groups/currentGroupForcedPlaylist'])
    const groupIsLoading = computed(() => store.getters['groups/currentGroupIsLoading'])
    const authStatus = computed(() => store.getters['auth/authStatus'])
    const pendingStripeSubscriptionUpdate = computed(() => store.getters['workspace/pendingStripeSubscriptionUpdate'])
    const daysBeforePendingUpdate = computed(() => {
      if (!pendingStripeSubscriptionUpdate.value) return null
      const now = moment()
      const pendingUpdateDate = moment(pendingStripeSubscriptionUpdate.value?.scheduledAtTimestampMs)
      return pendingUpdateDate.diff(now, 'days') || 1
    })
    const user = computed(() => store.getters['auth/user'])
    const isMigratedUser = computed(()=> user.value?.userCreatedBy === 'V1_MIGRATION')
    const initialQuery = ref(null)

    onMounted(async () => {
      initialQuery.value = cloneDeep(route.query)
      if (isAuthenticated.value && !authStatus.value) {
        await store.dispatch('auth/initUser')
      } else if (!authStatus.value) {
        return router.replace({
          name: 'Login',
          query: { ...route.query }
        })
      }
    })

    const updateFavicon = () => {
      const favicon = document.getElementById('favicon')
      favicon.href = workspaceFavicon.value || '/favicon.ico'
    }

    const showRenewalModal = () => {
      localStorage.setItem('renewal-modal-shown', 'true')
      return Modal.confirm({
        title: t('components.renewalModal.renewalModalTitle'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('components.renewalModal.renewalModalContent', {days: daysBeforePendingUpdate.value}),
        okText: t('components.renewalModal.renewalModalOkButtonText'),
        cancelText: t('components.renewalModal.renewalModalCancelButtonText'),
        width: '768px',
        onOk () {
          store.dispatch('openUpdateSubscription')
        }
      })
    }

    const handleInitialQuery = () => {
      if (initialQuery.value?.settings === 'subscription' && (isAdmin.value || isOwner.value)) {
        store.dispatch('openGlobalSettings', 'subscription')
        initialQuery.value = null
      }
    }

    watch(workspaceFavicon, () => {
      updateFavicon()
    })

    watch(accentColor, (accentColor) => {
      store.dispatch('changeAccentColor', accentColor || defaultAccentColor.value || DEFAULT_ACCENT_COLOR)
    })

    watchEffect(() => {
      if (accentColor.value) {
        store.dispatch('changeAccentColor', accentColor.value)
      }
    })

    watchEffect(() => {
      if (renewalModalShown.value && isMigratedUser.value && pendingStripeSubscriptionUpdate.value && daysBeforePendingUpdate.value <= 3) {
        showRenewalModal()
      }
      handleInitialQuery()
    })


    updateFavicon()

    return {
      user,
      notificationsVisible,
      isEnterprize,
      isAdmin,
      isOwner,
      groupIsLoading,
      workspaceLoaded,
      forcedPlaylist,
      authStatus,
      changingWorkspace,
      paymentSuccessInfo,
      showCreateWorkspaceModal
    }
  }

})
</script>

