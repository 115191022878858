<template>
  <GroupsSidebar
    :group="groupId"
    :show="authStatus"
    @group-change="onGroupChange"
  />

  <a-layout>
    <GroupsNavbar v-if="!changingWorkspace" />
    <a-layout-content :style="{ background: '#fff', minHeight: '280px' }">
      <router-view v-if="!changingWorkspace" />
    </a-layout-content>
  </a-layout>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import GroupsSidebar from '@/components/GroupsSidebar.vue'
import GroupsNavbar from '@/components/GroupsNavbar.vue'

export default defineComponent({
  name: 'GroupsWrapperView',
  components: { GroupsNavbar, GroupsSidebar },
  props: {
    groupId: String,
    playlistId: String
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const authStatus = computed(() => store.getters['auth/authStatus'])
    const changingWorkspace = computed(() => store.getters['workspace/changingWorkspace'])

    const onGroupChange = (newGroupId) => {
      if (!newGroupId && !props.groupId) return
      if (!props.groupId) {
        return router.push({ name: 'Groups', params: { groupId: newGroupId } })
      }
      router.push({ params: { groupId: newGroupId } })
    }


    return {
      changingWorkspace,
      authStatus,
      onGroupChange
    }
  }
})
</script>

<style scoped>

</style>
