<template>
  <icon>
    <template #component>
      <svg
        width="62"
        height="56"
        viewBox="0 0 62 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_81388_106488)">
          <rect
            width="62"
            height="56"
            fill="white"
          />
          <rect
            width="57"
            height="27"
            transform="translate(3 1)"
            fill="url(#paint0_linear_81388_106488)"
          />
          <rect
            x="21.5"
            y="4.5"
            width="20"
            height="20"
            rx="10"
            fill="white"
          />
          <path
            d="M27.7383 18.5H29.1152L29.8828 16.2148H33.1172L33.8789 18.5H35.2617L32.209 10.0449H30.791L27.7383 18.5ZM31.4473 11.5273H31.5469L32.7656 15.1602H30.2344L31.4473 11.5273Z"
            fill="#222222"
          />
          <rect
            x="0.5"
            y="20"
            width="61"
            height="35"
            fill="white"
          />
          <rect
            x="21"
            y="27.5"
            width="20"
            height="20"
            rx="10"
            fill="black"
          />
          <path
            d="M28.0352 41.5H31.5098C33.3145 41.5 34.3984 40.5977 34.3984 39.1152V39.1035C34.3984 38.002 33.6367 37.1934 32.4941 37.0703V36.9707C33.3262 36.8301 33.9766 36.0391 33.9766 35.1602V35.1484C33.9766 33.8535 33.0215 33.0449 31.4336 33.0449H28.0352V41.5ZM31.1289 34.1172C32.1016 34.1172 32.6699 34.5801 32.6699 35.377V35.3887C32.6699 36.209 32.0664 36.6426 30.9062 36.6426H29.3477V34.1172H31.1289ZM31.1699 37.6445C32.4062 37.6445 33.0566 38.1133 33.0566 39.0215V39.0332C33.0566 39.9414 32.4297 40.4277 31.2461 40.4277H29.3477V37.6445H31.1699Z"
            fill="white"
          />
          <rect
            x="0.5"
            y="20"
            width="61"
            height="35"
            stroke="black"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_81388_106488"
            x1="28.5"
            y1="0"
            x2="28.5"
            y2="27"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-opacity="0.6" />
            <stop offset="1" />
          </linearGradient>
          <clipPath id="clip0_81388_106488">
            <rect
              width="62"
              height="56"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'PushBackTransition',
  components: {
    Icon
  }
})
</script>
