<template>
  <DurationSlideStep
    :slide="slide"
    :disable-duration-per-item="disableDurationPerItem"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :title="$t('components.slides.socialInstagram.slideStep1.title')"
  >
    <template #sub-title>
      <a-typography-link
        href="https://knowledgebase.kitcast.tv/how-to-connect-your-instagram-account"
        target="_blank"
      >
        {{ $t('components.slides.socialInstagram.slideStep1.subTitleLink') }}
      </a-typography-link>
      <br>
      {{ $t('components.slides.socialInstagram.slideStep1.subTitle') }}
    </template>
    <template #extra>
      <AddSocialAccountButton :socials="['instagram']" />
    </template>
    <a-select
      v-model:value="accounts"
      :placeholder="$t('components.slides.socialInstagram.slideStep1.accountSelectPlaceholder')"
      mode="multiple"
      style="width: 100%"
      @change="handleSocialAccountsChange"
    >
      <a-select-opt-group v-if="instagramAccounts.length > 0">
        <template #label>
          <span>
            <InstagramFilled />
            Instagram
          </span>
        </template>
        <a-select-option
          v-for="account in instagramAccounts"
          :key="account.id"
          :value="account.id"
        >
          <div>
            @ {{ account.name }}
          </div>
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </SlideStep>
  <SlideStep
    :sub-title="$t('components.slides.socialInstagram.slideStep2.subTitle')"
    :title="$t('components.slides.socialInstagram.slideStep2.title')"
  >
    <a-select
      :placeholder="$t('components.slides.socialInstagram.slideStep2.tagsInputPlaceholder')"
      :value="hashtags"
      mode="tags"
      style="width: 100%"
      @change="onHashtagsChange"
    />
  </SlideStep>
  <SlideStep :title="$t('components.slides.socialInstagram.slideStep3.title')">
    <a-radio-group v-model:value="feedType">
      <a-radio value="TOP">
        {{ $t('components.slides.socialInstagram.slideStep3.popularFirst') }}
      </a-radio>
      <a-radio value="RECENT">
        {{ $t('components.slides.socialInstagram.slideStep3.recentFirst') }}
      </a-radio>
    </a-radio-group>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialInstagram.slideStep4.title')"
  >
    <a-checkbox
      v-model:checked="includeOwnInstagramMedia"
    >
      {{ $t('components.slides.socialInstagram.slideStep4.includeOwnPosts') }}
    </a-checkbox>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialInstagram.slideStep5.title')"
    no-border
  >
    <SlideViewSelect
      v-model:view="view"
      :data="viewDataState"
    />
  </SlideStep>
</template>

<script>
import { InstagramFilled } from '@ant-design/icons-vue'
import { computed, defineComponent, reactive, ref, toRaw, toRefs, watch, watchEffect } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { getSocialHashtagsOrMentionsOnChange, SocialInstagramSlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import { toStandard } from '@/helpers/SocialAccount'
import { MAX_SOCIAL_ACCOUNTS_IN_SLIDE } from '@/constants'
import AddSocialAccountButton from '@/components/AddSocialAccountButton'
import { useI18n } from 'vue-i18n'
import SlideViewSelect from '@/components/slideModal/SlideViewSelect.vue'
import singleTypeImage from '@/assets/slide-type-single-active.svg'
import photowallTypeImage from '@/assets/slide-type-photowall-active.svg'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

export default defineComponent({
  name: 'SocialInstagramSlide',
  components: {
    DurationSlideStep,
    SlideViewSelect,
    AddSocialAccountButton,
    SlideStep,
    InstagramFilled
  },
  props: {
    title: String,
    slideObj: SocialInstagramSlide,
    groupId: String,
    disabled: Boolean,
    zoning: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const store = useStore()
    const { t } = useI18n()
    const slide = props.slideObj || new SocialInstagramSlide()
    const disableDurationPerItem = ref(false)
    const { muted } = slide.slideData
    const isMuted = props.zoning || muted
    const {
      hashtags,
      feedType,
      includeOwnInstagramMedia,
      socialInstagramAccountIds,
      view
    } = slide.slideData
    const state = reactive({
      accounts: [...socialInstagramAccountIds || []],
      hashtags: hashtags.map(h => h.replace('#', '')),
      feedType,
      view,
      includeOwnInstagramMedia
    })
    const instagramAccounts = computed(() => store.getters['social/socialInstagramAccounts'].map(toStandard))
    const isValid = computed(() => (state.hashtags?.length > 0 || state.includeOwnInstagramMedia) && state.accounts?.length > 0)
    const viewDataState = reactive([
      {
        text: t('components.slides.socialInstagram.singleImage'),
        view: 'SINGLE',
        image: singleTypeImage,
      },
      {
        text: t('components.slides.socialInstagram.photowall'),
        view: 'PHOTOWALL',
        image: photowallTypeImage
      }
    ])

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })
    emit('update:disabled', !isValid.value)

    const onHashtagsChange = (value) => {
      state.hashtags = getSocialHashtagsOrMentionsOnChange(value)
    }

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      const {
        feedType,
        hashtags,
        includeOwnInstagramMedia = false,
        view
      } = toRaw(state)
      const instagramAccountsIds = instagramAccounts.value.filter(a => state.accounts.includes(a.id)).map(a => a.id)
      slide.updateSlideData({
        view,
        hashtags: hashtags.map(h => `#${h.replace(/#/g, '')}`),
        includeOwnInstagramMedia,
        feedType,
        socialInstagramAccountIds: instagramAccountsIds.length ? instagramAccountsIds : null
      })
      slide.setMute(isMuted)
    }

    const handleSocialAccountsChange = (socialAccounts) => {
      if (socialAccounts.length >= MAX_SOCIAL_ACCOUNTS_IN_SLIDE) {
        state.accounts = state.accounts.slice(0, MAX_SOCIAL_ACCOUNTS_IN_SLIDE)
      }
    }

    watch(() => state, () => {
      toggleDisabled(!isValid.value)
      if (isValid.value) {
        updateSlideData()
        updateSlide()
        toggleDisabled(false)
      }
    }, { deep: true })

    watch(() => instagramAccounts.value, (value, oldValue) => {
      if (value?.length - oldValue?.length === 1) {
        const newSocialAccount = value?.find(({ id }) => !oldValue.some(({ id: oldId }) => oldId === id))
        newSocialAccount && state.accounts?.push(newSocialAccount.id)
      }
    })

    watchEffect(()=>{
      if (state.view === 'PHOTOWALL') {
        disableDurationPerItem.value = true
      } else {
        disableDurationPerItem.value = false
      }
    })

    return {
      slide,
      ...toRefs(state),
      isValid,
      instagramAccounts,
      viewDataState,
      disableDurationPerItem,
      onHashtagsChange,
      handleSocialAccountsChange,
      updateSlide
    }
  }
})
</script>

<style scoped>

</style>
