<template>
  <a-spin
    tip="Logging Out..."
    class="spinner"
  />
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'LogOutPage',
  setup () {
    const router = useRouter()
    const store = useStore()
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])

    onMounted(()=>{
      if (isAuthenticated.value) {
        store.dispatch('auth/logOut').finally(() => {
          router.push({ name: 'Login' })
        })
      }
      else {
        router.push({ name: 'Login' })
      }
    })

    return {}
  }
})
</script>

<style scoped>
.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
