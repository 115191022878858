import ApolloClient from '@/apollo'
import { GET_NOTIFICATIONS_LIST, GET_UNSEEN_NOTIFICATIONS_COUNT, } from '@/graphql/queries'
import { MARK_ALL_NOTIFICATIONS_AS_SEEN, } from '@/graphql/mutations'
import { ON_NEW_NOTIFICATIONS_TRIGGER } from '@/graphql/subscriptions'
import { handleError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    unseenNotificationsCountSubscription: null,
    unseenNotificationsCount: 0,
    notifications: null,
    notificationsLoading: false
  },
  actions: {
    async getUnreadNotificationsCount ({ commit }) {
      try {
        const { data: { getUnseenNotificationsCount } } = await ApolloClient.query({ query: GET_UNSEEN_NOTIFICATIONS_COUNT, fetchPolicy: 'no-cache' })
        commit('SET_UNSEEN_NOTIFICATIONS_COUNT', getUnseenNotificationsCount)
      } catch (e) {
        handleError(e)
      }
    },
    async subscribeToUnreadNotifications ({ state, dispatch }) {
      state.unseenNotificationsCountSubscription = ApolloClient.subscribe({ query: ON_NEW_NOTIFICATIONS_TRIGGER })
        .subscribe(({ data: { onNewNotificationsTrigger: { needReload } }, loading }) => {
          needReload && dispatch('getUnreadNotificationsCount')
        })
    },
    async getNotifications ({ commit, dispatch, state }, {pagination = { limit: 10}}) {
      const { offset } = pagination
      state.notificationsLoading = true
      try {
        const { data: { listNotifications: { data, pagination: _pagination } } } = await ApolloClient.query({ query: GET_NOTIFICATIONS_LIST, fetchPolicy: 'no-cache', variables: {pagination} })
        if (offset) {
          commit('ADD_NOTIFICATIONS', { data, pagination: _pagination })
        }
        else {
          commit('SET_NOTIFICATIONS', { data, pagination: _pagination })
        }
        state.unseenNotificationsCount && dispatch('markAllNotificationsAsSeen')
        state.notificationsLoading = false
      } catch (e) {
        state.notificationsLoading = false
      }
    },
    async markAllNotificationsAsSeen ({ commit }) {
      try {
        await ApolloClient.mutate({ mutation: MARK_ALL_NOTIFICATIONS_AS_SEEN })
        commit('SET_UNSEEN_NOTIFICATIONS_COUNT', 0)
      } catch (e) {
        handleError(e)
      }
    },
  },
  getters: {
    unseenNotificationsCount: state => state.unseenNotificationsCount,
    notificationsData: state => state.notifications?.data,
    notificationsPagination: state => state.notifications?.pagination,
    notificationsLoading: state => state.notificationsLoading,
  },
  mutations: {
    SET_UNSEEN_NOTIFICATIONS_COUNT (state, value) {
      state.unseenNotificationsCount = value
    },
    SET_NOTIFICATIONS (state, { data, pagination }) {
      state.notifications = { data, pagination }
    },
    ADD_NOTIFICATIONS (state, { data, pagination }) {
      state.notifications = { data: [...state.notifications?.data, ...data], pagination }
    },
    CLEAR_NOTIFICATIONS (state) {
      state.notifications = null
      state.unseenNotificationsCountSubscription?.unsubscribe()
      state.unseenNotificationsCountSubscription = null
    }
  }
}
