<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.socialTikTok.slideStep1.subTitle')"
    :title="$t('components.slides.socialTikTok.slideStep1.title')"
  >
    <a-select
      :disabled="loading"
      :placeholder="$t('components.slides.socialTikTok.slideStep1.hashtagsInputPlaceholder')"
      :value="hashtags"
      mode="tags"
      style="width: 100%;"
      @change="onHashtagsChange"
    />
  </SlideStep>
  <SlideStep
    :sub-title="$t('components.slides.socialTikTok.slideStep2.subTitle')"
    :title="$t('components.slides.socialTikTok.slideStep2.title')"
  >
    <a-select
      :disabled="loading"
      :placeholder="$t('components.slides.socialTikTok.slideStep2.mentionsInputPlaceholder')"
      :value="mentions"
      mode="tags"
      style="width: 100%"
      @change="onMentionsChange"
    />
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialTikTok.slideStep3.title')"
    no-border
  >
    <a-radio-group v-model:value="maxDurationIsSet">
      <div style="margin-bottom: 8px;">
        <a-radio
          :value="false"
        >
          {{ $t('components.slides.socialTikTok.slideStep3.maxDurationNotSet') }}
        </a-radio>
      </div>
      <div>
        <a-radio
          :value="true"
        >
          <div style="display: inline-flex; align-items: center; gap: 8px;">
            {{ $t('components.slides.socialTikTok.slideStep3.maxDuration') }} <a-input-number
              v-model:value="maxDurationState"
              :disabled="!maxDurationIsSet"
              min="5"
              max="180"
              style="width: 60px;"
            /> {{ $t('components.slides.socialTikTok.slideStep3.seconds') }}
          </div>
        </a-radio>
      </div>
    </a-radio-group>
  </SlideStep>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRaw, toRefs, watch } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { getSocialHashtagsOrMentionsOnChange, SocialTiktokSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

const DEFAULT_MAX_DURATION = 10
export default defineComponent({
  name: 'SocialTiktokSlide',
  components: {
    DurationSlideStep,
    SlideStep
  },
  props: {
    title: String,
    slideObj: SocialTiktokSlide,
    groupId: String,
    disabled: Boolean,
    zoning: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const slide = props.slideObj || new SocialTiktokSlide()
    const { hashtags, mentions, maxTimeMsPerVideo } = slide.slideData
    const loading = ref(false)
    const { muted } = slide.slideData
    const isMuted = props.zoning || muted
    const state = reactive({
      hashtags: hashtags || [],
      mentions: mentions || [],
      maxTimeMsPerVideo
    })
    const isValid = computed(() => state.hashtags?.length > 0 || state.mentions?.length > 0)
    const maxDurationIsSet = ref(state.maxTimeMsPerVideo !== null)
    const maxDurationState = ref(state.maxTimeMsPerVideo ? state.maxTimeMsPerVideo / 1000 : DEFAULT_MAX_DURATION)

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })
    emit('update:disabled', !isValid.value)

    const onHashtagsChange = (value) => {
      state.hashtags = getSocialHashtagsOrMentionsOnChange(value)
    }

    const onMentionsChange = (value) => {
      state.mentions = getSocialHashtagsOrMentionsOnChange(value)
    }

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      const { hashtags, mentions } = toRaw(state)
      slide.updateSlideData({
        hashtags: hashtags.length ? hashtags : null,
        mentions: mentions.length ? mentions : null,
        maxTimeMsPerVideo: maxDurationIsSet.value ? maxDurationState.value * 1000 : null
      })
      slide.setMute(isMuted)
    }

    watch(()=> maxDurationIsSet.value, () => {
      updateSlideData()
      updateSlide()
    })

    watch(() => maxDurationState.value, () => {
      updateSlideData()
      updateSlide()
    })

    watch(() => state, () => {
      toggleDisabled(!isValid.value)
      if (isValid.value) {
        updateSlideData()
        updateSlide()
        toggleDisabled(false)
      }
    }, { deep: true })

    return {
      slide,
      ...toRefs(state),
      isValid,
      loading,
      maxDurationIsSet,
      maxDurationState,
      onHashtagsChange,
      onMentionsChange,
      updateSlide
    }
  }
})
</script>

<style scoped>

</style>
