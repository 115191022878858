<template>
  <div class="text-element">
    <div class="text-input">
      <a-form-item
        :label="title"
        class="thick-border"
      >
        <a-textarea
          v-if="lines > 1"
          v-model:value="value"
          v-max-rows="lines"
          :auto-size="{ maxRows: lines }"
        />
        <template v-else>
          <a-input-number
            v-if="dataType === 'number'"
            v-model:value="value"
            v-max-length="2"
            v-numbers-only
            :max="99"
            :min="1"
            string-mode
            style="width: 100%;"
          />
          <a-input
            v-else
            v-model:value="value"
            :maxlength="maxLength"
          />
        </template>
      </a-form-item>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useModelWrapper } from '@/helpers/ModelWrapper'

export default defineComponent({
  name: 'TextElement',
  props: {
    title: String,
    valueModel: [String, Number, Object],
    lines: Number,
    maxLength: Number,
    dataType: String
  },
  emits: ['update:valueModel'],
  setup (props, { emit }) {
    const value = useModelWrapper(props, emit, 'valueModel')

    return {
      value,
    }
  }
})
</script>

<style lang="less" scoped>
.text-element {
  padding-bottom: 8px;

  .text-input {
    :deep(.ant-row) {
      display: block;
      margin: 0;
    }

    .thick-border {
      input, textarea {
        border: 2px solid #d9d9d9;
        padding: 3px 10px;
        border-radius: 6px;

        &:hover {
          border-color: var(--ant-primary-color-hover);
        }

        &:focus {
          border-color: var(--ant-primary-color-hover);
          border-right-width: 2px !important;
          box-shadow: 0 0 0 1px var(--ant-primary-color-outline);
          outline: 0;
        }
      }
    }
  }
}
</style>
