<script setup>
import { computed, nextTick, ref, watch } from 'vue'
import { ifIsYoutubeLink } from '@/utils'
const store = useStore()
const { t } = useI18n()
const youtubeLink = ref('')
const _soundtrackLoading = computed(() => store.getters['playlist/playlistLoading'])
const soundtrack = computed(() => store.getters['playlist/playlistSoundtrack'])
const soundtrackName = computed(() => soundtrack.value?.metadata?.name)
const soundtrackIsYoutube = computed(() => soundtrack.value?.__typename === 'ExternalUrlFileMediaModel')
const soundtrackLoading = ref(false)
const youtubeStrippedUrl = ref('')
const youtubeName = ref('')

const SUPPORTED_PROTOCOLS = ['https']
const MB_LIMIT = 150

const getYoutubeVideoInfo = (videoId) => {
  return store.dispatch('slides/getYoutubeVideoInfo', videoId)
}

const onChange = async () => {
  const stripUrl = normalizeUrl(youtubeLink.value)
  const urlProtocol = getUrlProtocol(youtubeLink.value)
  if (youtubeStrippedUrl.value === stripUrl) {
    return
  }
  youtubeStrippedUrl.value = stripUrl
  nextTick(()=> {
    youtubeLink.value = stripUrl
  })

  if (!isURL(urlProtocol + stripUrl, { protocols: SUPPORTED_PROTOCOLS })) {
    clearState()
    return
  }

  if (!ifIsYoutubeLink(stripUrl)) {
    return
  }
  const videoId = extractVideoIdFromYoutubeLink(stripUrl)
  if (!videoId) {
    return
  }
  soundtrackLoading.value = true
  try {
    const videoInfo = await getYoutubeVideoInfo(videoId)
    youtubeName.value = videoInfo?.snippet?.title
    await onSubmit()
  } catch (e) {
    error(e.message)
  } finally {
    soundtrackLoading.value = false
  }
}

const onSubmit = async () => {
  if (!youtubeLink.value || !youtubeName.value) {
    return
  }
  const input = {
    name: youtubeName.value,
    url: 'https://'+youtubeLink.value
  }
  await store.dispatch('playlist/setPlaylistSoundtrackUrl', input).catch(e => {
    error(e.message)
  })
}

const beforeSoundtrackUpload = async (fileRaw) => {
  const isLt2M = fileRaw.size / 1024 / 1024 < MB_LIMIT
  if (!isLt2M) {
    error(t('components.soundtrackButton.audioUploadError', { size: MB_LIMIT }))
    return false
  }

  soundtrackLoading.value = true
  await store.dispatch('playlist/uploadPlaylistSoundtrack', fileRaw).catch(e => {
    error(e.message)
  })
  return false
}

const deleteSoundtrack = async () => {
  await store.dispatch('playlist/deletePlaylistSoundtrack').then(()=>{
    clearState()
  }).catch(e => {
    error(e.message)
  })
}

const clearState = () => {
  youtubeLink.value = ''
  youtubeStrippedUrl.value = ''
  youtubeName.value = ''
}

watch(_soundtrackLoading, (loading) => {
  soundtrackLoading.value = loading
})

import { SoundOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { AUDIO_MIME_TYPES, DEFAULT_PROTOCOL } from '@/constants'
import { error, extractVideoIdFromYoutubeLink, getUrlProtocol, normalizeUrl } from '@/utils'
import { useStore } from 'vuex'
import { isURL } from 'validator'
import { useI18n } from 'vue-i18n'
</script>

<template>
  <a-popover
    placement="bottomLeft"
    trigger="click"
    :overlay-style="{width: '416px'}"
    @openChange="clearState()"
  >
    <a-button
      :type="!soundtrack ? 'dashed' : 'default'"
      style="display: flex; align-items: center;"
    >
      <template #icon>
        <SoundOutlined />
      </template>
      <template v-if="!soundtrack">
        {{ $t('components.soundtrackButton.addSoundtrack') }}
      </template>
      <span
        v-else
        style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
      >
        <span v-if="soundtrackIsYoutube">Youtube: </span>
        {{ soundtrackName }}
      </span>
    </a-button>
    <template #content>
      <div
        class="
        overlay-container"
      >
        <template v-if="!soundtrack">
          <div>
            {{ $t('components.soundtrackButton.uploadYourSoundtrack') }}
            <br>
            <a-typography-text type="secondary">
              <small>{{ $t('components.soundtrackButton.info') }}</small>
            </a-typography-text>
          </div>
          <a-upload
            :disabled="soundtrackLoading"
            name="audio"
            list-type="picture-card"
            class="audio-uploader"
            :before-upload="beforeSoundtrackUpload"
            :accept="AUDIO_MIME_TYPES"
            :show-upload-list="false"
          >
            <div>
              <LoadingOutlined v-if="soundtrackLoading" />
              <plus-outlined v-else />
              <div class="ant-upload-text">
                {{ $t('components.soundtrackButton.uploadAudio') }}
              </div>
            </div>
          </a-upload>
          {{ $t('components.soundtrackButton.pasteYoutubeLink') }}
          <a-input
            ref="inputRef"
            v-model:value="youtubeLink"
            :addon-before="DEFAULT_PROTOCOL"
            :disabled="soundtrackLoading"
            :placeholder="$t('components.soundtrackButton.youtubeLinkPlaceholder')"
            @change="onChange"
            @keydown.enter="onSubmit"
          />
        </template>
        <template v-else>
          {{ $t('components.soundtrackButton.currentSoundtrack') }}
          <a-tag
            closable
            class="audio-tag"
            :disabled="soundtrackLoading"
            @close="deleteSoundtrack"
          >
            <span style="overflow: hidden; text-overflow: ellipsis;">{{ soundtrackName }}</span>
          </a-tag>
        </template>
      </div>
    </template>
  </a-popover>
</template>
<style scoped lang="less">
.overlay-container {
  gap: 8px;
  display: flex;
  flex-direction: column;
  .audio-uploader {
    :deep(.ant-upload) {
      width: 100%;
      margin: 0;
    }
  }
  .audio-tag {
    display: flex;
    justify-content: space-between;
  }
}

</style>
