<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.news.slideStep1.subTitle')"
    :title="$t('components.slides.news.slideStep1.subTitle')"
  >
    <a-checkbox-group
      v-model:value="checkedList"
      :options="categoriesOptions"
      class="multirow-checkbox-group"
      @change="onTopicsChange"
    />
  </SlideStep>
  <a-alert
    :message="$t('components.slides.news.infoMessage')"
    show-icon
    type="info"
  />
</template>

<script>
import { computed, defineComponent, ref, toRaw } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { NEWS_CATEGORIES } from '@/constants'
import { NewsSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

export default defineComponent({
  name: 'NewsSlide',
  components: { DurationSlideStep, SlideStep },
  props: {
    title: String,
    slideObj: NewsSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const slide = props.slideObj || new NewsSlide()
    const { t } = useI18n()
    const { topics: topicsList } = slide.slideData
    const checkedList = ref(topicsList)
    const categoriesOptions = ref([
      {
        label: t('components.slides.news.categories.all'),
        value: 'all',
        indeterminate: false
      },
      ...NEWS_CATEGORIES.map(category => ({
        label: t(`components.slides.news.categories.${category}`),
        value: category
      }))
    ])
    const prevState = ref([])
    const topics = computed(() => {
      return checkedList.value.filter(l => l !== 'all')
    })

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const onTopicsChange = e => {
      const categoriesLength = categoriesOptions.value.length - 1
      const selectAllPressed = !prevState.value.includes('all') && checkedList.value.includes('all')
      const deselectAllPressed = prevState.value.includes('all') && !checkedList.value.includes('all')
      if (!selectAllPressed && !deselectAllPressed && categoriesLength === checkedList.value.length && prevState.value.length !== categoriesLength + 1) {
        checkedList.value = categoriesOptions.value.map(({ value }) => value)
      } else if (selectAllPressed) {
        checkedList.value = categoriesOptions.value.map(({ value }) => value)
      } else if (deselectAllPressed) {
        checkedList.value = []
      } else {
        checkedList.value = checkedList.value.filter(value => value !== 'all')
      }
      prevState.value = checkedList.value
      categoriesOptions.value[0].indeterminate = !!checkedList.value.length && checkedList.value.length < categoriesLength
      if (!topics.value.length) {
        toggleDisabled(true)
        return
      }
      slide.updateSlideData({ topics: toRaw(topics.value) })
      updateSlide()
      toggleDisabled(false)
    }

    onTopicsChange()

    return {
      slide,
      checkedList,
      categoriesOptions,
      onTopicsChange,
      updateSlide
    }
  }
})
</script>

<style lang="less">
.compact-form {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

</style>
