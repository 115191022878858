<script setup>

import { computed } from 'vue'
import { ArrowDownWideNarrow, CheckCheck, RefreshCcwDot, WrapText } from 'lucide-vue-next'
import CommandList from './CommandList.vue'

const props = defineProps({
  filter: {
    type: String,
    default: '',
  }
})

const emit = defineEmits(['select'])

const items = [
  {
    value: "improve",
    label: "Improve writing",
    icon: RefreshCcwDot,
  },
  {
    value: "fix",
    label: "Fix grammar",
    icon: CheckCheck,
  },
  {
    value: "shorter",
    label: "Make shorter",
    icon: ArrowDownWideNarrow,
  },
  {
    value: "longer",
    label: "Make longer",
    icon: WrapText,
  },
];

const filteredItems = computed(()=>{
  return items.filter(item => item.label.toLowerCase().includes(props.filter.toLowerCase()));
})

function selectItem(index) {
  const item = filteredItems.value[index];

  if (item) {
    emit('select', item);
  }
}
const handleSelect = (item) => {
  emit('select', item);
}
</script>

<template>
  <CommandList
    v-if="filteredItems.length > 0"
    :items="filteredItems"
    @select="handleSelect"
  >
    <template #item="{item, index, isActive}">
      <button
        :key="index"
        class="flex items-center w-full px-2 py-1 space-x-2 text-sm text-left rounded-md text-stone-900 hover:bg-stone-100"
        :class="isActive ? 'bg-stone-100 text-stone-900' : ''"
        @click="selectItem(index)"
      >
        <div class="flex items-center justify-center w-10 h-10 bg-white border rounded-md border-stone-200">
          <component
            :is="item.icon"
            size="18"
          />
        </div>
        <div>
          <p class="font-medium">
            {{ item.label }}
          </p>
        </div>
      </button>
    </template>
  </CommandList>
</template>

<style scoped>

</style>
