<script>

import { computed, defineComponent, provide, ref, toRefs, watch } from 'vue'
import { Slide } from '@/helpers/Slides'
import LayoutPickerDropdown from '@/components/cEngineEditor/LayoutPickerDropdown.vue'
import PagesSidebar from '@/components/cEngineEditor/PagesSidebar.vue'
import CEngineHeader from '@/components/cEngineEditor/CEngineHeader.vue'
import PageEditor from '@/components/cEngineEditor/PageEditor.vue'
import { CEngineSlide, ELEMENT_TYPES_TO_MEDIA_TYPES } from '@/components/cEngineEditor/helpers'
import PageControls from '@/components/cEngineEditor/PageControls.vue'
import TextElementSettingsModal from '@/components/cEngineEditor/TextElementSettingsModal.vue'

/*

Editor component for the CEngine
Basic components:
- The editor is a portal that is rendered in the body
- The editor provides the CEngineService to the components
- The editor has a sidebar with pages and a main section with the page editor
  - PageEditor.vue is a component that renders the page with rows and elements
  - PageEditorRow.vue is a component that renders a row with elements
  - PageEditorTextElement.vue is a component that renders a text element
    - RichText.vue is a component that renders a rich text editor
  - PageEditorMediaElement.vue is a component that renders a media element
  - PageControls.vue is a component that renders the controls for the page
    - Text and Media elements in sidebar are draggable and can be dropped in the page
    - Page styles can be changed in the sidebar

- The editor has a header with Slide name and actions: Save and Undo
- The editor has a footer with a button to add a new page (Template selector)
- The editor has a modal for text element settings
- The editor has a modal for media element settings

Logic:
  - Adding Text element:
    - Click on Text in sidebar to add Text element to the end of the page
    - Drag Text from sidebar to the page to add Text element preferred position
    - On adding Text element to the page, the text element settings modal is opened
    - On Text element added to the page, column is Selected (green outline)
  - Adding Media element:
    - Click on Media in sidebar to add Media element to the end of the page
    - Drag Media from sidebar to the page to add Media element preferred position
    - On adding Media element to the page, the media element settings modal is NOT opened
    - On Empty Media element there is a custom placeholder with actions to add Media
    - On Media element added to the page, column is Selected (green outline)
  - Clicking on Text element:
    - On Text element Click the text element settings modal is opened
  - Clicking on Media element:
    - On Media Column Click the media element settings modal is opened if the media element is not empty
      - On Media modal text visibility can be toggled
        - If toggled Visible, The text element is added to column and Text element is selected (green outline)
        - If toggled Hidden, The text element is removed from the column, and column is selected (green outline)
  - Clicking Text element INSIDE Media column:
    - On Text element Click the text element settings modal is opened, and the text element is selected (green outline)

 */

export default defineComponent({
  name: 'CEngineEditor',
  components: { TextElementSettingsModal, PageControls, PageEditor, CEngineHeader, PagesSidebar, LayoutPickerDropdown },
  props: {
    slide: Slide
  },
  setup () {
    const slide = new CEngineSlide({
      bgColor: '#fff',
      fontColor: '#000'
    })

    const style = ref('heading')
    const activeColumn = ref(null)
    const activeElement = ref(null)
    const activeItem = ref(null)

    const layoutPickerDropdownVisible = ref(false)
    const globalStyleDropdownVisible = ref(false)
    const textElementSettingsDropdownVisible = ref(false)
    const textElementDropdownVisible = ref(false)
    const mediaElementDropdownVisible = ref(false)
    const activePageIndex = ref(0)
    const activePage = computed(()=> {
      return slide.pages?.value[activePageIndex.value] || null
    })

    const handlePageChange = (index) => {
      activePageIndex.value = index
    }

    const handlePagesChangeOrder = (newIndex, oldIndex) => {
      if (activePageIndex.value === oldIndex) {
        activePageIndex.value = newIndex
      }
    }

    const handleDeletePage = (index) => {
      slide.removePage(index)
      handlePageChange(0)
    }

    const handleGlobalBgColorChange = (color) => {
      slide.setBgColor(color)
    }

    const handleFontColorChange = (color) => {
      activeElement.value.fontColor = color
    }

    const handleGlobalFontColorChange = (color) => {
      slide.setFontColor(color)
    }

    const handleAlignmentChange = (value) => {
      activeElement.value.alignment = value
    }

    const handleStyleChange = (value) => {
      if (activeItem.value) {
        activeItem.value.style = value
      }
    }

    const handleAddPage = (layout) => {
      slide.addPage(layout)
      handlePageChange(slide.pages.value.length - 1)
    }

    const handleLayoutPickerDropdownClose = () => {
      layoutPickerDropdownVisible.value = false
    }
    const handleGlobalStyleDropdownClose = () => {
      globalStyleDropdownVisible.value = false
    }
    const handleTextElementDropdownClose = () => {
      textElementDropdownVisible.value = false
    }
    const handleMediaElementDropdownClose = () => {
      mediaElementDropdownVisible.value = false
    }

    const handleTextElementSettingsDropdownClose = (visible) => {
      if (visible) return
      activeItem.value = null
      activeElement.value = null
      activeColumn.value = null
      textElementSettingsDropdownVisible.value = false
    }

    const handleElementSelect = (element) => {
      activeElement.value = element
      if (element.type === 'text') {
        textElementSettingsDropdownVisible.value = true
      } else {
        mediaElementDropdownVisible.value = true
      }
    }

    const handleItemSelect = (item) => {
      activeItem.value = item
    }

    const handleItemAdd = (item, afterIndex) => {
      activeElement.value.items.splice(afterIndex + 1, 0, item)
    }

    const handleMediaSelection = ([selection]) => {
      activeElement.value.url = selection.source
      activeElement.value.mediaType = ELEMENT_TYPES_TO_MEDIA_TYPES[selection.mediaType]
    }


    const handleObjectFitUpdate = (objectFit) => {
      activeElement.value.objectFit = objectFit
    }


    const handleColumnSelect = (column) => {
      activeColumn.value = column
    }

    watch(() => textElementSettingsDropdownVisible.value, (visible) => {
      if (visible) return
      activeElement.value = null
    })

    provide('cEngineService', {
      pages: slide.pages,
      slide,
      activePage,
      activeItem,
      activeColumn,
      activeElement,
      activePageIndex,
      addItem: handleItemAdd,
      addPage: handleAddPage,
      selectColumn: handleColumnSelect,
      selectElement: handleElementSelect,
      selectItem: handleItemSelect,
      selectMedia: handleMediaSelection,
      selectObjectFit: handleObjectFitUpdate,
      selectPage: handlePageChange,
      deletePage: handleDeletePage,
      changePagesOrder: handlePagesChangeOrder,
      changeStyle: handleStyleChange,
      changeFontColor: handleFontColorChange,
      changeAlignment: handleAlignmentChange,
      changeGlobalBgColor: handleGlobalBgColorChange,
      changeGlobalFontColor: handleGlobalFontColorChange
    })

    return {
      ...toRefs(slide.state),
      style,
      layoutPickerDropdownVisible,
      globalStyleDropdownVisible,
      textElementDropdownVisible,
      mediaElementDropdownVisible,
      textElementSettingsDropdownVisible,
      handleLayoutPickerDropdownClose,
      handleGlobalStyleDropdownClose,
      handleTextElementDropdownClose,
      handleMediaElementDropdownClose,
      handleTextElementSettingsDropdownClose
    }
  }
})
</script>

<template>
  <teleport to="body">
    <div
      class="c-engine-portal"
      :style="{backgroundColor: bgColor, color: fontColor}"
    >
      <CEngineHeader />
      <TextElementSettingsModal
        :open="textElementSettingsDropdownVisible"
        @close="handleTextElementSettingsDropdownClose"
      />
      <section class="c-engine-wrapper">
        <aside class="navigation">
          <PagesSidebar />
          <footer>
            <a-popover
              v-model:open="layoutPickerDropdownVisible"
              placement="rightBottom"
              trigger="click"
            >
              <template #content>
                <LayoutPickerDropdown @close="handleLayoutPickerDropdownClose" />
              </template>
              <a-button block>
                New Page
              </a-button>
            </a-popover>
          </footer>
        </aside>
        <main class="c-engine-editor">
          <div class="canvas">
            <PageEditor />
          </div>
        </main>
        <PageControls />
      </section>
    </div>
  </teleport>
</template>

<style scoped lang="less">
.c-engine-portal {
  --background-color: #f5f5f5;
  position: absolute;
  background-color: var(--background-color);
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .c-engine-wrapper {
    display: flex;
    flex: 1;
    > .navigation {
      width: 160px;
      display: flex;
      flex-direction: column;
      > footer {
        padding: 32px;
      }
    }
    > .c-engine-editor {
      flex: 1;
      padding: 50px 40px;
      .canvas {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
