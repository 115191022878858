<script>
import { defineComponent, inject } from 'vue'
import DropdownComponent from '@/components/cEngineEditor/DropdownComponent.vue'
import DropdownSection from '@/components/cEngineEditor/DropdownSection.vue'

export default defineComponent({
  name: 'PageStyleDropdown',
  components: { DropdownSection, DropdownComponent },
  emits: ['close'],
  setup (_, {emit}) {
    const service = inject('cEngineService')
    const fontColor = service.slide?.state.fontColor
    const bgColor = service.slide?.state.bgColor
    const closeDropdown = () => {
      emit('close')
    }
    const onFontColorChange = (e) => {
      service.changeGlobalFontColor(e.target.value)
    }

    const onBgColorChange = (e) => {
      service.changeGlobalBgColor(e.target.value)
    }

    return {
      fontColor,
      bgColor,
      onBgColorChange,
      onFontColorChange,
      closeDropdown
    }
  }
})

</script>

<template>
  <div class="global-style-dropdown">
    <DropdownComponent
      title="Style"
      @close="closeDropdown"
    >
      <template #content>
        <div class="settings-sections">
          <DropdownSection
            title="Background Color"
          >
            <a-input
              type="color"
              :value="bgColor"
              @input="onBgColorChange"
            />
          </DropdownSection>
          <DropdownSection
            title="Font Color"
          >
            <a-input
              type="color"
              :value="fontColor"
              @input="onFontColorChange"
            />
          </DropdownSection>
        </div>
      </template>
    </DropdownComponent>
  </div>
</template>

<style scoped lang="less">
.global-style-dropdown {
  .settings-sections {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
