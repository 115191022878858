<template>
  <div>
    <a-typography-title :level="5">
      {{ $t('components.externalApiSettings.title') }}
    </a-typography-title>
    <a-divider />
    <a-input-group
      compact
      style="display: flex; justify-content: flex-end; width: 100%; margin-bottom: 12px;"
    >
      <a-input
        v-model:value="apiKeyName"
        placeholder="New Api Key Name"
        style="width: 200px"
        @keydown.enter="createApiKey"
      />
      <a-button
        type="primary"
        @click="createApiKey"
      >
        {{ $t('components.externalApiSettings.createApiKey') }}
      </a-button>
    </a-input-group>
    <a-table
      :data-source="apiKeysListData"
      :loading="loading"
      :columns="columns"
      :scroll="{ x: 940 }"
      size="small"
    >
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.dataIndex === 'token'">
          <div
            v-if="!tokens[record.id]"
            style="position: relative;"
          >
            <span style="filter: blur(3px)">ki_0UkR2xpLYxn2e0hM10JBr83iQaW5lPpz</span>
            <div style="position: absolute; width: 100%; height: 100%; top: 0; display: flex; align-items: center; justify-content: center;">
              <a-button @click="revealAndCopyKey(record.id)">
                {{ $t('components.externalApiSettings.revealAndCopyKey') }}
              </a-button>
            </div>
          </div>
          <span
            v-else
            @click="copyApiKey(tokens[record.id])"
          >{{ tokens[record.id] }}</span>
        </template>
        <template v-if="column.dataIndex === 'name'">
          <div class="editable-cell">
            <div
              v-if="typeof editableData[record.id] === 'string'"
              class="editable-cell-input-wrapper"
            >
              <a-input
                v-model:value="editableData[record.id]"
                @press-enter="onNameChange({id: record.id})"
              />
              <CheckOutlined
                class="editable-cell-icon-check"
                @click="onNameChange({id: record.id})"
              />
            </div>
            <div
              v-else
              class="editable-cell-text-wrapper"
            >
              <span class="editable-cell-text-overflow">{{ text || ' ' }}</span>
              <EditOutlined
                class="editable-cell-icon"
                @click="startNameEdit(record.id)"
              />
            </div>
          </div>
        </template>
        <template v-if="column.dataIndex === 'actions'">
          <div style="display: flex; gap: 4px; justify-content: center;">
            <a-tooltip
              placement="top"
            >
              <template #title>
                {{ $t('components.externalApiSettings.deleteApiKey') }}
              </template>
              <a-popconfirm
                title="Are you sure?"
                @confirm="()=>{ deleteApiKey(record.id) }"
              >
                <a-button>
                  <template #icon>
                    <DeleteOutlined style="font-size: 12px" />
                  </template>
                </a-button>
              </a-popconfirm>
            </a-tooltip>
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons-vue'
import { error, formatDate, success } from '@/utils'
import { copyText } from 'vue3-clipboard'
import { useI18n } from 'vue-i18n'

const COLUMNS = [{
  title: 'Name',
  key: 'name',
  dataIndex: 'name',
  width: 200,
  ellipsis: true
}, {
  title: 'Token',
  key: 'token',
  dataIndex: 'token',
  width: 300,
  ellipsis: true
}, {
  title: 'Last Activity',
  key: 'lastActivityAt',
  dataIndex: 'lastActivityAt',
  width: 180,
  ellipsis: true
}, {
  title: 'Created At',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 180,
  ellipsis: true
}, {
  title: 'Actions',
  dataIndex: 'actions',
  key: 'actions',
  align: 'center',
  width: 100,
  fixed: 'right'
}]

export default defineComponent({
  name: 'ExternalApiSettings',
  components: {
    DeleteOutlined,
    EditOutlined,
    CheckOutlined
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const globalSettingsActiveTab = computed(() => store.getters.globalSettingsActiveTab)
    const apiKeyName = ref('')
    const isApiSettings = computed(()=> globalSettingsActiveTab.value === 'apiKeys')
    const apiKeysList = computed(() => store.getters['apiKeys/apiKeysList'])
    const apiKeysListData = computed(() => apiKeysList.value?.map(item => {
      return {
        ...item,
        createdAt: formatDate(item.createdAt),
        lastActivityAt: formatDate(item.lastActivityAt),
        key: item.id
      }

    }))
    const loading = computed(() => store.getters['apiKeys/apiKeysLoading'])

    const editableData = reactive({})
    const tokens = reactive({})

    const startNameEdit = id => {
      editableData[id] = apiKeysListData.value.find(item => id === item.id)?.name
    }

    const createApiKey = () => {
      if (apiKeyName.value) {
        store.dispatch('apiKeys/createApiKey', { name: apiKeyName.value } ).then(()=>{
          apiKeyName.value = ''
        }).catch(e => {
          error(e)
        })
      }
    }

    const deleteApiKey = (id) => {
      store.dispatch('apiKeys/deleteApiKey', { id }).catch(e => {
        error(e)
      })
    }

    const updateApiKey = async (id, name) => {
      store.dispatch('apiKeys/updateApiKey', { id, name }).catch(e => {
        error(e)
      })
    }

    const onNameChange = ({ id }) => {
      if (!editableData[id]) {
        return
      }
      const name = editableData[id]
      updateApiKey(id, name).then(()=>{
        delete editableData[id]
      }).catch(e => {
        error(e)
      })
    }

    const copyApiKey = (id) => {
      copyText(id, undefined, (error, event) => {
        if (error) {
        } else {
          success(t('components.externalApiSettings.copiedSuccessfully'))
        }
      })
    }

    const revealAndCopyKey = (id) => {
      store.dispatch('apiKeys/resolveApiKeyValue', { id }).then((token) => {
        tokens[id] = token
        copyApiKey(token)
      }).catch(e => {
        error(e)
      })
    }
    watchEffect(()=>{
      if (isApiSettings.value) {
        store.dispatch('apiKeys/getApiKeysList').catch(e => {
          error(e)
        })
      }
    })

    return {
      apiKeyName,
      isApiSettings,
      apiKeysList,
      apiKeysListData,
      loading,
      editableData,
      columns: COLUMNS,
      tokens,
      createApiKey,
      deleteApiKey,
      updateApiKey,
      onNameChange,
      startNameEdit,
      copyApiKey,
      revealAndCopyKey
    }
  }
})
</script>

<style lang="less">

</style>
