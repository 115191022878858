<script>
import { defineComponent } from 'vue'

const ALIGNMENTS = [
  'topLeft',
  'topCenter',
  'topRight',
  'middleLeft',
  'middleCenter',
  'middleRight',
  'bottomLeft',
  'bottomCenter',
  'bottomRight'
]

export default defineComponent({
  name: 'AlignmentSectionItem',
  props: {
    currentAlignment: {
      type: String,
      default: 'middleCenter'
    }
  },
  emits: ['change'],
  setup (_, {emit}) {

    const handleAlignmentChange = (alignment) => {
      emit('change', alignment)
    }

    return {
      alignments: ALIGNMENTS,
      handleAlignmentChange
    }
  }
})

</script>

<template>
  <div class="alignment-settings">
    <div
      v-for="item in alignments"
      :key="item"
      class="alignment-item"
      :class="{active: item === currentAlignment}"
      @click="handleAlignmentChange(item)"
    >
      <div class="alignment-dot" />
    </div>
  </div>
</template>

<style scoped lang="less">
.alignment-settings {
  --background-dot-color: #8C8C8C;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 64px;
  height: 64px;
  align-items: center;
  justify-items: center;
  border: 1px solid #BFBFBF;
  border-radius: 3px;
  cursor: pointer;
  .alignment-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .alignment-dot {
      border: 0;
      border-radius: 1.5px;
      width: 3px;
      height: 3px;
      background-color: var(--background-dot-color);
      display: block;
      padding: 0;
      margin: 0;
      transition: all 0.3s ease;
      &:before, &:after {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        position: absolute;
        background-color: var(--background-dot-color);
        border-radius: 1.5px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 0.3s ease;
      }

    }
    &.active {
      .alignment-dot {
        width: 14px;
        background: var(--ant-primary-color);
        &:before, &:after {
          background: var(--ant-primary-color);
          width: 8px;
        }
        &:before {
          transform: translateY(-5px);
        }
        &:after {
          transform: translateY(5px);
        }
      }
    }
  }
}
</style>
