<script setup>
import VirtualList from 'vue-virtual-draglist'

import { ref } from 'vue'

const draggableList = ref([])

defineProps({
  zoneKey: String,
  dragging: Boolean
})

const emit = defineEmits([
  'slide-add'
])

const onSlideAdd = ({ item }) => {
  const slideType = item.id
  emit('slide-add', slideType)
  draggableList.value = []
}

</script>

<template>
  <VirtualList
    v-model="draggableList"
    class="dragArea"
    :class="{ dragging }"
    :group="{ name: 'widgets', pull: false, put: true}"
    :data-key="'slideKey'"
    :sortable="false"
    :size="40"
    @drop="onSlideAdd"
  >
    <template #item="{ element }">
      <div>{{ element.name }}</div>
    </template>
  </VirtualList>
</template>

<style scoped lang="less">
.dragArea {
  width: 100%;
  min-height: 36px;
  overflow-y: auto;
  border: dashed 2px var(--ant-primary-color);
  display: none;
  &.dragging {
    display: block;
  }
}

</style>
