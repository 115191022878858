<template>
  <icon>
    <template #component>
      <svg
        width="54px"
        height="54px"
        viewBox="0 0 54 54"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <!-- Generator: Sketch 44.1 (41455) - http://www.bohemiancoding.com/sketch -->
        <title>Combined Shape</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Previews/Text/Sale-1-Copy-2"
            transform="translate(-33.000000, -33.000000)"
            fill="#FFFFFF"
          >
            <path
              id="Combined-Shape"
              d="M33,33 L87,33 L87,87 L33,87 L33,33 Z M48,56 L48,57 L73,57 L73,56 L48,56 Z M48,60 L48,61 L73,61 L73,60 L48,60 Z M54,64 L54,65 L67,65 L67,64 L54,64 Z"
            />
          </g>
        </g>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'AlignIcon',
  components: {
    Icon
  }
})
</script>
