<template>
  <a-layout-content style="background: #fff; height: 100%; overflow-x: auto; display: flex; flex-direction: column">
    <NewEventModal
      :is-am-pm="isAmPmFormat"
      :new-event="newEvent"
      :playlists-options="playlistsOptions"
      :open="showPlaylistSelectModal"
      @create="onNewEventCreate"
      @update-time="handleNewEventTimeChange"
      @update-playlist-id="handleNewEventPlaylistSelect"
      @cancel="onNewEventCancel"
    />
    <a-page-header
      :title="$t('components.weekLoopPage.title')"
      style="position: sticky; z-index: 3; top: 0;"
    >
      <template #extra>
        <div style="display: flex; gap: 8px; align-items: center;">
          <a-switch
            v-model:checked="isAmPmFormat"
            :checked-children="$t('components.weekLoopPage.ampmFormat')"
            :un-checked-children="$t('components.weekLoopPage.24HFormat')"
          />
        </div>
      </template>
      <CalendarPageSubtitle v-if="!isSmartGroup" />
    </a-page-header>
    <div
      ref="calendarWrapper"
      class="week-loop-calendar"
    >
      <CalendarEventModal
        v-model:visible="showCalendarEventModal"
        :is-am-pm="isAmPmFormat"
        :event="selectedEvent"
        repeatable
        @update="handleEventModalUpdate"
        @remove="removeEvent"
      />
      <FullCalendar
        ref="calendar"
        class="week-loop-view"
        :options="calendarOptions"
      >
        <template #eventContent="{timeText, event}">
          <b
            v-if="event.extendedProps.paused"
            style="color: rgba(0,0,0,0.3)"
          >{{ timeText }}</b>
          <b v-else>{{ timeText }}</b>
          <a-tooltip
            v-if="event.extendedProps.paused"
            :title="$t('components.weekLoopPage.pausedTooltipTitle')"
            placement="topLeft"
          >
            <i style="color: #000; font-weight: normal"><PauseCircleFilled
              style="margin-right: 2px;"
            /> {{ event.title }}</i>
          </a-tooltip>
          <i v-else>{{ event.title }}</i>
        </template>
      </FullCalendar>
    </div>
  </a-layout-content>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, watch, watchEffect } from 'vue'
import moment from 'moment'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentPlugin from '@fullcalendar/moment'
import { cloneDeep } from 'lodash-es'
import { error, getIsAmPmFormatFromLocalStorage, setIsAmPmFormatToLocalStorage, success } from '@/utils'
import { DAYS, EVENT_BASE } from '@/constants'
import interactionPlugin from '@fullcalendar/interaction'
import CalendarEventModal from '@/components/inputModals/CalendarEventModal.vue'
import { PauseCircleFilled } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import {
  eventWeekLoopToDto as eventToDto,
  isAnOverlapEvent,
  mapElementsToEvents,
  WEEKLY_LOOP_TYPE
} from '@/helpers/Calendar'
import { useI18n } from 'vue-i18n'
import CalendarPageSubtitle from '@/components/CalendarPageSubtitle.vue'
import NewEventModal from '@/components/inputModals/NewEventModal.vue'

const DEFAULT_EVENT_TYPE = 'REPEATABLE_WEEKLY_WINDOW'

export default defineComponent({
  name: 'WeekLoopPage',
  components: {
    NewEventModal,
    CalendarPageSubtitle,
    CalendarEventModal,
    FullCalendar,
    PauseCircleFilled
  },
  props: {
    visible: Boolean
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const events = ref([])
    const calendar = ref()
    const calendarWrapper = ref()
    const showCalendarEventModal = ref(false)
    const showPlaylistSelectModal = ref(false)
    const selectedEvent = reactive(cloneDeep(EVENT_BASE))
    const schedule = computed(() => store.getters['groups/currentGroupSchedule'])
    const parentSchedules = computed(() => store.getters['groups/currentGroupParentSchedules'])
    const playlists = computed(() => store.getters['groups/currentGroupPlaylists'])
    const isSmartGroup = computed(() => store.getters['groups/currentGroupTypeIsSmart'])
    const scheduleId = computed(() => schedule.value?.id)
    const scheduleElements = computed(() => schedule.value?.data?.elements || [])
    const parentScheduleElements = computed(() => parentSchedules.value || [])
    const playlistsOptions = computed(() => playlists.value?.map(p => ({ label: p.name, value: p.id, color: p.color, disabled: p.isMainPlaylist })))
    const newEvent = reactive({
      playlistId: null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null
    })
    let calendarWrapperWidth = null
    let calendarApi
    const isAmPmFormat = ref(getIsAmPmFormatFromLocalStorage())

    onMounted(() => {
      window.addEventListener('click', detectWidthChange)
      calendarApi = calendar.value.getApi()
      calendarWrapperWidth = calendarWrapper.value.clientWidth
      nextTick(() => {
        calendarApi.updateSize()
      })
    })

    onUnmounted(() => {
      window.removeEventListener('click', detectWidthChange)
    })

    const handleWeekendsToggle = () => {
      calendarOptions.value.weekends = !calendarOptions.value.weekends // update a property
    }

    const handleDateSelect = ({ startStr, endStr }) => {
      newEvent.startDate = startStr
      newEvent.endDate = endStr
      newEvent.startTime = moment(startStr).format('HH:mm')
      newEvent.endTime = moment(endStr).format('HH:mm')
      const events = calendarApi.getEvents()?.filter(e => e.extendedProps.element.type === WEEKLY_LOOP_TYPE)
      if (isAnOverlapEvent(startStr, endStr, events)) {
        error(t('components.weekLoopPage.eventsOverlapError'))
        calendarApi.unselect()
        return
      }
      showPlaylistSelectModal.value = true
      return true
    }

    const handleEventClick = (input) => {
      if (input.event.display === 'background') {
        return
      }
      const timeTo = moment(input.event.endStr).format('HH:mm')
      selectedEvent.eventId = input.event.id
      selectedEvent.playlistName = input.event.title
      selectedEvent.playlistId = input.event.id
      selectedEvent.color = input.event.extendedProps.color
      selectedEvent.paused = input.event.extendedProps.paused
      selectedEvent.timeFrom = moment(input.event.startStr).format('HH:mm')
      selectedEvent.timeTo = timeTo === selectedEvent.timeFrom ? '24:00' : timeTo
      selectedEvent.day = DAYS[input.event.start.getDay()]
      selectedEvent.event = input.event
      showCalendarEventModal.value = true
    }

    const detectWidthChange = () => {
      setTimeout(() => {
        if (calendarWrapper.value !== null && calendarWrapper.value?.clientWidth !== calendarWrapperWidth) {
          calendarApi.updateSize()
          calendarWrapperWidth = calendarWrapper.value?.clientWidth
        }
      }, 300)
    }

    const onPlaylistConfirm = () => {
      showPlaylistSelectModal.value = false
    }

    const normalizeEvent = ({ playlistId, startWeeklyWindowTimeMs, endWeeklyWindowTimeMs, type, startDate, endDate }) => {
      return {
        playlistId,
        startWeeklyWindowTimeMs,
        endWeeklyWindowTimeMs,
        type,
        startDate,
        endDate
      }
    }

    const handleScheduleUpdate = (elements) => {
      const payload = {
        id: scheduleId.value,
        input: {
          data: {
            elements
          }
        }
      }
      return store.dispatch('groups/updateCurrentGroupSchedule', payload).then(() => {
        success()
      })
    }

    const createEvent = () => {
      const elements = [...cloneDeep(scheduleElements.value.map(normalizeEvent)), {
        ...eventToDto(newEvent),
        type: DEFAULT_EVENT_TYPE
      }]
      handleScheduleUpdate(elements)
    }

    const removeEvent = (index) => {
      const elements = [...cloneDeep(scheduleElements.value.map(normalizeEvent))]
      elements.splice(index, 1)

      handleScheduleUpdate(elements)
    }

    const handleEventChange = (info) => {
      const { startStr: startDate, endStr: endDate, id } = info.event
      const event = eventToDto({ startDate, endDate })
      const elements = [...cloneDeep(scheduleElements.value.map(normalizeEvent))]
      elements[id] = { ...elements[id], ...event }
      return handleScheduleUpdate(elements).catch(() => {
        info.revert()
      })
    }

    const handleEventDragStop = (info) => {
      return handleEventChange(info)
    }

    const handleEventResizeStop = (info) => {
      return handleEventChange(info)
    }

    const handleNewEventPlaylistSelect = (value) => {
      newEvent.playlistId = value
    }

    const onNewEventCreate = () => {
      showPlaylistSelectModal.value = false
      createEvent()
    }

    const onNewEventCancel = () => {
      showPlaylistSelectModal.value = false
      calendarApi.unselect()
      newEvent.startDate = null
      newEvent.endDate = null
      newEvent.startTime = null
      newEvent.endTime = null
      newEvent.playlistId = null
    }

    const handleNewEventTimeChange = ({ timeFrom, timeTo }) => {
      const startDate = moment(newEvent.startDate).set({
        hour: parseInt(timeFrom.split(':')[0], 10),
        minute: parseInt(timeFrom.split(':')[1], 10)
      }).toDate()
      const endDate = moment(newEvent.endDate).set({
        hour: parseInt(timeTo.split(':')[0], 10),
        minute: parseInt(timeTo.split(':')[1], 10)
      }).toDate()
      newEvent.startDate = startDate
      newEvent.endDate = endDate
    }

    const handleEventModalUpdate = ({ timeFrom, timeTo, event }) => {
      const calendarEvent = event.event
      const { startStr, endStr } = calendarEvent
      const startDate = moment(startStr)
      const endDate = moment(endStr);

      const newStartDate = startDate.set({
        hour: parseInt(timeFrom.split(':')[0], 10),
        minute: parseInt(timeFrom.split(':')[1], 10),
      }).toDate()

      const newEndDate = endDate.set({
        hour: parseInt(timeTo.split(':')[0], 10),
        minute: parseInt(timeTo.split(':')[1], 10),
      }).toDate()

      const events = calendarApi?.getEvents()?.filter(e => e.id !== event.eventId)

      if (isAnOverlapEvent(newStartDate, newEndDate, events)) {
        error(t('components.calendarPage.eventsOverlapError'))
        return
      }

      calendarEvent.setStart(newStartDate)
      calendarEvent.setEnd(newEndDate)

      const newEvent = eventToDto({ startDate, endDate })
      const elements = [...cloneDeep(scheduleElements.value.map(normalizeEvent))]
      elements[event.eventId] = { ...elements[event.eventId], ...newEvent }
      handleScheduleUpdate(elements).catch((e) => {
        error(e.message)
        calendarEvent.setStart(startStr)
        calendarEvent.setEnd(endStr)
      })
    }

    const calendarOptions = computed(() => ({
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        momentPlugin,
        interactionPlugin // needed for dateClick
      ],
      headerToolbar: '',
      initialView: 'timeGridWeek',
      dayHeaderFormat: { weekday: 'short' },
      events: events.value,
      eventOverlap: function (stillEvent, movingEvent) {
        return stillEvent.display === 'background'
      },
      editable: true,
      height: '100%',
      eventColor: '#00c792',
      nowIndicator: true,
      selectable: true,
      selectMirror: true,
      scrollTimeReset: false,
      scrollTime: '08:00:00',
      slotDuration: '00:15:00',
      snapDuration: '00:15:00',
      firstDay: 0,
      slotLabelInterval: {
        minutes: 30
      },
      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        hour12: isAmPmFormat.value
      },
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        hour12: isAmPmFormat.value
      },
      dayMaxEvents: true,
      weekends: true,
      eventDidMount: function (info) {
        info.el.parentNode.style.zIndex = info.event.extendedProps.order
      },
      select: handleDateSelect,
      eventClick: handleEventClick,
      eventsSet: () => {},
      eventDrop: handleEventDragStop,
      eventResize: handleEventResizeStop
      /* you can update a remote database when these fire:
      eventAdd:
      eventChange:
      eventRemove:
      */
    }))

    watch([scheduleElements, parentScheduleElements], ([a, b]) => {
      const parentElements = cloneDeep(b).map((s, index) => {
        return s.data?.elements.map((e) => ({
          ...e,
          background: true,
          index: (index + 1) * 2
        }))
      }).flat(1)
      events.value = mapElementsToEvents([...a.map(e => ({ ...e, index: 0 })), ...parentElements])
    })

    watchEffect(() => {
      store.dispatch('groups/getCurrentGroupSchedules')
    })

    watch(isAmPmFormat, (newValue) => {
      setIsAmPmFormatToLocalStorage(newValue)
    })

    return {
      newEvent,
      calendar,
      calendarOptions,
      playlistsOptions,
      calendarWrapper,
      showCalendarEventModal,
      showPlaylistSelectModal,
      selectedEvent,
      isAmPmFormat,
      isSmartGroup,
      removeEvent,
      onNewEventCreate,
      onNewEventCancel,
      onPlaylistConfirm,
      handleWeekendsToggle,
      handleEventModalUpdate,
      handleNewEventTimeChange,
      handleNewEventPlaylistSelect
    }
  }
})
</script>

<style lang="less">
@import '../styles/variables';
.week-loop-calendar {
  padding: 16px;
  flex: 1;
}
.week-loop-view {
  .fc-col-header-cell-cushion {
    cursor: default;
    color: @text-color-secondary-dark;
  }
}
.fc-now-indicator.fc-now-indicator-arrow::before {
  background-color: #ef6c00;
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-top: -7px;
}

.fc-now-indicator.fc-now-indicator-arrow {
  left: 44px !important;
  right: 0;
  background-color: #ef6c00;
  height: 2px;
  margin: 0;
  border: none;
}

</style>
