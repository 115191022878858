<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()
const rootGroupMainPlaylistId = computed(() => store.getters['groups/rootGroupMainPlaylist']?.id)
const rootGroup = computed(() => store.getters['groups/rootGroup'])

const goToMainPlaylist = () => {
  if (!rootGroup.value) return
  router.push({ name: 'PlaylistPage', params: { groupId: rootGroup.value?.id, playlistId: rootGroupMainPlaylistId.value }})
}

</script>

<template>
  <a-typography type="secondary">
    <i18n-t
      keypath="components.CalendarPageSubtitle.subtitle"
      tag="span"
      for="components.CalendarPageSubtitle.playlistLink"
    >
      <a-typography-link @click="goToMainPlaylist">
        {{ $t('components.CalendarPageSubtitle.playlistLink') }}
      </a-typography-link>
    </i18n-t>
  </a-typography>
</template>

<style scoped lang="less">

</style>
