import { gql } from 'graphql-tag'
import {
  API_KEYS_BASE,
  DEVICE_BASE,
  GROUP_SCHEDULE,
  PAGINATION,
  PLAYLIST_BASE,
  PLAYLIST_CHANGELOGS,
  PLAYLIST_EXTENDED, PROMO_CODE_INFO,
  SLIDE_BASE,
  SMART_GROUP_BASE,
  SMART_GROUP_EXTENDED,
  SMART_GROUP_PLAYLISTS_BASE,
  SOCIAL_ACCOUNT_BASE,
  SOCIAL_ACCOUNTS_ALL_BASE,
  STANDARD_GROUP_BASE,
  STANDARD_GROUP_EXTENDED,
  STANDARD_GROUP_PLAYLISTS_BASE,
  STATIC_MEDIA_BASE,
  STRIPE_BILLING_INFO,
  STRIPE_PRICE_INFO,
  SUBSCRIPTION, UPDATED_PLAYLIST,
  USER_BASE, USER_PROFILE_BASE,
  WIDGET,
  WORKSPACE_BASE,
  WORKSPACE_BILLING_INFO_BASE,
  WORKSPACE_CHANGE_LOGS,
  WORKSPACE_CUSTOMER,
  WORKSPACE_DIRECTORY_MEDIA_EXTENDED,
  WORKSPACE_FEATURES,
  WORKSPACE_MEDIA_BASE, WORKSPACE_MEMBER,
  WORKSPACE_ROLE_NOTIFICATION_SETTINGS,
  WORKSPACE_ROLE_RESTRICTIONS,
  WORKSPACE_SETTINGS,
  WORKSPACE_UPLOADABLE_FILE_MEDIA_EXTENDED
} from '@/graphql/fragments'

// AUTH

export const LIST_SESSIONS = gql`
  query listMySessions {
    listMySessions {
      sessionId
      ip
      country
      isCurrent
      createdAt
      updatedAt
    }
  }
`

export const GET_PENDING_INVITE_BY_TOKEN = gql`
  query getPendingInviteByToken($token: String!) {
    getPendingInviteByToken(token: $token) {
      email
      workspaceId
      workspace {
        name
        id
      }
      author {
        id
        email
      }
    }
  }
`

export const LIST_MY_PENDING_INVITES= gql`
  query listMyPendingInvites {
    listMyPendingInvites {
      id
      email
      workspaceId
      workspace {
        name
        id
      }
      author {
        id
        email
      }
    }
  }
`


// PROFILE

export const LOGGED_IN_USER = gql`
  ${STATIC_MEDIA_BASE}
  ${USER_PROFILE_BASE}
  query {
    getMyProfile {
      ...userProfileBase
      features {
        ownedWorkspacesLimit
      }
      avatarMedia {
        ...staticMediaBase
      }
    }
  }
`

export const CHECK_SESSION_STATUS = gql`
  query {
    getMyProfile {
      id
    }
  }
`

export const GET_PROFILE_AVATAR = gql`
  ${STATIC_MEDIA_BASE}
  query {
    getMyProfile {
      id
      avatarMedia {
        ...staticMediaBase
      }
    }
  }
`

// GROUPS

export const GET_AVAILABLE_STANDARD_GROUPS = gql`
  ${STANDARD_GROUP_BASE}
  ${STANDARD_GROUP_PLAYLISTS_BASE}
  query listAvailableStandardGroups ($filters: ListStandardGroupsFiltersInput){
    listAvailableStandardGroups(filters: $filters) {
      ...standardGroupBase
      ...standardGroupPlaylistsBase
    }
  }
`

export const GET_AVAILABLE_SMART_GROUPS = gql`
  ${SMART_GROUP_BASE}
  ${SMART_GROUP_PLAYLISTS_BASE}
  query listAvailableSmartGroups {
    listAvailableSmartGroups {
      ...smartGroupBase
      ...smartGroupPlaylistsBase
    }
  }
`

export const GET_GROUP_BY_ID = gql`
  ${STANDARD_GROUP_EXTENDED}
  ${SMART_GROUP_EXTENDED}
  query getGroupById ($id: String!) {
    getGroupById(
      id: $id
    ) {
      ...standardGroupExtended
      ...smartGroupExtended
      ...on StandardGroupModel {
        schedule {
          id
          recursiveForcedPlaylist {
            id
            name
            groupId
          }
          data {
            forcedPlaylist {
              id
              name
              groupId
            }
          }
        }
      }
      ...on SmartGroupModel {
        schedule {
          id
          recursiveForcedPlaylist {
            id
            name
            groupId
          }
          data {
            forcedPlaylist {
              id
              name
              groupId
            }
          }
        }
      }
    }
  }
`

export const GET_GROUP_PLAYLISTS_BY_ID = gql`
  ${PLAYLIST_BASE}
  query getGroupById ($id: String!) {
    getGroupById(
      id: $id
    ) {
      id
      ...on StandardGroupModel {
        playlists {
          ...playlistBase
        }
      }
      ...on SmartGroupModel {
        playlists {
          ...playlistBase
        }
      }
    }
  }
`

export const GET_GROUP_BY_ID_WITH_PARENTS = gql`
  ${PLAYLIST_BASE}
  ${DEVICE_BASE}
  ${STANDARD_GROUP_EXTENDED}
  query getGroupByIdWithParents ($id: String!) {
    getGroupByIdWithParents(
      id: $id
    ) {
      ...standardGroupExtended
      devices {
        ...deviceBase
      }
      schedule {
        id
      }
      playlists {
        ...playlistBase
      }
    }
  }
`

export const GET_GROUP_SCHEDULE_ID = gql`
  query getGroupById ($id: String!) {
    getGroupById(
      id: $id
    ) {
      schedule {
        id
      }
    }
  }
`

export const GET_GROUP_SCHEDULES = gql`
  ${GROUP_SCHEDULE}
  query getHierarchySchedulesByGroupId ($groupId: String!) {
    getHierarchySchedulesByGroupId(
      groupId: $groupId
    ) {
      ...groupSchedule
    }
  }
`

export const GET_GROUP_STANDARD_SCHEDULES_BY_ID_WITH_PARENTS = gql`
  ${STANDARD_GROUP_BASE}
  ${GROUP_SCHEDULE}
  query getStandardGroupByIdWithParents ($id: String!) {
    getStandardGroupByIdWithParents(
      id: $id
    ) {
      ...standardGroupBase
      schedule {
        ...groupSchedule
      }
    }
  }
`

export const GET_GROUP_SMART_SCHEDULES_BY_ID = gql`
  ${SMART_GROUP_BASE}
  ${GROUP_SCHEDULE}
  query getSmartGroupById ($id: String!) {
    getSmartGroupById(
      id: $id
    ) {
      ...smartGroupBase
      schedule {
        ...groupSchedule
      }
    }
  }
`

export const GET_GROUP_SCHEDULE_BY_ID = gql`
  ${STANDARD_GROUP_BASE}
  ${GROUP_SCHEDULE}
  query getGroupById ($id: String!) {
    getGroupById(
      id: $id
    ) {
      ...standardGroupBase
      schedule {
        ...groupSchedule
      }
    }
  }
`

// TAGS
export const LIST_TAGS = gql`
  query listTags($pagination: OffsetPaginationInput $filters: ListTagsFiltersInput) {
    listTags(pagination: $pagination filters: $filters) {
      pagination {
        limit
        offset
        total
      }
      data {
        key
        title
        isSystemGenerated
      }
    }
  }
`
export const GET_DEVICES_BY_MATCHING_RULE = gql`
  ${DEVICE_BASE}
  query getDevicesBySmartGroupMatchingRule($input: GetDevicesBySmartGroupMatchingRule!) {
    getDevicesBySmartGroupMatchingRule(input: $input) {
      ...deviceBase
    }
  }
`


// WORKSPACES

export const GET_WORKSPACE_PUBLIC_INFO_BY_WHITE_LABEL = gql`
  ${STATIC_MEDIA_BASE}
  query getWorkspacePublicInfoByWhitelabel ($whitelabel: String!) {
    getWorkspacePublicInfoByWhitelabel(whitelabel: $whitelabel) {
      id
      name
      whitelabel
      whitelabelLogoMedia {
        ...staticMediaBase
      }
      whitelabelFaviconMedia {
        ...staticMediaBase
      }
      settings {
        whitelabelAccentColor
      }
    }
  }
`

export const GET_WORKSPACE_ID_BY_SHORT_ID = gql`
  query getWorkspacePublicInfoByShortId ($shortId: String!) {
    getWorkspacePublicInfoByShortId(shortId: $shortId) {
      id
      ssoAvailable
    }
  }
`

export const GET_WORKSPACES = gql`
  ${WORKSPACE_BASE}
  query listMyWorkspaces {
    listMyWorkspaces {
      ...workspaceBase
    }
  }
`

export const GET_CURRENT_WORKSPACE = gql`
  ${WORKSPACE_BASE}
  ${WORKSPACE_FEATURES}
  ${WORKSPACE_SETTINGS}
  ${WORKSPACE_ROLE_NOTIFICATION_SETTINGS}
  ${WORKSPACE_ROLE_RESTRICTIONS}
  ${WORKSPACE_BILLING_INFO_BASE}
  ${STATIC_MEDIA_BASE}
  query getCurrentWorkspace {
    getCurrentWorkspace {
      deviceInitializationToken
      pendingStripeSubscriptionUpdate {
        billingInterval
        billingDevicesQuantity
        scheduledAtTimestampMs
      }
      ...workspaceBase
      appearance {
        menuItems
      }
      whitelabel
      enterpriseRequestFormSubmittedAt
      features {
        ...workspaceFeatures
      }
      whitelabelLogoMedia {
        ...staticMediaBase
      }
      whitelabelFaviconMedia {
        ...staticMediaBase
      }
      watermarkMedia {
        ...staticMediaBase
      }
      billingInfo {
        ...billingInfoBase
      }
      settings {
        ...settings
      }
      myMemberRole {
        role
        restrictions {
          ...restrictions
        }
        notificationSettings {
          ...notificationSettings
        }
      }
    }
  }
`

export const GET_CURRENT_WORKSPACE_BILLING_INFO = gql`
  ${WORKSPACE_BILLING_INFO_BASE}
  query getCurrentWorkspace {
    getCurrentWorkspace {
      billingInfo {
        ...billingInfoBase
      }
    }
  }
`

export const GET_CURRENT_WORKSPACE_BILLING_INFO_AND_FROZEN_STATUSES = gql`
  ${WORKSPACE_BILLING_INFO_BASE}
  query getCurrentWorkspace {
    getCurrentWorkspace {
      billingInfo {
        ...billingInfoBase
      }
      frozen
      frozenReason
    }
  }
`

export const GET_CURRENT_WORKSPACE_EDU_TIER_APPLICATION = gql`
  query getCurrentWorkspace {
    getCurrentWorkspace {
      eduTierApplication {
        applied
        available
        shown
      }
    }
  }
`

export const GET_CURRENT_WORKSPACE_LOGO_AND_FAVICON = gql`
  ${STATIC_MEDIA_BASE}
  query getCurrentWorkspace {
    getCurrentWorkspace {
      id
      whitelabelLogoMedia {
        ...staticMediaBase
      }
      whitelabelFaviconMedia {
        ...staticMediaBase
      }
    }
  }
`

export const GET_CURRENT_WORKSPACE_WATERMARK = gql`
  ${STATIC_MEDIA_BASE}
  query getCurrentWorkspace {
    getCurrentWorkspace {
      id
      watermarkMedia {
        ...staticMediaBase
      }
    }
  }
`

export const GET_WORKSPACE_MEMBERS = gql`
  ${WORKSPACE_MEMBER}
  query listCurrentWorkspaceMemberRoles {
    listCurrentWorkspaceMemberRoles {
      ...workspaceMember
    }
  }
`
export const GET_WORKSPACE_PENDING_INVITES = gql`
  ${WORKSPACE_ROLE_RESTRICTIONS}
  query listCurrentWorkspacePendingInvites {
    listCurrentWorkspacePendingInvites {
      id
      email
      status
      role
      restrictions {
        ...restrictions
      }
      createdAt
    }
  }
`
export const GET_WORKSPACE_PENDING_INVITE_LINK = gql`
  query getCurrentWorkspacePendingInviteLink ($inviteId: String!) {
    getCurrentWorkspacePendingInviteLink(inviteId: $inviteId) 
  }
`



export const GET_WORKSPACE_SUBSCRIPTION = gql`
  ${SUBSCRIPTION}
  query getWorkspaceSubscription{
    getWorkspaceSubscription {
      ...subscriptionInfo
    }
  }
`

export const GET_WORKSPACE_SSO_IDENTITY_PROVIDER = gql`
  query getSsoIdentityProvider{
    getSsoIdentityProvider {
      id
      type
      clientId
      clientSecret
      issuer
      createdAt
      updatedAt
      workspaceId
      authorIdentityId
    }
  }
`

export const GET_WORKSPACE_ACTIVITY_LOGS = gql`
  ${PAGINATION}
  ${STATIC_MEDIA_BASE}
  ${WORKSPACE_CHANGE_LOGS}
  query listActivityLogs($filters: ListActivityLogsFiltersInput $pagination: OffsetPaginationInput!) {
    listActivityLogs(filters: $filters pagination: $pagination) {
      pagination { 
        ...pagination
      }
      data {
        ...workspaceChangelogs
      }
    }
  }
`

// PLAYLISTS

export const GET_EMERGENCY_PLAYLISTS = gql`
  ${PLAYLIST_EXTENDED}
  ${SLIDE_BASE}
  ${WIDGET}
  query listPlaylists($filters: ListPlaylistsFiltersInput!) {
    listPlaylists(filters: $filters) {
      ...playlistExtended
      layout {
        type
        zones {
          id
          slides {
            ...slideBase
            widget {
              ...widget
            }
          }
        }
      }
    }
  }
`

export const GET_PLAYLIST_BY_ID = gql`
  ${UPDATED_PLAYLIST}
  query getPlaylist($playlistVersion: Int $id: String!) {
    getPlaylist(playlistVersion: $playlistVersion playlistId: $id) {
      ...updatedPlaylist
    }
  }
`
export const GET_PLAYLIST_VERSIONS = gql`
  ${PLAYLIST_EXTENDED}
  ${PLAYLIST_CHANGELOGS}
  ${PAGINATION}
  query getPlaylistVersions($playlistId: String! $pagination: OffsetPaginationInput!) {
    getPlaylistVersions(playlistId: $playlistId pagination: $pagination) {
      pagination { 
        ...pagination
      }
      data {
        version
        ...playlistExtended
        changeLogs {
          ...playlistChangelogs
        }
      }
    }
  }
`

export const GET_PLAYLIST_CHANGELOGS = gql`
  ${PLAYLIST_CHANGELOGS}
  ${PAGINATION}
  query listPlaylistChangeLogPatches($playlistId: String! $pagination: OffsetPaginationInput!) {
    listPlaylistChangeLogPatches(playlistId: $playlistId pagination: $pagination) {
      pagination { 
        ...pagination
      }
      data {
        playlistId
        playlistVersion
        changeLogs {
          ...playlistChangelogs
        }
      }
    }
  }
`

// SCHEDULES

export const GET_SCHEDULE_BY_ID = gql`
  query getScheduleById($id: String!) {
    getScheduleById(id: $id) {
      id
      createdAt
      updatedAt
      groupId
      authorIdentityId
    }
  }
`

export const LIST_WIDGET_DISPLAY_RESTRICTIONS = gql`
  query listWidgetDisplayRestrictions {
    listWidgetDisplayRestrictions {
      playlistLayoutType
      zones {
        bannedWidgets {
          widgetType
          additionalData
        }
      }
    }
  }
`

// MEDIA

export const GET_MEDIA_BY_ID = gql`
  ${WORKSPACE_MEDIA_BASE}
  ${WORKSPACE_DIRECTORY_MEDIA_EXTENDED}
  ${WORKSPACE_UPLOADABLE_FILE_MEDIA_EXTENDED}
  query getWorkspaceMediaById($id: String!) {
    getWorkspaceMediaById(id: $id) {
      ...workspaceMediaBase
      ...workspaceDirectoryMediaExtended
      ...workspaceUploadableFileMediaExtended
    }
  }
`
export const LIST_WORKSPACE_MEDIA = gql`
  ${WORKSPACE_MEDIA_BASE}
  ${WORKSPACE_DIRECTORY_MEDIA_EXTENDED}
  ${WORKSPACE_UPLOADABLE_FILE_MEDIA_EXTENDED}
  query listWorkspaceMedia($filters: ListWorkspaceMediaFiltersInput!) {
    listWorkspaceMedia(filters: $filters) {
      ...workspaceMediaBase
      ...workspaceDirectoryMediaExtended
      ...workspaceUploadableFileMediaExtended
    }
  }
`

export const GET_GOOGLE_DRIVES = gql`
  query googleDriveListDrives($googleSocialAccountId: String! $params: GoogleDriveDriveListParams) {
    googleDriveListDrives(googleSocialAccountId: $googleSocialAccountId params: $params) {
      drives {
        id
        name
        colorRgb
      }
      nextPageToken
    }
  }
`

export const GET_GOOGLE_DRIVE_FILES = gql`
  query googleDriveListFiles($googleSocialAccountId: String! $params: GoogleDriveFileListParams) {
    googleDriveListFiles(googleSocialAccountId: $googleSocialAccountId params: $params) {
      files {
        createdTime
        driveId
        folderColorRgb
        fullFileExtension
        iconLink
        id
        mimeType
        modifiedTime
        name
        originalFilename
        ownedByMe
        sharedWithMeTime
        size
        starred
        thumbnailLink
        trashed
        trashedTime
        version
        webContentLink
        webViewLink
      }
      nextPageToken
    }
  }
`

export const GET_DROPBOX_FILES = gql`
  query dropboxFilesListFolder($dropboxSocialAccountId: String! $params: DropboxListFolderParams!) {
    dropboxFilesListFolder(dropboxSocialAccountId: $dropboxSocialAccountId params: $params) {
      entries {
        name
        path_lower
        path_display
        preview_url
        ...on DropboxListFolderEntryFile {
          id
          client_modified
          server_modified
          rev
          size
          is_downloadable
          content_hash
        }
        ...on DropboxListFolderEntryFolder {
          id
        }
      }
      cursor
      has_more
    }
  }
`

export const GET_DROPBOX_FILES_CONTINUE = gql`
  query dropboxFilesListFolderContinue($dropboxSocialAccountId: String! $params: DropboxListFolderContinueParams!) {
    dropboxFilesListFolderContinue(dropboxSocialAccountId: $dropboxSocialAccountId params: $params) {
      entries {
        name
        path_lower
        path_display
        preview_url
        ...on DropboxListFolderEntryFile {
          id
          client_modified
          server_modified
          rev
          size
          is_downloadable
          content_hash
        }
        ...on DropboxListFolderEntryFolder {
          id
        }
      }
      cursor
      has_more
    }
  }
`

// DEVICES

export const LIST_DEVICES = gql`
  ${DEVICE_BASE}
  query listDevices($filters: ListDevicesFiltersInput!) {
    listDevices(filters: $filters) {
      ...deviceBase
    }
  }
`

export const GET_DEVICE_BY_ID = gql`
  ${DEVICE_BASE}
  query getDeviceById($id: String!) {
    getDeviceById(id: $id) {
      ...deviceBase
    }
  }
`

export const GET_DEVICE_PREVIEW_WEBRTC_ICE_SERVERS = gql`
  query getDevicePreviewWebrtcIceServers($deviceId: String!) {
    getDevicePreviewWebrtcIceServers(deviceId: $deviceId) {
      urls
      username
      credential
      credentialType
    }
  }
`

// SOCIAL

export const LIST_SOCIAL_ACCOUNTS = gql`
  ${SOCIAL_ACCOUNT_BASE}
  ${SOCIAL_ACCOUNTS_ALL_BASE}
  query listSocialAccounts {
    listSocialAccounts {
      ...socialAccountBase
      ...socialAccountsAllModelsBase
    }
  }
`

export const GET_SOCIAL_ACCOUNT_BY_ID = gql`
  ${SOCIAL_ACCOUNT_BASE}
  query getSocialAccountById($id: String!) {
    getSocialAccountById(id: $id) {
      ...socialAccountBase
    }
  }
`

export const GET_AVAILABLE_CALENDARS_BY_GOOGLE_SOCIAL_ACCOUNT_ID = gql`
  query getAvailableCalendarsByGoogleSocialAccountId($googleSocialAccountId: String!) {
    getAvailableCalendarsByGoogleSocialAccountId(googleSocialAccountId: $googleSocialAccountId) {
      id
      backgroundColor
      description
      foregroundColor
      summary
    }
  }
`
export const GET_AVAILABLE_CALENDARS_BY_MICROSOFT_SOCIAL_ACCOUNT_ID = gql`
  query getAvailableCalendarsByMicrosoftSocialAccountId($microsoftSocialAccountId: String!) {
    getAvailableCalendarsByMicrosoftSocialAccountId(microsoftSocialAccountId: $microsoftSocialAccountId) {
      id
      name
    }
  }
`

// SUBSCRIPTION

export const GET_PAYMENT_METHOD = gql`
  query getPaymentMethod {
    getPaymentMethod {
      card {
        brand
        country
        exp_month
        exp_year
        last4
      }
      type
    }
  }
`

export const GET_STRIPE_GROWTH_PRICES = gql`
  ${STRIPE_PRICE_INFO}
  query getStripeGrowthPrices {
    getStripeGrowthPrices {
      monthly {
        ...priceInfo
      }
      yearly {
        ...priceInfo
      }
    }
  }
`

export const GET_WORKSPACE_CUSTOM_PRICE = gql`
  ${STRIPE_PRICE_INFO}
  query getWorkspaceStripeCustomPrice {
    getWorkspaceStripeCustomPrice {
      ...priceInfo
    }
  }
`

export const LIST_WORKSPACE_INVOICES = gql`
  query listWorkspaceInvoices ($params: StripeListInvoicesParams) {
    listWorkspaceInvoices(params: $params) {
      data {
        id
        charge {
          receipt_pdf
        }
        invoice_pdf
        created
        paid
      }
      has_more
    }
  }
`

export const GET_WORKSPACE_CUSTOMER = gql`
  ${WORKSPACE_CUSTOMER}
  query getWorkspaceCustomer {
    getWorkspaceCustomer {
      ...customer
    }
  }
`

export const GET_ACTIVE_COUPONS = gql`
  ${PROMO_CODE_INFO}
  query getActiveCoupons {
    getActiveCoupons {
      ...promoCodeInfo
    }
  }
`



// CONFIG

export const GET_APPLICATION_PUBLIC_CONFIG = gql`
  query getApplicationPublicConfig {
    getApplicationPublicConfig {
      emailUnverifiedUsersDaysToDelete
    }
  }
`

// NOTIFICATIONS

export const GET_UNSEEN_NOTIFICATIONS_COUNT = gql`
  query getUnseenNotificationsCount {
    getUnseenNotificationsCount 
  }
`

export const GET_NOTIFICATIONS_LIST = gql`
  ${PAGINATION}
  ${STRIPE_BILLING_INFO}
  query listNotifications ($pagination: OffsetPaginationInput) {
    listNotifications (pagination: $pagination) {
      pagination { 
        ...pagination
      }
      data {
        id
        type
        seen
        createdAt
        ...on NotificationDeviceStatusesChangedModel {
          data {
            deviceNames
            devicesCount
            online
          }
        }
        ...on NotificationMediaPendingAutoDeleteModel {
          data {
            unusedMediaCount
          }
        }
        ...on NotificationMediaAutoDeleteInUseModel {
          data {
            mediaName
          }
        }
        ...on NotificationUserRoleChangedModel {
          data {
            newRole
          }
        }
        ...on NotificationWorkspaceSubscriptionPaymentFailedModel {
          data {
            attemptNumber
          }
        }
        ...on NotificationWorkspaceSubscriptionUpdatedModel {
          data {
            newBillingInfo {
              ...stripeBillingInfo
            }
            previousBillingInfo {
              ...stripeBillingInfo
            }
          }
        }
      }
    }
  }
`

export const GET_INFO = gql`
  query getInfo {
    getInfo {
      ok
    }
  }
`
export const ON_INFO = gql`
  subscription onInfoSubscription {
    onInfoSubscription {
      ok
    }
  }
`

// COMPOSER

export const GET_COMPOSER_JSON = gql`
  query getWidgetById($id: String!) {
    getWidgetById(id: $id) {
      id
      ...on WidgetImageComposerModel {
        jsonMedia {
          url
        }
      }
    }
  }
`

// OPENAI

export const GET_OPEN_AI_COMPLETION = gql`
  query createOpenAICompletion($prompt: String!) {
    createOpenAICompletion(prompt: $prompt)
  }
`

export const SEARCH_PEXELS_PHOTOS = gql`
  query searchPexelsPhotos($query: String!) {
    searchPexelsPhotos(query: $query) {
      page
      per_page
      total_results
      photos {
        id
        width
        height
        url
        alt
        avg_color
        photographer
        photographer_url
        photographer_id
        liked
        src {
          original
          large2x
          large
          medium
          small
          portrait
          landscape
          tiny
        }
      }
    }
  }
`

export const SEARCH_PEXELS_VIDEOS = gql`
  query searchPexelsVideos($query: String!) {
    searchPexelsVideos(query: $query) {
      page
      per_page
      total_results
      videos {
        id
        url
        video_files {
          id
          quality
          width
          height
          link
          fps
        }
      }
    }
  }
`

//YOUTUBE

export const GET_YOUTUBE_VIDEO_INFO = gql`
  query getYoutubeVideoInfo($videoId: String!) {
    getYoutubeVideoInfo(videoId: $videoId) {
      snippet {
        title
        thumbnails {
          medium {
            url
          }
        }
      }
      contentDetails {
        duration
      }
    }
  }
`

// TICKERS

export const SEARCH_TICKERS = gql`
  query searchTickers($search: String $exchange: String $limit: Int $offset: Int) {
    searchTickers(search: $search exchange: $exchange limit: $limit offset: $offset) {
      data {
        name
        symbol
      }
      pagination {
        count
        limit
        offset
        total
      }
    }
  }
`

export const LIST_TASTY_TAGS = gql`
  query listTastyTags {
    listTastyTags {
      id
      name
      display_name
      type
      root_tag_type
    }
  }
`

export const GET_TED_TALKS_TOPICS = gql`
  query getTedTalksTopics {
    getTedTalksTopics {
      slug
    }
  }
`

export const GET_TED_TALKS_SPEAKERS = gql`
  query getTedTalksSpeakers {
    getTedTalksSpeakers {
      slug
      full_name
    }
  }
`

export const SEARCH_TED_TALKS = gql`
  query searchTedTalks($speaker: String $topic: String) {
    searchTedTalks(speaker: $speaker topic: $topic) {
      id
      url
      title
      description
      audio_language
      event
      publish_date
      record_date
      duration_in_seconds
      thumbnail_url
      mp4_url
      embed_url
    }
  }
`

export const GOOGLE_PLACE_AUTOCOMPLETE = gql`
  query googlePlaceAutocomplete($query: String!) {
    googlePlaceAutocomplete(query: $query) {
      place_id
      description
      types
    }   
  }
`

export const TRIP_ADVISOR_LOCATION_SEARCH = gql`
  query tripAdvisorLocationSearch($searchQuery: String!) {
    tripAdvisorLocationSearch(searchQuery: $searchQuery) {
      address_obj {
        address_string
      }
      location_id
      name
      rating
    }
  }
`

//API KEYS

export const LIST_EXTERNAL_API_KEYS = gql`
  ${API_KEYS_BASE}
  query listExternalApiKeys {
    listExternalApiKeys {
      ...apiKeyBase
    }
  }
`

export const GET_EXTERNAL_API_KEY_BY_ID = gql`
  ${API_KEYS_BASE}
  query getExternalApiKeyById($id: String!){
    getExternalApiKeyById(id: $id){ 
      ...apiKeyBase
    }
  }
`

// PLAYLIST_SOUNDTRACK

export const GET_PLAYLIST_SOUNDTRACK_BY_ID = gql`
  ${STATIC_MEDIA_BASE}
  query getPlaylist($playlistVersion: Int $id: String!) {
    getPlaylist(playlistVersion: $playlistVersion playlistId: $id) {
      mediaAudio {
        ...staticMediaBase
      }
    }
  }
`
