<script setup>
const YEARLY = 'year'
const MONTHLY = 'month'

defineProps({
  disableFields: {
    type: Boolean,
    default: false
  },
  pricesInfo: {
    type: Object,
    default: null
  },
  isYearly: {
    type: Boolean,
    default: false
  },
  isMonthly: {
    type: Boolean,
    default: false
  }
})

defineEmits(['changeBillingInterval'])

</script>

<template>
  <a-list
    v-if="pricesInfo?.monthly && pricesInfo?.yearly"
    size="small"
  >
    <a-list-item>
      <a-list-item-meta>
        <template #title>
          <a-radio
            :checked="isYearly"
            :disabled="disableFields"
            @change="$emit('changeBillingInterval',YEARLY)"
          >
            {{ $t('components.updateSubscriptionModal.yearly') }} <b v-html="$t('components.updateSubscriptionModal.saveUpTo', {number: pricesInfo?.yearlyDiscountPercent})" />
          </a-radio>
        </template>
      </a-list-item-meta>
      <div style="text-align: end">
        <a-space
          direction="vertical"
          size="0"
        >
          <a-typography-text :disabled="disableFields">
            <span v-format-cents="pricesInfo?.yearly?.perMonth" /> {{ $t('components.updateSubscriptionModal.perScreenMonth') }}
          </a-typography-text>
          <a-typography-text
            type="secondary"
            :disabled="disableFields"
          >
            <small>
              <span v-format-cents="pricesInfo?.yearly?.perYear" /> {{ $t('components.updateSubscriptionModal.perScreenYear') }}
            </small>
          </a-typography-text>
        </a-space>
      </div>
    </a-list-item>
    <a-list-item>
      <a-list-item-meta>
        <template #title>
          <a-radio
            :checked="isMonthly"
            :disabled="disableFields"
            @change="$emit('changeBillingInterval',MONTHLY)"
          >
            {{ $t('components.updateSubscriptionModal.monthly') }}
          </a-radio>
        </template>
      </a-list-item-meta>
      <div style="text-align: end">
        <a-space
          direction="vertical"
          size="0"
        >
          <a-typography-text :disabled="disableFields">
            <span v-format-cents="pricesInfo?.monthly?.perMonth" /> {{ $t('components.updateSubscriptionModal.perScreenMonth') }}
          </a-typography-text>
          <a-typography-text
            type="secondary"
            :disabled="disableFields"
          >
            <small>
              <span v-format-cents="pricesInfo?.monthly?.perYear" /> {{ $t('components.updateSubscriptionModal.perScreenYear') }}
            </small>
          </a-typography-text>
        </a-space>
      </div>
    </a-list-item>
  </a-list>
  <template v-else-if="pricesInfo?.yearly">
    <a-space
      direction="vertical"
      size="small"
    >
      <a-typography-text>
        {{ $t('components.updateSubscriptionModal.yearly') }}
      </a-typography-text>
      <a-space
        direction="vertical"
        size="0"
      >
        <a-typography-text :disabled="disableFields">
          <span v-format-cents="pricesInfo?.yearly?.perMonth" /> {{ $t('components.updateSubscriptionModal.perScreenMonth') }}
        </a-typography-text>
        <a-typography-text
          type="secondary"
          :disabled="disableFields"
        >
          <small>
            <span v-format-cents="pricesInfo?.yearly?.perYear" /> {{ $t('components.updateSubscriptionModal.perScreenYear') }}
          </small>
        </a-typography-text>
      </a-space>
    </a-space>
  </template>
  <template v-else-if="pricesInfo?.monthly">
    <a-space
      direction="vertical"
      size="small"
    >
      <a-typography-text>
        {{ $t('components.updateSubscriptionModal.monthly') }}
      </a-typography-text>
      <a-space
        direction="vertical"
        size="0"
      >
        <a-typography-text :disabled="disableFields">
          <span v-format-cents="pricesInfo?.monthly?.perMonth" /> {{ $t('components.updateSubscriptionModal.perScreenMonth') }}
        </a-typography-text>
        <a-typography-text
          type="secondary"
          :disabled="disableFields"
        >
          <small>
            <span v-format-cents="pricesInfo?.monthly?.perYear" /> {{ $t('components.updateSubscriptionModal.perScreenYear') }}
          </small>
        </a-typography-text>
      </a-space>
    </a-space>
  </template>
</template>

<style scoped lang="less">

</style>
