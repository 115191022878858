<template>
  <InfoHeader type="danger">
    <a-space>
      <span>
        <i18n-t
          v-if="!isCurrentPlaylist"
          keypath="components.forcedPlaylist.overrideModePlaylistText"
          tag="label"
          for="components.forcedPlaylist.overrideModePlaylistLink"
        >
          <router-link
            style="color: white; font-weight: bold;"
            :to="{ name: 'PlaylistPage', params: { groupId: forcedPlaylist.groupId, playlistId: forcedPlaylist.id } }"
          >
            {{ forcedPlaylist.name }}
          </router-link>
        </i18n-t>
        <span v-else>{{ $t('components.forcedPlaylist.overrideModeCurrentPlaylist') }}</span>
      </span>
      <a-button
        type="primary"
        size="small"
        @click="deactivateForcedPlaylist"
      >
        {{ $t('components.forcedPlaylist.deactivateButtonText') }}
      </a-button>
    </a-space>
  </InfoHeader>
</template>

<script>
import { computed, defineComponent, toRef } from 'vue'
import { useStore } from 'vuex'
import { success } from '@/utils'
import InfoHeader from '@/components/InfoHeader.vue'

export default defineComponent({
  name: 'ForcedPlaylist',
  components: { InfoHeader },
  props: {
    forcedPlaylist: Object,
    groupId: String,
    playlistId: String
  },
  setup (props) {
    const store = useStore()
    const forcedPlaylist = toRef(props, 'forcedPlaylist')
    const groupId = toRef(props, 'groupId')
    const playlistId = toRef(props, 'playlistId')

    const deactivateForcedPlaylist = () => {
      const payload = {
        groupId: forcedPlaylist.value?.groupId,
        input: {
          data: {
            forcedPlaylistId: null
          }
        }
      }
      return store.dispatch('groups/updateGroupScheduleByGroupId', payload).then(() => {
        success()
      })
    }

    const isCurrentGroup = computed(() => {
      return groupId.value === forcedPlaylist.value?.groupId
    })

    const isCurrentPlaylist = computed(() => {
      return isCurrentGroup.value && playlistId.value === forcedPlaylist.value?.id
    })

    return {
      isCurrentGroup,
      isCurrentPlaylist,
      deactivateForcedPlaylist
    }
  }
})
</script>

<style scoped>

</style>
