<template>
  <a-collapse-panel
    class="zone-collapse-panel"
    :class="{flex}"
  >
    <template #header>
      <div style="display: flex; gap: 8px; align-items: center">
        <ZoningLayoutIcon
          :layout="zoningLayoutSettings"
          :active-zone="activeZone"
        />
        <span v-once>
          {{ zoneTitle }}
        </span>
      </div>
    </template>
    <div
      class="list-container"
    >
      <div
        v-if="zoneIsEmpty"
        class="zone-empty"
      >
        <a-typography-text type="secondary">
          <StopOutlined /> {{ $t('components.zoningCollapsePanel.areaEmpty') }}
        </a-typography-text>
      </div>
      <a-list
        item-layout="horizontal"
      >
        <VirtualList
          ref="dragAreaList"
          v-model="slides"
          class="dragArea list-group"
          :disabled="editOnly"
          :chosen-class="'dragging-chosen'"
          :class="{dragging: isDragging}"
          :size="62"
          :group="{ name: 'widgets', pull: true, put: true}"
          :style="{height: scrollerHeight+'px'}"
          :data-key="'slideKey'"
          @drop="handleDrop"
          @drag="handleDrag"
          @add="onSlideAdd"
        >
          <template #item="{ record }">
            <SlideCard
              v-if="isSlide(record)"
              :key="record.id+record.updateTime"
              :unused-in-current-layout-zone="(slideHasDisplayRestrictions(record))"
              :slide="record"
              :edit-only="!!record.editOnlyMode"
              :hide-export="hideExport"
              :main-zone="zoneKey === 'MAIN'"
              :selected="selectedSlideIds?.has(record.id)"
              :zone-key="zoneKey"
              :layout-type="zoningLayoutSettings?.type"
              @click="(e)=> $emit('slide-click', { slide: record, e, zoneKey})"
              @delete-slide="$emit('slide-delete', {slide: $event, zoneKey})"
              @duration-change="$emit('slide-duration-change', {...$event, zoneKey})"
              @paused-change="$emit('slide-paused-change', {...$event, zoneKey})"
              @duplicate-slide="$emit('slide-duplicate', {slide: $event, zoneKey})"
              @export-slide="$emit('slide-export', {slide: $event})"
              @edit-slide="$emit('slide-edit', {slide: $event, zoneKey})"
              @mute-slide="$emit('slide-mute', {...$event, zoneKey})"
              @change-object-fit="$emit('slide-object-fit-change', {...$event, zoneKey})"
            />
            <div
              v-else
              style="height: 0"
            />
          </template>
        </VirtualList>
      </a-list>
    </div>
  </a-collapse-panel>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRefs, watch, watchEffect } from 'vue'
import { Slide } from '@/helpers/Slides'
import { StopOutlined } from '@ant-design/icons-vue'
import VirtualList from 'vue-virtual-draglist'
import ZoningLayoutIcon from '@/components/ZoningLayoutIcon'
import SlideCard from '@/components/slideCard/SlideCard.vue'
import { matches } from 'lodash'
import { useStore } from 'vuex'

const SLIDE_HEIGHT = 62

export default defineComponent({
  name: 'ZoneCollapsePanel',
  components: {
    SlideCard,
    ZoningLayoutIcon,
    StopOutlined,
    VirtualList
  },
  props: {
    activeZone: {
      type: String,
      default: 'a'
    },
    containerHeight: Number,
    totalZonesNumber: Number,
    selectedSlideIds: Set,
    zoningLayoutSettings: Object,
    widgetsDisplayRestrictions: Object,
    zoneSlides: Array,
    flex: Boolean,
    zoneIsEmpty: Boolean,
    editOnly: Boolean,
    hideExport: Boolean,
    zoneTitle: String,
    zoneKey: String
  },
  emits: [
    'zone-slides-move',
    'zone-slides-add',
    'slide-click',
    'slide-delete',
    'slide-duration-change',
    'slide-paused-change',
    'slide-object-fit-change',
    'slide-duplicate',
    'slide-export',
    'slide-edit',
    'slide-mute',
    'slide-add',
    'slide-start-drag',
    'slide-stop-drag'
  ],
  setup (props, {emit}) {
    const isDragging = ref(false)
    const dragAreaList = ref(null)
    const slides = ref(props.zoneSlides)
    const store = useStore()
    const draggedFrom = computed(() => store.state.slides.draggingSlideFromZone)

    const scrollerHeight = computed(() => {
      const maxHeight = props.containerHeight - 50 * props.totalZonesNumber
      const slidesInList = slides.value?.filter(isSlide)
      const currentHeight = SLIDE_HEIGHT * slidesInList.length || maxHeight - 54
      return Math.min(currentHeight, maxHeight)
    })

    const isSlide = (element) => {
      return element instanceof Slide
    }

    const slideHasDisplayRestrictions = (slide) => {
      if (!props.widgetsDisplayRestrictions) return false
      return !!props.widgetsDisplayRestrictions
          .find(rule => rule.widgetType === slide.widget?.type
              && (!rule.data || (matches(rule.data))(slide.slideData)))
    }

    const onSlideAdd = (evt) => {
      const afterIndex = evt.newIndex
      const slideType = evt.item._underlying_vm_.id
      emit('slide-add', {afterIndex, slideType, zoneKey: props.zoneKey})
    }

    const handleDrag = (evt) => {
      // isDragging.value = true
      emit('slide-start-drag')
      store.dispatch('slides/setDraggingSlideZone', props.zoneKey)
    }

    const handleDrop = (evt) => {
      emit('slide-stop-drag')
      // isDragging.value = false
      const {changed, oldIndex, newIndex, item} = evt
      if (draggedFrom.value === null) {
        return emit('slide-add', {afterIndex: newIndex, slideType: item.id, zoneKey: props.zoneKey})
      }

      if (!changed || newIndex === -1) return
      emit('zone-slides-move', { slideIndex: newIndex, down: newIndex > oldIndex, slide: item, zoneKey: props.zoneKey})
    }

    const scrollToBottom = () => {
      dragAreaList.value.scrollToBottom()
    }

    watchEffect(() => {
      slides.value = props.zoneSlides
    })


    return {
      dragAreaList,
      slides,
      isDragging,
      scrollerHeight,
      slideHasDisplayRestrictions,
      isSlide,
      onSlideAdd,
      handleDrop,
      handleDrag,
      scrollToBottom
    }
  }
})
</script>

<style scoped lang="less">
@import '../styles/variables';
.dragging-chosen {
  opacity: 0.15;
}
.zone-empty {
  text-align: center;
  padding: 16px 0;
}
.ant-collapse-ghost>.zone-collapse-panel {
  &.flex {
    flex: 1;
  }
  border-bottom: solid 1px @border-color-light;
  &:last-child {
    border-bottom: none;
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
</style>
