<template>
  <icon>
    <template #component>
      <svg
        width="82"
        height="26"
        viewBox="0 0 82 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_81388_106490)">
          <rect
            width="82"
            height="26"
            fill="white"
          />
          <path
            d="M62 0H20L0 26H82L62 0Z"
            fill="url(#paint0_linear_81388_106490)"
          />
          <rect
            x="31"
            y="3"
            width="20"
            height="20"
            rx="10"
            fill="white"
          />
          <path
            d="M37.2383 17H38.6152L39.3828 14.7148H42.6172L43.3789 17H44.7617L41.709 8.54492H40.291L37.2383 17ZM40.9473 10.0273H41.0469L42.2656 13.6602H39.7344L40.9473 10.0273Z"
            fill="black"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_81388_106490"
            x1="41.6078"
            y1="-10"
            x2="41.6078"
            y2="26"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop
              offset="1"
              stop-color="#656565"
            />
          </linearGradient>
          <clipPath id="clip0_81388_106490">
            <rect
              width="82"
              height="26"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'FlipBackTransition',
  components: {
    Icon
  }
})
</script>
