import { createI18n } from 'vue-i18n'
import languages from '@/i18n/languages'
import { pluralizationUa } from '@/utils'

export const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: languages, // set locale messages
  legacy: false,
  pluralRules: {
    ua: pluralizationUa
  },
  datetimeFormats: {
    'en': {
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
    },
    'ua': {
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      },
      short: {
        month: 'long',
        day: '2-digit',
      }
    }
  }
  // If you need to specify other options, you can set other options
  // ...
})
