<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const LOCAL_STORAGE_KEY = 'smart-playlist-info-modal-shown'
const modalWasShown = ref(localStorage.getItem(LOCAL_STORAGE_KEY) === 'true' || false)
const store = useStore()
const isSmartGroup = computed(() => store.getters['groups/currentGroupTypeIsSmart'])

const showModal = computed(()=> isSmartGroup.value && !modalWasShown.value)

const handleOk = () => {
  localStorage.setItem(LOCAL_STORAGE_KEY, 'true')
  modalWasShown.value = true
}
</script>

<template>
  <a-modal
    :title="$t('components.smartPlaylistInfoModal.title')"
    :open="showModal"
    :ok-text="$t('components.smartPlaylistInfoModal.okText')"
    :cancel-button-props="{ hidden: true }"
    :destroy-on-close="true"
    :closable="false"
    @ok="handleOk"
  >
    <a-typography-paragraph>
      {{ $t('components.smartPlaylistInfoModal.descriptionP1') }}
    </a-typography-paragraph>
    <a-typography-paragraph>
      {{ $t('components.smartPlaylistInfoModal.descriptionP2') }}
    </a-typography-paragraph>
    <a-typography-paragraph>
      {{ $t('components.smartPlaylistInfoModal.benefits') }}
      <ul>
        <li>{{ $t('components.smartPlaylistInfoModal.benefit1') }}</li>
        <li>{{ $t('components.smartPlaylistInfoModal.benefit2') }}</li>
      </ul>
    </a-typography-paragraph>
  </a-modal>
</template>

<style scoped lang="less">

</style>
