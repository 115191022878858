<template>
  <div
    :style="{width: collapsed ? 0 : formattedWidth}"
    class="resizable-container"
    :class="{resizing, collapsed}"
  >
    <div
      class="resizer"
      @mousedown="mouseDown"
    />
    <a-layout-sider
      v-bind="attrs"
      :width="formattedWidth"
      :collapsed="collapsed"
      theme="dark"
    >
      <slot />
    </a-layout-sider>
  </div>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref, useAttrs } from 'vue'

const DEFAULT_WIDTH = 250

export default defineComponent({
  name: 'ResizableSidebar',
  props: {
    collapsed: Boolean,
    width: Number,
    minWidth: Number,
    maxWidth: Number
  },
  setup (props) {
    const defaultWidth = props.width || DEFAULT_WIDTH
    const minWidth = props.minWidth || (defaultWidth < DEFAULT_WIDTH ? defaultWidth : DEFAULT_WIDTH)
    const maxWidth = props.maxWidth || DEFAULT_WIDTH
    const resizing = ref(false)
    const resizableWidth = ref(defaultWidth)
    const currentWidth = ref(defaultWidth)
    const _mPos = ref(null)

    const { ...attrs } = useAttrs()

    const formattedWidth = computed(() => {
      return resizableWidth.value + 'px'
    })

    const resize = (e) => {
      const dx = _mPos.value - e.x
      const newWidth = currentWidth.value - dx
      resizableWidth.value = newWidth > minWidth ? newWidth < maxWidth ? newWidth : maxWidth : minWidth
    }

    const mouseDown = (e) => {
      _mPos.value = e.x
      resizing.value = true
      document.addEventListener('mousemove', resize, false)
      document.addEventListener('mouseup', stopResize, false)
    }

    onUnmounted(() => {
      document.removeEventListener('mousemove', resize, false)
      document.removeEventListener('mouseup', stopResize, false)
    })

    const stopResize = () => {
      currentWidth.value = resizableWidth.value
      resizing.value = false
      document.removeEventListener('mousemove', resize, false)
    }

    const resWidth = ref('100px')
    return {
      resWidth,
      attrs,
      resizing,
      resizableWidth,
      formattedWidth,
      mouseDown
    }
  }
})
</script>

<style lang="less">
.resizable-container {
  position: relative;
  display: flex;
  transition: all .2s;
  &:hover {
    .resizer {
      background-color: var(--ant-primary-3);
    }
  }
  &.collapsed {
    .resizer {
      display: none;
    }
  }
  &.resizing {
    cursor: ew-resize;
    transition: none;
    .ant-layout-sider {
      transition: none;
    }
    .resizer {
      background-color: var(--ant-primary-color);
    }
  }
  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    width: 3px;
    height: 100%;
    z-index: 1;
    cursor: ew-resize;
    user-select: none;
    &:hover {
      background-color: var(--ant-primary-color);
    }
  }
}

</style>
