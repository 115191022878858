<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DropdownSection',
  props: {
    title: {
      type: String,
      default: ''
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  setup () {

    return {}
  }
})
</script>

<template>
  <div class="dropdown-section">
    <div class="dropdown-section__header">
      <a-typography-text
        class="dropdown-section__title"
        type="secondary"
        style="font-size: 12px;"
      >
        {{ title }}
      </a-typography-text>
    </div>
    <div
      class="dropdown-section__content"
      :class="{centered}"
      style="display: flex;"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped lang="less">
.dropdown-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &.centered {
      align-items: center;
    }
  }
}
</style>
