<script setup>

import { useStore } from 'vuex'
import { computed } from 'vue'
const store = useStore()

const isPaid = computed(() => store.getters['workspace/isPaid'])
const allDevicesNumber = computed(()=>store.getters['devices/allDevicesNumber'])
const billingDevicesQuantity = computed(()=>store.getters['workspace/billingDevicesQuantity'])
const isBusinessTier = computed(()=>store.getters['workspace/isBusinessTier'])
const isEducationStarterTier = computed(()=>store.getters['workspace/isEducationStarterTier'])
const devices = computed(()=>{
  if (isEducationStarterTier.value) {
    return allDevicesNumber.value
  }
  return isPaid.value ? billingDevicesQuantity.value : allDevicesNumber.value
})

const openDevicesModal = () => {
  store.dispatch('openDevices')
}

</script>

<template>
  <p>
    {{ $t(`components.updateSubscriptionModal.${isBusinessTier ? 'kitcastGrowth' : 'kitcastEDUGrowth'}`) }}
    <br>
    <span style="color: var(--ant-primary-color); white-space: nowrap">
      {{ $t('components.updateSubscriptionModal.screensNumber', {count: devices}) }}
    </span>
  </p>
  <a-typography-link
    v-if="allDevicesNumber && (!isPaid || isEducationStarterTier)"
    type="secondary"
    underline
    @click="openDevicesModal"
  >
    {{ $t('components.updateSubscriptionModal.manageScreensLink') }}
  </a-typography-link>
</template>

<style scoped lang="less">

</style>
