<script>
import { defineComponent, onMounted, onUnmounted } from 'vue'
import { error, success } from '@/utils'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'SocialHandler',
  setup: function () {
    const store = useStore()
    const { t } = useI18n()

    onMounted(() => {
      window.addEventListener('message', receiveMessage, false)
    })

    onUnmounted(() => {
      window.removeEventListener('message', receiveMessage)
    })

    const receiveMessage = ({
      data,
      origin
    }) => {
      if (origin.replace(/\/$/, '') !== process.env.VUE_APP_DEFAULT_URL.replace(/\/$/, '')) return
      try {
        data = JSON.parse(data)
      } catch (e) {
        return
      }

      const {
        action,
        platform,
        oauth_token: oauthToken,
        oauth_verifier: oauthVerifier,
        code
      } = data

      if (!action || !platform) return

      let input, dispatchActionName
      switch (action) {
        case 'link':
          switch (platform) {
            case 'twitter':
              input = {
                oauthToken,
                oauthVerifier
              }
              // input = { code, state }
              dispatchActionName = 'social/linkTwitterAccount'
              break
            case 'instagram':
              input = { code }
              dispatchActionName = 'social/linkInstagramAccount'
              break
            case 'google':
              input = { code }
              dispatchActionName = 'social/linkGoogleAccount'
              break
            case 'microsoft':
              input = { code }
              dispatchActionName = 'social/linkMicrosoftAccount'
              break
            case 'dropbox':
              input = { code }
              dispatchActionName = 'social/linkDropboxAccount'
              break
          }
          if (!input || !dispatchActionName) return
          break
      }
      if (!input || !dispatchActionName) return
      store.dispatch(dispatchActionName, input).then(() => {
        action === 'link' && success()
      }).catch(() => {
        error(t('components.socialHandler.error'))
      })
    }

    return {}
  }
})
</script>

<template>
  <template />
</template>
