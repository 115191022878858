<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const isEnterprise = computed(() => store.getters['workspace/isEnterprize'])
const enterpriseRequestFormSubmitted = computed(() => !!store.getters['workspace/enterpriseRequestFormSubmittedAt'])

const openContactUs = () => {
  store.dispatch('openContactUs')
}

</script>

<template>
  <div
    v-if="!isEnterprise"
  >
    <i18n-t
      v-if="!enterpriseRequestFormSubmitted"
      keypath="components.enterpriseContactUsString.enterprisePlanText"
      tag="label"
    >
      <a-tooltip
        placement="left"
      >
        <template #title>
          <a-typography-text style="display: block; text-align: center; color: #fff; padding: 16px;">
            {{ $t('components.enterpriseContactUsString.enterpriseFeaturesTitle') }}
          </a-typography-text>
          <ul style="list-style-type: disc; padding: 0 8px 8px 20px; margin-bottom: 0;">
            <li
              v-for="feature in (new Array(17)).fill(null).map((_, index) => index+1)"
              :key="feature"
            >
              {{ $t(`components.enterpriseContactUsString.enterpriseFeatures.feature${feature}`) }}
            </li>
          </ul>
        </template>
        <a-typography-link>
          {{ $t('components.enterpriseContactUsString.enterprisePlanTooltip') }}
        </a-typography-link>
      </a-tooltip>
      <a-typography-link
        class="underline-link"
        @click="openContactUs"
      >
        {{ $t('components.enterpriseContactUsString.enterprisePlanLink') }}
      </a-typography-link>
    </i18n-t>
    <a-typography-text
      v-else
      type="secondary"
    >
      {{ $t('components.enterpriseContactUsString.enterpriseRequestPending') }}
    </a-typography-text>
  </div>
</template>

<style scoped lang="less">

</style>
