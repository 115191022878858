<template>
  <div
    class="zoning-layout"
    :class="{'hover-mode': hoverMode, 'layout-active': layoutActive}"
  >
    <div
      v-for="(value, key) in layout.styles"
      :key="layout.type+key"
      :class="[key, { active: activeZone === key}]"
      :style="value"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ZoningLayoutIcon',
  props: {
    layout: Object,
    activeZone: String,
    layoutActive: Boolean,
    hoverMode: Boolean
  },
  emits: ['click'],
  setup () {
    return {}
  }
})
</script>

<style lang="less">

@import '../styles/variables';
@zoning-background: #595959;
@zoning-background-main: #FAFAFA;
@zoning-background-active: #fff;

.zoning-layout {
  display: grid;
  border-radius: 3px;
  border: solid 1px @zoning-background;
  background-color: @zoning-background;
  overflow: hidden;
  grid-gap: 1px;
  width: 24px;
  height: 16px;
  &.hover-mode:hover, &.layout-active {
    cursor: pointer;
    border-color: var(--ant-primary-color);
    background-color: var(--ant-primary-color);
  }
  > div {
    background-color: @zoning-background-active;
    &.active {
      background-color: var(--ant-primary-3);
    }
  }
}
</style>
