<script>
import { defineComponent, inject, nextTick, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { MenuOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { widgetIcons } from '@/helpers/Icons'
import VirtualList from 'vue-virtual-draglist'

import { useStore } from 'vuex'
import TallyModal from '@/components/TallyModal.vue'

const TALLY_URL = 'https://tally.so/embed/w8Gvl5?dynamicHeight=1'
const TALLY_FORM_COMPLETION_CLOSE_DELAY = 1000

const DEFAULT_MOUSE_ENTER_DELAY = 99999 // s
const DELAY_THRESHOLD = 400 // ms
export default defineComponent({
  name: 'PlaylistWidgetsTab',
  components: {
    TallyModal,
    MenuOutlined,
    PlusOutlined,
    VirtualList,
    ...widgetIcons
  },
  props: {
    siderCollapsed: Boolean,
    slideTypeList: Array,
    disabledWidgets: Array,
    layoutZonesSettings: Array,
    editOnlyMode: Boolean,
    filterSlideTypes: String
  },
  emits: ['update:filterSlideTypes', 'slideAddLast', 'startDragNewSlide', 'stopDragNewSlide'],
  setup (props, { emit }) {
    const store = useStore()
    const worldClockRef = ref(null)
    const weatherForecastRef = ref(null)
    const onboardingService = inject('onboardingService')
    const tooltipMouseEnterDelay = ref(DEFAULT_MOUSE_ENTER_DELAY)
    const tooltipCount = ref(0)
    const lastInteractionTime = ref(Date.now())
    const widgetsList = ref(props.slideTypeList)
    const showAddWidgetModal = ref(false)

    const handleAddSlideLast = (elementId, zoneKey) => {
      onboardingService.pause()
      emit('slideAddLast', elementId, zoneKey)
    }

    const startDragNewSlide = () => {
      store.dispatch('slides/setDraggingSlideZone', null)
      onboardingService.pause()
      emit('startDragNewSlide')
    }

    const handleTooltipOpenChange = (open) => {
      if (open) {
        tooltipCount.value++
        tooltipMouseEnterDelay.value = 0
        lastInteractionTime.value = Date.now()
      } else {
        tooltipCount.value--
      }
    }

    const handleShowAddWidgetModal = () => {
      showAddWidgetModal.value = true
    }

    const closeAddWidgetModal = () => {
      showAddWidgetModal.value = false
    }


    watch(()=> tooltipCount.value, (value) => {
      if (value === 0) {
        setTimeout(() => {
          if (Date.now() - lastInteractionTime.value >= DELAY_THRESHOLD) {
            tooltipMouseEnterDelay.value = DEFAULT_MOUSE_ENTER_DELAY
          }
        }, DELAY_THRESHOLD)
      }
    })

    watch(() => worldClockRef.value, (value) => {
      if (value) {
        onboardingService.setRef(value, 'worldClock', () => {})
      }
    })

    watch(() => weatherForecastRef.value, (value) => {
      if (value) {
        onboardingService.setRef(value, 'weatherForecast', () => {})
      }
    })

    watchEffect(() => {
      widgetsList.value = props.slideTypeList
    })

    return {
      worldClockRef,
      weatherForecastRef,
      tooltipMouseEnterDelay,
      widgetsList,
      showAddWidgetModal,
      tallyIframeUrl: TALLY_URL,
      TALLY_FORM_COMPLETION_CLOSE_DELAY,
      closeAddWidgetModal,
      handleShowAddWidgetModal,
      handleTooltipOpenChange,
      startDragNewSlide,
      handleAddSlideLast
    }
  }
})

</script>

<template>
  <div
    class="widgets-tab"
    :class="{disabled: editOnlyMode}"
  >
    <TallyModal
      :open="showAddWidgetModal"
      :src="tallyIframeUrl"
      :close-delay="TALLY_FORM_COMPLETION_CLOSE_DELAY"
      @cancel="closeAddWidgetModal"
      @close="closeAddWidgetModal"
    />
    <a-input-search
      :value="filterSlideTypes"
      :placeholder="$t('components.playlistWidgetsTab.widgetsFilterPlaceholder')"
      @input="$emit('update:filterSlideTypes', $event.target.value)"
    />
    <div
      v-if="!siderCollapsed"
      class="widget-list"
    >
      <VirtualList
        v-model="widgetsList"
        class="dragArea list-group"
        :group="{ name: 'widgets', pull: 'clone', put: false }"
        :data-key="'id'"
        :sortable="false"
        :size="40"
        @drag="startDragNewSlide"
        @drop="$emit('stopDragNewSlide')"
      >
        <template #item="{ record }">
          <div
            :ref="record.name === 'worldClock' ? 'worldClockRef' : record.name === 'weatherForecast' ? 'weatherForecastRef' : null"
            class="widget-list-item"
            :class="{disabled: disabledWidgets.includes(record.id)}"
          >
            <a-tooltip
              placement="left"
              class="widget-tooltip"
              destroy-tooltip-on-hide
              :mouse-enter-delay="tooltipMouseEnterDelay"
              :overlay-style="{maxWidth:'400px'}"
              @open-change="handleTooltipOpenChange"
            >
              <template #title>
                <div class="tooltip-image-container">
                  Gif
                </div>
              </template>
              <div class="widget-item-icon">
                <a-tooltip
                  placement="top"
                  class="move-icon"
                >
                  <template #title>
                    {{ $t('components.playlistWidgetsTab.moveTooltipTitle') }}
                  </template>
                  <MenuOutlined class="widget-icon-drag" />
                </a-tooltip>
                <PlusOutlined class="add-icon" />
              </div>
              <a-dropdown
                v-if="layoutZonesSettings?.length > 1"
                placement="bottom"
                :trigger="['click']"
              >
                <span
                  class="widget-item-title"
                >
                  <component :is="record.icon" />
                  <a-badge
                    v-if="disabledWidgets.includes(record.id)"
                    color="#f50"
                  />
                  <span>{{ $t(`slides.${record.name}`) }}</span>
                </span>
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                      v-for="zone in layoutZonesSettings"
                      :key="zone.key"
                      @click="handleAddSlideLast(record.id, zone.key)"
                    >
                      {{ $t(`zoneNames.${zone.nameKey}`) }}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <span
                v-else
                class="widget-item-title"
                @click="handleAddSlideLast(record.id)"
              >
                <component :is="record.icon" />
                <a-badge
                  v-if="disabledWidgets.includes(record.id)"
                  color="#f50"
                />
                <span>{{ $t(`slides.${record.name}`) }}</span>
              </span>
            </a-tooltip>
          </div>
        </template>
        <template #footer>
          <a-button
            style="margin: 0 8px 8px; width: calc(100% - 16px);"
            type="primary"
            @click="handleShowAddWidgetModal"
          >
            {{ $t('components.playlistWidgetsTab.moreWidgets') }}
          </a-button>
        </template>
      </VirtualList>
    </div>
  </div>
</template>

<style scoped lang="less">
@import '../../styles/variables';
.tooltip-image-container {
  width: 258px;
  height: 146px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widgets-tab {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  :deep(.widget-tooltip) {
    display: flex;
    :deep(.tooltip-image-container) {
      width: 258px;
      height: 146px;
    }
  }
  .ant-input-group-wrapper {
    padding: 0 20px 20px 20px;
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
    user-select: none;
  }

  .widget-list {
    overflow: hidden;

    .list-group {
      overflow-x: auto;
    }
  }
  .widget-list {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }

  .widget-list-item {
    height: 32px;
    display: flex;
    cursor: pointer;


    &.disabled {
      pointer-events: none;
      opacity: 1;
      cursor: not-allowed;
      background-color: @bg-light-grey;
    }

    &:hover {
      background-color: #f0f0f0;
    }

    &:first-child {
      border-top: solid 1px #f0f0f0;
    }

    .widget-item-icon {
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: width .1s ease-in-out;
      .move-icon {
        display: none;
      }
      .add-icon {
        display: none;
      }
    }

    .widget-item-title {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 16px 0 0;

      .anticon {
        display: inline-block;
        width: 24px;
        text-align: left;
      }
    }

    &:hover {
      .widget-item-icon {
        width: 32px;
        .move-icon {
          display: none;
        }

        .add-icon {
          display: flex;
        }

        &:hover {
          .move-icon {
            display: flex;
            cursor: move;
          }

          .add-icon {
            display: none;
          }
        }
      }
    }
  }
}
</style>
