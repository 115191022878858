<script setup>

import { PlayCircleFilled } from '@ant-design/icons-vue'
import EventTimePicker from '@/components/EventTimePicker.vue'
import { ref, watch } from 'vue'
import { DEFAULT_PLAYLIST_COLOR } from '@/constants'

const props = defineProps({
  open: Boolean,
  newEvent: Object,
  playlistsOptions: Array,
  isAmPm: Boolean
})

const emit = defineEmits(['create', 'update-time', 'update-playlist-id', 'cancel'])

const playlistId = ref(null)

const filterOption = (input, option) => {
  return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const handlePlaylistSelect = (value) => {
  emit('update-playlist-id', value)
}

const handleTimeChange = ({ timeFrom, timeTo }) => {
  emit('update-time', { timeFrom, timeTo })
}

const onCancel = () => {
  emit('cancel')
  playlistId.value = null
}

const onNewEventCreate = () => {
  emit('create')
  playlistId.value = null
}

watch(()=> props.open, (value) => {
  if (value) {
    const options = props.playlistsOptions?.filter(option => !option.disabled)
    if (options.length === 1) {
      playlistId.value = options[0].value
      emit('update-playlist-id', playlistId.value)
    }
  }
})

</script>

<template>
  <a-modal
    :open="open"
    width="300px"
    centered
    :closable="false"
    destroy-on-close
    :title="$t('components.newEventModal.title')"
    @cancel="onCancel"
  >
    <a-space
      direction="vertical"
      style="width: 100%;"
    >
      <EventTimePicker
        :is-am-pm="isAmPm"
        :time-from="newEvent.startTime"
        :time-to="newEvent.endTime"
        @update="handleTimeChange"
      />
      <a-select
        v-model:value="playlistId"
        style="width: 100%"
        :placeholder="$t('components.newEventModal.playlistSelectPlaceholder')"
        show-search
        :filter-option="filterOption"
        @change="handlePlaylistSelect"
      >
        <a-select-option
          v-for="option in playlistsOptions"
          :key="option.value"
          :name="option.label"
          :value="option.value"
          :disabled="option.disabled"
        >
          <span>
            <a-space>
              <PlayCircleFilled :style="{color: option.color || DEFAULT_PLAYLIST_COLOR}" />
              {{ option.label }}
            </a-space>
          </span>
        </a-select-option>
      </a-select>
    </a-space>
    <template #footer>
      <a-button
        :disabled="!playlistId"
        type="primary"
        size="small"
        @click="onNewEventCreate"
      >
        {{ $t('components.newEventModal.createEvent') }}
      </a-button>
    </template>
  </a-modal>
</template>

<style scoped lang="less">

</style>
