<template>
  <icon>
    <template #component>
      <svg
        width="48"
        height="48"
        viewBox="0 0 256 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_402_2)">
          <rect
            width="256"
            height="256"
            fill="white"
          />
          <circle
            cx="128"
            cy="128"
            r="118"
            stroke="#05CC97"
            stroke-width="20"
          />
          <path
            d="M195.188 160.25C195.156 161.226 195.04 162.197 194.838 163.153C193.656 169.388 188.119 183.497 162.938 181.75L170.543 171L176.375 162.938L195.188 160.25Z"
            fill="#D6A62D"
          />
          <path
            d="M195.187 160.25C195.156 161.226 195.04 162.197 194.838 163.153C190.646 167.694 183.309 171.538 170.651 171.215L176.375 162.938L195.187 160.25Z"
            fill="#FFD466"
          />
          <path
            d="M176.375 162.938L195.188 160.25C195.188 160.25 195.188 183.981 162.938 181.75L176.375 162.938Z"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M85 184.438C85 184.438 74.25 192.043 74.25 195.188C74.25 198.332 82.3125 197.875 82.3125 197.875C82.0838 198.943 82.2253 200.057 82.7137 201.034C83.2021 202.011 84.0084 202.792 85 203.25C85.6466 203.556 86.3349 203.764 87.0425 203.868C89.3541 204.19 91.7097 203.874 93.8551 202.955C96.0006 202.036 97.8545 200.549 99.2169 198.654L103.812 192.5L85 184.438Z"
            fill="#FFF0C9"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M149.5 184.438C149.5 184.438 160.25 192.043 160.25 195.188C160.25 198.332 152.188 197.875 152.188 197.875C152.416 198.943 152.275 200.057 151.786 201.034C151.298 202.011 150.492 202.792 149.5 203.25C148.853 203.556 148.165 203.764 147.458 203.868C145.146 204.19 142.79 203.874 140.645 202.955C138.499 202.036 136.646 200.549 135.283 198.654L130.688 192.5L149.5 184.438Z"
            fill="#FFF0C9"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M146.812 189.812C144.483 190.744 142.199 191.577 139.959 192.312C97.4431 205.937 71.5625 179.788 71.5625 159.578C71.6617 155.269 72.2569 150.986 73.3362 146.812C74.8423 141.16 77.286 135.8 80.5656 130.956L79.625 130.687C78.9531 129.156 78.3619 127.677 77.8781 126.253C67.5581 97.2012 87.6875 85 87.6875 85L88.9506 85.215C90.375 76.3462 95.75 65.6231 111.875 58.125C135.337 47.375 157.025 58.9044 166.404 77.7169C166.727 77.3137 167.076 76.9375 167.426 76.9375C167.717 76.8668 168.021 76.8668 168.312 76.9375C168.312 76.9375 178.176 82.7425 176.375 106.5C176.019 112.396 174.908 118.221 173.069 123.834L175.031 124.157C176.671 125.592 178.037 127.313 179.062 129.236C179.666 130.273 180.195 131.351 180.648 132.461C186.803 147.269 184.223 174.521 146.812 189.812Z"
            fill="#FFD466"
          />
          <path
            d="M138.75 95.75C140.234 95.75 141.438 93.9451 141.438 91.7188C141.438 89.4924 140.234 87.6875 138.75 87.6875C137.266 87.6875 136.062 89.4924 136.062 91.7188C136.062 93.9451 137.266 95.75 138.75 95.75Z"
            fill="#001C63"
          />
          <path
            d="M144.125 172.344C144.148 179.218 142.729 186.02 139.959 192.312C97.4431 205.938 71.5625 179.788 71.5625 159.578C71.6617 155.269 72.2569 150.986 73.3362 146.812C76.5828 141.212 81.2145 136.541 86.7868 133.246C92.359 129.952 98.6846 128.145 105.156 128C126.683 128 144.125 147.861 144.125 172.344Z"
            fill="#F0C763"
          />
          <path
            d="M146.812 189.812C100.238 208.84 71.5625 180.863 71.5625 159.578C71.6942 149.336 74.8242 139.358 80.5656 130.876L79.625 130.688C78.9531 129.156 78.3619 127.678 77.8781 126.253L79.625 128L90.375 122.625C89.8375 123.834 67.585 156.38 82.3125 171C110.531 198.896 147.135 185.781 162.938 171C176.697 158.154 177.531 152.537 180.702 132.461C186.803 147.269 184.223 174.521 146.812 189.812Z"
            fill="#F0C763"
          />
          <path
            d="M98.4375 93.0625C99.9218 93.0625 101.125 91.2576 101.125 89.0312C101.125 86.8049 99.9218 85 98.4375 85C96.9532 85 95.75 86.8049 95.75 89.0312C95.75 91.2576 96.9532 93.0625 98.4375 93.0625Z"
            fill="#001C63"
          />
          <path
            d="M133.375 58.125L130.688 47.375"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M133.375 58.125L111.875 47.375"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M179.063 129.236C171.806 134.799 166.028 137.299 168.313 130.687C173.365 115.584 165.625 82.3125 165.625 82.3125L167.426 76.9375C167.717 76.8668 168.021 76.8668 168.313 76.9375C168.313 76.9375 178.176 82.7425 176.375 106.5C176.019 112.405 174.908 118.239 173.069 123.861L175.031 124.184C176.668 125.611 178.034 127.323 179.063 129.236V129.236Z"
            fill="#F0C763"
          />
          <path
            d="M90.375 101.125C88.6494 96.0088 88.1613 90.5564 88.9506 85.215C90.375 76.3463 95.75 65.6231 111.875 58.125C121.862 53.1849 133.396 52.3901 143.966 55.9135C154.537 59.4369 163.287 66.9933 168.313 76.9375"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M144.125 117.25C144.125 117.25 153.988 104.699 154.875 93.0625C155.762 81.4256 162.938 90.375 162.938 90.375C162.938 90.375 164.093 80.7269 166.404 77.7169C166.996 76.9912 167.614 76.6687 168.312 76.9644C168.312 76.9644 178.176 82.7962 176.375 106.527C176.019 112.431 174.908 118.266 173.069 123.888C169.844 133.375 165.625 136.062 165.625 136.062"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M87.6875 122.625C84.9557 125.045 82.5604 127.82 80.5656 130.876C74.8242 139.358 71.6942 149.336 71.5625 159.578C71.5625 180.863 100.238 208.84 146.812 189.812C193.387 170.785 185.996 133.16 175.031 124.184"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M87.6875 85C87.6875 85 65.5156 98.4375 79.625 130.688"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M122.625 117.25C113.864 120.583 81.1837 125.313 71.5625 117.25C70.0837 116.11 68.8498 114.685 67.9344 113.058C64.9781 107.494 71.5625 104.189 76.9375 103.813C83.6562 103.356 100.668 100.453 103.812 95.75C106.957 91.0469 114.562 93.0625 114.562 93.0625C119.937 93.7075 122.222 98.8675 132.085 103.248L133.375 103.813C144.34 108.301 132.031 113.676 122.625 117.25Z"
            fill="#FFDB7D"
          />
          <path
            d="M132.085 103.248C130.876 106.5 123.431 109.51 117.25 111.875C108.972 115.019 79.2487 119.427 67.9344 113.058C64.9781 107.494 71.5625 104.189 76.9375 103.813C83.6562 103.356 100.668 100.453 103.812 95.75C106.957 91.0469 114.562 93.0625 114.562 93.0625C119.937 93.7075 122.222 98.8675 132.085 103.248Z"
            fill="#FFF0C9"
          />
          <path
            d="M114.563 93.0625C114.563 93.0625 106.957 91.0469 103.813 95.75C100.668 100.453 83.6563 103.356 76.9375 103.813C70.2188 104.269 61.9413 109.188 71.5625 117.25C81.1838 125.313 113.864 120.583 122.625 117.25C132.031 113.676 144.34 108.301 133.375 103.813C122.41 99.3244 120.153 93.7344 114.563 93.0625Z"
            stroke="#001C63"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M115.906 103.812C116.648 103.812 117.25 103.211 117.25 102.469C117.25 101.727 116.648 101.125 115.906 101.125C115.164 101.125 114.562 101.727 114.562 102.469C114.562 103.211 115.164 103.812 115.906 103.812Z"
            fill="#001C63"
          />
          <path
            d="M102.469 103.812C103.211 103.812 103.812 103.211 103.812 102.469C103.812 101.727 103.211 101.125 102.469 101.125C101.727 101.125 101.125 101.727 101.125 102.469C101.125 103.211 101.727 103.812 102.469 103.812Z"
            fill="#001C63"
          />
          <path
            d="M141.438 63.5C144.385 64.0573 147.151 65.3287 149.494 67.2027C151.837 69.0768 153.684 71.4965 154.875 74.25"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M157.563 82.3125C159.047 82.3125 160.25 81.1093 160.25 79.625C160.25 78.1407 159.047 76.9375 157.563 76.9375C156.078 76.9375 154.875 78.1407 154.875 79.625C154.875 81.1093 156.078 82.3125 157.563 82.3125Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_402_2">
            <rect
              width="256"
              height="256"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'KitcastNotification',
  components: {
    Icon
  }
})
</script>
