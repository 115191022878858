<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :title="$t('components.slides.ticker.slideStep1.title')"
    no-border
  >
    <a-select
      ref="symbolInput"
      v-model:value="selectedSymbol"
      :disabled="inputDisabled"
      :filter-option="true"
      :loading="inputDisabled"
      :max-tag-text-length="10"
      :not-found-content="loading ? undefined : null"
      :options="suggestionOptions"
      :placeholder="$t('components.slides.ticker.slideStep1.symbolSelectPlaceholder')"
      :show-search="true"
      style="width: 100%; margin-bottom: 16px"
      @select="onSelect"
      @search="fetchTickers"
    >
      <template
        v-if="loading"
        #notFoundContent
      >
        <a-spin size="small" />
      </template>
    </a-select>
    <a-space style="flex-wrap: wrap; margin-bottom: 8px;">
      <a-tag
        v-for="(ticker, index) in selectedTickers"
        :key="ticker.symbol"
        closable
        @close.prevent="removeSymbol(index)"
      >
        {{ ticker.symbol }} - {{ ticker.name }}
      </a-tag>
    </a-space>
  </SlideStep>
  <div>
    <a-typography-text
      v-if="showSymbolsToAdd"
      type="secondary"
    >
      {{ $t('components.slides.ticker.slideStep1.autofill', {symbolsToAdd}, symbolsToAdd) }}
    </a-typography-text>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { debounce } from 'lodash-es'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { TickerSlide } from '@/helpers/Slides'
import { warn } from '@/utils'
import { useStore } from 'vuex'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

export default defineComponent({
  name: 'TickerSlide',
  components: {
    DurationSlideStep,
    SlideStep
  },
  props: {
    title: String,
    slideObj: TickerSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const slide = props.slideObj || new TickerSlide()
    const store = useStore()
    const { t } = useI18n()
    const { tickers } = slide.slideData
    const suggestions = ref([])
    const selectedTickers = ref(tickers || [])
    const selectedSymbol = ref(null)
    const symbolInput = ref()
    const inputDisabled = ref(false)
    const SYMBOLS_LIMIT = process.env.VUE_APP_MAX_TICKERS || 12

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const suggestionOptions = computed(() => {
      const options = suggestions?.value?.filter(suggestion => !selectedTickers.value?.some(ticker => ticker.symbol === suggestion.symbol)) ?? []
      return options.map((ticker) => ({
        label: `${ticker.symbol} - ${ticker.name}`,
        value: ticker.symbol,
        ticker
      }))
    })

    const loading = computed(() => {
      return false
    })

    const symbolsToAdd = computed(() => {
      return SYMBOLS_LIMIT - (selectedTickers.value?.length || 0)
    })

    const showSymbolsToAdd = computed(()=> SYMBOLS_LIMIT !== selectedTickers.value?.length)

    onMounted(async () => {
      symbolInput.value.focus()
    })

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        tickers: selectedTickers.value?.map(t=> ({interval: '15min', ...t}))
      })
    }

    const onSelect = (v, option) => {
      if (selectedTickers.value.length < SYMBOLS_LIMIT) {
        if (selectedTickers.value?.indexOf(v) === -1) {
          const { name, symbol } = option.ticker
          const ticker = {
            name,
            symbol
          }
          selectedTickers.value.push(ticker)
        }
        else {
          selectedTickers.value = selectedTickers.value.filter(s => s !==v)
        }
      }
      else {
        warn(t('components.slides.ticker.limitReachedError'))
      }
      selectedSymbol.value = null
    }

    const getSymbolDescription = (s) => {
      const symbolObject = suggestions.value?.find(suggestion => suggestion.symbol === s) || null
      return symbolObject ? `${symbolObject.symbol} - ${symbolObject.name}` : ''
    }

    const removeSymbol = (index) => {
      selectedTickers.value.splice(index, 1)
    }

    const fetchTickers = debounce(async (input) => {
      const tickers = await store.dispatch('slides/searchTickers', { search: input })
      suggestions.value = tickers?.data || []
    }, 500)

    watch(selectedTickers.value, () => {
      updateSlideData()
      updateSlide()
      toggleDisabled(selectedTickers.value.length === 0)
    })


    if (!slide.widgetId) {
      emit('update:disabled', true)
    }

    return {
      slide,
      symbolInput,
      loading,
      inputDisabled,
      selectedTickers,
      selectedSymbol,
      suggestionOptions,
      symbolsToAdd,
      showSymbolsToAdd,
      fetchTickers,
      onSelect,
      removeSymbol,
      getSymbolDescription,
      updateSlide
    }
  }
})
</script>

<style lang="less">

</style>
