<template>
  <div
    :style="{ alignItems, backgroundColor, color: foreground }"
    class="facebook-group"
  >
    <div class="wrapper">
      <img :src="logo || ''">
      <div class="caption">
        Our facebook group:
      </div>
      <div class="address">
        {{ address || 'facebook.com/\nkitcastapp' }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { LAYOUT_ALIGN_MAP } from '@/constants'

export default defineComponent({
  name: 'FacebookGroupTemplate',
  props: {
    data: Object,
    assets: Object
  },
  setup (props) {
    const layout = computed(() => props.data?.layout)
    const alignItems = computed(() => LAYOUT_ALIGN_MAP[layout.value])
    const foreground = computed(() => props.data?.foreground)
    const backgroundColor = computed(() => props.data?.background?.color)
    const address = computed(() => props.data?.address)
    const logo = computed(() => props.assets?.logo?.source)

    return {
      alignItems,
      foreground,
      backgroundColor,
      address,
      logo,
    }
  }
})
</script>

<style lang="less" scoped>
.facebook-group {
  line-height: 1.2;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 16px 32px;
  overflow: hidden;
  justify-content: center;
  text-align: center;

  div {
    white-space: pre;
  }

  .wrapper {
    background: #fff;
    height: 300px;
    width: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-top: 38px;
      max-height: 86px;
    }

    .caption {
      margin-top: 35px;
      font-size: 14px;
    }

    .address {
      width: 100%;
      overflow: hidden;
      word-break: break-word;
      margin-top: 10px;
      padding: 0 10px 0 10px;
      font-size: 26px;
      height: 34%;
      font-weight: bold;
    }
  }
}
</style>
