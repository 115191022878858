<template>
  <div
    :style="{ backgroundColor }"
    class="floor-map"
  >
    <div class="wrapper">
      <div class="floors">
        <div class="floor">
          <div
            class="floor-number"
            :style="{ color: foreground }"
          >
            <span>{{ floor }}</span>
            <span>Floor</span>
          </div>
          <div class="description">
            <div class="description-wrapper">
              <div
                class="floor-item"
                :style="{ color: foreground }"
              >
                {{ sections }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'FloorMapTemplate',
  props: {
    data: Object
  },
  setup (props) {
    const foreground = computed(() => props.data?.foreground || '#000')
    const backgroundColor = computed(() => props.data?.background?.color)
    const floor = computed(() => props.data?.floor)
    const sections = computed(() => props.data?.sections)

    return {
      foreground,
      backgroundColor,
      floor,
      sections,
    }
  }
})
</script>

<style lang="less" scoped>
.floor-map {
  line-height: 1.2;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 28px;
  overflow: hidden;
  justify-content: flex-start;

  div {
    white-space: pre-wrap;
    display: flex;
    flex: 1;
  }

  .wrapper {
    padding: 16px 4px;
    width: 100%;
    display: flex;
    flex: 1;

    .floors {
      margin-top: 14px;
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;

      .floor {
        display: flex;
        flex: 1;
        align-items: stretch;
        height: 596px;

        > div {
          padding: 16px;
        }

        .floor-number {
          flex: 0 0 141px;
          display: flex;
          margin-right: 4px;
          background-color: #fff;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          border-right: 0;
          > span {
            &:first-child {
              flex: 1;
              font-size: 66px;
              line-height: 66px;
              letter-spacing: -5px;
            }

            &:last-child {
              font-size: 10px;
              line-height: 10px;
            }
          }
        }

        .description {
          width: 435px;
          display: flex;
          font-size: 27px;
          line-height: 33px;
          flex: 1;
          background-color: #fff;
          align-items: flex-start;
          padding-left: 40px;

          .description-wrapper {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            padding: 0 8px;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

</style>
