<script>
import { computed, defineComponent, inject, ref } from 'vue'
import DropdownSection from '@/components/cEngineEditor/DropdownSection.vue'
import AlignmentSectionItem from '@/components/cEngineEditor/AlignmentSectionItem.vue'
import MovableModal from '@/components/MovableModal.vue'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'TextElementSettingsModal',
  components: { ArrowLeftOutlined, MovableModal, AlignmentSectionItem, DropdownSection },
  props: {
    open: Boolean
  },
  emits: ['close'],
  setup (_, {emit}) {

    const prompt = ref('')
    const showAITextGenerator = ref(false)
    const service = inject('cEngineService')
    const currentAlignment = computed(()=>service.activeElement?.value?.alignment)
    const currentFontColor = computed(()=>service.activeElement?.value?.fontColor)

    const dropdownTitle = computed(()=> {
      return showAITextGenerator.value ? 'Ask AI' : 'Text'
    })

    const resetDropdown = () => {
      prompt.value = ''
      showAITextGenerator.value = false
    }

    const closeDropdown = () => {
      setTimeout(resetDropdown, 300)
      emit('close')
    }

    const handleAlignmentChange = (alignment) => {
      service.changeAlignment(alignment)
    }

    const handleStyleChange = (style) => {
      service.changeStyle(style)
    }

    const openAITextGenerator = () => {
      showAITextGenerator.value = true
    }

    const closeAITextGenerator = () => {
      prompt.value = ''
      showAITextGenerator.value = false
    }

    const handleFontColorChange = (event) => {
      service.changeFontColor(event.target.value)
    }



    return {
      prompt,
      showAITextGenerator,
      dropdownTitle,
      currentAlignment,
      currentFontColor,
      closeDropdown,
      openAITextGenerator,
      closeAITextGenerator,
      handleAlignmentChange,
      handleFontColorChange,
      handleStyleChange
    }
  }
})

</script>

<template>
  <MovableModal
    :open="open"
    @close="closeDropdown"
  >
    <template #title>
      <a-button
        v-if="showAITextGenerator"
        class="back"
        type="link"
        @click="closeAITextGenerator"
      >
        <ArrowLeftOutlined />
      </a-button>
      <span class="title">
        {{ dropdownTitle }}
      </span>
    </template>
    <template #content>
      <div class="settings-sections">
        <template v-if="!showAITextGenerator">
          <DropdownSection title="Content">
            <a-button
              block
              @click="openAITextGenerator"
            >
              Ask AI
            </a-button>
          </DropdownSection>
          <DropdownSection
            title="Alignment"
            centered
          >
            <AlignmentSectionItem
              :current-alignment="currentAlignment"
              @change="handleAlignmentChange"
            />
          </DropdownSection>
          <DropdownSection title="Text Color">
            <a-input
              :value="currentFontColor"
              type="color"
              @change="handleFontColorChange"
            />
          </DropdownSection>
        </template>
        <template v-else>
          <DropdownSection title="Prompt">
            <a-textarea
              v-model="prompt"
              rows="7"
            />
            <a-button>
              Generate
            </a-button>
          </DropdownSection>
        </template>
      </div>
    </template>
  </MovableModal>
</template>

<style scoped lang="less">
button.ant-btn.back {
  --icon-color: #656565;
  padding: 0;
  margin-right: 8px;
  :deep(.anticon) {
    color: var(--icon-color);
  }
}
.text-element-settings-dropdown {
  .settings-sections {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
