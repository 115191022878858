<script setup>
import { computed, defineProps, ref, watchEffect } from 'vue'
import EmergencyPlaylistZone from '@/components/emergencyPlaylist/EmergencyPlaylistZone.vue'
import { createSlides } from '@/helpers/Slides'
import { cloneDeep } from 'lodash'

const HIGHLIGHT = 'highlight'

const props = defineProps({
  playlist: Object,
  filterState: Object,
  widgetDisplayRestrictions: Object
})

const emit = defineEmits(['slide-click', 'delete', 'slide-object-fit-change', 'slide-mute'])

const zoneKey = 'MAIN'
const slidesRaw = computed(()=> props.playlist?.layout?.zones?.[0]?.slides )
const zoneId = computed(()=> props.playlist?.layout?.zones?.[0]?.id )
const highlightMode = computed(() => props.filterState.type === HIGHLIGHT && !!props.filterState.input)

const slides = ref('')
const slidesFiltered = ref('')

const getFilteredSlideList = (slideList) => {

  if (highlightMode.value) {
    return slideList.map(slide => {
      slide.setHighlighted(slideIncludesFilters(slide))
      return slide
    })
  } else {
    return slideList.filter(slideIncludesFilters)
    .map(slide => {
      slide.setEditOnlyMode(true)
      slide.setHighlighted(false)
      return slide
    })
  }
}

const slideIncludesFilters = (slide) => {
  const filter = props.filterState.input?.toLowerCase()
  return slide.widgetType.toLowerCase().includes(filter) ||
      slide.getName()?.toLowerCase().includes(filter)
}

const mapRawSlidesToSlides = (raw) => {
  return createSlides(cloneDeep(raw))
}

watchEffect(()=>{
  slides.value = mapRawSlidesToSlides(slidesRaw.value)
  slidesFiltered.value = getFilteredSlideList(slides.value)
})

const onSlideClick = (payload) => {
  emit('slide-click', { playlistId: props.playlist.id, zoneId: zoneId.value, ...payload })
}

const onPlaylistDelete = () => {
  emit('delete', props.playlist.id)
}

const onSlideObjectFitChange = (payload) => {
  emit('slide-object-fit-change', { playlistId: props.playlist.id, zoneId: zoneId.value, ...payload })
}

const onSlideToggleSound = (payload) => {
  emit('slide-mute', { playlistId: props.playlist.id, zoneId: zoneId.value, ...payload })
}

</script>

<template>
  <EmergencyPlaylistZone
    v-if="slidesFiltered.length"
    :zone-key="zoneKey"
    :zone-slides="slidesFiltered"
    :widgets-display-restrictions="widgetDisplayRestrictions?.V?.[zoneKey]"
    :playlist-id="playlist.id"
    @slide-click="onSlideClick"
    @slide-delete="onPlaylistDelete"
    @slide-object-fit-change="onSlideObjectFitChange"
    @slide-mute="onSlideToggleSound"
    @slide-copy="copyPlaylistId"
  />
</template>

<style scoped lang="less">

</style>
