<script setup>
import VerifyEmailHeader from '@/components/conditionalHeaders/VerifyEmailHeader.vue'
import UpdatePaymentMethodHeader from '@/components/conditionalHeaders/UpdatePaymentMethodHeader.vue'
import RenewSubscriptionHeader from '@/components/conditionalHeaders/RenewSubscriptionHeader.vue'
import WorkspaceFrozenNonAdminHeader from '@/components/conditionalHeaders/WorkspaceFrozenNonAdminHeader.vue'
import TrialHeader from '@/components/conditionalHeaders/TrialHeader.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
import SubscriptionPausedHeader from '@/components/conditionalHeaders/SubscriptionPausedHeader.vue'
import InactiveSubscriptionHeader from '@/components/conditionalHeaders/InactiveSubscriptionHeader.vue'
import WorkspaceFrozenAdminHeader from '@/components/conditionalHeaders/WorkspaceFrozenAdminHeader.vue'
import CardExpirationHeader from '@/components/CardExpirationHeader.vue'
import WorkspaceFrozenTooManyDevices from '@/components/conditionalHeaders/WorkspaceFrozenTooManyDevices.vue'

const store = useStore()

const emailVerified = computed(() => store.getters['auth/emailVerified'])
const isTrialTier = computed(() => store.getters['workspace/isTrialTier'])
const isPastDue = computed(() => store.getters['workspace/isPastDue'])
const isUnpaid = computed(() => store.getters['workspace/isUnpaid'])
const isInactive = computed(() => store.getters['workspace/isInactive'])
const isPaused = computed(() => store.getters['workspace/isPaused'])
const isOwnerOrAdmin = computed(() => store.getters['workspace/isOwner'] || store.getters['workspace/isAdmin'])
const isNotOwnerOrAdmin = computed(()=>!isOwnerOrAdmin.value)
const isManuallyFrozen = computed(() => store.getters['workspace/isManuallyFrozen'])
const isFrozenTooManyDevices = computed(() => store.getters['workspace/isFrozenTooManyDevices'])
const isFrozen = computed(() => store.getters['workspace/isFrozen'])
const isEnterprize = computed(() => store.getters['workspace/isEnterprize'])
const daysToCardExpiration = computed(() => store.getters['subscription/daysToCardExpiration'])

</script>

<template>
  <VerifyEmailHeader v-if="!emailVerified" />
  <WorkspaceFrozenNonAdminHeader v-else-if="isFrozen && isNotOwnerOrAdmin" />
  <template v-else-if="isOwnerOrAdmin && !isEnterprize">
    <WorkspaceFrozenAdminHeader v-if="isManuallyFrozen" />
    <WorkspaceFrozenTooManyDevices v-else-if="isFrozenTooManyDevices" />
    <TrialHeader v-else-if="isTrialTier" />
    <SubscriptionPausedHeader v-else-if="isPaused" />
    <InactiveSubscriptionHeader v-else-if="isInactive" />
    <UpdatePaymentMethodHeader v-else-if="isPastDue" />
    <RenewSubscriptionHeader v-else-if="isUnpaid" />
    <WorkspaceFrozenAdminHeader v-else-if="isFrozen" />
    <CardExpirationHeader v-else-if="daysToCardExpiration !== null && daysToCardExpiration <= 30" />
  </template>
  <template v-else-if="isOwnerOrAdmin && isEnterprize">
    <WorkspaceFrozenAdminHeader v-if="isFrozen" />
  </template>
</template>

<style scoped lang="less">

</style>
