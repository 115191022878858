<template>
  <a-menu
    theme="light"
    mode="inline"
    class="sider-menu"
    :selected-keys="[]"
  >
    <a-menu-item
      v-if="addBlockFunction"
      key="1"
      @click="addBlockFunction"
    >
      <template #icon>
        <PlusCircleOutlined />
      </template>
      {{ $t('components.smartGroupDevicesSider.addBlock') }}
    </a-menu-item>
  </a-menu>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { PlusCircleOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SmartGroupDevicesSider',
  components: {
    PlusCircleOutlined
  },
  setup () {
    const store = useStore()
    const addBlockFunction = computed(()=> store.getters['tags/addBlockFunction'])

    return {
      addBlockFunction
    }
  }
})

</script>

<style scoped>

</style>
