<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const billingDevicesQuantity = computed(() => store.getters['workspace/billingDevicesQuantity'])
const isYearly = computed(() => store.getters['workspace/billingInterval'] === 'year')
const currentPeriodEndDate = computed(() => store.getters['subscription/currentPeriodEndsFormatted'](isYearly.value? 'MMMM D YYYY': undefined ))
const isInactive = computed(() => store.getters['workspace/isInactive'])
const isPastDue = computed(() => store.getters['workspace/isPastDue'])

</script>

<template>
  <a-card>
    <a-row>
      <a-col
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text>{{ $t('components.subscriptionSettingsInfoCardEnterprise.planName') }}</a-typography-text>
        <a-typography-text type="secondary">
          {{ $t('components.subscriptionSettingsInfoCardEnterprise.includesNumberOfLicenses', {count: billingDevicesQuantity}) }}
        </a-typography-text>
      </a-col>
      <a-col
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text
          v-if="!isInactive"
          type="secondary"
        >
          {{ $t('components.subscriptionSettingsInfoCardEnterprise.renewalDate') }} <span v-text="currentPeriodEndDate" />
        </a-typography-text>
        <a-typography-text
          v-if="isPastDue"
          type="danger"
        >
          {{ $t('components.subscriptionSettingsInfoCardEnterprise.failed') }}
        </a-typography-text>
      </a-col>
    </a-row>
  </a-card>
</template>

<style scoped lang="less">

</style>
