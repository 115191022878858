<script setup>

</script>

<template>
  <div style="position: sticky; bottom: 0; left: 0; z-index: 2;">
    <slot />
  </div>
</template>

<style scoped lang="less">

</style>
