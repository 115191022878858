<template>
  <a-spin :spinning="loading">
    <div style="height: 100vh" />
  </a-spin>
  <AuthModal
    v-if="!loading"
    :is-login="isLogin"
    :is-signup="isSignup"
    :is-simple-signup="isSimpleSignup"
    :is-reset="isReset"
    :is-s-s-o-login="isSSOLogin"
    :is-email-confirmed="isEmailConfirmed"
    :token="token"
  />
</template>

<script>
import { computed, defineComponent, inject, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AuthModal from '@/components/authModal/AuthModal.vue'
import { error } from '@/utils'

const GTM_METHOD_MAP = {
  google: 'google',
  microsoft: 'ms',
  oidc: 'sso'
}


export default defineComponent({
  name: 'AuthPage',
  components: { AuthModal },
  props: {
    token: String
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const gtm = inject('gtm')
    const userId = computed(() => store.getters['auth/userId'])
    const authStatus = computed(() => store.getters['auth/authStatus'])
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
    const isLogin = computed(() => route.name === 'Login')
    const isSignup = computed(() => route.name === 'Signup')
    const isSSOLogin = computed(() => route.name === 'SSOLogin')
    const isSimpleSignup = computed(() => route.name === 'SimpleSignup')
    const isReset = computed(() => route.name === 'ForgotPassword')
    const isEmailConfirmed = computed(() => route.name === 'EmailConfirmed')
    const loading = ref(true)

    onMounted(async () => {
      if (isAuthenticated.value && !authStatus.value) {
        await store.dispatch('auth/initUser').then(() => {
          return router.push({ name: 'Home', query: { inviteToken: props.token} })
        }).catch(() => {
          loading.value = false
        })
      } else if (authStatus.value) {
        return router.push({ name: 'Home', query: { inviteToken: props.token} })
      } else {
        loading.value = false
      }
      window.addEventListener('message', receiveMessage, false)
    })

    onUnmounted(() => {
      window.removeEventListener('message', receiveMessage)
    })

    const receiveMessage = ({ data }) => {
      try {
        data = JSON.parse(data)
      } catch (e) {
        return
      }

      let {
        action,
        platform,
        state,
        code,
        error: errorMessage
      } = data

      if (errorMessage) {
        return error(errorMessage)
      }

      if (state && code) {
        platform = 'oidc'
        action = 'login'
      }

      if (!action || !platform) return

      let input, dispatchActionName
      switch (action) {
        case 'login':
          switch (platform) {
            case 'google':
              input = { code }
              dispatchActionName = 'auth/loginWithGoogle'
              break
            case 'microsoft':
              input = { code }
              dispatchActionName = 'auth/loginWithMicrosoft'
              break
            case 'oidc':
              input = { code, state }
              dispatchActionName = 'auth/loginWithOIDC'
              break
          }
      }
      const method = GTM_METHOD_MAP[platform]
      if (!input || !dispatchActionName) return
      store.dispatch(dispatchActionName, input).then((isSignup) => {
        const event = isSignup
          ? { event: 'RegistrationForm', action: 'show', method, label: 'success', userId: userId.value }
          : { event: `Login`, action: 'success', method, label: 'success', userId:userId.value }
        gtm.push(event)
        redirectHome()
      }).catch(e => {
        error(e.message)
      })
    }

    const redirectHome = () => {
      router.push({name: 'Home', query: { ...route.query }})
    }

    return {
      loading,
      isAuthenticated,
      authStatus,
      isLogin,
      isSignup,
      isSimpleSignup,
      isReset,
      isSSOLogin,
      isEmailConfirmed
    }
  }
})

</script>

<style scoped>

</style>
