<script>
import draggable from 'vuedraggable'
import { computed, defineComponent, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import PageEditorTextElement from '@/components/cEngineEditor/PageEditorTextElement.vue'
import PageEditorMediaElement from '@/components/cEngineEditor/PageEditorMediaElement.vue'
import {
  CEngineRow,
  ELEMENT_TYPE_TO_COMPONENT_MAP,
  MediaElement,
  TextElement
} from '@/components/cEngineEditor/helpers'

function getElementProps (element) {
  switch (element.type) {
    case 'text':
      return new TextElement({
        type: element.type,
        items: [
          {
            style: element.name,
            text: element.name
          }
        ]
      })
    case 'media':
      return new MediaElement({
        type: element.type,
        url: element.url,
        objectFit: element.objectFit,
        textVisible: !!element.textVisible,
        mediaType: element.mediaType
      })
  }
}

export default defineComponent({
  name: 'PageEditorRow',
  components: {
    draggable,
    PageEditorTextElement,
    PageEditorMediaElement
  },
  props: {
    row: {
      type: CEngineRow,
      default: () => ({})
    },
    rowIndex: {
      type: Number,
      default: 0
    },
    empty: {
      type: Boolean,
      default: true
    },
  },
  emits: ['has-children'],
  setup (props, { emit }) {

    const listRef = ref()
    const hasChildren = ref(false)
    const rowObject = ref(props.row)
    const service = inject('cEngineService')
    const activeElement = computed(() => service.activeElement)
    const activeColumn = computed(() => service.activeColumn.value)

    onMounted(() => {
      window.addEventListener('drag', handleRowDrag)
      handleRowDrag()

    })

    onUnmounted(() => {
      window.removeEventListener('drag', handleRowDrag)
    })

    const onColumnAdd = (event) => {
      if (!event.added || (event.added && !event.added.element?.isNewItem)) {
        return
      }

      rowObject.value?.removeColumn(event.added.newIndex) // remove the ghost column
      const { element } = event.added

      if (!element) {
        return
      }

      const column = {
        elements: [getElementProps(element)]
      }
      rowObject.value?.addColumn(column, event.added.newIndex)
    }

    const deleteColumn = (index) => {
      rowObject.value?.removeColumn(index)
    }


    const handleRowDrag = () => {
      setChildrenExist()
    }

    const setChildrenExist = () => {
      const childrenExist = listRef.value?.targetDomElement?.children?.length > 0
      if (childrenExist !== hasChildren.value) {
        hasChildren.value = childrenExist
        emit('has-children', { hasChildren: childrenExist, index: props.rowIndex } )
      }
    }

    const handleColumnClick = (column) => {
      service.selectColumn(column)
    }

    const handleElementClick = (element) => {
      service.selectElement(element)
    }

    const handleItemSelect = (element, item) => {
      service.selectElement(element)
      service.selectItem(item)
    }

    watch(() => props.row, (newVal) => {
      rowObject.value = newVal
      setChildrenExist()
    })


    return {
      rowObject,
      hasChildren,
      listRef,
      activeElement,
      activeColumn,
      ELEMENT_TYPE_TO_COMPONENT_MAP,
      setChildrenExist,
      handleElementClick,
      handleColumnClick,
      handleItemSelect,
      onColumnAdd,
      deleteColumn
    }
  }
})


</script>

<template>
  <div
    class="page-editor-row"
    :class="{'flex-empty': empty }"
    :style="{flex: rowObject?.height}"
  >
    <div class="row-drag-handle">
      <span />
      <span />
      <span />
    </div>
    <draggable
      ref="listRef"
      class="drag-area"
      :list="rowObject?.columns"
      :group="{ name: `row${rowIndex}`, put: ['textElements', 'row0', 'row1', 'row2'] }"
      item-key="name"
      :sort="true"
      @change="(e) => onColumnAdd(e)"
    >
      <template #item="{ element: column, index: columnIndex }">
        <div
          class="page-editor-column"
          :style="{flex: column.width}"
          :class="{selected: activeColumn === column}"
          @click.stop="handleColumnClick(column)"
        >
          <a-dropdown :trigger="['contextmenu']">
            <template
              v-for="(element, elementIndex) in column.elements"
              :key="elementIndex"
            >
              <component
                :is="ELEMENT_TYPE_TO_COMPONENT_MAP[element.type]"
                :element="element"
                :is-active="activeElement === element"
              />
            </template>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  key="1"
                  @click="deleteColumn(columnIndex)"
                >
                  Delete block
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </template>
    </draggable>
  </div>
</template>

<style scoped lang="less">
.page-editor-row {
  flex: 6 1 0;
  position: relative;
  border-radius: 24px;
  word-break: break-word;
  .row-drag-handle {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0, .5);
    width: 18px;
    height: 40px;
    border: solid 1px rgba(0,0,0, .1);
    border-radius: 4px;
    top: 0;
    bottom: 0;
    left: -10px;
    margin: auto;
    position: absolute;
    font-size: 30px;
    z-index: 1000;
    cursor: move;
    span {
      display: block;
      width: 10px;
      height: 2px;
      background-color: rgba(255,255,255,0.3);
      margin: 2px 0;
    }
  }
  &:hover {
    &:not(.flex-empty) {
      box-shadow: 0 0 1px 1px #d9d9d9;
      .row-drag-handle {
        display: flex;
      }
    }
  }
  &.flex-empty {
    flex: 0 1 20px;
  }
  .drag-area {
    display: flex;
    gap: var(--block-gap);
    height: 100%;
    width: 100%;
    .page-editor-column {
      border-radius: 24px;
      position: relative;
      cursor: pointer;
      flex: 6 0 0;
      &:hover {
        background-color: rgba(245, 245, 245, .5);
      }
      &.selected {
        outline: 2px solid var(--ant-primary-color);
      }
    }
    :deep(.sortable-ghost) {
      border-radius: 24px;
      flex: 6 0 0;
      background-color: rgba(245, 245, 245, .5);
      button {
        display: none;
      }
      .column-preview {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 22px;
      }
    }
  }
}
</style>
