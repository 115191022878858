<script setup>

import { DownOutlined } from '@ant-design/icons-vue'
import { computed, ref } from 'vue'
import { isEmail } from 'validator'
import { useStore } from 'vuex'
import { ROLES } from '@/constants'

const store = useStore()

defineProps({
  newMemberAccess:{
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['inviteMember', 'editMemberAccess', 'roleChange'])

const FULL_ACCESS_ROLES = [ROLES.ADMIN, ROLES.OWNER]

const ROLE_OPTIONS = ref([
  {
    key: ROLES.ADMIN,
    show: true,
    label: 'components.collaborationModal.adminRole',
    description: 'components.collaborationModal.adminRoleDescription'
  },
  {
    key: ROLES.MANAGER,
    show: true,
    label: 'components.collaborationModal.managerRole',
    description: 'components.collaborationModal.managerRoleDescription'
  },
  {
    key: ROLES.AUTHOR,
    show: computed(()=> store.getters['workspace/authorRoleEnabled']),
    label: 'components.collaborationModal.authorRole',
    description: 'components.collaborationModal.authorRoleDescription'
  }
])

const isNewEmailValid = computed(() => {
  return isEmail(newMemberEmail.value)
})

const newMemberEmail = ref('')
const newMemberRole = ref(ROLES.MANAGER)


const handleNewMemberRoleChange = (e) => {
  emit('roleChange', e.key)
  newMemberRole.value = e.key
}

const handleInviteMember = () => {
  if (!isNewEmailValid.value) {
    return
  }
  emit('inviteMember', {email: newMemberEmail.value, role: newMemberRole.value})
}

const resetForm = () => {
  newMemberEmail.value = ''
  newMemberRole.value = ROLES.MANAGER
}

defineExpose({
  resetForm
})

</script>

<template>
  <a-input-group
    compact
  >
    <a-input
      v-model:value="newMemberEmail"
      style="width: 200px"
      :placeholder="$t('components.collaborationModal.newMemberEmailPlaceholder')"
      @press-enter="handleInviteMember"
    />
    <a-dropdown :overlay-class-name="'role-dropdown'">
      <template #overlay>
        <a-menu @click="handleNewMemberRoleChange">
          <template
            v-for="option in ROLE_OPTIONS"
            :key="option.key"
          >
            <a-menu-item
              v-if="option.show"
              :key="option.key"
            >
              {{ $t(option.label) }}
              <div>
                <small>
                  {{ $t(option.description) }}
                </small>
              </div>
            </a-menu-item>
          </template>
        </a-menu>
      </template>
      <a-button id="role-dropdown-button">
        {{ $t(`components.collaborationModal.${newMemberRole?.toLowerCase()}Role`) }}
        <DownOutlined />
      </a-button>
    </a-dropdown>
    <a-button
      :disabled="FULL_ACCESS_ROLES.includes(newMemberRole)"
      @click="$emit('editMemberAccess', newMemberRole)"
    >
      {{ newMemberAccess?.length ? $t('components.collaborationModal.accessToButtonText', {count: newMemberAccess?.length}) : $t('components.collaborationModal.accessAllButtonText') }}
    </a-button>
    <a-button
      type="primary"
      :disabled="!isNewEmailValid"
      :loading="loading"
      @click="handleInviteMember"
    >
      {{ $t('components.collaborationModal.inviteButtonText') }}
    </a-button>
  </a-input-group>
</template>

<style scoped lang="less">

</style>
