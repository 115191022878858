<template>
  <div class="template-picker">
    <header>
      {{ $t('components.smartTemplateConstructor.templatePicker.title') }}
    </header>
    <div class="templates">
      <div
        v-for="menuItem in templateMenu"
        :key="menuItem.type"
        class="template"
        @click="handleTemplatePick(menuItem.type)"
      >
        <div class="template-preview">
          <img
            :src="menuItem.preview"
            alt=""
          >
        </div>
        <div class="template-name">
          {{ menuItem.name }}
        </div>
        <div class="forward-icon">
          <ForwardArrow />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ForwardArrow from '@/components/icons/ForwardArrow'
import { TEMPLATE_MENU } from '@/helpers/Slides'

export default defineComponent({
  name: 'TemplatePicker',
  components: { ForwardArrow },
  emits: ['change'],
  setup (props, { emit }) {

    const handleTemplatePick = (template) => {
      emit('change', template)
    }

    return {
      templateMenu: TEMPLATE_MENU,
      handleTemplatePick,
    }
  }
})
</script>

<style lang="less">
@import '@/styles/variables.less';

.template-picker {
  overflow-x: auto;

  header {
    line-height: 56px;
    border-bottom: solid 1px @border-color-light;
  }

  :deep(svg) * {
    fill: orange;
  }

  > .templates {
    display: block;

    > .template {
      display: flex;
      height: 80px;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      position: relative;
      border-bottom: solid 1px #ECECEC;

      .template-preview {
        background-color: #f6f6f6;
        width: 100px;
        aspect-ratio: 16/9;
        margin-right: 16px;
        border-radius: 3px;
        overflow: hidden;

        img {
          width: 100px;
          aspect-ratio: 16/9;
        }
      }

      .template-name {
        flex: 1;
        color: #B8B8B8;
        transition: color .3s ease;
      }

      .forward-icon {
        opacity: 0;
        transition: opacity .3s ease;
      }

      &:hover {
        .forward-icon {
          opacity: 1;
        }

        .template-name {
          color: #000;
        }
      }
    }
  }


}
</style>
