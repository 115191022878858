<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import TallyModal from '@/components/TallyModal.vue'

const store = useStore()
const TALLY_FORM_COMPLETION_CLOSE_DELAY = 2000
const TALLY_URL = 'https://tally.so/embed/w4vPNo?dynamicHeight=1'
const userEmail = computed(() => store.getters['auth/userEmail'])
const workspaceId = computed(() => store.getters['workspace/workspaceId'])
const workspaceName = computed(() => store.getters['workspace/workspaceName'])
const escapedEmail = computed(() => encodeURIComponent(userEmail.value))
const prefilledFields = computed(() => {
  return `&email=${escapedEmail.value}&workspaceId=${workspaceId.value}&workspaceName=${workspaceName.value}`
})

const tallyUrlWithPrefilledFields = computed(() => {
  return `${TALLY_URL}${prefilledFields.value}`
})

const showModal = ref(false)

const handleShowModal = () => {
  showModal.value = true
}

const closeModal = () => {
  showModal.value = false
}

</script>

<template>
  <TallyModal
    :open="showModal"
    :src="tallyUrlWithPrefilledFields"
    :close-delay="TALLY_FORM_COMPLETION_CLOSE_DELAY"
    @cancel="closeModal"
    @close="closeModal"
  />
  <div style="margin-top: 16px;">
    <a-typography-link @click="handleShowModal">
      {{ $t('components.subscriptionSettings.downgradeOrCancel') }}
    </a-typography-link>
  </div>
</template>

<style lang="less">
.close-to-top-modal .ant-modal {
  top: 24px;
}
</style>
