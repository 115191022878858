<template>
  <DurationSlideStep
    :key="durationValue"
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.stream.slideStep1.subTitle')"
    :title="$t('components.slides.stream.slideStep1.title')"
  >
    <a-form @submit="onSubmit">
      <button
        style="display: none"
        type="submit"
      />
      <a-input
        ref="inputRef"
        v-model:value="streamUrl"
        :placeholder="$t('components.slides.stream.slideStep1.streamUrlPlaceholder')"
        @change="onChange"
      >
        <template #addonBefore>
          <a-select
            v-model:value="protocol"
            :options="protocolOptions"
            style="width: 90px"
            @change="onChange"
          />
        </template>
      </a-input>
    </a-form>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.stream.slideStep2.title')"
    no-border
  >
    <a-checkbox
      :checked="showCCEnabled"
      :disabled="!isYouTubeLink"
      @change="handleCCChange"
    >
      {{ $t('components.slides.stream.slideStep2.showCC') }}
    </a-checkbox>
  </SlideStep>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { isURL } from 'validator'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { DEFAULT_PROTOCOL } from '@/constants'
import { extractVideoIdFromYoutubeLink, getUrlProtocol, ifIsYoutubeLink, normalizeUrl } from '@/utils'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { StreamSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

function durationToSeconds (duration) {
  const regex = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/
  const matches = regex.exec(duration)
  if (!matches) return null
  const hours = parseInt(matches[1] || 0)
  const minutes = parseInt(matches[2] || 0)
  const seconds = parseInt(matches[3] || 0)
  return (hours * 3600) + (minutes * 60) + seconds
}

const SUPPORTED_PROTOCOLS = ['https', 'rtp', 'rtsp', 'rtmp', 'mms']

export default defineComponent({
  name: 'StreamSlide',
  components: { DurationSlideStep, SlideStep },
  props: {
    title: String,
    slideObj: StreamSlide,
    groupId: String,
    zoning: Boolean,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const slide = props.slideObj || new StreamSlide()
    const { duration:slideDuration } = slide
    const {
      ccEnabled,
      url,
      muted,
      duration,
      thumbnailUrl: thumbnail,
      objectFit
    } = slide.slideData
    const inputRef = ref(null)
    const protocol = ref(getUrlProtocol(url) || DEFAULT_PROTOCOL)
    const durationValue = ref(slideDuration)
    const streamUrl = ref(normalizeUrl(url))
    const closeCaptionEnabled = ref(ccEnabled)
    const isMuted = props.zoning || muted
    const youtubeVideoDuration = ref(duration)
    const thumbnailUrl = ref(thumbnail)

    const isYouTubeLink = computed(()=> {
      return ifIsYoutubeLink(streamUrl.value)
    })

    const showCCEnabled = computed(()=> !!closeCaptionEnabled.value && isYouTubeLink.value)

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    onMounted(() => {
      inputRef.value && inputRef.value.focus()
    })

    const onSubmit = () => {
      if (!props.disabled) {
        emit('save')
      }
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        ccEnabled: showCCEnabled.value ? closeCaptionEnabled.value : false,
        url: protocol.value + streamUrl.value,
        duration: youtubeVideoDuration.value,
        thumbnailUrl: thumbnailUrl.value,
        muted: isMuted,
        forcedCaptions: null,
        objectFit
      })
      slide.setDuration(youtubeVideoDuration.value || slideDuration)
    }

    const updateSlide = () => {
      durationValue.value = slide.duration
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const normalizeInput = () => {
      const stripUrl = normalizeUrl(streamUrl.value)
      const urlProtocol = getUrlProtocol(streamUrl.value)
      if (urlProtocol) {
        protocol.value = urlProtocol
      }
      if (!isURL(protocol.value + stripUrl, { protocols: SUPPORTED_PROTOCOLS })) {
        toggleDisabled(true)
        return
      }
      toggleDisabled(false)
      streamUrl.value = stripUrl
    }

    const handleYoutubeVideoDuration = async(url) => {
      if (isYouTubeLink.value) {
        const videoId = extractVideoIdFromYoutubeLink(url)
        if (!videoId) {
          thumbnailUrl.value = null
          youtubeVideoDuration.value = null
          return
        }
        const videoInfo = await getYoutubeVideoInfo(videoId)
        const { duration } = videoInfo?.contentDetails || {}
        youtubeVideoDuration.value = duration ? durationToSeconds(duration) : null
        thumbnailUrl.value = videoInfo?.snippet?.thumbnails?.medium?.url
      } else {
        thumbnailUrl.value = null
        youtubeVideoDuration.value = null
      }
    }

    const onChange = async () => {
      normalizeInput()
      await handleYoutubeVideoDuration(streamUrl.value)
      updateSlideData()
      updateSlide()
    }

    const handleCCChange = (e) => {
      closeCaptionEnabled.value = e.target.checked
      onShowCCChange()
    }


    const onShowCCChange = () => {
      updateSlideData()
      updateSlide()
    }

    const getYoutubeVideoInfo = (videoId) => {
      return store.dispatch('slides/getYoutubeVideoInfo', videoId)
    }

    watch(()=> showCCEnabled.value, (show) => {
      if (show && closeCaptionEnabled.value) {
        updateSlideData()
        updateSlide()
      }
    })

    return {
      protocolOptions: SUPPORTED_PROTOCOLS.map(p => ({
        label: p + '://',
        value: p + '://'
      })),
      slide,
      durationValue,
      protocol,
      streamUrl,
      inputRef,
      isYouTubeLink,
      showCCEnabled,
      onChange,
      handleCCChange,
      onSubmit,
      updateSlide
    }
  }
})
</script>

<style lang="less">

</style>
