<template>
  <div
    :style="{ backgroundColor, color: foreground }"
    class="working-hours"
  >
    <div class="wrapper">
      <div class="header">
        {{ header || 'We Are Open For Service:' }}
      </div>
      <div
        :class="{ 'dynamic-height': schedule?.length > 3 }"
        class="body"
      >
        <div
          v-for="(item, $index) in schedule"
          :key="'item' + $index"
          class="row"
        >
          <div class="day">
            {{ item?.day }}
          </div>
          <div class="time">
            {{ item?.time }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'WorkingHoursTemplate',
  props: {
    data: Object
  },
  setup (props) {
    const foreground = computed(() => props.data?.foreground)
    const backgroundColor = computed(() => props.data?.background?.color)
    const header = computed(() => props.data?.header)
    const schedule = computed(() => props.data?.workingHours)

    return {
      foreground,
      backgroundColor,
      header,
      schedule
    }
  }
})
</script>

<style lang="less" scoped>
.working-hours {
  line-height: 1.2;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 32px;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  div {
    white-space: pre-wrap;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 23vmax;
    width: 31vmax;

    .header {
      font-size: 30px;
    }

    .body {
      margin-top: 10px;
      font-size: 30px;
      width: 100%;

      &.dynamic-height {
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 23px;

        .row {
          height: auto;
          flex: 1;
        }
      }

      .row {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        height: 66px;

        > div {
          display: flex;
          background: #fff;
          color: #000;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          word-break: break-word;
          overflow: hidden;

          &.day {
            margin-right: 5px;
            width: 40%;
          }

          &.time {
            width: 60%;
          }
        }
      }
    }
  }
}
</style>
