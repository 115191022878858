<template>
  <div class="simple-text-editor">
    <TextElement
      v-model:value-model="fieldsState.caption"
      :max-length="50"
      :title="$t('components.smartTemplateConstructor.simpleSignEditor.captionInputTitle')"
    />
    <TextElement
      v-model:value-model="fieldsState.header"
      :lines="3"
      :max-length="100"
      :title="$t('components.smartTemplateConstructor.simpleSignEditor.headerInputTitle')"
    />
    <TextElement
      v-model:value-model="fieldsState.subHeader"
      :lines="2"
      :max-length="50"
      :title="$t('components.smartTemplateConstructor.simpleSignEditor.subHeaderInputTitle')"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive, watchEffect } from 'vue'
import TextElement from '@/components/slideModal/smartTemplateConstructor/segmentElements/TextElement'

export default defineComponent({
  name: 'SimpleSignEditor',
  components: { TextElement },
  props: {
    data: Object,
  },
  emits: ['validChange'],
  setup (props, { emit }) {
    const fieldsState = reactive(props.data)

    const isValid = computed(() => !!(fieldsState.caption
        && fieldsState.header
        && fieldsState.subHeader))

    watchEffect(() => {
      emit('validChange', isValid.value)
    })

    return {
      fieldsState
    }
  }
})
</script>

<style scoped>

</style>
