let gtmInstance = null;

export default function initializeGTM(gtmId) {
  if (!gtmInstance) {
    if (!gtmId) {
      gtmInstance = {
        push (params) {
          console.log('GTM is not initialized');
          console.log('GTM push', params)
        }
      }
    }
    else {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmId);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', gtmId);
      gtmInstance = {
        dataLayer: window.dataLayer,
        push (data) {
          // console.log('GTM push', data)
          window.dataLayer && window.dataLayer.push(data)
        }
      };
    }
  }

  return gtmInstance;
}

