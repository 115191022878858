export default {
  namespaced: true,
  state: {

  },
  actions: {

  },
  getters: {

  },
  mutations: {

  }
}
