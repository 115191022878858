<template>
  <div class="element-title">
    <a-typography-text>
      <template v-if="title">
        {{ title }}
      </template>
      <slot
        v-else
        name="title"
      />
    </a-typography-text>
  </div>
  <div>
    <slot />
  </div>
  <a-divider
    v-if="!noBorder"
    class="small"
  />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DesignElement',
  props: {
    title: String,
    noBorder: {
      type: Boolean,
      default: () => false,
    }
  },
  setup () {
    return {}
  }
})
</script>

<style lang="less" scoped>
.element-title {
  display: flex;
  height: 32px;
  align-items: center;
}

</style>
