<script setup>

import SubscriptionPlanDescription from '@/components/updateSubscriptionModal/SubscriptionPlanDescription.vue'
import SubscriptionBillingFrequency from '@/components/updateSubscriptionModal/SubscriptionBillingFrequency.vue'

const MIN_DEVICES_FOR_GROWTH_PLAN = 1

defineProps({
  disableFields: {
    type: Boolean,
    default: false
  },
  devices: {
    type: Number,
    default: 0
  },
  pricesInfo: {
    type: Object,
    default: null
  },
  isYearly: {
    type: Boolean,
    default: false
  },
  isMonthly: {
    type: Boolean,
    default: false
  },
})



defineEmits(['onDevicesNumberChange', 'changeBillingInterval'])

</script>

<template>
  <a-space
    direction="vertical"
    style="width: 100%"
    size="large"
  >
    <div
      class="subscription-element"
      :class="{disabled: disableFields}"
    >
      <a-typography-title :level="5">
        {{ $t('components.updateSubscriptionModal.selectedPlan') }}
      </a-typography-title>
      <a-space
        direction="vertical"
        style="width: 100%"
        size="middle"
      >
        <a-card :bordered="false">
          <a-row>
            <a-col :span="9">
              <SubscriptionPlanDescription />
            </a-col>
            <a-col
              :span="13"
              :offset="2"
              style="display: flex; flex-direction: column; justify-content: center"
            >
              <p>{{ $t('components.updateSubscriptionModal.howManyScreens') }}</p>
              <a-input-number
                :value="devices"
                :min="MIN_DEVICES_FOR_GROWTH_PLAN"
                @change="$emit('onDevicesNumberChange', $event)"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-space>
    </div>
    <div
      class="subscription-element"
      :class="{disabled: disableFields}"
    >
      <a-typography-title :level="5">
        {{ $t('components.updateSubscriptionModal.billingFrequency') }}
      </a-typography-title>
      <a-card
        :bordered="false"
        class="small-x-borders"
      >
        <SubscriptionBillingFrequency
          :prices-info="pricesInfo"
          :is-yearly="isYearly"
          :is-monthly="isMonthly"
          :disable-fields="disableFields"
          @change-billing-interval="$emit('changeBillingInterval', $event)"
        />
      </a-card>
    </div>
  </a-space>
</template>

<style scoped lang="less">

</style>
