<template>
  <template v-if="mediaExists">
    <DurationSlideStep
      :slide="slide"
      show-duration-per-item
      @update-slide="updateSlide"
    />
    <SlideStep
      :sub-title="documentName"
      :title="$t('components.slides.document.slideStep1.title')"
      no-border
    >
      <template #extra>
        <a-button @click="replaceMedia">
          <template #icon>
            <SyncOutlined />
          </template>
          {{ $t('components.slides.document.slideStep1.replaceButtonsText') }}
        </a-button>
      </template>
      <div class="document-card">
        <div class="document-thumbnail">
          <img
            v-if="thumbnail"
            :src="thumbnail"
          >
          <SyncOutlined
            v-else
            spin
          />
        </div>
        <div class="document-desc">
          <a-typography-text>
            {{ $t('components.slides.document.slideStep1.extension', { ext: documentExtension?.toUpperCase() }) }}
          </a-typography-text>
          <br>
          <a-typography-text>
            {{
              documentPagesCount ? $t('components.slides.document.slideStep1.pages', { count: documentPagesCount }) : $t('components.slides.document.slideStep1.pagesUnknown')
            }}
          </a-typography-text>
        </div>
      </div>
    </SlideStep>
  </template>
  <MediaSelectModal
    v-model:visible="showMediaSelectModal"
    :exclude-headings="['actions']"
    :selected-media="selectedMedia"
    :types="['DOCUMENT']"
    selection="single"
    @close="onCloseMediaModal"
    @select="onSelectChange"
  />
</template>
<script>
import { computed, defineComponent, ref } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import MediaSelectModal from '@/components/MediaSelectModal'
import { useI18n } from 'vue-i18n'
import { DocumentSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

export default defineComponent({
  name: 'DocumentSlide',
  components: {
    DurationSlideStep,
    MediaSelectModal,
    SlideStep,
    SyncOutlined
  },
  props: {
    title: String,
    slideObj: DocumentSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const isNewSlide = ref(!props.slideObj)
    const showMediaSelectModal = ref(isNewSlide.value)
    const slide = props.slideObj || new DocumentSlide()
    const selectedObjectFit = ref(slide.slideData.objectFit)
    const asset = ref(slide.document)
    const mediaExists = ref(!!asset.value)
    const thumbnail = computed(() => asset.value?.thumbnail)
    const documentExtension = asset.value?.extension?.toUpperCase()
    const documentName = asset.value?.name
    const documentPagesCount = computed(() => asset.value?.metadata?.numPages || 'Unknown')
    const selectedMedia = ref(mediaExists.value ? [asset] : [])
    const selectedMediaId = computed(() => selectedMedia.value?.[0]?.id)

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        objectFit: selectedObjectFit.value,
        mediaDocumentReference: selectedMedia.value?.map(m => m.mediaReference)?.[0]
      })
      !isNewSlide.value && slide.setDocument(selectedMedia.value?.[0])
    }

    const onCloseMediaModal = () => {
      if (isNewSlide.value) {
        emit('close')
      }
    }

    const onSelectChange = (selection) => {
      selectedMedia.value = selection
      updateSlideData()
      updateSlide()
      toggleDisabled(!selectedMediaId.value)
      emit('save')
    }

    const replaceMedia = () => {
      showMediaSelectModal.value = true
    }

    return {
      slide,
      isNewSlide,
      showMediaSelectModal,
      mediaExists,
      selectedMedia,
      documentExtension,
      documentPagesCount,
      documentName,
      thumbnail,
      updateSlide,
      replaceMedia,
      onSelectChange,
      onCloseMediaModal
    }
  }
})
</script>

<style lang="less">
.document-card {
  margin-bottom: 16px;
  overflow: auto;
  display: flex;

  .document-thumbnail {
    width: 384px;
    height: 216px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 384px;
      height: 216px;
      float: left;
      margin-right: 32px;
      border-radius: 4px;
    }
  }

  .document-desc {

  }
}

</style>
