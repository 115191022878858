<template>
  <div class="directory-template">
    <div class="wrapper">
      <div
        :class="{single: directories?.length === 1}"
        class="floors"
      >
        <div
          v-for="(floor, index) in directories"
          :key="'floor'+ index"
          class="floor"
        >
          <div
            :style="{borderColor: foreground, color: foreground}"
            class="floor-number"
          >
            {{ floor?.number }}
          </div>
          <div
            :style="{ borderColor: foreground, backgroundColor: foreground }"
            class="description"
          >
            <div class="description-wrapper">
              <div class="floor-title">
                {{ floor?.title }}
              </div>
              <div class="floor-description">
                {{ floor?.description }}
              </div>
            </div>
          </div>
          <div
            :style="{borderColor: foreground}"
            class="direction"
          >
            <div class="direction-arrow">
              <DirectionArrow
                :style="{
                  transform:
                    `rotate(${floor?.direction}deg)`
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import DirectionArrow from '@/components/icons/DirectionArrow'

export default defineComponent({
  name: 'DirectoryTemplate',
  components: { DirectionArrow },
  props: {
    data: Object
  },
  setup (props) {
    const foreground = computed(() => props.data?.foreground)
    const backgroundColor = computed(() => props.data?.background?.color)
    const directories = computed(() => props.data?.directories)

    return {
      foreground,
      backgroundColor,
      directories
    }
  }
})
</script>

<style lang="less" scoped>
.directory-template {
  line-height: 1.2;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  overflow: hidden;
  justify-content: center;
  text-align: center;

  div {
    white-space: pre;
  }

  .wrapper {
    padding: 10px 4px;
    width: 100%;
  }

  .floors {
    display: flex;
    flex-direction: column;

    &.single {
      .description {
        flex: 1;
        height: 240px;
        justify-content: flex-start;
        width: 415px;
      }

      .description-wrapper {
        justify-content: flex-start;
        width: 100%;
      }

      .floor-title {
        flex: 1;
      }
    }

    .floor {
      display: flex;
      flex: 1;
      align-items: flex-start;
      color: #fff;

      > div {
        height: 67px;
        border: solid 3px #fff;
        margin-bottom: 5px;
      }

      .floor-number {
        width: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 0;
        font-size: 34px;
      }

      .description {
        background: #fff;
        color: #000;
        display: flex;
        font-size: 12px;
        overflow: hidden;

        .description-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 4px;
          width: 100%;

          .floor-title {
            font-size: 30px;
            line-height: 1;
            width: 100%;
            overflow: hidden;
            text-align: start;
          }
        }
      }

      .direction {
        border-left: 0;
        width: 100px;
        display: flex;
      }
    }

    .direction-arrow {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      padding-right: 8px;
    }

    .direction-arrow :deep(svg) {
      height: 30px;
      width: 30px;
      transition: transform .3s ease;
    }
  }
}
</style>
