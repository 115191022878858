<script>
import { defineComponent, inject, ref, watch } from 'vue'
import PageEditorRow from '@/components/cEngineEditor/PageEditorRow.vue'
import draggable from 'vuedraggable'

const MAX_ROWS = 3


export default defineComponent({
  name: 'PageEditor',
  components: { draggable, PageEditorRow },
  setup () {
    const service = inject('cEngineService')
    const layout = ref(service.activePage?.value?.layout || null)
    const list = ref([{
      name: 1,
      id: 1
    }, {
      name: 2,
      id: 2
    },
      {
        name: 3,
        id: 3
      }])
    const rowsRefs = []
    const rowsEmptyState = ref(new Array(MAX_ROWS).fill(0).map(() => true))

    const onChildrenInRowChange = ({
      hasChildren,
      index
    }) => {
      rowsEmptyState.value[index] = !hasChildren
    }

    const setItemRef = (el, rowIndex) => {
      rowsRefs[rowIndex] = (el)
    }

    const setRowsEmptyState = () => {
      rowsRefs.forEach((row) => {
        row.setChildrenExist()
      })
    }

    const onRowsOrderChange = () => {
      setRowsEmptyState()
    }

    watch(()=> service.activePage?.value?.layout, (newVal) => {
      layout.value = newVal
    })


    return {
      list,
      layout,
      rowsRefs,
      rowsEmptyState,
      setItemRef,
      onChildrenInRowChange,
      onRowsOrderChange
    }
  }
})

</script>

<template>
  <draggable
    v-model="layout.rows"
    class="page-editor"
    handle=".row-drag-handle"
    :sort="true"
    @change="onRowsOrderChange"
  >
    <template #item="{ element: row, index: rowIndex }">
      <PageEditorRow
        :ref="(el) => setItemRef(el, rowIndex)"
        :key="rowIndex"
        :row="row"
        :row-index="rowIndex"
        :empty="rowsEmptyState[rowIndex]"
        @has-children="onChildrenInRowChange"
      />
    </template>
  </draggable>
</template>

<style scoped lang="less">
.page-editor {
  width: 100%;
  height: 100%;
  --block-gap: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--block-gap);
  position: relative;
  .page-editor-row {
    flex: 6 1 0;
    &.flex-empty {
      flex: 0 1 20px;
    }
    .drag-area {
      display: flex;
      gap: var(--block-gap);
      height: 100%;
      width: 100%;
      .page-editor-column {
        border-radius: 24px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        flex: 6 0 0;
        &:hover {
          background-color: rgba(245, 245, 245, .5);
        }
      }
      :deep(.sortable-ghost) {
        border-radius: 24px;
        flex: 6 0 0;
        background-color: rgba(245, 245, 245, .5);
        button {
          display: none;
        }
        .column-preview {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-size: 22px;
        }
      }
    }
  }
}
</style>
