const VALUE_TYPE_REGEX = /^([\s\S]*):([\s\S]*)$/
const INCLUDE_KEY_TAGS = 'includeTagKeys'
const EXCLUDE_KEY_TAGS = 'excludeTagKeys'

const RULES_TYPES_MAP = {
  'standard-group:id': 'groups',
  'device:id': 'devices',
  'device:location:city': 'locations',
  'device:timezone': 'timezones',
  'device:orientation': 'orientations',
  'customDeviceTags': 'customDeviceTags'
}

const RULES_TYPES_DTO_MAP = {
  'groups': 'standard-group:id:',
  'devices': 'device:id:',
  'locations': 'device:location:city:',
  'timezones': 'device:timezone:',
  'orientations': 'device:orientation:',
  'customDeviceTags': ''
}

export const RULES_TYPES = [
  'groups',
  'devices',
  'locations',
  // 'timezones',
  'orientations',
  'customDeviceTags'
]

const ALL_DEVICES_RULE = { includeTagKeys: ["device:*" ] }

export class Rules {
  blocks = []
  update
  constructor ({rules, updateFn}) {
    this.update = () => updateFn && updateFn(this.toObject())
    this.blocks = rules?.map(block=> new RulesBlock({ block: block.and, updateFn: this.update })) || []
    if (!this.blocks.length) {
      this.addBlock()
    }
  }

  removeBlock(block) {
    this.blocks.splice(this.blocks.indexOf(block), 1)
    this.onUpdate()
  }

  addBlock() {
    this.blocks.push(new RulesBlock({updateFn: this.update}))
  }

  onUpdate() {
    this.update && this.update()
  }

  toObject() {
    const blocks = this.blocks.map(block=> block.toObject())?.filter(Boolean)
    return {
      or: !blocks.length? [] : blocks
    }
  }
}

class RulesBlock {
  rulesGroups = {
    groups: null,
    devices: null,
    locations: null,
    timezones: null,
    orientations: null,
    customDeviceTags: null
  }

  update

  constructor ({block, updateFn}) {
    this.update = updateFn
    if (block) {
      block.forEach((group) => {
        const groupRules = group.and
        const firstGroupRule = groupRules?.[0]?.or?.[0] || groupRules?.[0]?.and?.[0]
        if (!firstGroupRule) {
          if (groupRules[0]?.includeTagKeys?.[0] === 'device:*') {
            this.rulesGroups.customDeviceTags = new RulesBlockGroup({
              type: 'customDeviceTags',
              rules: groupRules.slice(1),
              updateFn
            })
          }
          return
        }

        const firstInGroupRuleValue = Object.values(firstGroupRule)?.[0]?.[0]
        const rulesType = this.#getRuleTypeByInput(firstInGroupRuleValue)
        if (rulesType){
          this.rulesGroups[RULES_TYPES_MAP[rulesType]] = new RulesBlockGroup({
            type: RULES_TYPES_MAP[rulesType],
            rules: groupRules,
            updateFn
          })
        }
      })
      Object.keys(this.rulesGroups).map(key => this.rulesGroups[key] = this.rulesGroups[key] || new RulesBlockGroup({ type: key, updateFn}))
    }
    else {
      Object.keys(this.rulesGroups).forEach(key=>{
        this.rulesGroups[key] = new RulesBlockGroup({
          type: key,
          rules: [],
          updateFn
        })
      })
    }
  }

  #getRuleTypeByInput (value) {
    const match = value.match(VALUE_TYPE_REGEX)
    return match?.[1] || null
  }

  addRule(ruleType) {
    this.rulesGroups.hasOwnProperty(ruleType) && this.rulesGroups[ruleType].addRule()
  }

  onUpdate() {
    this.update && this.update()
  }

  toObject() {
    const groupsArray = Object.values(this.rulesGroups).map(group => group.toObject()).filter(Boolean)
    if (!groupsArray.length) return null
    return {
      and: groupsArray
    }
  }

}

class RulesBlockGroup {
  update
  constructor ({ type, rules = [], updateFn }) {
    this.type = type
    this.update = updateFn
    this.rules = rules.map(rule => new Rule({ type, rule, updateFn })) || []
  }

  removeRule(rule) {
    this.rules.splice(this.rules.indexOf(rule), 1)
    this.onUpdate()
  }

  addRule() {
    this.rules.push(new Rule({type: this.type, updateFn: this.update}))
  }

  onUpdate() {
    this.update && this.update()
  }

  toObject() {
    if (!this.rules.length) return null
    const rulesArray = this.rules.map(rule => rule.toObject())?.filter(Boolean)
    if (!rulesArray.length) return null
    const result =  {
      and: this.rules.map(rule => rule.toObject())?.filter(Boolean)
    }
    if (this.type === 'customDeviceTags') {
      result.and.unshift(ALL_DEVICES_RULE)
    }
    return result
  }

}

class Rule {
  include = true
  tags = []
  update
  constructor ({ type, rule = null, updateFn }) {
    this.type = type
    this.dtoType = RULES_TYPES_DTO_MAP[type]
    this.update = updateFn
    if (rule) {
      this.include = !!rule.or
      const ruleObj = rule.or || rule.and
      this.tags = Object.values(ruleObj).reduce((acc, b)=> {
        acc.push(this.type === 'customDeviceTags' ? Object.values(b)?.[0]?.[0] : this.#getRuleValueByInput(Object.values(b)?.[0]?.[0]))
        return acc
      }, []) || []
    }
    this.rule = rule
  }

  #getRuleValueByInput (value) {
    const match = value.match(VALUE_TYPE_REGEX)
    return match?.[2] || null
  }

  toggleTag(tag) {
    const tagIndex = this.tags.indexOf(tag)
    if (tagIndex !== -1) {
      this.removeTag(null, tagIndex)
    }
    else {
      this.addTag(tag)
    }
  }

  setInclude(value) {
    this.include = value
    this.onUpdate()
  }

  removeTag(tagToRemove, tagIndex) {
    this.tags.splice(tagIndex || this.tags.indexOf(tagToRemove), 1)
    this.onUpdate()
  }

  addTag(tagToAdd) {
    this.tags.push(tagToAdd)
    this.onUpdate()
  }

  changeTags(tags) {
    this.tags.splice(0)
    tags.forEach(t=>this.tags.push(t))
    this.onUpdate()
  }

  onUpdate() {
    this.update && this.update()
  }
  toObject() {
    if (this.tags.length === 0) return null
    return {
      [this.include ? 'or' : 'and']: this.tags.map(tag=>{
        return { [this.include ? INCLUDE_KEY_TAGS : EXCLUDE_KEY_TAGS]: [`${this.dtoType}${tag}`] }
      })
    }
  }

}


