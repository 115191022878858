<script setup>

import InfoHeader from '@/components/InfoHeader.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { error } from '@/utils'
const store = useStore()
const loading = ref(false)
const emailValidationResent = ref(false)

const user = computed(() => store.getters['auth/user'])
const email = computed(() => user.value?.email)

const resentEmailValidation = () => {
  loading.value = true
  store.dispatch('auth/resendEmailVerification').then(() => {
    emailValidationResent.value = true
  }).catch(e => {
    error(e.message)
  }).then(()=>{
    loading.value = false
  })
}
</script>

<template>
  <InfoHeader type="danger">
    <a-space>
      <template v-if="!emailValidationResent">
        <span>{{ $t('components.verifyEmailHeader.pleaseConfirmEmail', {email}) }}</span>
        <a-button
          type="primary"
          size="small"
          :loading="loading"
          @click="resentEmailValidation"
        >
          {{ $t('components.verifyEmailHeader.resendButtonText') }}
        </a-button>
      </template>
      <span v-else>{{ $t('components.verifyEmailHeader.successText') }}</span>
    </a-space>
  </InfoHeader>
</template>

<style scoped lang="less">

</style>
