<template>
  <GeneralSider>
    <template v-if="currentGroupLoaded">
      <SmartGroupDevicesSider v-if="currentGroupTypeIsSmart" />
      <StandardGroupDevicesSider v-else />
    </template>
  </GeneralSider>
</template>

<script>
import { computed, defineComponent } from 'vue'
import GeneralSider from '@/components/siders/GeneralSider.vue'
import { useStore } from 'vuex'
import StandardGroupDevicesSider from '@/components/siders/StandardGroupDevicesSider.vue'
import SmartGroupDevicesSider from '@/components/siders/SmartGroupDevicesSider.vue'

export default defineComponent({
  name: 'DevicesSider',
  components: {
    SmartGroupDevicesSider,
    StandardGroupDevicesSider,
    GeneralSider,
  },
  setup () {
    const store = useStore()
    const currentGroupTypeIsSmart = computed(() => store.getters['groups/currentGroupTypeIsSmart'])
    const currentGroupLoaded = computed(() => store.getters['groups/currentGroupLoaded'])

    return {
      currentGroupTypeIsSmart,
      currentGroupLoaded
    }
  }
})
</script>

<style scoped lang="less">
</style>
