import { createApp, h, provide } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import '@/styles/variables.less'
import { createApolloProvider } from '@vue/apollo-option'
import apolloClient from '@/apollo'
import { DefaultApolloClient } from '@vue/apollo-composable'
import loadGmaps from '@/./g-maps'
// import GAuth from 'vue3-google-oauth2'
// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'
import moment from 'moment'
import { ROLE_NAMES_MAP, TIER_NAMES_MAP } from '@/constants'
import { isInputOrTextarea, pluralizationUa } from '@/utils'
import { Translation } from 'vue-i18n'
import initializeGTM from '@/gtm.js';
import { i18n } from '@/i18n'
import VueTelInput from 'vue-tel-input';

const gtmId = process.env.VUE_APP_GTM_ID
const gtmInstance = initializeGTM(gtmId);


const isDev = false

// TODO look up too google api keys
loadGmaps(process.env.VUE_APP_GOOGLE_MAPS_API_KEY)

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient
})
const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },

  render: () => h(App)
})

app.directive('format-cents', (el, binding) => {
  if (!Number.isInteger(parseInt(binding.value))) return
  const value =
  el.innerHTML = `$${(binding.value / 100).toFixed(2)}`
})

app.directive('max-rows', { updated: (el, binding) => {
  const maxRows = binding.value
  const currentRows = el.value.split('\n')
  const currentRowsLength = currentRows.length
  if (currentRowsLength >= maxRows) {
    currentRows.length = maxRows
    const event = new Event("input", { bubbles: true });
    el.value = currentRows.join('\n')
    el.dispatchEvent(event);
  }
}})

app.directive('max-length', { updated: (el, binding) => {
  const maxLength = binding.value
  const isInput = isInputOrTextarea(el)
  const inputElement = isInput ? el : (el.querySelector('input') || el.querySelector('textarea'))
  const currentLength = inputElement.value?.length
  if (currentLength > maxLength) {
    const event = new Event("input", { bubbles: true });
    inputElement.value = inputElement.value.slice(0,maxLength)
    inputElement.dispatchEvent(event);
  }
}})

app.directive('numbers-only', { updated: (el, binding) => {
  const isInput = isInputOrTextarea(el)
  const inputElement = isInput ? el : (el.querySelector('input') || el.querySelector('textarea'))
  if (!inputElement.value) return
  const event = new Event("input", { bubbles: true });
  inputElement.value = ((inputElement.value).match(/\d/g))?.join('') || 0
  inputElement.dispatchEvent(event);
}})

app.directive('role', (el, binding) => {
  el.innerHTML = ROLE_NAMES_MAP[binding.value]
})

app.directive('tier', (el, binding) => {
  el.innerHTML = TIER_NAMES_MAP[binding.value]
})

app.directive('format-date', (el, binding) => {
  const date = (binding.value.date+'').length === 10 ? binding.value.date * 1000 : binding.value.date
  el.innerHTML = moment(date).format(binding.value.format || 'MMM D, hh:mm')
})

// if (isDev) {
//   Sentry.init({
//     app,
//     dsn: 'https://7b718f47b8de4a39bece72a9d4400371@o1149270.ingest.sentry.io/6221244',
//     integrations: [
//       new BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ['http://localhost:3000/', 'https://staging.next.kitcast.tv/', /^\//]
//       })
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//     logErrors: true
//   })
// }
app.use(VueTelInput)
app.use(router)
app.use(i18n)
app.component(Translation)
app.provide('gtm', gtmInstance)


// app.use(GAuth, gAuthOptions)
app.use(Antd).use(store).use(apolloProvider)
app.mount('#app')
