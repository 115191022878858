<script>
import { defineComponent } from 'vue'
import { MediaElement } from '@/components/cEngineEditor/helpers'

export default defineComponent({
  name: 'PageEditorMediaElement',
  props: {
      element: {
        type: MediaElement,
        default: () => null
      }
  },
  setup () {
    return {}
  }
})
</script>

<template>
  <div class="media-element">
    <img
      v-if="element.mediaType === 'image' && element.url"
      :src="element.url"
      alt=""
      class="media-image"
      :class="element.objectFit"
    >
    <video
      v-if="element.mediaType === 'video' && element.url"
      muted
      loop
      autoplay
      class="media-video"
      :src="element.url"
      :class="element.objectFit"
    />
  </div>
</template>

<style scoped lang="less">
.media-element {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .media-image {
    width: 100%;
    height: 100%;
    &.fit {
      object-fit: cover;
    }
    &.fill {
      object-fit: fill;
    }
    &.crop {
      object-fit: none;
    }
  }
  .media-video {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
