<script setup>
import AddChangePaymentMethodLink from '@/components/subscriptionSettings/AddChangePaymentMethodLink.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
const store = useStore()

const isEnterprise = computed(() => store.getters['workspace/isEnterprize'])
const emailVerified = computed(() => store.getters['auth/emailVerified'])
const isTrialTier = computed(() => store.getters['workspace/isTrialTier'])
const isEducationStarterTier = computed(() => store.getters['workspace/isEducationStarterTier'])

const showLink = computed(() => {
  return !isEnterprise.value && emailVerified.value && (isTrialTier.value || isEducationStarterTier.value)
})

</script>

<template>
  <AddChangePaymentMethodLink v-if="showLink" />
</template>

<style scoped lang="less">

</style>
