<template>
  <div class="color-picker">
    <div
      v-if="title"
      class="title"
    >
      <a-typography-text>
        {{ title }}
      </a-typography-text>
    </div>
    <div
      :class="{active: isSelected}"
      class="color-picker-input"
    >
      <a-input
        v-model:value="value"
        :bordered="false"
        type="color"
      />
      <a-input
        v-model:value="value"
        :bordered="false"
        :placeholder="$t('components.smartTemplateConstructor.colorPicker.colorPlaceholder')"
        pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
        type="text"
        @focus="onFocus"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useModelWrapper } from '@/helpers/ModelWrapper'

export default defineComponent({
  name: 'ColorPicker',
  props: {
    color: [String, Object],
    title: String,
    isSelected: Boolean,
  },
  emits: ['selected'],

  setup (props, { emit }) {

    const value = useModelWrapper(props, emit, 'color')

    const onFocus = () => {
      emit('selected')
    }

    return {
      value,
      onFocus
    }
  }
})
</script>

<style lang="less" scoped>
@import '@/styles/variables.less';

.color-picker {
  margin-bottom: 16px;

  .title {
    align-items: center;
    color: rgba(0, 0, 0, .85);
    display: inline-flex;
    font-size: 14px;
    height: 32px;
    max-width: 100%;
    position: relative;
  }

  .color-picker-input {
    font-feature-settings: "tnum";
    background-color: #fff;
    background-image: none;
    border: 2px solid #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    padding: 4px 11px 4px 9px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    min-width: 0;
    position: relative;
    transition: all .3s;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;

    input[type="color"] {
      width: 32px;
      height: 32px;
      padding: 1px;
    }

    &.active {
      border-color: var(--ant-primary-5);
    }
  }

  .input-area {
    display: flex;

    input[type="text"] {
      border: 0;
      width: 85px;
      text-align: right;
    }

    input[type="color"] {
      width: 32px;
      height: 32px;
      padding: 2px;
    }
  }
}

</style>
