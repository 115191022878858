<script setup>

import { DeleteOutlined, ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons-vue'
import { computed, createVNode, ref } from 'vue'
import { success, error } from '@/utils'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
import { ROLES } from '@/constants'
const store = useStore()
const { t } = useI18n()
const props = defineProps({
  userFilter: {
    type: String,
    default: ''
  },
  members: {
    type: Array,
    default: () => []
  },
  availableGroupsIdMap: {
    type: Object,
    required: true
  },
  availableGroupsPlaylistsIdMap: {
    type: Object,
    required: true
  },
  updatingMemberAccess: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['accessChange'])

const ROLE_OPTIONS = ref([
  {
    key: ROLES.ADMIN,
    show: true,
    label: 'components.collaborationModal.adminRole',
    description: 'components.collaborationModal.adminRoleDescription'
  },
  {
    key: ROLES.MANAGER,
    show: true,
    label: 'components.collaborationModal.managerRole',
    description: 'components.collaborationModal.managerRoleDescription'
  },
  {
    key: ROLES.AUTHOR,
    show: computed(()=> store.getters['workspace/authorRoleEnabled']),
    label: 'components.collaborationModal.authorRole',
    description: 'components.collaborationModal.authorRoleDescription'
  }
])

const RESTRICTION_NAMES_MAP = {
  [ROLES.MANAGER]: 'Group(s)',
  [ROLES.AUTHOR]: 'Playlist(s)'
}

const RESTRICTION_PARAMS_MAP = {
  [ROLES.MANAGER]: 'groupIds',
  [ROLES.AUTHOR]: 'playlistIds'
}

const MEMBER_COLUMNS = [
  {
    title: '',
    dataIndex: 'avatar',
    width: 64
  },
  {
    title: 'name',
    dataIndex: 'name',
    key: 'name',
    width: 300
  },
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
    width: 300
  },
  {
    title: 'role',
    dataIndex: 'role',
    key: 'role',
    width: 100
  },
  {
    title: 'access',
    dataIndex: 'access',
    key: 'access',
    width: 150
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions'
  }]

const loading = ref(false)
const userId = computed(() => store.getters['auth/userId'])

const showRoleDropdown = (member) => {
  return member.role !== ROLES.OWNER && member.userId !== userId.value
}

const filteredMembers = computed(() => {
  return props.members?.filter(member => member.name.toLowerCase().includes(props.userFilter.toLowerCase()) ||
      member.email?.toLowerCase().includes(props.userFilter.toLowerCase())
  )
})

const handleRemoveUser = (userId) => {
  store.dispatch('workspace/removeWorkspaceMember', userId).then(()=>{
    success()
  })
}

const handleRoleChange = (member, role) => {
  if (role === member.role) return
  const roleName = t(`components.collaborationModal.${role}`)
  return Modal.confirm({
    title: t('components.collaborationModal.changeRoleModalTitle'),
    icon: createVNode(ExclamationCircleOutlined),
    content: t('components.collaborationModal.changeRoleModalContent', {name: member.name, role: roleName}),
    okText: t('components.collaborationModal.changeRoleModalOkButtonText'),
    cancelText: t('components.collaborationModal.changeRoleModalCancelButtonText'),
    width: '400px',
    onOk () {
      loading.value = true
      store.dispatch('workspace/updateWorkspaceMember', {
        userId: member.userId,
        role,
        ...(RESTRICTION_PARAMS_MAP[role] ? {
          restrictions: {
            [RESTRICTION_PARAMS_MAP[role]]: null
          }
        } : {})
      }).then(()=>{
        success()
      }).catch(e => {
        error(e.message)
      }).finally(() => {
        loading.value = false
      })
    }
  })

}

const handleAccessChange = (member) => {
  emit('accessChange', member)
}

</script>

<template>
  <a-table
    :columns="MEMBER_COLUMNS"
    :data-source="filteredMembers"
    :loading="loading || updatingMemberAccess"
  >
    <template #headerCell="{ column }">
      <template v-if="column.key">
        {{ $t(`components.collaborationModal.${column.key}`) }}
      </template>
    </template>
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.dataIndex === 'avatar'">
        <a-avatar
          v-if="text"
          :src="text"
        />
        <a-avatar v-else>
          <template #icon>
            <UserOutlined />
          </template>
        </a-avatar>
      </template>
      <template v-else-if="column.dataIndex === 'name'">
        {{ text }}
      </template>
      <template v-else-if="column.dataIndex === 'role'">
        <a-select
          v-if="showRoleDropdown(record)"
          size="small"
          :dropdown-match-select-width="100"
          :value="text"
          @select="(value)=>handleRoleChange(record, value)"
        >
          <template
            v-for="option in ROLE_OPTIONS"
            :key="option"
          >
            <a-select-option
              v-if="option.show"
              :key="option.key"
              :value="option.key"
            >
              {{ $t(option.label) }}
            </a-select-option>
          </template>
        </a-select>
        <span
          v-else
          style="text-transform: capitalize"
        >{{ $t(`components.collaborationModal.${text?.toLowerCase()}Role`) }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'access'">
        <template v-if="record.role === ROLES.MANAGER">
          <a-tooltip v-if="record.restrictions?.length">
            <template #title>
              <div
                v-for="restrictionId in record.restrictions"
                :key="restrictionId"
              >
                {{ availableGroupsIdMap[restrictionId] }}
              </div>
            </template>
            <a-typography-link @click="handleAccessChange(record)">
              {{ `${record.restrictions.length} ${RESTRICTION_NAMES_MAP[record.role]}` }}
            </a-typography-link>
          </a-tooltip>
          <template v-else>
            <a-typography-link @click="handleAccessChange(record)">
              {{ $t('components.collaborationModal.all') }}
            </a-typography-link>
          </template>
        </template>
        <template v-else-if="record.role === ROLES.AUTHOR">
          <a-tooltip v-if="record.restrictions?.length">
            <template #title>
              <div
                v-for="restrictionId in record.restrictions"
                :key="restrictionId"
              >
                {{ availableGroupsPlaylistsIdMap[restrictionId] }}
              </div>
            </template>
            {{ `${record.restrictions.length} ${RESTRICTION_NAMES_MAP[record.role]}` }}
          </a-tooltip>
          <template v-else>
            {{ $t('components.collaborationModal.all') }}
          </template>
        </template>
        <template v-else>
          {{ $t('components.collaborationModal.all') }}
        </template>
      </template>
      <template v-else-if="column.dataIndex === 'actions'">
        <div style="display: flex; gap: 8px; align-items: center; justify-content: center;">
          <a-popconfirm
            v-if="record.role !== 'OWNER' && record.userId !== userId"
            placement="bottom"
            :title="$t('components.collaborationModal.popConfirmTitle')"
            :ok-text="$t('components.collaborationModal.popConfirmOkText')"
            :cancel-text="$t('components.collaborationModal.popConfirmCancelText')"
            @confirm="handleRemoveUser(record.userId)"
          >
            <a-button>
              <template #icon>
                <DeleteOutlined style="font-size: 12px" />
              </template>
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </template>
  </a-table>
</template>

<style scoped lang="less">

</style>
