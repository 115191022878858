<script setup>

import { BgColorsOutlined, FontSizeOutlined, PictureFilled } from '@ant-design/icons-vue'
import PageStyleDropdown from '@/components/cEngineEditor/PageStyleDropdown.vue'
import pageStyleDropdown from '@/components/cEngineEditor/PageStyleDropdown.vue'
import { ref } from 'vue'
import draggable from 'vuedraggable'

const pageStyleDropdownVisible = ref(false)
const mediaList = ref([{
  key: 'media',
  type: 'media',
  name: 'Media',
  icon: PictureFilled,
  isNewItem: true
}])
const textList = ref([{
  key: 'text',
  type: 'text',
  name: 'Text',
  icon: FontSizeOutlined,
  isNewItem: true
}])

const handleGlobalStyleDropdownClose = () => {
  pageStyleDropdownVisible.value = false
}

</script>

<template>
  <aside class="edit-actions">
    <a-tooltip placement="top">
      <template #title>
        Media
      </template>
      <draggable
        class="dragArea styles-group"
        :list="mediaList"
        item-key="name"
        :group="{ name: 'textElements', pull: 'clone', put: false }"
        :sort="false"
        :empty-insert-threshhold="200"
      >
        <template #item="{element}">
          <div>
            <a-button
              shape="circle"
            >
              <component :is="element.icon" />
            </a-button>
          </div>
        </template>
      </draggable>
    </a-tooltip>
    <a-tooltip placement="top">
      <template #title>
        Text
      </template>
      <draggable
        class="dragArea styles-group"
        :list="textList"
        item-key="name"
        :group="{ name: 'textElements', pull: 'clone', put: false }"
        :sort="false"
        :empty-insert-threshhold="200"
      >
        <template #item="{element}">
          <div>
            <a-button
              shape="circle"
            >
              <component :is="element.icon" />
            </a-button>
          </div>
        </template>
      </draggable>
    </a-tooltip>
    <a-popover
      v-model:open="pageStyleDropdownVisible"
      placement="left"
      trigger="click"
    >
      <template #content>
        <PageStyleDropdown @close="handleGlobalStyleDropdownClose" />
      </template>
      <a-tooltip placement="top">
        <template #title>
          Page Settings
        </template>
        <a-button
          shape="circle"
        >
          <BgColorsOutlined />
        </a-button>
      </a-tooltip>
    </a-popover>
  </aside>
</template>

<style scoped lang="less">
.edit-actions {
  width: 104px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
</style>
