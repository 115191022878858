<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.web.slideStep1.subTitle')"
    :title="$t('components.slides.web.slideStep1.title')"
    no-border
  >
    <a-form @submit="onSubmit">
      <a-input
        ref="inputRef"
        v-model:value="websiteUrl"
        :addon-before="protocol"
        :placeholder="$t('components.slides.web.slideStep1.websiteUrlPlaceholder')"
        @change="onChange"
      />
    </a-form>
  </SlideStep>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { isURL } from 'validator'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { DEFAULT_PROTOCOL } from '@/constants'
import { normalizeUrl } from '@/utils'
import { useI18n } from 'vue-i18n'
import { WebsiteSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

export default defineComponent({
  name: 'WebsiteSlide',
  components: { DurationSlideStep, SlideStep },
  props: {
    title: String,
    slideObj: WebsiteSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const slide = props.slideObj || new WebsiteSlide()
    const { url } = slide.slideData
    const inputRef = ref(null)
    const websiteUrl = ref(normalizeUrl(url))

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    onMounted(() => {
      inputRef.value && inputRef.value.focus()
      onChange()
    })

    const onSubmit = () => {
      if (!props.disabled) {
        emit('save')
      }
    }

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        url: DEFAULT_PROTOCOL + websiteUrl.value
      })
    }

    const onChange = () => {
      const stripUrl = normalizeUrl(websiteUrl.value)
      if (!isURL(DEFAULT_PROTOCOL + stripUrl)) {
        toggleDisabled(true)
        return true
      }
      websiteUrl.value = stripUrl
      updateSlideData()
      updateSlide()
      toggleDisabled(false)
    }

    return {
      slide,
      protocol: DEFAULT_PROTOCOL,
      websiteUrl,
      inputRef,
      onChange,
      onSubmit,
      updateSlide
    }
  }
})
</script>

<style lang="less">

</style>
