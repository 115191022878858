import {
  AudioOutlined,
  BellOutlined,
  BgColorsOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  CloudOutlined,
  CoffeeOutlined,
  CreditCardOutlined,
  DotChartOutlined,
  ExperimentOutlined,
  EyeOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  FolderFilled,
  FormatPainterOutlined,
  GlobalOutlined,
  HeartOutlined,
  InfoCircleOutlined,
  InstagramFilled,
  InstagramOutlined,
  LinkOutlined,
  MessageOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  PaperClipOutlined,
  PictureOutlined,
  PlaySquareOutlined,
  ReadOutlined,
  RestOutlined,
  RocketOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  SmileOutlined,
  StarOutlined,
  StockOutlined,
  ThunderboltOutlined,
  TrophyOutlined,
  UserOutlined,
  YoutubeOutlined,
  ApiOutlined
} from '@ant-design/icons-vue'
import TiktokOutlined from '@/components/icons/TiktokOutlined'
import TwitterOutlined from '@/components/icons/TwitterOutlined'

export const filesIcons = {
  FolderFilled,
  FileOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  FileTextOutlined,
  FilePptOutlined,
  FileExcelOutlined,
  FileWordOutlined,
  AudioOutlined,
  YoutubeOutlined
}

export const widgetIcons = {
  PictureOutlined,
  PlaySquareOutlined,
  PaperClipOutlined,
  YoutubeOutlined,
  CalendarOutlined,
  LinkOutlined,
  ExperimentOutlined,
  RocketOutlined,
  DotChartOutlined,
  InfoCircleOutlined,
  InstagramOutlined,
  TwitterOutlined,
  HeartOutlined,
  InstagramFilled,
  CloudOutlined,
  ClockCircleOutlined,
  MessageOutlined,
  GlobalOutlined,
  NotificationOutlined,
  RestOutlined,
  FormatPainterOutlined,
  TiktokOutlined,
  ThunderboltOutlined,
  StockOutlined,
  ReadOutlined,
  StarOutlined,
  TrophyOutlined,
  UserOutlined,
  SmileOutlined,
  CoffeeOutlined,
  BgColorsOutlined
}

export const WORKSPACE_MENU_ICONS = {
  SettingOutlined,
  CreditCardOutlined,
  EyeOutlined,
  NotificationOutlined,
  BgColorsOutlined,
  OrderedListOutlined,
  ApiOutlined
}

export const ACCOUNT_MENU_ICONS = {
  SmileOutlined,
  SafetyCertificateOutlined,
  BellOutlined
}
