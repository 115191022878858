import { reactive, ref } from 'vue'
import predefinedLayouts from '@/components/cEngineEditor/predefinedLayouts'

export class CEngineElement {
  constructor (element) {
    this.type = element.type
  }
}

export class MediaElement extends CEngineElement {
  constructor (element) {
    super(element)
    this.url = element.url
    this.objectFit = element.objectFit
    this.textVisible = !!element.textVisible
    this.mediaType = element.mediaType
  }

  toObject () {
    return {
      type: this.type,
      url: this.url,
      objectFit: this.objectFit,
      textVisible: this.textVisible,
      mediaType: this.mediaType
    }
  }
}

export class TextElement extends CEngineElement {
  constructor (element) {
    super(element)
    this.text = element.text
    this.alignment = element.alignment || 'middleCenter'
  }

  toObject () {
    return {
      type: this.type,
      text: this.text
    }
  }
}

export const elementFactory = (element) => {
  switch (element.type) {
    case 'text':
      return new TextElement(element)
    case 'media':
      return new MediaElement(element)
  }
}

export class CEngineColumn {
  constructor ({ width = 6, elements = [] } = {}) {
    this.width = width
    this.elements = elements.map(element => elementFactory(element))
  }

  addElement (element) {
    this.elements.push(element)
  }

  removeElement (index) {
    this.elements.splice(index, 1)
  }

  toObject () {
    return {
      width: this.width,
      elements: this.elements.map(element => element.toObject())
    }
  }
}

export class CEngineRow {
  constructor ({ columns = [] }) {
    this.columns = columns.map(column => new CEngineColumn(column))
  }

  addColumn (column, index) {
    if (index+'') {
      return this.columns.splice(index, 0, new CEngineColumn(column))
    }
    this.columns.push(new CEngineColumn(column))
  }

  removeColumn (index) {
    this.columns.splice(index, 1)
  }

  toObject () {
    return {
      columns: this.columns.map(column => column.toObject())
    }
  }
}

export class CEngineLayout {
  constructor ({rows}) {
    this.rows = rows.map(row => new CEngineRow(row))
  }

  toObject () {
    return {
      rows: this.rows.map(row => row.toObject())
    }
  }
}

export class CEnginePage {
  constructor ({ layout, name }) {
    this.layout = new CEngineLayout(layout)
    this.name = name
  }
}

export class CEngineSlide {
  pages = ref([])

  constructor (slide) {
    this.state = reactive({
      bgColor: slide.bgColor || '#fff',
      fontColor: slide.fontColor || '#000'
    })
    if (!slide.pages) {
      this.addPage(predefinedLayouts[0])
    }
  }

  addPage (layout) {
    this.pages.value.push(new CEnginePage({ layout, name: `Page ${this.pages.value.length + 1}` }))
  }

  setBgColor (color) {
    this.state.bgColor = color
  }

  setFontColor (color) {
    this.state.fontColor = color
  }

  removePage (index) {
    if (this.pages.value.length === 1) {
      return
    }
    this.pages.value.splice(index, 1)
  }

}

export const ELEMENT_TYPE_TO_COMPONENT_MAP = {
  'text': 'PageEditorTextElement',
  'media': 'PageEditorMediaElement',
}


export const ELEMENT_TYPES_TO_MEDIA_TYPES = {
  'IMAGE': 'image',
  'VIDEO': 'video',
  default: 'image'
}

