<script setup>
import { computed, reactive, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
  showDrawer: Boolean,
  currentState: Object
})

defineEmits(['closeDrawer', 'saveAccess'])

const drawerState = reactive({
  treeData: [],
  treeCheckedKeys: []
})

const checkStrictly = ref(true)

const drawerTitle = computed(()=>{
  const defaultTitle = t('components.collaborationModal.accessDrawerTitle')
  return props.currentState?.member? defaultTitle + ' (' + props.currentState.member.name + ')' : defaultTitle
})

const filterGroupsLabels = (groups = []) => {
  return groups.filter((id)=>!['smart-groups', 'groups'].includes(id))
}

watchEffect(() => {
  if (props.showDrawer) {
    drawerState.treeData = props.currentState?.treeData
    drawerState.treeCheckedKeys = { checked: props.currentState?.treeCheckedKeys }
  }
})

</script>

<template>
  <a-drawer
    :title="drawerTitle"
    placement="right"
    :closable="true"
    :open="showDrawer"
    :get-container="false"
    :style="{ position: 'absolute' }"
    @close="$emit('closeDrawer')"
  >
    <a-typography-paragraph>{{ drawerState.treeCheckedKeys?.checked?.length ? $t('components.collaborationModal.selectedCount', {count: filterGroupsLabels(drawerState.treeCheckedKeys.checked).length}) : $t('components.collaborationModal.selectedAll') }}</a-typography-paragraph>
    <a-tree
      v-if="showDrawer"
      v-model:checkedKeys="drawerState.treeCheckedKeys"
      multiple
      checkable
      :check-strictly="checkStrictly"
      :selectable="false"
      default-expand-all
      :placeholder="$t('components.collaborationModal.selectPlaylist')"
      allow-clear
      :tree-data="drawerState.treeData"
    />
    <template #footer>
      <a-space>
        <a-button
          style="margin-right: 8px"
          @click="$emit('closeDrawer')"
        >
          {{ $t('components.collaborationModal.drawerCancelButtonText') }}
        </a-button>
        <a-button
          type="primary"
          @click="$emit('saveAccess', drawerState.treeCheckedKeys.checked)"
        >
          {{ $t('components.collaborationModal.drawerSaveButtonText') }}
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<style scoped lang="less">

</style>
