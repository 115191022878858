<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.weather.slideStep1.subTitle')"
    :title="$t('components.slides.weather.slideStep1.title')"
    no-border
  >
    <GeoSuggest
      ref="geoSuggest"
      :debounce="fn => debounce(fn, 500)"
      :search="searchInput"
      :suggestion="selectedSuggestion"
      @geocoded="onDecode"
    />
    <a-checkbox
      v-model:checked="isCustomLocation"
      style="margin-bottom: 16px;"
      @change="onChange"
    >
      {{ $t('components.slides.weather.slideStep1.pickLocationManuallyCheckbox') }}
    </a-checkbox>
    <a-select
      v-if="isCustomLocation"
      ref="geoInput"
      v-model:value="selectedPlace"
      :disabled="inputDisabled"
      :filter-option="false"
      :loading="inputDisabled"
      :max-tag-text-length="10"
      :not-found-content="loading ? undefined : null"
      :options="suggestionOptions"
      :placeholder="$t('components.slides.weather.slideStep1.placeSelectPlaceholder')"
      :show-search="true"
      style="width: 100%; margin-bottom: 16px"
      @search="onSearch"
      @select="onSelect"
    >
      <template
        v-if="loading"
        #notFoundContent
      >
        <a-spin size="small" />
      </template>
    </a-select>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.weather.slideStep2.title')"
    no-border
  >
    <a-radio-group
      v-model:value="temperatureUnit"
      button-style="solid"
    >
      <a-radio-button value="fahrenheit">
        {{ $t('components.slides.weather.slideStep2.fahrenheit') }}
      </a-radio-button>
      <a-radio-button value="celsius">
        {{ $t('components.slides.weather.slideStep2.celsius') }}
      </a-radio-button>
    </a-radio-group>
  </SlideStep>
  <a-typography-text
    style="display: block; margin-top: 16px;"
    type="secondary"
  >
    {{ $t('components.slides.weather.providedBy') }}
  </a-typography-text>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { debounce } from 'lodash-es'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import GeoSuggest from '@/components/GeoSuggest'
import { useI18n } from 'vue-i18n'
import { WeatherSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

export default defineComponent({
  name: 'WeatherSlide',
  components: {
    DurationSlideStep,
    GeoSuggest,
    SlideStep
  },
  props: {
    title: String,
    slideObj: WeatherSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const slide = props.slideObj || new WeatherSlide()
    const { location, metric } = slide.slideData
    const geoSuggest = ref(null)
    const geoInput = ref(null)
    const inputDisabled = ref(false)
    const selectedPlace = ref(location?.address || null)
    const searchInput = ref(location?.address || '')
    const isCustomLocation = ref(!!selectedPlace.value)
    const selectedSuggestion = ref(null)
    const selectedLocation = ref(null)
    const temperatureUnit = ref(metric ? 'celsius' : 'fahrenheit')

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const suggestionOptions = computed(() => {
      const suggestions = geoSuggest?.value?.suggestions ?? []
      return suggestions.map(({
        description,
        placeId
      }) => ({
        label: description,
        value: placeId
      }))
    })

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        location: isCustomLocation.value ? (selectedLocation.value || location) : null,
        metric: temperatureUnit.value === 'celsius'
      })
    }

    const onDecode = async ({
      formattedAddress: address,
      location: {
        lat: latitude,
        lng: longitude
      }
    }) => {
      selectedLocation.value = {
        address,
        latitude,
        longitude
      }
      selectedPlace.value = address
      inputDisabled.value = false
      selectedSuggestion.value = null
      updateSlideData()
      updateSlide()
      toggleDisabled(false)
    }

    const onSelect = (v) => {
      const suggestion = geoSuggest?.value?.suggestions.find(s => s.placeId === v)
      inputDisabled.value = true
      selectedSuggestion.value = suggestion
    }

    const onSearch = (value) => {
      searchInput.value = value
    }

    const onChange = () => {
      if (isCustomLocation.value && (!selectedLocation.value && !location)) {
        toggleDisabled(true)
        return
      }
      updateSlideData()
      updateSlide()
      toggleDisabled(false)
    }

    if (!slide.widgetId) {
      updateSlideData()
      updateSlide()
    }

    watch(temperatureUnit, (value) => {
      updateSlideData()
      updateSlide()
      toggleDisabled(false)
    })

    return {
      slide,
      debounce,
      isCustomLocation,
      searchInput,
      geoInput,
      geoSuggest,
      inputDisabled,
      suggestionOptions,
      selectedSuggestion,
      selectedPlace,
      temperatureUnit,
      onChange,
      onSearch,
      onSelect,
      onDecode,
      updateSlide
    }
  }
})
</script>

<style lang="less">

</style>
