<template>
  <div
    :style="{textAlign: layout, color:foreground}"
    class="simple-sign"
  >
    <div class="caption">
      {{ caption }}
    </div>
    <div class="header">
      {{ header }}
    </div>
    <div class="subHeader">
      {{ subHeader }}
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'SimpleSignTemplate',
  props: {
    data: Object
  },
  setup (props) {
    const layout = computed(() => props.data?.layout)
    const foreground = computed(() => props.data?.foreground)
    const caption = computed(() => props.data.caption)
    const header = computed(() => props.data.header)
    const subHeader = computed(() => props.data.subHeader)

    return {
      layout,
      foreground,
      caption,
      header,
      subHeader
    }
  }
})
</script>

<style lang="less" scoped>
.simple-sign {
  line-height: 1.2;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px 60px 15px 60px;
  overflow: hidden;

  div {
    white-space: pre;
    justify-content: center;
  }

  .caption {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 3px;
    height: 13px;
    overflow: hidden;
  }

  .header {
    white-space: pre;
    margin-top: 65px;
    font-size: 50px;
    height: 180px;
    overflow: hidden;
    line-height: 60px;
  }

  .subHeader {
    margin-top: 10px;
    font-size: 16.5px;
    height: 40px;
    overflow: hidden;
    line-height: 20px;
  }
}
</style>
