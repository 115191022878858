<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.tripAdvisor.slideStep1.subTitle')"
    :title="$t('components.slides.google_reviews.slideStep1.title')"
    no-border
  >
    <a-form @submit="onSubmit">
      <a-select
        ref="selectRef"
        v-model:value="tripAdvisorLocationId"
        show-search
        :placeholder="$t('components.slides.tripAdvisor.slideStep1.tripAdvisorLocationIdPlaceholder')"
        style="width: 100%"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        :options="data"
        @search="handleSearch"
        @change="handleChange"
      />
    </a-form>
  </SlideStep>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { TripAdvisorSlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import { debounce } from 'lodash-es'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

export default defineComponent({
  name: 'TripAdvisorSlide',
  components: { DurationSlideStep, SlideStep },
  props: {
    title: String,
    slideObj: TripAdvisorSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const slide = props.slideObj || new TripAdvisorSlide()
    const { locationId } = slide.slideData
    const locationDetails = ref(slide.locationDetails)
    const selectRef = ref(null)
    const googlePlace = ref('')
    const tripAdvisorLocationId = ref(locationId)
    const data = ref([{
      value: locationId,
      label: locationDetails.value?.name || locationId
    }]);

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    onMounted(() => {
      selectRef.value && selectRef.value.focus()
    })

    const onSubmit = () => {
      if (!props.disabled) {
        emit('save')
      }
    }

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        locationId: tripAdvisorLocationId.value,
      })
    }

    const debouncedOnSearch = debounce((val) => {
      store.dispatch('slides/searchTripAdvisorLocation', { searchQuery: val }).then(d => {
        data.value = d.map(item => {
          return {
            value: item.location_id,
            label: `${item.name} [${item.address_obj.address_string}]`,
          }
        })
      })
    }, 300);

    const handleSearch = val => {
      debouncedOnSearch(val);
    }

    const handleChange = val => {
      tripAdvisorLocationId.value = val;
      updateSlideData()
      updateSlide()
      toggleDisabled(false)
    };

    return {
      slide,
      tripAdvisorLocationId,
      selectRef,
      googlePlace,
      data,
      onSubmit,
      handleSearch,
      handleChange,
      updateSlide
    }
  }
})
</script>

<style lang="less">

</style>
