<template>
  <div
    :class="className"
  >
    <button @click="save">
      Save
    </button>
    <!--  <div-->
    <!--    :class="className"-->
    <!--    @click.exact="editor?.chain().focus().run()"-->
    <!--  >-->
    <BubbleMenu
      v-if="editor"
      :editor="editor"
    />
    <EditorContent
      :editor="editor"
      :default-value="defaultEditorContent"
    />
  </div>
</template>

<script setup>
import { provide, ref, watch, watchEffect } from 'vue'
import { EditorContent, useEditor } from '@tiptap/vue-3'
import { useDebounceFn } from '@vueuse/core'
import { useCompletion } from 'ai/vue'

import { defaultEditorContent } from './lib/default-content'
import { defaultExtensions } from './extensions'
import { defaultEditorProps } from './lib/props'
import BubbleMenu from './BubbleMenu/BubbleMenu'
import { getPrevText } from './lib/editor'
import './index.less'

const props = defineProps({
  /**
   * The API route to use for the Vercel Blob.
   * Defaults to "/api/upload".
   */
  blobApi: {
    type: String,
    default: "/api/upload",
  },
  /**
   * The API route to use for the OpenAI completion API.
   * Defaults to "/api/generate".
   */
  completionApi: {
    type: String,
    default: "/api/generate",
  },
  token: {
    type: String,
    default: "",
  },
  /**
   * Additional classes to add to the editor container.
   * Defaults to "relative min-h-[500px] w-full max-w-screen-lg border-stone-200 bg-white p-12 px-8 sm:mb-[calc(20vh)] sm:rounded-lg sm:border sm:px-12 sm:shadow-lg".
   */
  className: {
    type: String,
    default:
        "rich-editor-container",
  },
  /**
   * The default value to use for the editor.
   * Defaults to defaultEditorContent.
   */
  defaultValue: {
    type: Object,
    default: () => {
      return defaultEditorContent
    },
  },
  /**
   * A list of extensions to use for the editor, in addition to the default Novel extensions.
   * Defaults to [].
   */
  extensions: {
    type: Array,
    default: () => [],
  },
  /**
   * Props to pass to the underlying Tiptap editor, in addition to the default Novel editor props.
   * Defaults to {}.
   */
  editorProps: {
    type: Object,
    default: () => {},
  },
  /**
   * A callback function that is called whenever the editor is updated.
   * Defaults to () => {}.
   */
  onUpdate: {
    type: Function,
    default: () => {},
  },
  /**
   * A callback function that is called whenever the editor is updated, but only after the defined debounce duration.
   * Defaults to () => {}.
   */
  onDebouncedUpdate: {
    type: Function,
    default: () => {},
  },
  /**
   * The duration (in milliseconds) to debounce the onDebouncedUpdate callback.
   * Defaults to 750.
   */
  debounceDuration: {
    type: Number,
    default: 750,
  },
  /**
   * The key to use for storing the editor's value in local storage.
   * Defaults to "novel__content".
   */

})

provide("completionApi", props.completionApi)
provide("token", props.token)

const content = ref(props.defaultValue)

const debouncedUpdate = useDebounceFn(({ editor }) => {
  content.value = editor.getJSON()
  props.onDebouncedUpdate(editor)
}, props.debounceDuration)

const editor = useEditor({
  extensions: [...defaultExtensions, ...props.extensions],
  editorProps: {
    ...defaultEditorProps,
    ...props.editorProps,
  },
  onUpdate: (e) => {
    const selection = e.editor.state.selection
    const lastTwo = getPrevText(e.editor, {
      chars: 2,
    })
    if (lastTwo === "++" && !isLoading.value) {
      e.editor.commands.deleteRange({
        from: selection.from - 2,
        to: selection.from,
      })
      complete(getPrevText(e.editor, { chars: 5000 }))
    } else {
      props.onUpdate(e.editor)
      debouncedUpdate(e)
    }
  },
  autofocus: "end",
})

defineExpose({
  editor,
})

const { complete, completion, isLoading, stop } = useCompletion({
  id: "novel-vue",
  api: props.completionApi,
  onFinish: (_prompt, completion) => {
    editor.value?.commands.setTextSelection({
      from: editor.value.state.selection.from - completion.length,
      to: editor.value.state.selection.from,
    })
  },
  onError: (err) => {
    console.error(err)
  },
})

watch(
    () => completion.value,
    (newCompletion, oldCompletion) => {
      const diff = newCompletion?.slice(oldCompletion?.length)
      if (diff) {
        editor.value?.commands.insertContent(diff)
      }
    }
)

const onKeyDown = (e) => {
  if (e.key === "Escape" || (e.metaKey && e.key === "z")) {
    stop()
    if (e.key === "Escape") {
      editor.value?.commands.deleteRange({
        from: editor.value.state.selection.from - completion.value.length,
        to: editor.value.state.selection.from,
      })
    }
    editor.value?.commands.insertContent("++")
  }
}

const mousedownHandler = (e) => {
  e.preventDefault()
  e.stopPropagation()
  stop()
  if (window.confirm("AI writing paused. Continue?")) {
    complete(editor.value?.getText() || "")
  }
}

watch(
    () => isLoading.value,
    (isLoading) => {
      if (isLoading) {
        document.addEventListener("keydown", onKeyDown)
        window.addEventListener("mousedown", mousedownHandler)
      } else {
        document.removeEventListener("keydown", onKeyDown)
        window.removeEventListener("mousedown", mousedownHandler)
      }
    }
)

const hydrated = ref(false)
watchEffect(() => {
  if (editor.value && content.value && !hydrated.value) {
    editor.value.commands.setContent(content.value)
    hydrated.value = true
  }
})

const save = () => {
  console.log(editor.value?.getJSON())
  console.log(editor.value?.getHTML())
}

</script>


<style>
.rich-editor-container {
  padding: 24px;
  min-height: 1em;
  //min-width: 200px;
  max-height: 20em;
  overflow-x: auto;
  * :focus-visible {
    outline: none;
  }
  p {
    margin-bottom: 0;
  }
}
</style>
