export const openLoginWithMicrosoftWindow = () => {
  const scopesStr= `?scopes=${process.env.VUE_APP_MICROSOFT_SCOPES}`
  const fullUrl = `${process.env.VUE_APP_MICROSOFT_LOGIN_LINK}${scopesStr}`
  const authWindow = window.open(fullUrl, 'kitcast', 'height=600,width=800,toolbar=0')
  const interval = setInterval(() => {
    if (!authWindow.closed) {
      authWindow.postMessage({ platform: 'microsoft', action: 'login' }, process.env.VUE_APP_DEFAULT_URL)
    } else {
      clearInterval(interval)
    }
  }, 500)
  if (authWindow.focus) {
    authWindow.focus()
  }
}
