<template>
  <a-drawer
    :width="508"
    :title="$t('components.notificationsSider.title')"
    :placement="'left'"
    :open="visible"
    @close="$emit('update:visible', !visible)"
  >
    <a-list
      item-layout="horizontal"
      :data-source="notifications"
      :loading="notificationsLoading"
    >
      <template #loadMore>
        <div
          v-if="!notificationsLoading && hasMore"
          :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
        >
          <a-button @click="loadMore">
            {{ $t('components.notificationsSider.loadMoreButtonText') }}
          </a-button>
        </div>
      </template>
      <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta>
            <template
              #description
            >
              <template v-if="item.type === 'WORKSPACE_SUBSCRIPTION_UPDATED'">
                <template
                  v-if="item.data.newBillingInfo.tier !==item.data.previousBillingInfo.tier "
                >
                  <template v-if="item.data.newBillingInfo.tier === null">
                    <i18n-t
                      keypath="components.notificationsSider.subscriptionStopped"
                      tag="span"
                    >
                      <a-typography-text
                        class="underline-link"
                        @click="openUpdateSubscription"
                      >
                        {{ $t('components.notificationsSider.subscriptionStoppedUpdate') }}
                      </a-typography-text>
                    </i18n-t>
                  </template>
                  <template v-else>
                    {{ $t('components.notificationsSider.planUpgradedTo') }} <span v-tier="item.data.newBillingInfo.tier" />.
                  </template>
                </template>
              </template>
              <template
                v-else-if="item.type === 'TIER_TO_BUSINESS_GROWTH'"
              >
                {{ $t('components.notificationsSider.planUpgradedToBusinessGrowth') }}
              </template>
              <template
                v-else-if="item.type === 'TIER_TO_EDUCATION_GROWTH'"
              >
                {{ $t('components.notificationsSider.planUpgradedToEducationGrowth') }}
              </template>
              <template
                v-else-if="item.type === 'TIER_TO_ENTERPRIZE'"
              >
                {{ $t('components.notificationsSider.planUpgradedToEnterprise') }}
              </template>
              <template
                v-else-if="item.type === 'SUBSCRIPTION_TRIAL_STARTED'"
              >
                <i18n-t
                  keypath="components.notificationsSider.trialStarted"
                  tag="span"
                >
                  <a-typography-text
                    class="underline-link"
                    @click="openUpdateSubscription"
                  >
                    {{ $t('components.notificationsSider.trialStartedUpdate') }}
                  </a-typography-text>
                </i18n-t>
              </template>
              <template
                v-else-if="item.type === 'SUBSCRIPTION_TRIAL_ENDED'"
              >
                <i18n-t
                  keypath="components.notificationsSider.trialEnded"
                  tag="span"
                >
                  <a-typography-text
                    class="underline-link"
                    @click="openUpdateSubscription"
                  >
                    {{ $t('components.notificationsSider.trialEndedUpdate') }}
                  </a-typography-text>
                </i18n-t>
              </template>
              <template
                v-else-if="item.type === 'SUBSCRIPTION_UPGRADED'"
              >
                {{ $t('components.notificationsSider.screensAdded', {addedDevices: item.data.addedDevices}) }} {{ $t('components.notificationsSider.currentDevicesNumber', {currentDevices: item.data.currentDevices}) }}
                <a-typography-text
                  class="underline-link"
                  @click="openDevicesModal"
                >
                  {{ $t('components.notificationsSider.manageScreensLink') }}
                </a-typography-text>
              </template>
              <template
                v-else-if="item.type === 'SUBSCRIPTION_DOWNGRADED'"
              >
                {{ $t('components.notificationsSider.screensRemoved', {removedDevices: item.data.removedDevices}) }} {{ $t('components.notificationsSider.currentDevicesNumber', {currentDevices: item.data.currentDevices}) }}
                <a-typography-text
                  class="underline-link"
                  @click="openDevicesModal"
                >
                  {{ $t('components.notificationsSider.manageScreensLink') }}
                </a-typography-text>
              </template>
              <template
                v-else-if="item.type === 'SUBSCRIPTION_PAST_DUE'"
              >
                <i18n-t
                  keypath="components.notificationsSider.paymentUnsuccessful"
                  tag="span"
                >
                  <a-typography-text
                    class="underline-link"
                    @click="openUpdatePaymentMethod"
                  >
                    {{ $t('components.notificationsSider.paymentUnsuccessfulPaymentMethod') }}
                  </a-typography-text>
                </i18n-t>
              </template>
              <template
                v-else-if="item.type === 'SUBSCRIPTION_UNPAID'"
              >
                <i18n-t
                  keypath="components.notificationsSider.subscriptionStopped"
                  tag="span"
                >
                  <a-typography-text
                    class="underline-link"
                    @click="openUpdateSubscription"
                  >
                    {{ $t('components.notificationsSider.subscriptionStoppedUpdate') }}
                  </a-typography-text>
                </i18n-t>
              </template>
              <template
                v-else-if="item.type === 'SUBSCRIPTION_TO_INACTIVE'"
              >
                <i18n-t
                  keypath="components.notificationsSider.subscriptionInactive"
                  tag="span"
                >
                  <a-typography-text
                    class="underline-link"
                    @click="openUpdateSubscription"
                  >
                    {{ $t('components.notificationsSider.subscriptionInactiveUpdate') }}
                  </a-typography-text>
                </i18n-t>
              </template>
              <template
                v-else-if="item.type === 'MEDIA_CLEANUP'"
              >
                {{ $t('components.notificationsSider.mediaCleanup', {daysToDelete: item.data.datsToDelete, unusedMediaCount: item.data.unusedMediaCount }) }}
              </template>
              <template
                v-else-if="item.type === 'MEDIA_CLEANUP_IN_USE'"
              >
                {{ $t('components.notificationsSider.mediaCleanupInUse', {name: item.data.mediaName }) }}
              </template>
              <template
                v-else-if="item.type === 'ROLE_CHANGED'"
              >
                {{ $t('components.notificationsSider.roleChanged', {newRole: item.data.newRole }) }}
              </template>
              <template
                v-else-if="item.type === 'PAYMENT_FAILED'"
              >
                <template v-if="item.data.attemptNumber === 1">
                  {{ $t('components.notificationsSider.paymentFailedFirstAttempt') }}
                </template>
                <template v-else>
                  {{ $t('components.notificationsSider.paymentFailedLastAttempt') }}
                </template>
                <a-typography-text
                  class="underline-link"
                  @click="openUpdatePaymentMethod"
                >
                  {{ $t('components.notificationsSider.updatePaymentInfo') }}
                </a-typography-text>
              </template>
              <template
                v-else-if="item.type === 'DEVICES_STATUSES'"
              >
                <b>{{ item.data.devicesCount > 1 ? $t('components.notificationsSider.devicesStatusChange', {devicesCount: item.data.devicesCount}) : (item.data.deviceNames?.[0] || $t('components.notificationsSider.deviceStatusChange')) }}</b>
                {{ $t('components.notificationsSider.went') }}
                <a-typography-text
                  v-if="item.data.online"
                  type="success"
                >
                  {{ $t('components.notificationsSider.deviceStatusOnline') }}
                </a-typography-text>
                <template v-else>
                  <a-typography-text

                    type="danger"
                  >
                    {{ $t('components.notificationsSider.deviceStatusOffline') }}
                  </a-typography-text>&nbsp;<a-typography-text type="secondary">
                    {{ $t('components.notificationsSider.deviceStatusOfflineTooltip') }}
                  </a-typography-text>
                </template>
              </template>
            </template>
            <template #avatar>
              <a-avatar
                :size="48"
                style="border: 0"
              >
                <template #icon>
                  <KitcastNotification />
                </template>
              </a-avatar>
            </template>
          </a-list-item-meta>
          <div class="notification-date">
            <a-typography-text type="secondary">
              <span v-format-date="{date: item.createdAt}" />
            </a-typography-text>
          </div>
        </a-list-item>
      </template>
    </a-list>
  </a-drawer>
</template>

<script>
import { computed, defineComponent, reactive, watch } from 'vue'
import KitcastNotification from '@/components/icons/KitcastNotification.vue'
import { useStore } from 'vuex'

const NOTIFICATIONS_PER_PAGE = 10

class Notification {
  data
  type
  constructor (notification) {
    this.createdAt = notification.createdAt
    this.#init(notification.data, notification.type)
  }

  #init (data, type) {
    switch (type) {
      case 'USER_ROLE_CHANGED':
        this.type = 'ROLE_CHANGED'
        this.data = data
        break
      case 'MEDIA_PENDING_AUTO_DELETE':
        this.type = 'MEDIA_CLEANUP'
        this.data = data
        break
      case 'MEDIA_AUTO_DELETE_IN_USE':
        this.type = 'MEDIA_CLEANUP_IN_USE'
        this.data = data
        break
      case 'DEVICE_STATUSES_CHANGED':
        this.type = 'DEVICES_STATUSES'
        this.data = data
        break
      case 'WORKSPACE_SUBSCRIPTION_UPDATED':
        const {newBillingInfo, previousBillingInfo} = data
          if (newBillingInfo.status !== previousBillingInfo.status
              && newBillingInfo.tier !== previousBillingInfo.tier
              && newBillingInfo.status === 'paid') {
            if (newBillingInfo.tier === 'business-enterprize' && newBillingInfo.tier === 'education-enterprize') {
              this.type = 'TIER_TO_ENTERPRIZE'
              return
            }
            else if (newBillingInfo.tier === 'business-growth') {
              this.type = 'TIER_TO_BUSINESS_GROWTH'
              return
            }else if (newBillingInfo.tier === 'education-growth') {
              this.type = 'TIER_TO_EDUCATION_GROWTH'
              return
            }
          }
          else if (newBillingInfo.tier === 'business-trial'
              && newBillingInfo.status ==='trialing'
              && !(previousBillingInfo.tier === 'business-trial' && previousBillingInfo.status === 'trialing')) {
            this.type = 'SUBSCRIPTION_TRIAL_STARTED'
            return
          }
          else if (newBillingInfo.tier === 'business-trial'
              && newBillingInfo.status !=='trialing'
              && previousBillingInfo.tier ==='business-trial'
              && previousBillingInfo.status ==='trialing') {
            this.type = 'SUBSCRIPTION_TRIAL_ENDED'
            return
          }
          else if (newBillingInfo.status !== previousBillingInfo.status) {
            if (newBillingInfo.status === 'past_due') {
              this.type = 'SUBSCRIPTION_PAST_DUE'
              return
            }
            else if (newBillingInfo.status === 'unpaid') {
              this.type = 'SUBSCRIPTION_UNPAID'
              return
            }
            else if (newBillingInfo.status === 'inactive') {
              this.type = 'SUBSCRIPTION_TO_INACTIVE'
              return
            }
          }
          else if (newBillingInfo.status === 'paid' && previousBillingInfo.status === 'paid') {
            if (newBillingInfo.billingDevicesQuantity > previousBillingInfo.billingDevicesQuantity) {
              this.type = 'SUBSCRIPTION_UPGRADED'
              this.data = {
                addedDevices: newBillingInfo.billingDevicesQuantity - previousBillingInfo.billingDevicesQuantity,
                currentDevices: newBillingInfo.billingDevicesQuantity
              }
              return
            }
            else if (previousBillingInfo.billingDevicesQuantity > newBillingInfo.billingDevicesQuantity ) {
              this.type = 'SUBSCRIPTION_DOWNGRADED'
              this.data = {
                removedDevices: previousBillingInfo.billingDevicesQuantity - newBillingInfo.billingDevicesQuantity,
                currentDevices: newBillingInfo.billingDevicesQuantity
              }
              return
            }
          }
        break
      case 'WORKSPACE_SUBSCRIPTION_PAYMENT_FAILED':
        this.type = 'PAYMENT_FAILED'
        this.data = data
        break
    }
  }
}

export default defineComponent({
  name: 'NotificationsSider',
  components: {
    KitcastNotification
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  setup (props) {
    const store = useStore()
    const pagination = computed(()=> store.getters['notifications/notificationsPagination'])
    const notifications = computed(()=> store.getters['notifications/notificationsData']?.map(n=> new Notification(n)))
    const notificationsLoading = computed(()=> store.getters['notifications/notificationsLoading'])
    const notificationsTotal = computed(()=> pagination.value?.total)
    const paginationState = reactive({
      limit: NOTIFICATIONS_PER_PAGE,
      offset: 0
    })

    // const isEmpty = computed(()=>!notifications.value?.length)

    const hasMore = computed(()=>{
      return notificationsTotal.value > paginationState.offset + NOTIFICATIONS_PER_PAGE
    })

    const loadMore = () => {
      if (!hasMore.value) return
      paginationState.offset+=NOTIFICATIONS_PER_PAGE
      fetchNotifications()
    }

    const fetchNotifications = () => {
      store.dispatch('notifications/getNotifications', {pagination: paginationState})
    }

    const openDevicesModal = () => {
      store.dispatch('openDevices')
    }

    const openSubscriptionTab = () => {
      store.dispatch('openGlobalSettings', 'subscription')
    }

    const openUpdateSubscription = () => {
      store.dispatch('handleUpdateSubscription')
    }

    const openUpdatePaymentMethod = () => {
      store.dispatch('openUpdatePaymentMethod')
    }

    watch(()=>props.visible, (value)=>{
      fetchNotifications()
    })

    return {
      notifications,
      hasMore,
      notificationsLoading,
      loadMore,
      openSubscriptionTab,
      openUpdateSubscription,
      openUpdatePaymentMethod,
      openDevicesModal,
    }

  }
})
</script>

<style scoped lang="less">
.notification-date {
  width: 100px;
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  justify-content: flex-end;
}
</style>
