<template>
  <div class="floor-editor">
    <TextElement
      v-model:value-model="fieldsState.floor"
      :max-length="3"
      :title="$t('components.smartTemplateConstructor.floorMapEditor.floorInputTitle')"
    />
    <TextElement
      v-model:value-model="fieldsState.sections"
      :lines="6"
      :title="$t('components.smartTemplateConstructor.floorMapEditor.sectionsInputTitle')"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive, watchEffect } from 'vue'
import TextElement from '@/components/slideModal/smartTemplateConstructor/segmentElements/TextElement'

export default defineComponent({
  name: 'FloorMapEditor',
  components: { TextElement },
  props: {
    data: Object
  },
  emits: ['validChange'],
  setup (props, { emit }) {
    const fieldsState = reactive(props.data)

    const isValid = computed(() => !!(fieldsState.floor
        && fieldsState.sections))

    watchEffect(() => {
      emit('validChange', isValid.value)
    })

    return {
      fieldsState
    }
  }
})
</script>

<style lang="less" scoped>
.floor-editor {
  :deep(.ant-row) {
    display: block;
    margin: 0;
  }
}
</style>
