<template>
  <a-layout-content style="background: #fff; height: 100%; overflow-x: auto; display: flex; flex-direction: column">
    <a-page-header :title="$t('components.smartGroupDevicesPage.title')" />
    <div
      class="rules"
      style="flex: 1"
    >
      <div
        v-for="(block, blockIndex) in rulesBlocks?.blocks"
        :key="blockIndex"
        class="rules-block"
      >
        <div
          v-for="(group, groupIndex) in Object.values(block.rulesGroups).filter(v=> v.rules?.length)"
          :key="groupIndex"
          class="rules-group"
        >
          <template v-if="group?.rules?.length">
            <SmartPlaylistRule
              v-for="(rule, ruleIndex) in group.rules"
              :key="'rule'+ruleIndex"
              :rule="rule"
              :hide-title="!!ruleIndex"
              :custom-devices-tags="customDevicesTags"
              @remove="removeRule(group, rule)"
              @toggle-include="(value)=>toggleRuleInclude(rule, value)"
            />
          </template>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <a-dropdown>
            <a
              class="ant-dropdown-link"
              @click.prevent
            >
              <PlusCircleFilled style="margin: 0 4px 0 8px;" />
              {{ $t('components.smartGroupDevicesPage.addRule') }}
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  v-for="ruleType in RULES_TYPES"
                  :key="ruleType"
                  @click="addRule(block, ruleType)"
                >
                  <a>
                    {{ $t(`components.smartGroupDevicesPage.${ruleType}`) }}
                  </a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-button
            danger
            @click="removeBlock(rulesBlocks, block)"
          >
            {{ $t('components.smartGroupDevicesPage.removeBlock') }}
          </a-button>
        </div>
      </div>
    </div>
    <StickyFooter>
      <FixedFooter>
        <template #left>
          {{ devicesMatchingRulesCount }} Screens match selected rules
        </template>
        <template #right>
          <a-space>
            <!--            <a-button-->
            <!--              :disabled="saving"-->
            <!--              @click="addBlock(rulesBlocks)"-->
            <!--            >-->
            <!--              Add block-->
            <!--            </a-button>-->
            <a-button
              :disabled="saving || !rulesChanged"
              @click="cancelChanges"
            >
              Cancel
            </a-button>
            <a-button
              type="primary"
              :disabled="!rulesChanged"
              :loading="saving"
              @click="updateGroupRules"
            >
              Save
            </a-button>
          </a-space>
        </template>
      </FixedFooter>
    </StickyFooter>
  </a-layout-content>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { cloneDeep } from '@apollo/client/utilities'
import { PlusCircleFilled } from '@ant-design/icons-vue'
import SmartPlaylistRule from '@/components/SmartPlaylistRule.vue'
import FixedFooter from '@/components/FixedFooter.vue'
import { Rules, RULES_TYPES } from '@/helpers/Rule'
import StickyFooter from '@/components/StickyFooter.vue'

export default defineComponent({
  name: 'SmartGroupDevicesPage',
  components: { StickyFooter, FixedFooter, SmartPlaylistRule, PlusCircleFilled },
  setup () {
    const store = useStore()
    const devicesMatchingRulesCount = computed(()=> store.getters['devices/devicesMatchingRulesCount'])
    const rules = computed(()=> store.getters['groups/currentGroupDeviceMatchingRule'])
    const rulesBlocks = ref(null)
    const saving = ref(false)
    const devicesTags = computed(()=> store.getters['tags/devicesTags'])
    const customDevicesTags = computed(()=> devicesTags.value?.data?.filter(tag => !tag.isSystemGenerated))

    onMounted(async ()=>{
      await store.dispatch('tags/getDevicesTags')
    })

    const deconstructRules = (rules) => {
      rulesBlocks.value = new Rules({rules: cloneDeep(rules.or), updateFn: checkMatchingRules})
    }

    const rulesBlockObject = computed(()=> rulesBlocks.value?.toObject())
    const rulesChanged = computed(()=> JSON.stringify(rulesBlockObject.value) !== JSON.stringify(rules.value))

    const cancelChanges = () => {
      rules.value && deconstructRules(rules.value)
    }

    const updateGroupRules = () => {
      store.dispatch('groups/updateCurrentGroup', { input: {
          deviceMatchingRule: rulesBlocks.value.toObject()
        }}).then(()=>{
        saving.value = false
      })
    }

    const toggleRuleInclude = (rule, value) => {
      rule.setInclude(value)
    }

    const removeRule = (group, rule) => {
      group.removeRule(rule)
    }
    const removeBlock = (rules, block) => {
      rules.removeBlock(block)
    }

    const addBlock = (rules) => {
      rules.addBlock()
    }

    const addRule = (block, ruleType) => {
      block.addRule(ruleType)
    }

    const checkMatchingRules = (matchingRules = {}) => {
      if (!Object.keys(matchingRules).length) {
        return
      }
      matchingRules && store.dispatch('devices/getDevicesMatchingRules', { deviceMatchingRule: matchingRules })
    }

    watchEffect(()=> {
      if (rules.value) {
        deconstructRules(rules.value)
        checkMatchingRules(rules.value)
        store.dispatch('tags/setAddBlockFunction', rulesBlocks.value?.addBlock?.bind(rulesBlocks.value))
      }
      else {
        store.dispatch('tags/setAddBlockFunction', null)
      }
    })

    return {
      rulesBlocks,
      saving,
      RULES_TYPES,
      devicesMatchingRulesCount,
      customDevicesTags,
      rulesChanged,
      toggleRuleInclude,
      updateGroupRules,
      cancelChanges,
      removeRule,
      removeBlock,
      addBlock,
      addRule
    }
  }
})
</script>

<style scoped lang="less">

.rules {
  padding: 0 20px 20px 20px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  .rules-block {
    border: dashed 2px var(--ant-primary-3);
    border-radius: 8px;
    padding: 12px;
    min-height: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .rules-group {
      display: flex;
      flex-direction: column;
      background-color: #F5F5F5;
      border-radius: 4px;
    }
  }
}


</style>


