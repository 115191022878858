import { gql } from 'graphql-tag'
import { DEVICE_BASE } from '@/graphql/fragments'

export const ON_NEW_NOTIFICATIONS_TRIGGER = gql`
  subscription onNewNotificationsTrigger {
    onNewNotificationsTrigger {
      needReload
    }
  }
`

export const ON_DEVICE_UPDATED = gql`
  ${DEVICE_BASE}
  subscription onDeviceUpdated {
    onDeviceUpdated {
      ...deviceBase
    }
  }
`
