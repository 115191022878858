<template>
  <div
    :style="{ alignItems, color: foreground }"
    class="sale"
  >
    <div class="wrapper">
      <div class="caption">
        {{ caption }}
      </div>
      <div class="discount">
        <div>{{ discount || 0 }}</div>
        <div class="mask">
          <SaleMask />
        </div>
      </div>
      <div class="header">
        {{ header }}
      </div>
      <div class="description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { LAYOUT_ALIGN_MAP } from '@/constants'
import SaleMask from '@/components/icons/SaleMask'

export default defineComponent({
  name: 'SaleTemplate',
  components: { SaleMask },
  props: {
    data: Object
  },
  setup (props) {
    const layout = computed(() => props.data?.layout)
    const alignItems = computed(() => LAYOUT_ALIGN_MAP[layout.value])
    const foreground = computed(() => props.data?.foreground)
    const caption = computed(() => props.data?.caption)
    const header = computed(() => props.data?.header)
    const description = computed(() => props.data?.description)
    const discount = computed(() => props.data?.discount)

    return {
      alignItems,
      foreground,
      caption,
      header,
      description,
      discount
    }
  }
})
</script>

<style lang="less" scoped>
.sale {
  line-height: 1.2;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 16px 32px;
  overflow: hidden;
  justify-content: center;
  text-align: center;
  line-height: 1;

  div {
    white-space: pre;
  }

  .wrapper {
    padding: 25px;
    background: #fff;
    height: 300px;
    width: 290px;

    .caption {
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 3px;
      height: 10px;
    }

    .discount {
      display: inline-block;
      margin-top: -2px;
      position: relative;
      color: #000;
      font-size: 156px;
      letter-spacing: -17px;
      margin-left: -50px;
      line-height: 184px;

      .mask {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 146px;
        letter-spacing: -4px;

        :deep(.anticon) {
          display: inline;

          svg {
            height: 76%;
            margin-top: 22px;
            margin-left: 6px;
          }
        }

      }
    }

    .header {
      margin-top: 5px;
      font-size: 16px;
      height: 17px;
    }

    .description {
      margin-top: 7px;
      font-size: 10px;
      height: 10px;
    }
  }
}
</style>
