<template>
  <SlideStep
    :sub-title="$t('components.slides.covidAwareness.slideStep1.subTitle')"
    :title="$t('components.slides.covidAwareness.slideStep1.title')"
    no-border
  />
</template>

<script>
import { defineComponent } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { CovidAwarenessSlide } from '@/helpers/Slides'

export default defineComponent({
  name: 'CovidAwarenessSlide',
  components: { SlideStep },
  props: {
    title: String,
    slideObj: CovidAwarenessSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const slide = props.slideObj || new CovidAwarenessSlide()

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    if (!slide.widgetId) {
      updateSlide()
    }

    return {}
  }
})
</script>

<style lang="less">

</style>
