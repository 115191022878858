<script setup>

import LoadingCircle from '../icons/loadingCircle.vue'
import { Sparkles } from 'lucide-vue-next'
import { inject, nextTick, onMounted, ref } from 'vue'
import AICompletionCommands from './AICompletionCommands.vue'
import AISelectorCommands from './AISelectorCommands.vue'
import { useCompletion } from 'ai/vue'
import { error } from '@/utils'

const inputValue = ref("")
const inputRef = ref()
const hasCompletion = ref(false)

const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
  open: {
    type: Boolean,
    required: true,
  }
})

const emit = defineEmits(['onOpenChange'])
const completionApi = inject('completionApi')
const token = inject('token')
const completion = ref('')

const { complete, isLoading } = useCompletion({
  id: "novel-vue",
  api: completionApi,
  ...(token ? {headers: {
    Authorization: `Bearer ${token}`
  }} : null),
  onResponse: (response) => {
    if (response.status === 429) {
      return error("You have reached your request limit for the day.")
    }
  },
  onError: (e) => {
    error(e.message)
  },
})

// console.log(complete, isLoading, completion)


const handleInput = (event) => {
  const target = event.target
  inputValue.value = target.value
}

const handleSelect = (item) => {
  setTimeout(async () => {
    const slice = props.editor.state.selection.content()
    const text = props.editor.storage.markdown.serializer.serialize(
        slice.content,
    )
    complete(text, {
      body: { option: item.value, command: inputValue },
    }).then(async ()=>{
      inputValue.value = ""
      completion.value = 'this is asdasdass'
      hasCompletion.value = true
      await nextTick()
      inputRef.value?.focus()
    })
  }, 100)
}

onMounted(()=> {
  inputRef.value?.focus()
})

</script>

<template>
  <div
    id="ai-selector"
    class="w-[300px]"
  >
    <div
      v-if="completion"
      class="px-2 py-2 text-sm"
    >
      {{ completion }}
    </div>
    <div
      v-if="open && !isLoading"
      class="relative"
    >
      <input
        ref="inputRef"
        :value="inputValue"
        type="text"
        class="w-full h-10 px-2 text-sm font-medium text-muted-foreground rounded-md outline"
        :placeholder="hasCompletion
          ? 'Tell AI what to do next'
          : 'Ask AI to edit or generate...'"
        @input="handleInput"
      >
    </div>
    <template v-if="!hasCompletion && !isLoading">
      <AISelectorCommands
        :filter="inputValue"
        @select="handleSelect"
      />
    </template>
    <AICompletionCommands
      v-else-if="hasCompletion && !isLoading"
      :completion="completion"
      :editor="editor"
      :filter="inputValue"
      @on-discard="()=>{
        editor.chain().unsetHighlight().focus().run()
        emit('onOpenChange', false)}"
    />
    <div
      v-else-if="isLoading"
      class="flex h-12 w-full items-center px-4 text-sm font-medium text-muted-foreground text-purple-500"
    >
      <Sparkles class="mr-2 h-4 w-4 shrink-0" />
      AI is thinking
      <div class="ml-2 mt-1">
        <LoadingCircle />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
