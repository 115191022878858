<template>
  <div class="sale-editor">
    <TextElement
      v-model:value-model="fieldsState.caption"
      :max-length="25"
      :title="$t('components.smartTemplateConstructor.saleEditor.captionInputTitle')"
    />
    <TextElement
      v-model:value-model="fieldsState.discount"
      :max-length="2"
      :title="$t('components.smartTemplateConstructor.saleEditor.discountInputTitle')"
      data-type="number"
    />
    <TextElement
      v-model:value-model="fieldsState.header"
      :max-length="25"
      :title="$t('components.smartTemplateConstructor.saleEditor.headerInputTitle')"
    />
    <TextElement
      v-model:value-model="fieldsState.description"
      :lines="2"
      :max-length="40"
      :title="$t('components.smartTemplateConstructor.saleEditor.descriptionInputTitle')"
    />
  </div>
</template>
<script>
import { computed, defineComponent, reactive, watchEffect } from 'vue'
import TextElement from '@/components/slideModal/smartTemplateConstructor/segmentElements/TextElement'

export default defineComponent({
  name: 'SaleEditor',
  components: {
    TextElement
  },
  props: {
    data: Object
  },
  emits: ['validChange'],
  setup (props, { emit }) {
    const fieldsState = reactive(props.data)

    const isValid = computed(() => !!(fieldsState.caption
        && fieldsState.discount
        && fieldsState.header
        && fieldsState.description))

    watchEffect(() => {
      emit('validChange', isValid.value)
    })

    return {
      fieldsState
    }
  }
})
</script>

<style lang="less" scoped>
.sale-editor {
}

</style>
