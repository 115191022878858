<template>
  <div
    style="display: flex; gap: 8px; align-items: center"
  >
    <div
      v-for="layoutInList in ZONING_LAYOUTS"
      :key="layoutInList.type"
      @click="handleLayoutChange(layoutInList)"
    >
      <a-tooltip
        :title="$t(`zones.${layoutInList.key}`)"
      >
        <ZoningLayoutIcon
          :layout="layoutInList"
          :layout-active="layout.type === layoutInList.type"
          :hover-mode="true"
        />
      </a-tooltip>
    </div>
  </div>
</template>

<script>

import { defineComponent } from 'vue'
import { ZONING_LAYOUTS } from '@/constants'
import ZoningLayoutIcon from '@/components/ZoningLayoutIcon'

export default defineComponent({
  name: 'ZoningInlineMenu',
  components: { ZoningLayoutIcon },
  props: {
    layout: Object
  },
  emits: ['change-layout'],
  setup (props, { emit }) {

    const handleLayoutChange = (layout) => {
      if (props.layout.type === layout.type) return
      emit('change-layout', layout)
    }
    return {
      handleLayoutChange,
      ZONING_LAYOUTS
    }
  }
})
</script>

<style scoped lang="less">


</style>
