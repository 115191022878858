<template>
  <div class="smart-playlist-rule">
    <GeoSuggest
      ref="geoSuggest"
      :types="['(cities)']"
      :search="placeSearchInput"
      :suggestion="selectedSuggestion"
      :debounce="fn => debounce(fn, 500)"
      @geocoded="onDecode"
    />
    <div class="rule-body">
      <a-row
        gutter
        type="flex"
      >
        <a-col
          flex="220px"
          style="padding-right: 32px;"
        >
          <div style="flex: 1;display: flex; align-items: center;justify-content: space-between">
            <a-typography-text
              strong
              style="margin-bottom: 0; margin-right: 12px; display: inline-flex"
            >
              <span v-if="!hideTitle">{{ $t(`components.smartPlaylistRule.${rule.type}`) }}</span>
            </a-typography-text>
            <a-switch
              :checked="rule?.include"
              :checked-children="$t(`components.smartPlaylistRule.is`)"
              :un-checked-children="$t(`components.smartPlaylistRule.isNot`)"
              @change="(value) =>{ $emit('toggle-include', value) }"
            />
          </div>
        </a-col>
        <a-col flex="1">
          <div class="tags">
            <template v-if="rule?.type === 'orientations'">
              <a-tag
                v-for="orientation in rule?.tags"
                :key="orientation"
                closable
                @close.once.stop="(e)=>removeTag(rule, orientation, e)"
              >
                {{ $t(`components.smartPlaylistRule.${orientation}`) }}
              </a-tag>
              <a-popover
                trigger="click"
                placement="bottomRight"
                overlay-class-name="no-arrow"
              >
                <a-tag
                  style="cursor: pointer"
                >
                  <PlusOutlined />
                </a-tag>
                <template
                  #content
                >
                  <div class="popover-container">
                    <a-checkbox-group
                      :value="rule.tags"
                      :options="orientationOptions"
                      @change="(value)=>changeTags(rule, value)"
                    />
                  </div>
                </template>
              </a-popover>
            </template>
            <template v-else-if="rule?.type === 'groups'">
              <a-tag
                v-for="groupId in rule?.tags"
                :key="groupId"
                closable
                @close.once.stop="(e)=>removeTag(rule, groupId, e)"
              >
                {{ availableGroupsIdMap[groupId] }}
              </a-tag>
              <a-popover
                trigger="click"
                placement="bottomRight"
                overlay-class-name="no-arrow"
              >
                <a-tag
                  style="cursor: pointer"
                >
                  <PlusOutlined />
                </a-tag>
                <template
                  #content
                >
                  <div class="popover-container">
                    <a-tree
                      :checked-keys="rule.tags"
                      multiple
                      checkable
                      check-strictly
                      :selectable="false"
                      default-expand-all
                      :placeholder="$t('components.smartPlaylistRule.selectPlaylist')"
                      allow-clear
                      tree-default-expand-all
                      :tree-data="groupsSelectableTree"
                      @check="(value) => changeTags(rule, value?.checked)"
                    />
                  </div>
                </template>
              </a-popover>
            </template>
            <template v-else-if="rule?.type === 'devices'">
              <a-tag
                v-for="deviceId in rule?.tags"
                :key="deviceId"
                closable
                @close.once.stop="(e)=>removeTag(rule, deviceId, e)"
              >
                {{ devicesListMap[deviceId] }}
              </a-tag>
              <a-popover
                v-if="showDevicesAddButton"
                trigger="click"
                placement="bottomRight"
                overlay-class-name="no-arrow"
                @visible-change="focusDevicesInput"
              >
                <a-tag
                  style="cursor: pointer"
                >
                  <PlusOutlined />
                </a-tag>
                <template
                  #content
                >
                  <div class="popover-container">
                    <a-select
                      v-if="showDevicesSelect"
                      ref="devicesInput"
                      :default-open="true"
                      :value="devicesSelectValue"
                      mode="multiple"
                      show-search
                      placeholder="Select a Screen"
                      style="width: 100%"
                      :filter-option="devicesFilterOption"
                      :options="devicesListOptions"
                      @select="(value) => handleDeviceSelect(rule, value)"
                    />
                  </div>
                </template>
              </a-popover>
            </template>
            <template v-else-if="rule?.type === 'locations'">
              <a-tag
                v-for="(tag) in rule.tags"
                :key="tag"
                closable
                @close.prevent="(e)=>removeTag(rule, tag, e)"
              >
                {{ tag }}
              </a-tag>
              <a-popover
                trigger="click"
                placement="bottomRight"
                overlay-class-name="no-arrow"
                @visible-change="focusLocationInput"
              >
                <a-tag
                  style="cursor: pointer"
                >
                  <PlusOutlined />
                </a-tag>
                <template
                  #content
                >
                  <div class="popover-container">
                    <a-select
                      ref="geoInput"
                      v-model:value="selectedPlace"
                      :placeholder="$t('components.smartPlaylistRule.placeSelectPlaceholder')"
                      style="width: 100%"
                      :max-tag-text-length="10"
                      :filter-option="false"
                      :not-found-content="loading ? undefined : null"
                      :show-search="true"
                      :loading="inputDisabled"
                      :disabled="inputDisabled"
                      :options="suggestionOptions"
                      @select="onPlaceSelect"
                      @search="onPlaceSearch"
                    />
                  </div>
                </template>
              </a-popover>
            </template>
            <template v-else-if="rule?.type === 'customDeviceTags'">
              <a-tag
                v-for="(tag) in rule.tags"
                :key="tag"
                closable
                @close.prevent="(e)=>removeTag(rule, tag, e)"
              >
                {{ tag }}
              </a-tag>
              <a-popover
                trigger="click"
                placement="bottomRight"
                overlay-class-name="no-arrow"
                @visible-change="focusDeviceTagsInput"
              >
                <a-tag
                  style="cursor: pointer"
                >
                  <PlusOutlined />
                </a-tag>
                <template
                  #content
                >
                  <div class="popover-container">
                    <a-select
                      v-if="showDeviceTagsSelect"
                      ref="deviceTagsInput"
                      :default-open="true"
                      :value="deviceTagsSelectValue"
                      mode="multiple"
                      show-search
                      :placeholder="$t('components.smartPlaylistRule.deviceTagSelectPlaceholder')"
                      style="width: 100%"
                      :filter-option="devicesFilterOption"
                      :options="customDevicesTagsOptions"
                      @select="(value) => handleDeviceTagSelect(rule, value)"
                    />
                  </div>
                </template>
              </a-popover>
            </template>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="rule-actions">
      <a-tooltip :title="$t('components.smartPlaylistRule.remove')">
        <MinusCircleFilled
          class="button"
          @click="$emit('remove')"
        />
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, ref, watch } from 'vue'
import { debounce } from 'lodash-es'
import { MinusCircleFilled, PlusOutlined } from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { error } from '@/utils'
import { getTimezoneByLocation } from '@/helpers/Google'
import GeoSuggest from '@/components/GeoSuggest.vue'

export default defineComponent({
  name: 'SmartPlaylistRule',
  components: {
    GeoSuggest,
    MinusCircleFilled,
    PlusOutlined
  },
  props: {
    rule: Object,
    hideTitle: Boolean,
    customDevicesTags: Array
  },
  emits: ['remove', 'change-setting', 'toggle-include'],
  setup (props) {
    const store = useStore()
    const { t } =useI18n()
    const groups = ref()
    const placeSearchInput = ref('')
    const geoSuggest = ref(null)
    const geoInput = ref(null)
    const showDevicesSelect = ref(false)
    const showDeviceTagsSelect = ref(false)
    const devicesSelectValue = ref([])
    const deviceTagsSelectValue = ref([])
    const selectedPlaces = ref([])
    const selectedPlace = ref(null)
    const devicesInput = ref()
    const deviceTagsInput = ref()
    const inputDisabled = ref(false)
    const selectedSuggestion = ref(null)
    const checkedListOrientations = ref(['0', '90'])
    const groupsSelectableTree = computed(() => store.getters['groups/availableStandardGroupsTree']({ checkable: true }))
    const availableGroupsIdMap = computed(() => store.getters['groups/availableStandardGroupsIdMap'])
    const devicesList = computed(()=> store.getters['devices/allDevices'])
    const devicesListMap = computed(()=> store.getters['devices/allDevicesIdsMap'])
    const devicesListOptions = computed(()=>devicesList.value?.filter(({id})=> !props.rule?.tags?.includes(id)).map(({name, id})=>({ label: name, value: id})))
    const customDevicesTagsOptions = computed(()=>props.customDevicesTags?.filter(({key})=> !props.rule?.tags?.includes(key)).map(({key, title})=>({ label: title, value: key})))

    const suggestionOptions = computed(() => {
      const suggestions = geoSuggest?.value?.suggestions ?? []
      return suggestions.map(({ description, placeId }) => ({
        label: description,
        value: placeId
      }))
    })

    const loading = computed(() => {
      return geoSuggest?.value?.loading
    })

    const showDevicesAddButton = computed(()=>!!devicesListOptions.value?.length)


    const ORIENTATION_OPTIONS = [
      {
        label: t('components.smartPlaylistRule["0"]'),
        value: '0'
      },
      {
        label: t('components.smartPlaylistRule["90"]'),
        value: '90'
      },
      {
        label: t('components.smartPlaylistRule["180"]'),
        value: '180'
      },
      {
        label: t('components.smartPlaylistRule["270"]'),
        value: '270'
      }
    ]


    const removeTag = (rule, tag, e) => {
      e.preventDefault()
      rule.removeTag(tag)
    }

    const addTag = (rule, tag) => {
      rule.addTag(tag)
    }

    const changeTags = (rule, tags) => {
      rule.changeTags(tags)
    }

    const devicesFilterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const removeGroup = (groupId, e) => {
      e.preventDefault()
      groups.value.checked = groups.value.checked.filter(gid => gid!== groupId)
    }

    const onPlaceSearch = (value) => {
      placeSearchInput.value = value
    }

    const onDecode = async ({ normalizedAddress : { city } }) => {
      city && props.rule?.toggleTag(city)
      selectedSuggestion.value = null
      nextTick(() => {
        geoInput.value.focus()
      })
    }

    const focusLocationInput = (visible) => {
      if(visible) {
        nextTick(() => {
          setTimeout(() => geoInput.value?.focus(), 1)
        })
      }
      else {
        selectedSuggestion.value = null
      }
    }

    const focusDeviceTagsInput = (visible) => {
      if(visible) {
        nextTick(() => {
          setTimeout(()=>{
            showDeviceTagsSelect.value = true
          }, 5)
          setTimeout(()=>{
            deviceTagsInput.value?.focus()
          }, 50)

        })
      }
      else {
        resetDeviceTagsSelect()
      }
    }

    const focusDevicesInput = (visible) => {
      if(visible) {
        nextTick(() => {
          setTimeout(()=>{
            showDevicesSelect.value = true
          }, 5)
          setTimeout(()=>{
            devicesInput.value?.focus()
          }, 50)

        })
      }
      else {
        resetDevicesSelect()
      }
    }

    const resetDevicesSelect = () => {
      showDevicesSelect.value = false
      devicesSelectValue.value = []
    }

    const resetDeviceTagsSelect = () => {
      showDeviceTagsSelect.value = false
      deviceTagsSelectValue.value = []
    }

    const onPlaceSelect = (v) => {
      const suggestion = geoSuggest?.value?.suggestions.find(s => s.placeId === v)
      selectedSuggestion.value = suggestion
      selectedPlace.value = null
    }

    const removePlace = (p) => {
      selectedPlaces.value.splice(p, 1)
    }

    const handleDeviceSelect = (rule, value) => {
      devicesSelectValue.value = []
      addTag(rule, value)
    }

    const handleDeviceTagSelect = (rule, value) => {
      deviceTagsSelectValue.value = []
      addTag(rule, value)
    }

    const getTimezone = (location) => {
      const loc = location.lat + ',' + location.lng
      return getTimezoneByLocation(loc).then(json => {
        return json.timeZoneId
      }).catch(e => {
        error(e.message)
      })
    }

    watch(()=> showDevicesAddButton.value, (value) => {
      !value && resetDevicesSelect()
    })

    return {
      loading,
      debounce,
      placeSearchInput,
      showDevicesSelect,
      showDeviceTagsSelect,
      showDevicesAddButton,
      geoSuggest,
      geoInput,
      selectedPlaces,
      selectedPlace,
      inputDisabled,
      suggestionOptions,
      selectedSuggestion,
      availableGroupsIdMap,
      groupsSelectableTree,
      devicesSelectValue,
      deviceTagsSelectValue,
      groups,
      devicesInput,
      deviceTagsInput,
      devicesListOptions,
      devicesListMap,
      checkedListOrientations,
      customDevicesTagsOptions,
      orientationOptions: ORIENTATION_OPTIONS,
      removePlace,
      onDecode,
      onPlaceSelect,
      onPlaceSearch,
      removeGroup,
      removeTag,
      addTag,
      changeTags,
      focusLocationInput,
      focusDeviceTagsInput,
      focusDevicesInput,
      devicesFilterOption,
      handleDeviceSelect,
      handleDeviceTagSelect
    }
  }
})
</script>

<style scoped lang="less">
.smart-playlist-rule {
  display: flex;
  :deep(.ant-switch-inner){
    min-width: 34px;
  }
  .rule-body {
    flex: 1;
    padding: 12px;
    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      row-gap: 4px;
      .ant-tag {
        margin-right: 0;
      }
    }
  }
  .rule-actions {
    flex: 0 0 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.popover-container {
  width: 230px;
  :deep(.ant-checkbox-group) {
    display: flex;
    flex-direction: column;
  }
  :deep(.ant-tree-switcher) {
    display: none;
  }
}
</style>
