<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import SubscriptionSettingsInfoCardTrial from '@/components/subscriptionSettings/SubscriptionSettingsInfoCardTrial.vue'
import SubscriptionSettingsInfoCardEnterprise from '@/components/subscriptionSettings/SubscriptionSettingsInfoCardEnterprise.vue'
import SubscriptionSettingsInfoCardGrowth
  from '@/components/subscriptionSettings/SubscriptionSettingsInfoCardGrowth.vue'
import moment from 'moment/moment'
import SubscriptionSettingsInfoCardEDUStarter
  from '@/components/subscriptionSettings/SubscriptionSettingsInfoCardEDUStarter.vue'
import SubscriptionSettingsInfoCardEDUGrowth
  from '@/components/subscriptionSettings/SubscriptionSettingsInfoCardEDUGrowth.vue'
const store = useStore()
const isTrialTier = computed(() => store.getters['workspace/isTrialTier'])
const isEnterprise = computed(() => store.getters['workspace/isEnterprize'])
const isBusinessGrowthTier = computed(() => store.getters['workspace/isBusinessGrowthTier'])
const isEducationStarterTier = computed(() => store.getters['workspace/isEducationStarterTier'])
const isEducationGrowthTier = computed(() => store.getters['workspace/isEducationGrowthTier'])

const subscription = computed(() => store.getters['subscription/workspaceSubscription'])
const workspaceCustomer = computed(() => store.getters['subscription/workspaceCustomer'])
const discount = computed(()=>workspaceCustomer.value?.discount)
const coupon = computed(()=>discount.value?.coupon)
const billingAmount = computed(()=>subscription.value?.billingInfo?.billingAmount)
const amountDiscount = computed(()=>coupon.value?.amount_off)
const percentDiscount = computed(()=>coupon.value?.percent_off)

const discountIsActive = computed(()=>{
  return currentDateFitsInterval(discount.value?.start, discount.value?.end)
})

const currentBillingAmount = computed(()=>{
  const hasDiscount = !!amountDiscount.value || !!percentDiscount.value
  if (!billingAmount.value) return null
  if (!hasDiscount || !discountIsActive.value) {
    return billingAmount.value
  }
  if (percentDiscount.value ) {
    return (billingAmount.value * (1 - percentDiscount.value/100))
  }
  else {
    return (billingAmount.value - amountDiscount.value)
  }
})

const currentDateFitsInterval = (startIso, endIso) => {
  const now = moment()
  const start = startIso ? moment(startIso * 1000) : null
  const end = endIso ? moment(endIso * 1000) : null

  return (start && now.diff(start) > 0) && (!end || now.diff(end) < 0)
}

</script>

<template>
  <SubscriptionSettingsInfoCardTrial
    v-if="isTrialTier"
  />
  <SubscriptionSettingsInfoCardEnterprise
    v-else-if="isEnterprise"
  />
  <SubscriptionSettingsInfoCardGrowth
    v-else-if="isBusinessGrowthTier"
    :current-billing-amount="currentBillingAmount"
  />
  <SubscriptionSettingsInfoCardEDUStarter
    v-else-if="isEducationStarterTier"
  />
  <SubscriptionSettingsInfoCardEDUGrowth
    v-else-if="isEducationGrowthTier"
    :current-billing-amount="currentBillingAmount"
  />
</template>

<style scoped lang="less">
:deep(.current-plan-row) {
  display: flex;
  flex-direction: column;
}
</style>
