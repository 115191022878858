<script setup>
import { StripeElement, StripeElements } from 'vue-stripe-js'
import { ref, watch } from 'vue'

defineProps({
  disableFields: {
    type: Boolean,
    default: false
  },
  elementsOptions: {
    type: Object,
    default: null
  },
  stripeLoaded: {
    type: Boolean,
    default: false
  },
  stripeKey: {
    type: String,
    default: ''
  },
  complete: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onElementsSet', 'update:complete'])
const elementsRef = ref(null)

const handleElementChange = (event) => {
  emit('update:complete', event.complete)
}

watch(() => elementsRef.value, (elements) => {
  if (elements) {
    console.log('here', elements)
    emit('onElementsSet', elements)
  }
})

</script>

<template>
  <a-space
    direction="vertical"
    style="width: 100%"
    size="large"
  >
    <div
      class="subscription-element"
      :class="{disabled: disableFields}"
    >
      <a-typography-title :level="5">
        {{ $t('components.updateSubscriptionModal.paymentMethod') }}
      </a-typography-title>
      <StripeElements
        v-if="stripeLoaded && elementsOptions.clientSecret"
        ref="elementsRef"
        :key="elementsOptions.clientSecret"
        v-slot="{ elements }"
        :stripe-key="stripeKey"
        :elements-options="elementsOptions"
      >
        <StripeElement
          ref="card"
          type="payment"
          :elements="elements"
          :options="{}"
          @change="handleElementChange"
        />
      </StripeElements>
    </div>
  </a-space>
</template>

<style scoped lang="less">

</style>
