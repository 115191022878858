<template>
  <icon>
    <template #component>
      <svg
        width="94"
        height="36"
        viewBox="0 0 94 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_81388_106487)">
          <rect
            width="94"
            height="36"
            fill="white"
          />
          <rect
            width="62"
            height="36"
            fill="url(#paint0_linear_81388_106487)"
          />
          <rect
            x="21"
            y="8"
            width="20"
            height="20"
            rx="10"
            fill="white"
          />
          <path
            d="M27.2383 22H28.6152L29.3828 19.7148H32.6172L33.3789 22H34.7617L31.709 13.5449H30.291L27.2383 22ZM30.9473 15.0273H31.0469L32.2656 18.6602H29.7344L30.9473 15.0273Z"
            fill="#222222"
          />
          <rect
            x="62.5"
            y="0.5"
            width="31"
            height="35"
            fill="white"
          />
          <rect
            x="68"
            y="8"
            width="20"
            height="20"
            rx="10"
            fill="black"
          />
          <path
            d="M75.0352 22H78.5098C80.3145 22 81.3984 21.0977 81.3984 19.6152V19.6035C81.3984 18.502 80.6367 17.6934 79.4941 17.5703V17.4707C80.3262 17.3301 80.9766 16.5391 80.9766 15.6602V15.6484C80.9766 14.3535 80.0215 13.5449 78.4336 13.5449H75.0352V22ZM78.1289 14.6172C79.1016 14.6172 79.6699 15.0801 79.6699 15.877V15.8887C79.6699 16.709 79.0664 17.1426 77.9062 17.1426H76.3477V14.6172H78.1289ZM78.1699 18.1445C79.4062 18.1445 80.0566 18.6133 80.0566 19.5215V19.5332C80.0566 20.4414 79.4297 20.9277 78.2461 20.9277H76.3477V18.1445H78.1699Z"
            fill="white"
          />
          <rect
            x="62.5"
            y="0.5"
            width="31"
            height="35"
            stroke="black"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_81388_106487"
            x1="62"
            y1="36"
            x2="0"
            y2="36"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop
              offset="1"
              stop-opacity="0.6"
            />
          </linearGradient>
          <clipPath id="clip0_81388_106487">
            <rect
              width="94"
              height="36"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'PushToLeftTransition',
  components: {
    Icon
  }
})
</script>
