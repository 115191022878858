export function initIntercom (email) {
  if (process.browser && window.Intercom) {
    window.Intercom('boot', {
      email,
      app_id: 'x007y20s'
    })
  }
}

export function shutDownIntercom () {
  window.Intercom && window.Intercom('shutdown')
}

export function sendIntercomUpdate (payload) {
  process.browser && window.Intercom && window.Intercom('update', payload)
}

export function loadIntercom () {
  // if (process.env.VUE_APP_SHOW_INTERCOM) {
  //   (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', window.intercomSettings); } else { var d = document; var i = function () { i.c(arguments) }; i.q = []; i.c = function (args) { i.q.push(args) }; w.Intercom = i; function l() { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/x007y20s'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); } if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })()
  // }
}
