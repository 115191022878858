<script setup>

import { PauseOutlined, PlayCircleFilled } from '@ant-design/icons-vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
  isScheduled: Boolean,
  isPaused: Boolean
})

defineEmits(['toggle'])

const textRef = ref(null)
const hovered = ref(false)

const handleMouseLeave = () => {
  hovered.value = false
}

const handleMouseOver = () => {
  hovered.value = true
}

const buttonText = computed(() => {
  if (props.isPaused) {
    return t(`components.playlistPage.${hovered.value ? 'play' : 'paused'}`)
  }
  return t(`components.playlistPage.${hovered.value ? 'pause' : 'playing'}`)
})

const textWidth = computed(() => {
  if (textRef.value) {
    return textRef.value.offsetWidth
  }
  return 0
})

</script>

<template>
  <a-button
    v-if="isScheduled"
    class="btn-dark smooth-button"
    :type="isPaused ? 'dashed' : 'default'"
    style="transition: all 0.3s ease;"
    @mouseleave="handleMouseLeave"
    @mouseover="handleMouseOver"
    @click="$emit('toggle', !isPaused)"
  >
    <template #icon>
      <PauseOutlined v-if="(isPaused && !hovered) || (!isPaused && hovered)" />
      <PlayCircleFilled v-else-if="(isPaused && hovered) || (!isPaused && !hovered)" />
    </template>
    <span
      class="text-wrapper"
      :style="{ width: `${textWidth}px`}"
    >
      <transition name="fade">
        <span
          :key="buttonText"
          ref="textRef"
        >{{ buttonText }}</span>
      </transition>
    </span>
  </a-button>
  <a-tooltip
    v-else
    :title="$t('components.playlistPage.unscheduled')"
  >
    <a-button
      disabled
    >
      {{ $t('components.playlistPage.disabled') }}
    </a-button>
  </a-tooltip>
</template>

<style scoped lang="less">

.smooth-button {
  --animation-duration: 0.3s;
}
.text-wrapper {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  transition: width var(--animation-duration) ease;
}
.text-wrapper span {
  position: absolute;
  left: 0;
  top: -16px;
  transition: opacity var(--animation-duration) ease;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>
