<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import SubscriptionSettingsInfoCardFreeDevices
  from '@/components/subscriptionSettings/SubscriptionSettingsInfoCardFreeDevices.vue'

const store = useStore()
const isInactive = computed(() => store.getters['workspace/isInactive'])
const isPastDue = computed(() => store.getters['workspace/isPastDue'])
const isPaused = computed(() => store.getters['workspace/isPaused'])
const billingDevicesQuantity = computed(() => store.getters['workspace/billingDevicesQuantity'])
const isYearly = computed(() => store.getters['workspace/billingInterval'] === 'year')
const currentPeriodEndDate = computed(() => store.getters['subscription/currentPeriodEndsFormatted'](isYearly.value? 'MMMM D YYYY': undefined ))
const currentBillingInterval = computed(() => store.getters['subscription/currentBillingInterval'])
const allDevicesNumber = computed(()=>store.getters['devices/allDevicesNumber'])

defineProps({
  currentBillingAmount: Number
})

</script>

<template>
  <a-card>
    <a-row>
      <a-col
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text>{{ $t('components.subscriptionSettingsInfoCardGrowth.planName') }}</a-typography-text>
        <a-typography-text type="secondary">
          <template v-if="billingDevicesQuantity">
            {{ $t('components.subscriptionSettingsInfoCardGrowth.includesNumberOfLicenses', {count: billingDevicesQuantity}) }}
          </template>
          <template v-else>
            {{ $t('components.subscriptionSettingsInfoCardGrowth.screensConnected', {count: allDevicesNumber}) }}
          </template>
        </a-typography-text>
        <SubscriptionSettingsInfoCardFreeDevices />
      </a-col>
      <a-col
        v-if="!isInactive && !isPaused && billingDevicesQuantity"
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text v-if="currentBillingInterval">
          <span v-format-cents="currentBillingAmount" />/{{ $t(`components.subscriptionSettingsInfoCardGrowth.${currentBillingInterval}`) }}
        </a-typography-text>
        <a-typography-text type="secondary">
          {{ $t('components.subscriptionSettingsInfoCardGrowth.renewalDate') }} <span v-text="currentPeriodEndDate" />
        </a-typography-text>
        <a-typography-text
          v-if="isPastDue"
          type="danger"
        >
          {{ $t('components.subscriptionSettingsInfoCardGrowth.failed') }}
        </a-typography-text>
      </a-col>
    </a-row>
  </a-card>
</template>

<style scoped lang="less">

</style>
