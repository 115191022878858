<script setup>
</script>

<template>
  <a-alert
    show-icon
    type="warning"
  >
    <template #message>
      <span v-html="$t('components.subscriptionSettingsManageBlockVerifyEmail.verifyEmail')" />
    </template>
  </a-alert>
</template>

<style scoped lang="less">

</style>
