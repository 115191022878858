<script setup>

import SlideStep from '@/components/slideModal/SlideStep.vue'
import { ref, watch, watchEffect } from 'vue'
import { formatTimeForDuration, formatTimeToDuration } from '@/utils'
import { Slide } from '@/helpers/Slides'
import { DEFAULT_DURATION_PER_SLIDE_ITEM } from '@/constants'

const MIN_SLIDE_DURATION_PER_ITEM = 1

const emit = defineEmits(['update:disabled', 'updateSlide'])

const props = defineProps({
  slide: Slide,
  disableDurationPerItem: {
    type: Boolean,
    default: false
  },
  defaultDuration: {
    type: Number,
    default: 0
  },
  showDurationPerItem: {
    type: Boolean,
    default: false
  },
  adjustToItemsNumber: {
    type: Number,
    default: 0
  }
})

const durationInputRef = ref()
const durationTime = ref(formatTimeForDuration(props.slide?.duration))
const durationPerItemTime = ref(formatTimeForDuration(props.slide?.slideData?.durationPerItem))

const disabledTime = () => {
  return {
    disabledSeconds: (selectedHour, selectedMinute) =>
        selectedHour === 0 && selectedMinute === 0 ? [0] : []
  }
}

const updateSlide = () => {
  emit('updateSlide', props.slide)
}

const onDurationChange = (time) => {
  const seconds = formatTimeToDuration(time)
  if (seconds === 0) {
    durationTime.value = formatTimeForDuration(props.slide?.duration)
    return
  }
  updateSlideDuration(seconds)
}

const setCustomDuration = () => {
  updateSlideDuration(props.defaultDuration)
  durationInputRef.value.blur()
}

const updateSlideDuration = (seconds) => {
  if (seconds !== props.slide.duration) {
    props.slide.setDuration(seconds)
    durationTime.value = formatTimeForDuration(seconds)
  }
  updateSlide()
}

const onDurationPerItemChange = (time) => {
  const seconds = formatTimeToDuration(time)
  if (seconds === 0) {
    durationPerItemTime.value = formatTimeForDuration(MIN_SLIDE_DURATION_PER_ITEM)
    return
  }
  props.slide.updateSlideData({
    durationPerItem: seconds
  })
  if(props.adjustToItemsNumber > 0) {
    updateSlideDuration(seconds * props.adjustToItemsNumber)
  }
  updateSlide()
}

const adjustDurationForItemsNumber = (quantity) => {
  const duration = props.slide?.duration
  const seconds = formatTimeToDuration(durationPerItemTime.value)
  const newDuration = seconds * quantity
  if (newDuration !== duration) {
    props.slide.setDuration(newDuration)
    durationTime.value = formatTimeForDuration(newDuration)
  }
  updateSlide()
}

watch(() => props.slide?.duration, (duration) => {
  durationTime.value = formatTimeForDuration(duration)
})

watch(() => props.disableDurationPerItem, (disabled) => {
  if (disabled) {
    durationPerItemTime.value = formatTimeForDuration(DEFAULT_DURATION_PER_SLIDE_ITEM)
    props.slide.updateSlideData({
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM
    })
    updateSlide()
  }
})

watchEffect(() => {
  durationTime.value = formatTimeForDuration(props.slide?.duration)
  durationPerItemTime.value = formatTimeForDuration(props.slide?.slideData?.durationPerItem)
})

watch(() => props.adjustToItemsNumber, (quantity) => {
  if (!props.disableDurationPerItem) {
    adjustDurationForItemsNumber(quantity)
  }
})

</script>

<template>
  <SlideStep
    :title="$t('components.durationSlideStep.title')"
  >
    <a-form class="compact-form">
      <a-form-item v-if="showDurationPerItem">
        <a-space>
          <a-time-picker
            ref="inputRef"
            v-model:value="durationPerItemTime"
            :disabled="disableDurationPerItem"
            :minute-step="1"
            :second-step="1"
            :allow-clear="false"
            format="mm:ss"
            :show-now="false"
            style="width: 80px;"
            :disabled-time="disabledTime"
            @change="onDurationPerItemChange"
          />
          <a-typography-text type="secondary">
            {{ $t('components.durationSlideStep.durationPerItem') }}
          </a-typography-text>
        </a-space>
      </a-form-item>
      <a-form-item>
        <a-space>
          <a-time-picker
            ref="durationInputRef"
            v-model:value="durationTime"
            :minute-step="15"
            :second-step="10"
            :allow-clear="false"
            format="HH:mm:ss"
            :show-now="false"
            style="width: 110px;"
            :disabled-time="disabledTime"
            @change="onDurationChange"
          >
            <template
              v-if="defaultDuration && defaultDuration !== duration"
              #renderExtraFooter
            >
              <div style="line-height: 30px; display: flex; flex-direction: column; padding: 4px 0;">
                <a-typography-link
                  style="display: block; line-height: 30px;"
                  @click="setCustomDuration"
                >
                  {{ $t('components.slideCard.defaultMediaDurationTooltip') }}
                </a-typography-link>
              </div>
            </template>
          </a-time-picker>
          <a-typography-text type="secondary">
            {{ $t('components.durationSlideStep.duration') }}
          </a-typography-text>
        </a-space>
      </a-form-item>
    </a-form>
  </SlideStep>
</template>

<style scoped lang="less">

</style>
