<template>
  <a-layout style="height: 100%; overflow-x: auto;">
    <template v-if="currentGroupLoaded">
      <SmartGroupDevicesPage v-if="currentGroupTypeIsSmart" />
      <StandardGroupDevicesPage
        v-else
        :group-id="groupId"
      />
    </template>
  </a-layout>
</template>

<script>
import { computed, defineComponent } from 'vue'
import StandardGroupDevicesPage from '@/views/StandardGroupDevicesPage.vue'
import SmartGroupDevicesPage from '@/views/SmartGroupDevicesPage.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    SmartGroupDevicesPage,
    StandardGroupDevicesPage,
  },
  props: {
    groupId: {
      type: String
    }
  },
  setup () {
    const store = useStore()
    const currentGroupLoaded = computed(() => store.getters['groups/currentGroupLoaded'])
    const currentGroupTypeIsSmart = computed(() => store.getters['groups/currentGroupTypeIsSmart'])

    return {
      currentGroupLoaded,
      currentGroupTypeIsSmart
    }
  }

})
</script>
<style lang="less">

</style>
