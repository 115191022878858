<template>
  <a-layout-content style="overflow-x: auto; display: flex; align-items: center; justify-content: center;">
    <a-empty>
      <template #description>
        <span>
          {{ $t('components.connectNowView.connect', {title}) }}
        </span>
      </template>
      <a-button
        type="primary"
        @click="addSocialAccount(platform)"
      >
        {{ $t('components.connectNowView.connectNowButtonText') }}
      </a-button>
    </a-empty>
  </a-layout-content>
</template>

<script>
import { defineComponent } from 'vue'
import { addAccount } from '@/helpers/SocialAccount'

export default defineComponent({
  name: 'ConnectNowView',
  props: {
    title: String,
    platform: String
  },
  setup () {
    return {
      addSocialAccount: addAccount
    }
  }
})

</script>

<style scoped>

</style>
