import ApolloClient from '@/apollo'
import { LIST_TAGS } from '@/graphql/queries'
import { handleError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    tags: [],
    groupsTags: [],
    devicesTags: [],
    addBlockFunction: null
  },
  actions: {
    async getAllTags ({ commit }) {
      try {
        const { data: { listTags } } = await ApolloClient.query({ query: LIST_TAGS, variables: { filters: {} }, fetchPolicy: 'no-cache' })
        commit('SET_TAGS', listTags)
      } catch (e) {
        handleError(e)
      }
    },
    async getDevicesTags ({ commit }) {
      try {
        const { data: { listTags } } = await ApolloClient.query({ query: LIST_TAGS, variables: { filters: { linkTypes: 'DEVICE_TAG'} }, fetchPolicy: 'no-cache' })
        commit('SET_DEVICES_TAGS', listTags)
      } catch (e) {
        handleError(e)
      }
    },
    async getGroupsTags ({ commit }) {
      try {
        const { data: { listTags } } = await ApolloClient.query({ query: LIST_TAGS, variables: { filters: { linkTypes: 'GROUP_TAG'} }, fetchPolicy: 'no-cache' })
        commit('SET_GROUPS_TAGS', listTags)
      } catch (e) {
        handleError(e)
      }
    },
    setAddBlockFunction({state}, fn) {
      state.addBlockFunction = fn
    }
  },
  getters: {
    tags: state => state.tags,
    groupsTags: state => state.groupsTags,
    devicesTags: state => state.devicesTags,
    addBlockFunction: state => state.addBlockFunction
  },
  mutations: {
    SET_TAGS (state, tags) {
      state.tags = tags
    },
    SET_DEVICES_TAGS (state, tags) {
      state.devicesTags = tags
    },
    SET_GROUPS_TAGS (state, tags) {
      state.groupsTags = tags
    },
    CLEAR_TAGS_DATA (state) {
      state.tags = []
    }
  }
}
