<template>
  <div>
    <div style="align-items: center; text-align: center">
      <p>Design and make edits to the slide on Canva Interface</p>
      <a-button>Design with Canva</a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CanvaSlide',
  components: {},
  props: {
    title: String,
    slide: Object,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:title', 'update:disabled', 'update-slide'],
  setup (props, { emit }) {
    emit('update:title', 'Canva Extention')

    emit('update:disabled', false)

    return {}
  }
})
</script>

<style lang="less">

</style>
