<template>
  <div
    class="slide-type"
    :class="{ active }"
    :disabled="disabled"
    @click="changeType"
  >
    <figure>
      <div class="icon-container">
        <InlineSvg
          :src="image"
          :fill="active ? 'white' : 'black'"
        />
      </div>
      <figcaption :style="{textAlign: centered ? 'center': 'left'}">
        {{ text }}
      </figcaption>
    </figure>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  name: 'SlideView',
  components: {
    InlineSvg
  },
  props: {
    type: String,
    text: String,
    active: Boolean,
    image: String,
    disabled: Boolean,
    centered: Boolean
  },
  emits: ['change-type'],
  setup (props, { emit }) {
    const changeType = () => {
      emit('change-type', props.type)
    }
    return {
      changeType
    }
  }
})
</script>

<style scoped>

</style>
