<script>
import { defineComponent, onBeforeUnmount } from 'vue'
import { loadExternalScript, unloadExternalScript } from '@/utils'

const EXTERNAL_SCRIPT = '/pixie/pixie-integrate.js'


export default defineComponent({
  name: 'PixieComponent',
  emits: ['update', 'close', 'load'],
  setup (props, {emit}) {
    let pixie = window.Pixie || null

    const initPixie = (callback) => {
      if (pixie) {
        loadEmits(pixie)
        callback && callback()
        return
      }
      loadExternalScript(EXTERNAL_SCRIPT, '/pixie').then(()=>{
        pixie = window.Pixie && window.Pixie.setOptions({
          appendTo: 'body',
          enableCORS: true
        })
        loadEmits(pixie)
        pixie && pixie.setAssetsPath(process.env.VUE_APP_STATIC_ASSETS + '/composer')
        callback && callback()
      })
    }

    const loadEmits = (pixie) => {
      pixie.setOptions({
        onSaveButtonClick: () => {
          pixie.save()
        },
        onSave: (data) => {
          emit('update', data)
        },
        onClose: () => {
          emit('close', 'data')
        },
        onLoad: () => {
          emit('load')
        }
      })
    }

    const initAndOpenPixie = ({assets, images, json, aspectRatio, templates, getTemplateJson} = {}) => {
      initPixie(() => openModal({assets, images, json, aspectRatio, templates, getTemplateJson}))
    }

    const openModal = ({assets = [], images = [], json = {}, aspectRatio, templates = [], getTemplateJson} = {}) => {
      pixie && pixie.open({ images, assets, templates, getTemplateJson }, json, aspectRatio)
    }

    const closeModal = () => {
      pixie && pixie.close()
    }

    onBeforeUnmount(() => {
      pixie && pixie.destroy()
      unloadExternalScript(EXTERNAL_SCRIPT)
      delete window.Pixie
    })

    return {
      openModal: initAndOpenPixie,
      closeModal
    }
  }
})
</script>

<template>
  <template />
</template>
