<template>
  <a-layout-content style="overflow-x: auto">
    <a-modal
      :open="showFilesBulkMoveModal"
      width="572px"
      centered
      :closable="true"
      destroy-on-close
      :title="$t('components.storageView.moveFileModalTitle', { count: selectedMediaIds?.length, to: selectedTitle }, selectedMediaIds?.length)"
      wrap-class-name="full-height-modal"
      @close="closeFilesBulkMoveModal"
      @cancel="closeFilesBulkMoveModal"
    >
      <template #footer>
        <a-button
          type="primary"
          :disabled="selectedTreeKeys.length === 0"
          @click="handleFilesBulkMove"
        >
          {{ $t('components.storageView.moveButtonText') }}
        </a-button>
      </template>
      <a-spin :spinning="foldersLoading">
        <div style="min-height: 300px;">
          <a-tree
            v-if="showFilesBulkMoveModal"
            :selected-keys="selectedTreeKeys"
            :expanded-keys="expandedKeys"
            :load-data="onLoadData"
            :tree-data="folders"
            show-icon
            @expand="onTreeExpand"
            @select="onSelect"
          >
            <template #icon="{ isGroup }">
              <InboxOutlined v-if="isGroup" />
              <FolderOutlined v-else />
            </template>
          </a-tree>
        </div>
      </a-spin>
    </a-modal>
    <a-row
      type="flex"
      justify="space-between"
      style="position: sticky; z-index: 10; top: -24px; padding: 24px 16px 16px; margin-top: -24px; background-color: #fff"
    >
      <a-col span="8">
        <a-input-search
          v-model:value="nameFilter"
          :placeholder="$t('components.storageView.filterPlaceholder')"
          style="width: 200px"
        />
      </a-col>
      <a-col>
        <a-space>
          <a-button
            @click="startFolderCreate"
          >
            <template #icon>
              <FolderOutlined />
            </template>
            {{ $t('components.storageView.createFolderButtonText') }}
          </a-button>
          <a-button
            type="primary"
            @click="beforeFileUpload(mediaTypes)"
          >
            <template #icon>
              <UploadOutlined />
            </template>
            {{ $t('components.storageView.uploadButtonText') }}
          </a-button>
        </a-space>
      </a-col>
    </a-row>
    <MediaTable
      ref="mediaTable"
      padded
      :group-id="groupId"
      :exclude-headings="excludeHeadings"
      :types="mediaTypes"
      :selection="selection"
      :show-move="true"
      :folder-select-disabled="folderSelectDisabled"
      :filter="{dataIndex: 'name', value: nameFilter}"
      @move="onFilesBulkMove"
      @change-folder="onFolderChange"
    />
  </a-layout-content>
  <StickyFooter v-if="selectedMediaIds?.length">
    <FixedFooter>
      <template #left>
        {{ $t('components.storageView.filesSelected', { count: selectedMediaIds.length }, selectedMediaIds.length) }}
      </template>
      <template
        v-if="!disableActions"
        #right
      >
        <a-space>
          <a-button
            size="small"
            @click="onFilesBulkMove()"
          >
            {{ $t('components.storageView.moveButtonText') }}
          </a-button>
          <a-popconfirm
            :title="$t('components.storageView.popConfirmTitleWithCount', null, selectedMediaIds.length)"
            :ok-text="$t('components.storageView.popConfirmOkText')"
            :cancel-text="$t('components.storageView.popConfirmCancelText')"
            placement="bottomRight"
            @confirm="onFilesBulkDelete"
          >
            <a-button size="small">
              {{ $t('components.storageView.deleteButtonText') }}
            </a-button>
          </a-popconfirm>
        </a-space>
      </template>
      <a-button
        size="small"
        @click="deselectAll"
      >
        {{ $t('components.storageView.deselectAllButtonText') }}
      </a-button>
    </FixedFooter>
  </StickyFooter>
</template>

<script>
import { computed, defineComponent, inject, onUnmounted, reactive, ref, toRefs, watch } from 'vue'
import MediaTable from '@/components/tables/MediaTable.vue'
import FixedFooter from '@/components/FixedFooter'
import { FolderOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { error, success } from '@/utils'
import { MEDIA_TYPES } from '@/constants'
import StickyFooter from '@/components/StickyFooter.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'StorageView',
  components: {
    StickyFooter,
    MediaTable,
    FixedFooter,
    InboxOutlined,
    UploadOutlined,
    FolderOutlined
  },
  props: {
    groupId: {
      type: String,
      default: () => null
    },
    mediaTypes: {
      type: Array,
      validator (value) {
        return value.every(t =>MEDIA_TYPES.includes(t))
      }
    },
    selection: {
      type: String,
      validator (value) {
        return ['single', 'multiple', 'none'].indexOf(value) !== -1
      }
    },
    excludeHeadings: {
      type: Array,
      default: () => []
    },
    folderSelectDisabled: Boolean,
    disableActions: Boolean
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const {
      startFileUpload,
      startFolderCreate,
      deleteMediaBulk
    } = inject('mediaService')
    const showFilesBulkMoveModal = ref(false)
    const foldersLoading = ref(false)
    const selectedMoveDestination = reactive({
      selectedTitle: null,
      selectedTreeKeys: [],
      selectedParentDirectoryMediaId: null
    })
    const mediaTable = ref()
    const expandedKeys = ref(['root'])
    const nameFilter = ref('')
    const selectedMediaIds = computed(() => store.getters['media/selectedMediaIds'])
    const parentDirectoryMediaId = computed(() => store.getters['media/parentDirectoryMediaId'])
    const folders = ref([])

    onUnmounted(() => {
      deselectAll()
      store.commit('media/CLEAR_PARENT_DIRECTORY_MEDIA_ID')
    })

    const initFoldersTree = async () => {
      expandedKeys.value = ['root']
      return fetchFolders({
            parentDirectoryMediaId: null
          }
      ).then(listMedia => {
        folders.value = [{
          title: t('components.storageView.rootFolder'),
          key: 'root',
          parentDirectoryMediaId: null,
          selectable: parentDirectoryMediaId.value !== null,
          isLeaf: false,
          isLoaded: true,
          children: getMediaFolderTreeView(listMedia)
        }]
      })
    }

    const deselectAll = () => {
      store.commit('media/CLEAR_SELECTED_MEDIA')
    }

    const onFilesBulkMove = () => {
      showFilesBulkMoveModal.value = true
    }

    const moveMedia = (payload) => {
      return store.dispatch('media/moveMediaBulk', payload)
    }

    const handleMoveMedia = (payload) => {
      return moveMedia(payload).then(() => {
        closeFilesBulkMoveModal()
        deselectAll()
        success()
      })
    }

    const handleDeleteMedia = () => {
      closeFilesBulkMoveModal()
      deselectAll()
      success()
    }

    const handleFilesBulkMove = () => {
      const { selectedParentDirectoryMediaId: parentDirectoryMediaId } = selectedMoveDestination
      const fileIdsToMove = selectedMediaIds.value
      const payload = {
        ids: fileIdsToMove,
        input: {
          parentDirectoryMediaId
        }
      }
      handleMoveMedia(payload).catch((e) => {
        error(e.message || 'Error')
      })
    }

    const onFilesBulkDelete = () => {
      const fileIdsToDelete = selectedMediaIds.value
      deleteMediaBulk(
        { payload: { ids: fileIdsToDelete },
          success: handleDeleteMedia,
          error
        })
    }

    const onSelect = (data, { node }) => {
      if (selectedMediaIds.value?.includes(node.key)) return
      selectedMoveDestination.selectedTreeKeys = [node.key]
      selectedMoveDestination.selectedTitle = node.title
      selectedMoveDestination.selectedParentDirectoryMediaId = node.parentDirectoryMediaId
    }

    const resetBulkMoveSelection = () => {
      selectedMoveDestination.selectedTreeKeys = []
      selectedMoveDestination.selectedTitle = null
      selectedMoveDestination.selectedParentDirectoryMediaId = null
    }

    const closeFilesBulkMoveModal = () => {
      showFilesBulkMoveModal.value = false
      if (selectedMediaIds.value.length === 1) {
        deselectAll()
      }
      resetBulkMoveSelection()
    }

    const fetchFolders = async () => {
      foldersLoading.value = true
      const result = await store.dispatch('media/fetchMedia', {
        mimetypes: ['DIRECTORY']
      })
      foldersLoading.value = false

      return result
    }

    const getMediaFolderTreeView = (listWorkspaceMedia) => {
      return listWorkspaceMedia.map(m => ({
        title: m.name,
        key: m.id,
        parentDirectoryMediaId: m.id,
        selectable: parentDirectoryMediaId.value !== m.id,
        isLeaf: !m.childMedia?.some(m => m.type === 'DIRECTORY')
      }))
    }

    const onFolderChange = () => {
      nameFilter.value = ''
    }

    const beforeFileUpload = (mediaTypes) => {
      startFileUpload(mediaTypes, ()=>{
        mediaTable.value?.setTableSortByCreateDate && mediaTable.value.setTableSortByCreateDate()
      })
    }

    const onLoadData = (treeNode) => {
      return new Promise(resolve => {
        if (treeNode.dataRef.isLoaded) {
          return resolve()
        }
        const parentDirectoryMediaId = treeNode.dataRef.isGroup ? null : treeNode.dataRef.key
        return fetchFolders({
          parentDirectoryMediaId
        }
        ).then(listMedia => {
          treeNode.dataRef.children = [...treeNode.dataRef.children ?? [], ...getMediaFolderTreeView(listMedia)]
          treeNode.dataRef.isLoaded = true
          folders.value = [...folders.value]
          return resolve()
        })
      })
    }

    const onTreeExpand = (keys) => {
      expandedKeys.value = [...keys]
    }

    watch(showFilesBulkMoveModal, () => {
      initFoldersTree()
    })

    return {
      mediaTable,
      selectedMediaIds,
      folders,
      nameFilter,
      ...toRefs(selectedMoveDestination),
      showFilesBulkMoveModal,
      foldersLoading,
      parentDirectoryMediaId,
      expandedKeys,
      onTreeExpand,
      startFileUpload,
      startFolderCreate,
      beforeFileUpload,
      deselectAll,
      closeFilesBulkMoveModal,
      onSelect,
      onLoadData,
      onFolderChange,
      onFilesBulkMove,
      onFilesBulkDelete,
      handleFilesBulkMove
    }
  }
})
</script>

<style scoped>

</style>
