<template>
  <div class="direction-select">
    {{ $t('components.smartTemplateConstructor.directionSelect.title') }}
    <a-button
      shape="circle"
      type="default"
      @click="onDirectionChange"
    >
      <a-tooltip
        :title="$t('components.smartTemplateConstructor.directionSelect.tooltipText')"
        placement="left"
      >
        <ReloadOutlined :rotate="valueModel + 180" />
      </a-tooltip>
    </a-button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { ReloadOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'DirectionSelect',
  components: {
    ReloadOutlined
  },
  props: {
    valueModel: Number
  },
  emits: ['update:valueModel'],
  setup (props, { emit }) {
    const onDirectionChange = () => {
      const direction = props.valueModel + 45
      emit('update:valueModel', direction >= 360 ? 0 : direction)
    }
    return {
      onDirectionChange
    }
  }
})
</script>

<style lang="less" scoped>
.direction-select {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
