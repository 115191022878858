<script setup>

</script>

<template>
  <a-empty />
</template>

<style scoped lang="less">

</style>
