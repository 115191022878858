const APIKey = process.env.VUE_APP_GOOGLE_TIMEZONE_API_KEY

export const getTimezoneByLocation = (location) => {
  return fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=1458000000&key=${APIKey}`, {
    method: 'GET'
  })
    .then((res) => res.json())
    .then((json) => {
      if (json.status !== 'OK') {
        throw new Error(json.error)
      }
      return json
    })
}

export const openLoginWithGoogleWindow = () => {
  const authWindow = window.open(process.env.VUE_APP_GOOGLE_LOGIN_LINK, 'kitcast', 'height=600,width=800,toolbar=0')
  const interval = setInterval(() => {
    if (!authWindow.closed) {
      authWindow.postMessage({ platform: 'google', action: 'login' }, process.env.VUE_APP_DEFAULT_URL)
    } else {
      clearInterval(interval)
    }
  }, 500)
  if (authWindow.focus) {
    authWindow.focus()
  }
}
