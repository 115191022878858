<template>
  <div>
    <a-modal
      :open="showPasswordNeededModal"
      width="300px"
      :title="$t(`components.securitySettings.${actionWith2fa === ACTIONS_WITH_2FA.enable ? 'confirmPassModalEnableTitle' : 'confirmPassModalDisableTitle'}`)"
      :cancel-button-props="{disabled: loading2fa}"
      :ok-button-props="{loading:loading2fa, disabled: !otpActionFormState.passFor2fa || actionWith2fa === ACTIONS_WITH_2FA.disable && !otpActionFormState.otpCodeFor2faDisable}"
      @ok="handle2faAction"
      @cancel="reset2faModal"
    >
      <template v-if="actionWith2fa === ACTIONS_WITH_2FA.enable">
        <a-typography-paragraph>
          {{ $t('components.securitySettings.enable2faModalInfo') }}
        </a-typography-paragraph>
        <a-form
          ref="enable2faFormRef"
          layout="vertical"
          :model="otpActionFormState"
          :rules="otpActionsRules"
          style="margin-top: 16px;"
          :disabled="loading2fa"
          @finish="start2faSetup"
        >
          <a-form-item
            name="passFor2fa"
          >
            <a-input-password
              ref="enable2faPasswordRef"
              v-model:value="otpActionFormState.passFor2fa"
              :placeholder="$t('components.securitySettings.passwordFor2faPlaceholder')"
            />
          </a-form-item>
        </a-form>
      </template>
      <template v-else>
        <a-typography-paragraph>
          {{ $t('components.securitySettings.disable2faModalInfo') }}
        </a-typography-paragraph>
        <a-form
          ref="enable2faFormRef"
          layout="vertical"
          :model="otpActionFormState"
          :rules="otpActionsRules"
          style="margin-top: 16px;"
          :disabled="loading2fa"
          @finish="disable2FA"
        >
          <a-form-item
            name="passFor2fa"
          >
            <a-input-password
              ref="enable2faPasswordRef"
              v-model:value="otpActionFormState.passFor2fa"
              :placeholder="$t('components.securitySettings.passwordFor2faPlaceholder')"
              @press-enter="disable2FA"
            />
          </a-form-item>
          <a-form-item
            name="otpCodeFor2faDisable"
          >
            <a-input
              v-model:value="otpActionFormState.otpCodeFor2faDisable"
              :placeholder="$t('components.securitySettings.otpCodeFor2faPlaceholder')"
              @press-enter="disable2FA"
            />
          </a-form-item>
        </a-form>
      </template>
    </a-modal>
    <a-typography-title :level="5">
      {{ $t('components.securitySettings.title') }}
    </a-typography-title>
    <a-divider />
    <a-typography-paragraph strong>
      {{ $t('components.securitySettings.passwordSettings') }}
    </a-typography-paragraph>
    <a-form
      ref="passwordFormRef"
      layout="vertical"
      style="margin-bottom: 32px;"
      :model="passwordFormState"
      :rules="passwordRules"
      @finish="onChangePassword"
    >
      <a-row :gutter="0">
        <a-col :span="12">
          <a-form-item
            :label="$t('components.securitySettings.oldPasswordLabel')"
            name="oldPassword"
          >
            <a-input-password
              v-model:value="passwordFormState.oldPassword"
              :placeholder="$t('components.securitySettings.oldPasswordPlaceholder')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="0">
        <a-col :span="12">
          <a-form-item
            :label="$t('components.securitySettings.newPasswordLabel')"
            name="password"
          >
            <a-input-password
              v-model:value="passwordFormState.password"
              :placeholder="$t('components.securitySettings.newPasswordPlaceholder')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="0">
        <a-col :span="12">
          <a-form-item
            :label="$t('components.securitySettings.confirmPasswordLabel')"
            name="confirmPassword"
          >
            <a-input-password
              v-model:value="passwordFormState.confirmPassword"
              :placeholder="$t('components.securitySettings.confirmPasswordPlaceholder')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          :loading="loading"
        >
          {{ $t('components.securitySettings.changeButtonText') }}
        </a-button>
      </a-form-item>
    </a-form>
    <a-divider />
    <a-form-item style="margin-bottom: 8px;">
      <a-switch
        :checked="state2Fa"
        @click="handle2FaStateChange"
      />
      <a-typography-text style="margin-left: 16px;">
        {{ $t('components.securitySettings.switch2FaTitle') }}
      </a-typography-text>
    </a-form-item>
    <div v-if="state2Fa">
      <template v-if="!is2faSet && qrCodeUrl">
        <a-row style="margin-top: 16px;margin-bottom: 16px;">
          <a-col :span="18">
            <a-typography-text>
              {{ $t('components.securitySettings.2FaDescription') }}
            </a-typography-text>
          </a-col>
        </a-row>
        <a-row :gutter="32">
          <a-col :span="7">
            <img
              :src="qrCodeUrl"
              alt="QR Code"
              style="max-width: 100%"
            >
          </a-col>
          <a-col span="17">
            <a-typography-paragraph style="margin-bottom: 4px;">
              {{ $t('components.securitySettings.2FaStep1') }}
              <a-typography-link
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                target="_blank"
              >
                Google Authenticator
              </a-typography-link>
            </a-typography-paragraph>
            <a-typography-paragraph style="margin-bottom: 4px;">
              {{ $t('components.securitySettings.2FaStep2') }}
            </a-typography-paragraph>
            <a-input-search
              v-if="otpToken"
              :value="otpToken"
              style="width: 300px; margin-top: 4px; margin-bottom: 8px;"
              @search="copyOtpToken"
            >
              <template #enterButton>
                <a-button>
                  <a-tooltip :title="$t('components.securitySettings.copyOtpTokenTooltip')">
                    <CopyOutlined />
                  </a-tooltip>
                </a-button>
              </template>
            </a-input-search>
            <a-typography-paragraph style="margin-bottom: 4px;">
              {{ $t('components.securitySettings.2FaStep3') }}
            </a-typography-paragraph>
            <a-input-search
              v-model:value="otpCode"
              :placeholder="$t('components.securitySettings.optPlaceholder')"
              size="large"
              :loading="otpCodeProcessing"
              style="width: 300px; margin-top: 8px;"
              maxlength="6"
              @search="handleOtpCodeEnter"
            >
              <template #enterButton>
                <a-button>{{ $t('components.securitySettings.verifyOtpCodeButtonText') }}</a-button>
              </template>
            </a-input-search>
          </a-col>
        </a-row>
        <a-row>
          <a-col />
        </a-row>
      </template>
      <template v-else-if="is2faSet">
        <a-typography-text>
          {{ $t('components.securitySettings.2FaIsOn') }}
        </a-typography-text>
      </template>
    </div>
    <a-divider />
    <a-typography-paragraph strong>
      {{ $t('components.securitySettings.activeSessions') }}
    </a-typography-paragraph>
    <a-list
      class="sessions-list"
      :loading="sessionsLoading"
    >
      <a-list-item
        v-for="session in sessionsList"
        :key="session.sessionId"
      >
        <a-list-item-meta>
          <template #title>
            {{ session.ip }} <a-typography-text type="secondary">
              ({{ session.country }})
            </a-typography-text>
            <a-tag
              v-if="session.isCurrent"
              style="margin-left: 4px;"
              color="var(--ant-primary-color)"
            >
              {{ $t('components.securitySettings.currentSession') }}
            </a-tag>
          </template>
        </a-list-item-meta>
        <a-space>
          <div class="log-date">
            <a-typography-text>
              <span v-format-date="{date: session.createdAt, format: 'MMM D, HH:mm'}" />
            </a-typography-text>
          </div>

          <a-button
            v-if="!session.isCurrent"

            size="small"
            type="default"
            @click="deleteSession(session.sessionId)"
          >
            {{ $t('components.securitySettings.logOut') }}
          </a-button>
        </a-space>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, reactive, ref, toRaw, watch } from 'vue'
import { FORM_ITEM_LAYOUT_DEFAULT } from '@/constants'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { error, getSecretFromOtpUrl, success } from '@/utils'
import qrcode from 'qrcode'
import { CopyOutlined } from '@ant-design/icons-vue'
import { copyText } from 'vue3-clipboard'

const PASSWORD_STATE = {
  oldPassword: '',
  password: '',
  confirmPassword: ''
}

function generateQRCode(otpauth) {
  return new Promise((resolve, reject) => {
    qrcode.toDataURL(otpauth, (err, imageUrl) => {
      if (err) {
        console.log('Error with QR:', err);
        reject(err);
      } else {
        resolve(imageUrl);
      }
    });
  });
}

const ACTIONS_WITH_2FA = {
  enable: 'enable',
  disable: 'disable'
}

export default defineComponent({
  name: 'SecuritySettings',
  components: {
    CopyOutlined
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const passwordFormRef = ref()
    const enable2faFormRef = ref()
    const enable2faPasswordRef = ref()
    const loading = ref(false)
    const loading2fa = ref(false)
    const passwordFormState = reactive({ ...PASSWORD_STATE })
    const showGlobalSettings = computed(() => store.getters['showGlobalSettings'])
    const sessionsList = computed(() => store.getters['auth/sessionsList'])
    const sessionsLoading = computed(() => store.getters['auth/sessionsLoading'])
    const is2faSet = computed(() => store.getters['auth/is2faSet'])
    const state2Fa = ref(is2faSet.value)
    const qrCodeUrl = ref('')
    const otpCode = ref('')
    const otpCodeProcessing = ref(false)
    const otpToken = ref(null)
    const otpActionFormState = reactive({
      passFor2fa: '',
      otpCodeFor2faDisable: ''
    })
    const showPasswordNeededModal = ref(false)
    const actionWith2fa = ref(is2faSet.value ? ACTIONS_WITH_2FA.enable : ACTIONS_WITH_2FA.disable)

    const validateConfirmPass = async (_rule, value) => {
      if (value === '') {
        // eslint-disable-next-line
        return Promise.reject(t('components.securitySettings.confirmPasswordRequiredError'))
      } else if (value !== passwordFormState.password) {
        // eslint-disable-next-line
        return Promise.reject(t('components.securitySettings.confirmPasswordMismatchError'))
      } else {
        return Promise.resolve()
      }
    }

    const passwordRules = computed(()=>{
      return {
        oldPassword: [{
          required: true,
          trigger: 'blur',
          message: t('components.securitySettings.oldPasswordInvalidError')
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: t('components.securitySettings.passwordInvalidError')
        },
        {
          trigger: 'blur',
          min: 8,
          message: t('components.securitySettings.passwordTooShortError')
        },
        {
          trigger: 'blur',
          max: 20,
          message: t('components.securitySettings.passwordTooLongError')
        }],
        confirmPassword: [{
          validator: validateConfirmPass,
          trigger: 'change'
        }]
      }
    })

    const otpActionsRules = computed(()=>{
      return {
        passFor2fa: [{
          required: true,
          trigger: 'blur',
          message: t('components.securitySettings.passwordInvalidError')
        }],
        otpCodeFor2faDisable: [{
          required: actionWith2fa.value === ACTIONS_WITH_2FA.disable,
          trigger: 'blur',
          message: t('components.securitySettings.otpCodeInvalidError')
        }]
      }
    })

    const resetForm = () => {
      passwordFormRef.value.resetFields()
    }

    const onChangePassword = async () => {
      loading.value = true
      const { confirmPassword, ...user } = toRaw(passwordFormState)
      store.dispatch('auth/updateProfile', user).then(() => {
        success(t('components.securitySettings.passwordChanged'))
        resetForm()
      }).catch((e) => {
        const [errorObj] = e.graphQLErrors
        const [message] = errorObj?.extensions?.response?.message || [e.message]
        error(message)
      }).then(() => {
        loading.value = false
      })
    }

    const deleteSession = (sessionId) => {
      store.dispatch('auth/deleteUserSessionById', sessionId).then(()=>{
        success()
      }).catch(e=> {
        error(e.message)
      })
    }

    const handle2FaStateChange = (value) => {
      actionWith2fa.value = value ? ACTIONS_WITH_2FA.enable : ACTIONS_WITH_2FA.disable
      if (!is2faSet.value && !value) {
        state2Fa.value = false
        reset2faModal()
        return
      }
      showPasswordNeededModal.value = true
      nextTick(()=>{
        enable2faPasswordRef.value.focus()
      })
    }

    const handle2faAction = () => {
      if (actionWith2fa.value === ACTIONS_WITH_2FA.enable) {
        start2faSetup()
      } else {
        disable2FA()
      }
    }

    const start2faSetup = () => {
      loading2fa.value = true
      store.dispatch('auth/prepare2Fa', {password: otpActionFormState.passFor2fa}).then(async (otpUrl) => {
        showPasswordNeededModal.value = false
        const imageUrl = await generateQRCode(otpUrl)
        state2Fa.value = true
        qrCodeUrl.value = imageUrl
        otpToken.value = getSecretFromOtpUrl(otpUrl)
        reset2faModal()
      }).catch(e=>{
        error(e.message)
      }).finally(()=>{
        loading2fa.value = false
      })
    }

    const disable2FA = async () => {
      try {
        await enable2faFormRef.value.validate()
      } catch (e) {
        return
      }
      loading2fa.value = true
      store.dispatch('auth/disable2Fa', {password: otpActionFormState.passFor2fa, twoFactorCode: otpActionFormState.otpCodeFor2faDisable}).then(() => {
        success(t('components.securitySettings.2FaDisabled'))
        showPasswordNeededModal.value = false
        reset2faModal()
        state2Fa.value = false
        qrCodeUrl.value = ''
      }).catch(e=>{
        error(e.message)
      }).finally(()=>{
        loading2fa.value = false
      })
    }

    const handleOtpCodeEnter = (otpCode) => {
      if (otpCode?.length === 6 && /^\d+$/.test(otpCode)) {
        otpCodeProcessing.value = true
        store.dispatch('auth/finalize2faSetup', otpCode).then(()=>{
          qrCodeUrl.value = ''
          success(t('components.securitySettings.2FaEnabled'))
        }).catch(e=>{
          error(e.message)
        }).finally(()=>{
          otpCodeProcessing.value = false
        })
      }
    }

    const copyOtpToken = () => {
      copyText(otpToken.value,undefined, (error) => {
        if (error) {
        } else {
          success(t('components.securitySettings.otpTokenCopied'))
        }
      })
    }

    const reset2faModal = () => {
      showPasswordNeededModal.value = false
      otpActionFormState.passFor2fa = ''
      otpActionFormState.otpCodeFor2faDisable = ''
      enable2faFormRef.value.resetFields()
    }

    onMounted(()=>{
      store.dispatch('auth/getUserSessions')
    })

    watch(showGlobalSettings, (value)=>{
      value && store.dispatch('auth/getUserSessions')
    })

    watch(() => is2faSet.value, (value)=> {
      state2Fa.value = value
    })

    return {
      is2faSet,
      state2Fa,
      loading,
      passwordFormRef,
      passwordFormState,
      passwordRules,
      sessionsList,
      sessionsLoading,
      qrCodeUrl,
      otpToken,
      otpCode,
      otpCodeProcessing,
      showPasswordNeededModal,
      otpActionFormState,
      otpActionsRules,
      ACTIONS_WITH_2FA,
      actionWith2fa,
      enable2faFormRef,
      enable2faPasswordRef,
      loading2fa,
      copyOtpToken,
      reset2faModal,
      handleOtpCodeEnter,
      handle2FaStateChange,
      start2faSetup,
      disable2FA,
      handle2faAction,
      formItemLayout: FORM_ITEM_LAYOUT_DEFAULT,
      onChangePassword,
      deleteSession
    }
  }

})
</script>

<style scoped lang="less">

</style>
