import { createStore } from 'vuex'
import auth from './auth'
import workspace from './workspace'
import tags from './tags'
import groups from './groups'
import playlist from './playlist'
import devices from './devices'
import social from './social'
import slides from './slides'
import media from './media'
import apiKeys from './apiKeys'
import subscription from './subscription'
import notifications from './notifications'
import onboarding from './onboarding'
import { Modal, theme } from 'ant-design-vue'
import ApolloClient from '@/apollo'
import { GET_APPLICATION_PUBLIC_CONFIG, } from '@/graphql/queries'
import { DEFAULT_ACCENT_COLOR, DEFAULT_BORDER_RADIUS } from '@/constants'
import { cloneDeep, merge } from 'lodash'
import chroma from 'chroma-js'

function getAccentBgColor (color) {
  return chroma(color).alpha(0.1).css()
}

export default createStore({
  state: {
    showGlobalSettings: false,
    showCollaboration: false,
    showAuthorizedAccounts: false,
    showEmergencyPlaylistModal: false,
    showMedia: false,
    showDevices: false,
    showContactUs: false,
    showUpdateSubscription: false,
    showUpdatePaymentMethod: false,
    showEDUApplianceModal: false,
    showCreateWorkspaceModal: false,
    showEmailConfirmedModal: false,
    globalSettingsActiveTab: 'profile',
    whitelabel: null,
    config: null,
    prevRoute: null,
    themeConfig: {
      hashed: false,
      token: {
        colorPrimary: DEFAULT_ACCENT_COLOR,
        borderRadius: DEFAULT_BORDER_RADIUS,
        colorPrimaryBg: getAccentBgColor(DEFAULT_ACCENT_COLOR),
      },
      algorithm: theme.defaultAlgorithm
    },
    groupSidebarCollapsed: !!JSON.parse(localStorage.getItem('groupSidebarCollapsed'))
  },
  actions: {
    setPrevRoute ({commit}, route) {
      commit('SET_PREV_ROUTE', route)
    },
    openGlobalSettings ({ commit }, tab) {
      commit('OPEN_GLOBAL_SETTINGS', tab)
    },
    openGlobalSettingsSubscription ({ commit }) {
      commit('OPEN_GLOBAL_SETTINGS', 'subscription')
    },
    closeGlobalSettings ({ commit }) {
      commit('CLOSE_GLOBAL_SETTINGS')
    },
    openCollaboration ({ commit }) {
      commit('OPEN_COLLABORATION')
    },
    closeCollaboration ({ commit }) {
      commit('CLOSE_COLLABORATION')
    },
    openAuthorizedAccounts ({ commit }) {
      commit('OPEN_AUTHORIZED_ACCOUNTS')
    },
    closeAuthorizedAccounts ({ commit }) {
      commit('CLOSE_AUTHORIZED_ACCOUNTS')
    },
    openEmergencyPlaylistModal ({ commit }) {
      commit('OPEN_EMERGENCY_PLAYLIST_MODAL')
    },
    closeEmergencyPlaylistModal ({ commit }) {
      commit('CLOSE_EMERGENCY_PLAYLIST_MODAL')
    },
    openMedia ({ commit }) {
      commit('OPEN_MEDIA')
    },
    closeMedia ({ commit }) {
      commit('CLOSE_MEDIA')
    },
    openDevices ({ commit }) {
      commit('OPEN_DEVICES')
    },
    closeDevices ({ commit }) {
      commit('CLOSE_DEVICES')
    },
    handleUpdateSubscription ({ commit, rootGetters, dispatch }) {
      if (rootGetters['workspace/isEnterprize']) {
        dispatch('openContactUs')
      }
      else {
        dispatch('openUpdateSubscription')
      }
    },
    openContactUs ({ commit }) {
      commit('OPEN_CONTACT_US')
    },
    closeContactUs ({ commit }) {
      commit('CLOSE_CONTACT_US')
    },
    openCreateWorkspace ({ commit }) {
      commit('OPEN_CREATE_WORKSPACE')
    },
    closeCreateWorkspace ({ commit }) {
      commit('CLOSE_CREATE_WORKSPACE')
    },
    openEmailConfirmed ({ commit }) {
      commit('OPEN_EMAIL_CONFIRMED_MODAL')
    },
    closeEmailConfirmed ({ commit }) {
      commit('CLOSE_EMAIL_CONFIRMED_MODAL')
    },
    openUpdateSubscription ({ commit, rootGetters }) {
      if (rootGetters['workspace/isEnterprize']) {
        return commit('OPEN_CONTACT_US')
      }
      commit('OPEN_UPDATE_SUBSCRIPTION')
    },
    closeUpdateSubscription ({ commit }) {
      commit('CLOSE_UPDATE_SUBSCRIPTION')
    },
    openUpdatePaymentMethod ({ commit }) {
      commit('OPEN_UPDATE_PAYMENT_METHOD')
    },
    closeUpdatePaymentMethod ({ commit }) {
      commit('CLOSE_UPDATE_PAYMENT_METHOD')
    },
    openEDUAppliance ({ commit }) {
      commit('OPEN_EDU_APPLIANCE_MODAL')
    },
    closeEDUAppliance ({ commit }) {
      commit('CLOSE_EDU_APPLIANCE_MODAL')
    },
    async setApplicationPublicConfig ({ commit }) {
      try {
        const { data: { getApplicationPublicConfig }} = await ApolloClient.query({ query: GET_APPLICATION_PUBLIC_CONFIG })
        commit('SET_CONFIG', getApplicationPublicConfig)
      } catch (e) {
        console.log(e.message)
      }
    },
    toggleGroupSidebar ({ commit }) {
      commit('TOGGLE_GROUP_SIDEBAR')
    },
    closeAllModals ({ commit }) {
      Modal.destroyAll()
      commit('CLOSE_UPDATE_PAYMENT_METHOD')
      commit('CLOSE_UPDATE_SUBSCRIPTION')
      commit('CLOSE_CONTACT_US')
      commit('CLOSE_GLOBAL_SETTINGS')
      commit('CLOSE_COLLABORATION')
      commit('CLOSE_MEDIA')
      commit('CLOSE_DEVICES')
      commit('CLOSE_AUTHORIZED_ACCOUNTS')
      commit('CLOSE_EMERGENCY_PLAYLIST_MODAL')
      commit('CLOSE_CREATE_WORKSPACE')
    },
    changeAccentColor ({ commit, dispatch }, color) {
      commit('SET_THEME_CONFIG', {token: {colorPrimary: color, colorPrimaryBg: getAccentBgColor(color)}})
    },
    setThemeConfig ({ commit, dispatch }, themeConfig) {
      commit('SET_THEME_CONFIG', themeConfig)
    }
  },
  getters: {
    groupSidebarCollapsed: state => state.groupSidebarCollapsed,
    prevRoute: state => state.prevRoute,
    config: state => state.config,
    themeConfig: state => state.themeConfig,
    showGlobalSettings: state => state.showGlobalSettings,
    showCreateWorkspaceModal: state => state.showCreateWorkspaceModal,
    showCollaboration: state => state.showCollaboration,
    showAuthorizedAccounts: state => state.showAuthorizedAccounts,
    showEmergencyPlaylistModal: state => state.showEmergencyPlaylistModal,
    showEmailConfirmedModal: state => state.showEmailConfirmedModal,
    showEDUApplianceModal: state => state.showEDUApplianceModal,
    showContactUs: state => state.showContactUs,
    showMedia: state => state.showMedia,
    showDevices: state => state.showDevices,
    showUpdateSubscription: state => state.showUpdateSubscription,
    showUpdatePaymentMethod: state => state.showUpdatePaymentMethod,
    globalSettingsActiveTab: state => state.globalSettingsActiveTab,
    globalSettingsActiveTabOpened: state => state.showGlobalSettings ? state.globalSettingsActiveTab : null
  },
  mutations: {
    OPEN_GLOBAL_SETTINGS (state, tab) {
      state.globalSettingsActiveTab = tab
      state.showGlobalSettings = true
    },
    CHANGE_GLOBAL_SETTINGS_TAB(state, tab) {
      state.globalSettingsActiveTab = tab
    },
    CLOSE_GLOBAL_SETTINGS (state) {
      state.showGlobalSettings = false
    },
    OPEN_COLLABORATION (state) {
      state.showCollaboration = true
    },
    CLOSE_COLLABORATION (state) {
      state.showCollaboration = false
    },
    OPEN_AUTHORIZED_ACCOUNTS (state) {
      state.showAuthorizedAccounts = true
    },
    CLOSE_AUTHORIZED_ACCOUNTS (state) {
      state.showAuthorizedAccounts = false
    },
    OPEN_EMERGENCY_PLAYLIST_MODAL (state) {
      state.showEmergencyPlaylistModal = true
    },
    CLOSE_EMERGENCY_PLAYLIST_MODAL (state) {
      state.showEmergencyPlaylistModal = false
    },
    OPEN_EDU_APPLIANCE_MODAL (state) {
      state.showEDUApplianceModal = true
    },
    CLOSE_EDU_APPLIANCE_MODAL (state) {
      state.showEDUApplianceModal = false
    },
    OPEN_MEDIA (state) {
      state.showMedia = true
    },
    CLOSE_MEDIA (state) {
      state.showMedia = false
    },
    OPEN_DEVICES (state) {
      state.showDevices = true
    },
    CLOSE_DEVICES (state) {
      state.showDevices = false
    },
    OPEN_CONTACT_US (state) {
      state.showContactUs = true
    },
    CLOSE_CONTACT_US (state) {
      state.showContactUs = false
    },
    OPEN_UPDATE_SUBSCRIPTION (state) {
      state.showUpdateSubscription = true
    },
    CLOSE_UPDATE_SUBSCRIPTION (state) {
      state.showUpdateSubscription = false
    },
    OPEN_UPDATE_PAYMENT_METHOD (state) {
      state.showUpdatePaymentMethod = true
    },
    CLOSE_UPDATE_PAYMENT_METHOD (state) {
      state.showUpdatePaymentMethod = false
    },
    OPEN_CREATE_WORKSPACE (state) {
      state.showCreateWorkspaceModal = true
    },
    CLOSE_CREATE_WORKSPACE (state) {
      state.showCreateWorkspaceModal = false
    },
    OPEN_EMAIL_CONFIRMED_MODAL (state) {
      state.showEmailConfirmedModal = true
    },
    CLOSE_EMAIL_CONFIRMED_MODAL (state) {
      state.showEmailConfirmedModal = false
    },
    SET_WHITELABEL (state, whitelabel) {
      state.whitelabel = whitelabel
    },
    SET_CONFIG (state, config) {
      state.config = config
    },
    CLEAR_CONFIG (state) {
      state.config = null
    },
    SET_LANGUAGE (state, language) {
      state.language = language
    },
    SET_PREV_ROUTE (state, route) {
      state.prevRoute = route
    },
    CLEAR_PREV_ROUTE (state) {
      state.prevRoute = null
    },
    TOGGLE_GROUP_SIDEBAR (state) {
      localStorage.setItem('groupSidebarCollapsed', !state.groupSidebarCollapsed+'')
      state.groupSidebarCollapsed = !state.groupSidebarCollapsed
    },
    SET_THEME_CONFIG (state, themeConfig) {
      state.themeConfig = cloneDeep(merge({}, state.themeConfig, themeConfig))
    }
  },
  modules: {
    auth,
    tags,
    workspace,
    groups,
    playlist,
    social,
    devices,
    media,
    slides,
    subscription,
    onboarding,
    notifications,
    apiKeys
  }
})
