import CirclesTriangles from './circles-triangles.json'
import Circles from './circles.json'
import Clock from './clock.json'
import Spirals from './spirals.json'
import Triangles from './triangles.json'
import Tripattern from './tripattern.json'
import Memphis from './memphis.json'
import Memphis2 from './memphis2.json'

export const ANIMATIONS = {
  memphis: Memphis,
  memphis2: Memphis2,
  clock: Clock,
  circles: Circles,
  circles_triangles: CirclesTriangles,
  spirals: Spirals,
  triangles: Triangles,
  tripattern: Tripattern
}

export const DEFAULT_ANIMATIONS = {
  circles: Circles,
  circles_triangles: CirclesTriangles,
  spirals: Spirals,
  triangles: Triangles,
  tripattern: Tripattern
}
