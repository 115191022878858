import ApolloClient from '@/apollo'
import {
  GET_EMERGENCY_PLAYLISTS,
  GET_PLAYLIST_BY_ID,
  GET_PLAYLIST_CHANGELOGS,
  LIST_WIDGET_DISPLAY_RESTRICTIONS
} from '@/graphql/queries'
import {
  ADD_PLAYLIST_SLIDE,
  COPY_PLAYLIST_BY_ID, COPY_PLAYLIST_SLIDES, CREATE_PLAYLIST, DELETE_PLAYLIST_BY_ID,
  DELETE_PLAYLIST_SLIDE, DELETE_PLAYLIST_SOUNDTRACK,
  EXPORT_SLIDES_TO_PLAYLISTS,
  MOVE_PLAYLIST_BY_ID,
  MOVE_PLAYLIST_SLIDE,
  PUBLISH_PLAYLIST_VERSION, SET_PLAYLIST_SOUNDTRACK_URL,
  UPDATE_PLAYLIST_BY_ID,
  UPDATE_PLAYLIST_SLIDE, UPLOAD_PLAYLIST_SOUNDTRACK
} from '@/graphql/mutations'
import { LAYOUT_ZONE_KEYS_TO_INDEX } from '@/constants'
import { handleError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    playlist: null,
    playlistChangelogs: null,
    playlistChangelogsLoading: false,
    playlistLoading: false,
    emergencyPlaylistsLoading: false,
    widgetDisplayRestrictions: null,
    emergencyPlaylists: []
  },
  actions: {
    async refreshPlaylist ({ dispatch, state }) {
      const playlistId = state.playlist?.id
      await dispatch('getPlaylist', playlistId)
    },
    async getPlaylist ({ commit, state }, playlistId) {
      if (!playlistId) return
      state.playlistLoading = true
      try {
        const { data: { getPlaylist } } = await ApolloClient.query({ query: GET_PLAYLIST_BY_ID, variables: { id: playlistId }, fetchPolicy: 'no-cache' })
        commit('SET_PLAYLIST', getPlaylist)
      } catch (e) {
        state.playlistLoading = false
        handleError(e)
      }
    },
    async getEmergencyPlaylists ({ commit, state }) {
      state.emergencyPlaylistsLoading = true
      try {
        const { data: { listPlaylists } } = await ApolloClient.query({ query: GET_EMERGENCY_PLAYLISTS, variables: {filters: { specialization: 'EMERGENCY'}},  fetchPolicy: 'no-cache' })
        commit('SET_EMERGENCY_PLAYLISTS', listPlaylists.sort((a, b) => a.createdAt.localeCompare(b.createdAt)))
      } catch (e) {
        state.emergencyPlaylistsLoading = false
        handleError(e)
      }
    },
    async createEmergencyPlaylist ({ dispatch, state }, { layout }) {
      state.emergencyPlaylistsLoading = true
      const input = {
        color: null,
        layout,
        name: 'Emergency Playlist',
        paused: false,
        specialization: 'EMERGENCY'
      }
      try {
        await ApolloClient.mutate({ mutation: CREATE_PLAYLIST, variables: { input } })
        await dispatch('getEmergencyPlaylists')
      } catch (e) {
        state.emergencyPlaylistsLoading = false
        handleError(e)
      }
    },
    async deleteEmergencyPlaylist ({ dispatch, state }, payload) {
      state.emergencyPlaylistsLoading = true
      try {
        await ApolloClient.mutate({ mutation: DELETE_PLAYLIST_BY_ID, variables: payload, fetchPolicy: 'no-cache' })
        await dispatch('getEmergencyPlaylists')
      } catch (e) {
        state.emergencyPlaylistsLoading = true
        handleError(e)
      }
    },
    async widgetDisplayRestrictions ({ commit }) {
      try {
        const { data: { listWidgetDisplayRestrictions } } = await ApolloClient.query({ query: LIST_WIDGET_DISPLAY_RESTRICTIONS })
        commit('SET_WIDGET_DISPLAY_RESTRICTIONS', listWidgetDisplayRestrictions)
      } catch (e) {
        handleError(e)
      }
    },
    async getPlaylistChangelogs ({ commit, state }, playlistId) {
      if (!playlistId) return
      const pagination = {
        limit: 20
      }
      state.playlistChangelogsLoading = true
      try {
        const { data: { listPlaylistChangeLogPatches: { data } } } = await ApolloClient.query({ query: GET_PLAYLIST_CHANGELOGS, variables: { playlistId, pagination }, fetchPolicy: 'no-cache' })
        commit('SET_PLAYLIST_CHANGELOGS', data)
      } catch (e) {
        handleError(e)
      }
      finally {
        state.playlistChangelogsLoading = false
      }
    },
    async updatePlaylist ({ commit, state, getters }, { input, changeLogs }) {
      const playlistId = getters.playlistId
      state.playlistLoading = true
      try {
        const { data: { updatePlaylist } } = await ApolloClient.mutate({ mutation: UPDATE_PLAYLIST_BY_ID, variables: { id: playlistId, input, changeLogs } })
        commit('UPDATE_PLAYLIST', updatePlaylist)
        commit('groups/UPDATE_CURRENT_GROUP_PLAYLIST', updatePlaylist, { root: true })
      } catch (e) {
        state.playlistLoading = false
        handleError(e)
      }
    },
    async uploadPlaylistSoundtrack ({ commit, dispatch, getters, state }, file) {
      const playlistId = getters.playlistId
      state.playlistLoading = true
      try {
        const { data: { uploadPlaylistStaticMediaAudioByReference } } = await ApolloClient.mutate({ mutation: UPLOAD_PLAYLIST_SOUNDTRACK, variables: { file, playlistId }, context: { hasUpload: true } })
        commit('UPDATE_PLAYLIST', { mediaAudio: uploadPlaylistStaticMediaAudioByReference })
        return uploadPlaylistStaticMediaAudioByReference
      } catch (e) {
        handleError(e)
      }
    },
    async deletePlaylistSoundtrack ({ commit, state, getters }) {
      const playlistId = getters.playlistId
      state.playlistLoading = true
      try {
        await ApolloClient.mutate({ mutation: DELETE_PLAYLIST_SOUNDTRACK, variables: { playlistId } })
        state.playlistLoading = false
        commit('UPDATE_PLAYLIST', { mediaAudio: null })
      } catch (e) {
        handleError(e)
      }
    },
    async setPlaylistSoundtrackUrl ({ commit, state, getters }, {name, url}) {
      const playlistId = getters.playlistId
      state.playlistLoading = true
      try {
        await ApolloClient.mutate({ mutation: SET_PLAYLIST_SOUNDTRACK_URL, variables: { playlistId, name, url } })
        state.playlistLoading = false
        commit('UPDATE_PLAYLIST', { mediaAudio: { metadata: { name } } })
      } catch (e) {
        handleError(e)
      }
    },
    async movePlaylist ({ commit, state, getters }, input) {
      const playlistId = getters.playlistId
      try {
        const { data: { movePlaylistById } } = await ApolloClient.mutate({ mutation: MOVE_PLAYLIST_BY_ID, variables: { id: playlistId, input } })
        commit('UPDATE_PLAYLIST', movePlaylistById)
      } catch (e) {
        handleError(e)
      }
    },
    async copyPlaylist ({ commit, state, getters, rootGetters }, input) {
      const currentGroupId = rootGetters['groups/currentGroupId']
      const { groupId } = input
      const playlistId = getters.playlistId
      try {
        const { data: { copyPlaylist } } = await ApolloClient.mutate({ mutation: COPY_PLAYLIST_BY_ID, variables: { id: playlistId, input } })
        if (currentGroupId === groupId) {
          commit('groups/ADD_CURRENT_GROUP_PLAYLIST', copyPlaylist, { root: true })
        }
      } catch (e) {
        handleError(e)
      }
    },
    async deletePlaylistSlide ({ commit, state, getters }, { payload, zoneKey, changeLogs, zoneId }) {
      state.playlistLoading = true
      const playlistId = getters.playlistId
      zoneId = zoneId || getters.layoutZoneIdByKey(zoneKey)
      try {
        const { data: { deletePlaylistSlide } } = await ApolloClient.mutate({ mutation: DELETE_PLAYLIST_SLIDE, variables: { playlistId, zoneId, ...payload, changeLogs } })
        commit('UPDATE_PLAYLIST', deletePlaylistSlide)
      } catch (e) {
        state.playlistLoading = false
        handleError(e)
      }
    },
    async createPlaylistSlide ({ commit, state, getters }, { payload, zoneKey }) {
      state.playlistLoading = true
      const playlistId = getters.playlistId
      const zoneId = getters.layoutZoneIdByKey(zoneKey)
      try {
        const { data: { addPlaylistSlide } } = await ApolloClient.mutate({ mutation: ADD_PLAYLIST_SLIDE, variables: { playlistId, zoneId, ...payload } })
        commit('UPDATE_PLAYLIST', addPlaylistSlide)
      } catch (e) {
        handleError(e)
      } finally {
        state.playlistLoading = false
      }
    },
    async updatePlaylistSlide ({ commit, state, getters }, { payload, zoneKey, changeLogs, playlistId, zoneId }) {
      state.playlistLoading = true
      playlistId = playlistId || getters.playlistId
      zoneId = zoneId || getters.layoutZoneIdByKey(zoneKey)
      try {
        const { data: { updatePlaylistSlide } } = await ApolloClient.mutate({ mutation: UPDATE_PLAYLIST_SLIDE, variables: { playlistId, zoneId, ...payload, changeLogs } })
        commit('UPDATE_PLAYLIST', updatePlaylistSlide)
      } catch (e) {
        state.playlistLoading = false
        handleError(e)
      }
    },
    async movePlaylistSlide ({ commit, state, getters }, { payload, zoneId, changeLogs } ) {
      state.playlistLoading = true
      const playlistId = getters.playlistId
      try {
        const { data: { movePlaylistSlide } } = await ApolloClient.mutate({ mutation: MOVE_PLAYLIST_SLIDE, variables: { playlistId, zoneId, ...payload, changeLogs } })
        commit('UPDATE_PLAYLIST', movePlaylistSlide)
      } catch (e) {
        state.playlistLoading = false
        handleError(e)
      }
    },
    async exportPlaylistSlides ({ commit, state }, payload) {
      state.playlistLoading = true
      try {
        const { data: { appendPlaylistZones } } = await ApolloClient.mutate({ mutation: EXPORT_SLIDES_TO_PLAYLISTS, variables: payload })
        commit('UPDATE_PLAYLIST', appendPlaylistZones)
      } catch (e) {
        state.playlistLoading = false
        handleError(e)
      }
    },
    async copyPlaylistSlides ({ commit, state }, payload) {
      state.playlistLoading = true
      try {
        const { data: { appendPlaylistZones } } = await ApolloClient.mutate({ mutation: COPY_PLAYLIST_SLIDES, variables: payload })
        commit('UPDATE_PLAYLIST', appendPlaylistZones)
      } catch (e) {
        state.playlistLoading = false
        handleError(e)
      }
    },
    async publishPlaylistVersion ({ dispatch, state }, payload) {
      const { playlistId } = payload
      state.playlistLoading = true
      try {
        await ApolloClient.mutate({ mutation: PUBLISH_PLAYLIST_VERSION, variables: payload })
        // dispatch('getPlaylistChangelogs', playlistId)
        dispatch('getPlaylist', playlistId)
      } catch (e) {
        state.playlistLoading = false
        handleError(e)
      }
    }
  },
  getters: {
    playlist: state => state.playlist,
    emergencyPlaylists: state => state.emergencyPlaylists,
    emergencyPlaylistsLoading: state => state.emergencyPlaylistsLoading,
    playlistChangelogs: state => state.playlistChangelogs,
    playlistId: state => state.playlist?.id,
    playlistIsMain: state => state.playlist?.isMainPlaylist,
    playlistLoading: state => state.playlistLoading,
    playlistTransitionType: state => state.playlist?.layout?.transitionType,
    playlistVersion: state => state.playlist?.version,
    playlistSoundtrack: state => state.playlist?.mediaAudio,
    playlistChangelogsLoading: state => state.playlistChangelogsLoading,
    widgetDisplayRestrictions: state => {
      const restrictions = {}
      state.widgetDisplayRestrictions?.forEach((layout)=>{
        const [MAIN, ZONE_A, ZONE_B] = layout.zones.map((zone)=> {
          const { bannedWidgets = [] } = zone || {}
          return bannedWidgets.map(({widgetType, additionalData})=>{
            return {
              widgetType,
              data: additionalData
            }
          })
        })
        restrictions[layout.playlistLayoutType] = {
          MAIN, ZONE_A, ZONE_B
        }
      })
      return restrictions
    },
    zoningLayoutType: state => state.playlist?.layout?.type,
    zoningLayoutShuffle: state => state.playlist?.layout?.shuffle,
    zoningLayoutMusicType: state => state.playlist?.layout?.musicType || null,
    layoutMainZone: state => state.playlist?.layout?.zones?.[0] || null,
    layoutZoneA: state => state.playlist?.layout?.zones?.[1] || null,
    layoutZoneB: state => state.playlist?.layout?.zones?.[2] || null,
    layoutMainZoneSlides: state => state.playlist?.layout?.zones?.[0]?.slides || [],
    layoutZoneASlides: state => state.playlist?.layout?.zones?.[1]?.slides || [],
    layoutZoneBSlides: state => state.playlist?.layout?.zones?.[2]?.slides || [],
    layoutZoneIdByKey: state => zoneKey => {
      const index = LAYOUT_ZONE_KEYS_TO_INDEX[zoneKey]
      return index !== undefined ? state.playlist?.layout?.zones?.[index]?.id || null : null
    },
    layoutSideZones: state => (state.playlist?.layout?.zones || []).slice(1)
  },
  mutations: {
    SET_PLAYLIST (state, playlist) {
      state.playlist = playlist
      state.playlistLoading = false
    },
    SET_EMERGENCY_PLAYLISTS (state, playlists) {
      state.emergencyPlaylists = playlists
      state.emergencyPlaylistsLoading = false
    },
    SET_WIDGET_DISPLAY_RESTRICTIONS (state, widgetDisplayRestrictions) {
      state.widgetDisplayRestrictions = widgetDisplayRestrictions
    },
    SET_PLAYLIST_CHANGELOGS (state, changelogs) {
      state.playlistChangelogs = changelogs
      state.playlistLoading = false
    },
    UPDATE_PLAYLIST (state, playlist) {
      state.playlist = { ...state.playlist, ...playlist }
      state.playlistLoading = false
    },
    CLEAR_PLAYLIST_DATA (state) {
      state.playlist = null
      state.playlistLoading = false
    }
  }
}
