const onLinkTwitter = () => {
  openSocialLoginWindow(process.env.VUE_APP_TWITTER_OAUTH_LINK, 'twitter')
}

const onLinkInstagram = () => {
  openSocialLoginWindow(process.env.VUE_APP_INSTAGRAM_OAUTH_LINK, 'instagram')
}

const onLinkGoogle = () => {
  openSocialLoginWindow(process.env.VUE_APP_GOOGLE_OAUTH_LINK, 'google')
}

const onLinkMicrosoft = () => {
  openSocialLoginWindow(process.env.VUE_APP_MICROSOFT_OAUTH_LINK, 'microsoft')
}

const onLinkDropbox = () => {
  openSocialLoginWindow(process.env.VUE_APP_DROPBOX_OAUTH_LINK, 'dropbox')
}

export const getSocialAccountName = ({ type, info }) => {
  switch (type) {
    case 'TWITTER':
      return info?.screen_name || info?.name
    case 'INSTAGRAM':
      return info?.username || info?.name
    case 'GOOGLE':
      return info?.name || info?.given_name
    case 'MICROSOFT':
      return info?.username || info?.name
    case 'DROPBOX':
      return info?.display_name || info?.given_name
  }
}

export const toStandard = (sa) => ({ id: sa.id, type: sa.type, name: getSocialAccountName(sa), error: sa.info?.message })

export const addAccount = (type) => {
  switch (type) {
    case 'twitter':
      onLinkTwitter()
      break
    case 'instagram':
      onLinkInstagram()
      break
    case 'google':
      onLinkGoogle()
      break
    case 'microsoft':
      onLinkMicrosoft()
      break
    case 'dropbox':
      onLinkDropbox()
      break
  }
}

export const openSocialLoginWindow = (url, platform, action, closeFn) => {
  const authWindow = window.open(url, 'kitcast', 'height=600,width=800,toolbar=0')
  const interval = setInterval(() => {
    if (!authWindow.closed) {
      authWindow.postMessage({ platform, action: action || 'link' }, process.env.VUE_APP_DEFAULT_URL)
    } else {
      clearInterval(interval)
      closeFn && closeFn()
    }
  }, 500)
  if (authWindow && authWindow.focus) {
    authWindow.focus()
  }
}
