<script setup>
import ZoningInlineMenu from '@/components/ZoningInlineMenu.vue'
import PushBackTransition from '@/components/icons/PushBackTransition.vue'
import FlipLeftTransition from '@/components/icons/FlipLeftTransition.vue'
import PushToLeftTransition from '@/components/icons/PushToLeftTransition.vue'
import FlipBackTransition from '@/components/icons/FlipBackTransition.vue'
import { TRANSITIONS_NAMES_MAP } from '@/constants'

defineProps({
  zoningLayoutSettings: {
    type: Object,
    required: true
  },
  transitionType: {
    type: String,
    required: true
  }
})

defineEmits(['change-layout', 'change-transition'])

const transitionsList = Object.keys(TRANSITIONS_NAMES_MAP).map(k => ({
  label: TRANSITIONS_NAMES_MAP[k],
  value: k
}))

</script>

<template>
  <div
    class="design-tab"
  >
    <div class="zoning design-panel">
      <a-typography-paragraph
        type="secondary"
      >
        {{ $t('components.playlistDesignTab.zoning') }}
      </a-typography-paragraph>
      <div class="zoning-layouts">
        <ZoningInlineMenu
          v-if="zoningLayoutSettings"
          :layout="zoningLayoutSettings"
          @change-layout="($event) => $emit('change-layout', $event)"
        />
      </div>
    </div>
    <a-divider />
    <div class="transitions design-panel">
      <a-typography-paragraph
        type="secondary"
      >
        {{ $t('components.playlistDesignTab.transitions') }}
      </a-typography-paragraph>
      <div class="transitions-preview">
        <FlipLeftTransition v-if="transitionType === 'FLIP_LEFT'" />
        <FlipBackTransition v-else-if="transitionType === 'FLIP_BACK'" />
        <PushBackTransition v-else-if="transitionType === 'PUSH_BACK'" />
        <PushToLeftTransition v-else-if="transitionType === 'PUSH_TO_LEFT'" />
      </div>
      <a-select
        :value="transitionType"
        style="width: 100%;"
        size="small"
        @change="($event) => $emit('change-transition', $event)"
      >
        <a-select-option
          v-for="transition in transitionsList"
          :key="transition.value"
          :value="transition.value"
        >
          {{ $t(`transitions.${transition.label}`) }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<style scoped lang="less">
.design-tab {
  padding-top: 20px;

  .design-panel {
    padding: 0 20px;

    &.transitions {
      .transitions-preview {
        margin-bottom: 12px;
        aspect-ratio: 16 / 9;
        border: solid 1px #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
