<template>
  <a-modal
    :open="visible"
    :title="$t('components.mediaSelectModal.title')"
    width="100%"
    wrap-class-name="full-modal media-modal"
    :ok-button-props="{disabled}"
    @ok="onDone"
    @cancel="onCancel"
  >
    <MediaView
      :folder-select-disabled="true"
      :media-types="types"
      :selection="selection"
      :external-selection="selection"
      :exclude-headings="excludeHeadings"
      :disable-actions="true"
    />
  </a-modal>
</template>

<script>
import { computed, defineComponent, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
import MediaView from '@/views/MediaView'

export default defineComponent({
  name: 'MediaSelectModal',
  components: {
    MediaView
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selectedMedia: {
      type: Array,
      default: () => []
    },
    selection: {
      type: String,
      validator (value) {
        return ['single', 'multiple', 'none'].indexOf(value) !== -1
      }
    },
    types: {
      type: Array
    },
    excludeHeadings: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:visible', 'update', 'select', 'close'],
  setup (props, { emit }) {
    const store = useStore()
    const { startFileUpload } = inject('mediaService')
    const nameFilter = ref('')
    const selectedMedia = computed(() => store.getters['media/selectedMedia'])
    const disabled = computed(() => {
      return !selectedMedia.value.length
    })

    const onDone = () => {
      emit('update', selectedMedia.value)
      emit('select', selectedMedia.value)
      emit('update:visible', false)
      resetModal()
    }
    const onCancel = () => {
      emit('update:visible', false)
      emit('close')
      resetModal()
    }

    const resetModal = () => {
      store.commit('media/CLEAR_PARENT_DIRECTORY_MEDIA_ID')
      store.commit('media/CLEAR_SELECTED_MEDIA')
    }

    const onFileUpload = () => {
      startFileUpload(props.types)
    }

    const onFolderChange = () => {
      nameFilter.value = ''
    }

    watch(() => props.visible, (value) => {
      !value && store.commit('media/CLEAR_SELECTED_MEDIA')
      if (value && props.selection === 'single') {
        store.commit('media/SET_SELECTED_MEDIA', props.selectedMedia)
      }
    })

    return {
      nameFilter,
      disabled,
      selected: selectedMedia,
      onDone,
      onCancel,
      onFileUpload,
      onFolderChange
    }
  }
})
</script>

<style scoped>

</style>
