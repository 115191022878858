<template>
  <GeneralSider>
    <a-menu
      v-model:selectedKeys="selectedKeys"
      theme="light"
      mode="inline"
    >
      <a-menu-item key="WeekLoop">
        <router-link :to="{name: 'WeekLoop'}">
          <RetweetOutlined />
          <span>{{ $t('components.scheduleSider.weeklyLoop') }}</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="Calendar">
        <router-link :to="{name: 'Calendar'}">
          <CalendarOutlined />
          <span>{{ $t('components.scheduleSider.calendar') }}</span>
        </router-link>
      </a-menu-item>
    </a-menu>
  </GeneralSider>
</template>

<script>
import { computed, defineComponent } from 'vue'

import { CalendarOutlined, RetweetOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import GeneralSider from '@/components/siders/GeneralSider.vue'

export default defineComponent({
  name: 'PlaylistsSider',
  components: {
    GeneralSider,
    RetweetOutlined,
    CalendarOutlined
  },
  setup () {
    const route = useRoute()
    const selectedKeys = computed(() => [route.name])

    return {
      selectedKeys
    }
  }
})
</script>

<style scoped>

</style>
