<template>
  <a-modal
    :open="visible"
    centered
    width="310px"
    wrap-class-name="calendar-event-modal"
    @close="onCancel"
    @cancel="onCancel"
  >
    <a-row :gutter="8">
      <a-col
        :span="3"
        style="text-align: right; font-size: 18px; color: rebeccapurple;"
        :style="{color: event.color || DEFAULT_PLAYLIST_COLOR}"
      >
        <a-tooltip
          v-if="event.paused"
          :title="$t('components.calendarEventModal.pausedTooltipTitle')"
          placement="topLeft"
        >
          <PauseCircleFilled />
        </a-tooltip>
        <PlayCircleFilled v-else />
      </a-col>
      <a-col
        :span="21"
        style="padding-top: 4px;"
        class="desc"
      >
        <a-typography-text>{{ event.playlistName || 'Playlist name' }}</a-typography-text>
        <a-typography-text type="secondary">
          {{ event.day }}<span v-if="repeatable">s</span>
        </a-typography-text>
        <EventTimePicker
          :is-am-pm="isAmPm"
          :time-from="event.timeFrom"
          :time-to="event.timeTo"
          @update="handleTimeChange"
        />
      </a-col>
    </a-row>
    <template #footer>
      <a-button
        size="small"
        @click="removeEvent(event.playlistId)"
      >
        {{ $t('components.calendarEventModal.removeButtonText') }}
      </a-button>
      <a-button
        size="small"
        @click="onCancel"
      >
        {{ $t('components.calendarEventModal.cancelButtonText') }}
      </a-button>
      <a-button
        :disabled="!eventTimeChanged"
        type="primary"
        size="small"
        @click="onSave"
      >
        {{ $t('components.calendarEventModal.saveButtonText') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch, watchEffect } from 'vue'
import { PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons-vue'
import { DEFAULT_PLAYLIST_COLOR } from '@/constants'
import dayjs from 'dayjs'
import EventTimePicker from '@/components/EventTimePicker.vue'

const formatEventTimeToPickerTime = (from, to) => {
  if (!from || !to) return []
  const fromTime = dayjs(from+':00', 'HH:mm:ss')
  const toTime = dayjs(to+':00', 'HH:mm:ss')
  return [fromTime, toTime]
}

export default defineComponent({
  name: 'CalendarEventModal',
  components: {
    EventTimePicker,
    PlayCircleFilled,
    PauseCircleFilled
  },
  props: {
    visible: Boolean,
    event: Object,
    repeatable: Boolean,
    isAmPm: Boolean
  },
  emits: ['update:visible', 'remove', 'update'],
  setup (props, { emit }) {
    const eventTime = ref({
      timeFrom: props.event?.timeFrom,
      timeTo: props.event?.timeTo,
    })
    const eventTimeChanged = computed(() => {
      return props.event?.timeFrom !== eventTime.value.timeFrom || props.event?.timeTo !== eventTime.value.timeTo
    })
    const onCancel = () => {
      emit('update:visible', false)
    }

    const onSave = () => {
      emit('update', { timeFrom: eventTime.value.timeFrom, timeTo: eventTime.value.timeTo, event: props.event })
      emit('update:visible', false)
    }

    const removeEvent = (eventId) => {
      emit('remove', eventId)
      emit('update:visible', false)
    }

    const handleTimeChange = ({ timeFrom, timeTo }) => {
      eventTime.value = { timeFrom, timeTo }
    }

    watchEffect(() => {
      if (props.event) {
        eventTime.value = {
          timeFrom: props.event.timeFrom,
          timeTo: props.event.timeTo
        }
      }
    })

    watchEffect(()=>{
      if (props.visible) {
        eventTime.value = {
          timeFrom: props.event.timeFrom,
          timeTo: props.event.timeTo
        }
      }
    })

    return {
      DEFAULT_PLAYLIST_COLOR,
      eventTimeChanged,
      eventTime,
      onSave,
      onCancel,
      removeEvent,
      handleTimeChange
    }
  }
})
</script>

<style lang="less">
.calendar-event-modal {
  .desc {
    > span {
      text-transform: capitalize;
      display: block;
    }
  }
}
</style>
