<script>
import { computed, defineComponent, onMounted } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'InvitesHandler',
  props: {
    inviteToken: String
  },
  setup (props) {
    const store = useStore()
    const user = computed(() => store.getters['auth/user'])
    const { t } = useI18n()

    onMounted(async ()=>{
      if (props.inviteToken) {
        const inviteToken = props.inviteToken
        store.dispatch('auth/getPendingInviteByToken', inviteToken).then(({data: {getPendingInviteByToken}})=>{
          if (getPendingInviteByToken.email === user.value.email) {
            showInvitationModal({ inviteToken, authorEmail: getPendingInviteByToken.author?.email, workspaceName: getPendingInviteByToken.workspace?.name, workspaceId: getPendingInviteByToken.workspace?.id })
          }
        })
      }
      else {
        const pendingInvites = await store.dispatch('auth/getUserPendingInvites')
        if (pendingInvites.length) {
          const pendingInvite = pendingInvites[0]
          showInvitationModal({ inviteId: pendingInvite.id, authorEmail: pendingInvite.author?.email, workspaceName: pendingInvite.workspace?.name, workspaceId: pendingInvite.workspace?.id })
        }
      }
    })

    const showInvitationModal = ({ inviteToken, inviteId, workspaceId, workspaceName, authorEmail })=>{
      Modal.confirm({
        title: t('components.invitesHandler.invitationModalTitle'),
        content: t('components.invitesHandler.invitationModalContent', {authorEmail, workspaceName}),
        okText: t('components.invitesHandler.invitationModalOkText'),
        cancelText: t('components.invitesHandler.invitationModalOkCancel'),
        onOk: async () => {
          if (!inviteToken && !inviteId) return
          if (inviteToken) {
            await store.dispatch('auth/acceptInviteByToken', inviteToken)
          }
          else if (inviteId) {
            await store.dispatch('auth/acceptInviteById', inviteId)
          }
          await store.dispatch('workspace/changeWorkspace', workspaceId)
          message.info({
            content: () => t('components.invitesHandler.inviteAccepted'),
            class: 'notification-message'
          })
        },
        onCancel: async () => {
          if (inviteToken) {
            await store.dispatch('auth/rejectInviteByToken', inviteToken)
          }
          else if (inviteId) {
            await store.dispatch('auth/rejectInviteById', inviteId)
          }
          message.info({
            content: () => t('components.invitesHandler.inviteDeclined'),
            class: 'notification-message'
          })
        }
      });
    }

    return {}
  }
})
</script>

<template>
  <template />
</template>

<style lang="less">
.notification-message {
  display: flex;
  justify-content: flex-end;
  margin: 8px 32px;
}
</style>
