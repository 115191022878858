<script setup>
import InfoHeader from '@/components/InfoHeader.vue'
import { useStore } from 'vuex'
const store = useStore()
const openUpgradeSubscriptionModal = () => {
  store.dispatch('handleUpdateSubscription')
}
</script>

<template>
  <InfoHeader type="danger">
    <i18n-t
      keypath="components.workspaceFrozenAdminHeader.subscriptionSuspendedText"
      tag="label"
      for="components.workspaceFrozenAdminHeader.subscriptionSuspendedLink"
    >
      <a
        class="upgrade-link"
        href="mailto:support@kitcast.tv"
      >{{ $t('components.workspaceFrozenAdminHeader.subscriptionSuspendedLink') }}</a>
    </i18n-t>
  </InfoHeader>
</template>

<style scoped lang="less">
.upgrade-link{
  color: white;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
</style>
