<script>
import { defineComponent, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'PaymentMethodUpdateHandler',
  props: {
    success: Boolean
  },
  setup (props) {
    const { t } = useI18n()

    onMounted(()=>{
      if (props.success) {
        openPaymentMethodUpdatedMessage()
      }
    })

    const openPaymentMethodUpdatedMessage = () => {
      message.info({
        content: () => t('components.paymentMethodUpdateHandler.paymentMethodUpdated'),
        class: 'notification-message'
      })}

    return {}
  }
})
</script>

<template>
  <template />
</template>

<style lang="less">
.notification-message {
  display: flex;
  justify-content: flex-end;
  margin: 8px 32px;
}
</style>
