<script setup>

import { computed } from 'vue'
import {
  ArrowsAltOutlined, CaretRightOutlined,
  CopyOutlined, DeleteOutlined,
  EditOutlined,
  ExportOutlined, PauseOutlined,
  SoundOutlined,
  WarningOutlined
} from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'

defineEmits(['copyPlaylistId', 'deleteSlide', 'changeObjectFit', 'changeMute', 'changePause', 'editSlide', 'exportSlide', 'duplicateSlide'])
defineProps({
  showRestrictions: Boolean,
  showUnused: Boolean,
  showSlideEdit: Boolean,
  showExport: Boolean,
  showDuplicate: Boolean,
  showCopyPlaylistId: Boolean,
  showPause: Boolean,
  canBeMuted: Boolean,
  muted: Boolean,
  paused: Boolean,
  hasObjectFit: Boolean,
  objectFit: String,
  error: String
})
const { t } = useI18n()

const objectFitOptions = computed(()=>{
  return [{
    value: 'ORIGINAL',
    label: t('components.slideCard.objectFitMap.ORIGINAL')
  }, {
    value: 'FIT',
    label: t('components.slideCard.objectFitMap.FIT')
  }, {
    value: 'FILL',
    label: t('components.slideCard.objectFitMap.FILL')
  }, {
    value: 'STRETCH',
    label: t('components.slideCard.objectFitMap.STRETCH')
  }]
})

</script>

<template>
  <div style="display: flex; gap: 4px;">
    <a-tooltip
      v-if="showCopyPlaylistId"
      placement="top"
      overlay-class-name="playlist-id-tooltip"
    >
      <template #title>
        {{ $t('components.slideCard.copyPlaylistId') }}
      </template>
      <a-button @click.stop.prevent="$emit('copyPlaylistId')">
        <template #icon>
          <CopyOutlined style="font-size: 12px;" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip
      v-if="showRestrictions"
      placement="top"
      :title="$t('components.slideCard.displayRestrictionTooltipTitle')"
    >
      <WarningOutlined class="warning-icon" />
    </a-tooltip>
    <a-tooltip
      v-if="error"
      placement="top"
      :title="error"
    >
      <WarningOutlined class="warning-icon" />
    </a-tooltip>
    <a-tooltip
      v-if="showUnused"
      placement="top"
      :title="$t('components.slideCard.displayRestrictionTooltipTitle')"
    >
      <WarningOutlined class="warning-icon" />
    </a-tooltip>
    <a-tooltip
      v-if="hasObjectFit"
      placement="left"
      :title="$t('components.slideCard.fitMode')"
    >
      <a-dropdown
        placement="bottom"
        :trigger="['hover']"
      >
        <a-button @click.stop.prevent>
          <template #icon>
            <ArrowsAltOutlined style="font-size: 12px;" />
          </template>
        </a-button>
        <template #overlay>
          <a-menu
            selectable
            :selected-keys="[objectFit]"
          >
            <a-menu-item
              v-for="option in objectFitOptions"
              :key="option.value"
              selectable
              @click="$emit('changeObjectFit', option.value)"
            >
              {{ option.label }}
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </a-tooltip>
    <a-tooltip
      v-if="canBeMuted"
      placement="top"
    >
      <template
        v-if="!muted"
        #title
      >
        {{ $t('components.slideCard.muteTooltipTitle') }}
      </template>
      <template
        v-else
        #title
      >
        {{ $t('components.slideCard.unmuteTooltipTitle') }}
      </template>
      <a-button @click.stop.prevent="$emit('changeMute', !muted)">
        <template #icon>
          <SoundOutlined
            style="font-size: 12px;"
            :class="{muted}"
          />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip
      v-if="showSlideEdit"
      placement="top"
    >
      <template #title>
        {{ $t('components.slideCard.editTooltipTitle') }}
      </template>
      <a-button @click.stop.prevent="$emit('editSlide')">
        <template #icon>
          <EditOutlined style="font-size: 12px;" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip
      v-if="showExport"
      placement="top"
    >
      <template #title>
        {{ $t('components.slideCard.exportTooltipTitle') }}
      </template>
      <a-button @click.stop.prevent="$emit('exportSlide')">
        <template #icon>
          <ExportOutlined style="font-size: 12px;" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip
      v-if="showDuplicate"
      placement="top"
    >
      <template #title>
        {{ $t('components.slideCard.duplicateTooltipTitle') }}
      </template>
      <a-button @click.stop.prevent="$emit('duplicateSlide')">
        <template #icon>
          <CopyOutlined style="font-size: 12px;" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip
      v-if="showPause"
      placement="top"
    >
      <template #title>
        {{ $t(`components.slideCard.${!paused ? 'un' : ''}pauseTooltipTitle`) }}
      </template>
      <a-button @click.stop.prevent="$emit('changePause', !paused)">
        <template #icon>
          <CaretRightOutlined
            v-if="paused"
            style="font-size: 12px;"
          />
          <PauseOutlined
            v-else
            style="font-size: 12px;"
          />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip placement="top">
      <template #title>
        {{ $t('components.slideCard.deleteTooltipTitle') }}
      </template>
      <a-popconfirm
        :title="$t('components.slideCard.deletePopConfirmTitle')"
        :ok-text="$t('components.slideCard.deletePopConfirmOkText')"
        :cancel-text="$t('components.slideCard.deletePopConfirmCancelText')"
        placement="bottom"
        @confirm="$emit('deleteSlide')"
      >
        <a-button @click.prevent.stop>
          <template #icon>
            <DeleteOutlined style="font-size: 12px;" />
          </template>
        </a-button>
      </a-popconfirm>
    </a-tooltip>
  </div>
</template>

<style scoped lang="less">
.warning-icon {
  color: var(--ant-error-color);
  margin-right: 4px;
}

</style>
