<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.yelp.slideStep1.subTitle')"
    :title="$t('components.slides.yelp.slideStep1.title')"
    no-border
  >
    <a-form @submit="onSubmit">
      <a-input
        ref="inputRef"
        v-model:value="yelpBusinessIdOrAlias"
        :placeholder="$t('components.slides.yelp.slideStep1.yelpBusinessIdOrAliasPlaceholder')"
        @change="onChange"
      />
    </a-form>
  </SlideStep>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { YelpSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

export default defineComponent({
  name: 'YelpSlide',
  components: { DurationSlideStep, SlideStep },
  props: {
    title: String,
    slideObj: YelpSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const slide = props.slideObj || new YelpSlide()
    const { businessIdOrAlias } = slide.slideData
    const inputRef = ref(null)
    const yelpBusinessIdOrAlias = ref(businessIdOrAlias)

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    onMounted(() => {
      inputRef.value && inputRef.value.focus()
    })

    const onSubmit = () => {
      if (!props.disabled) {
        emit('save')
      }
    }

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        businessIdOrAlias: yelpBusinessIdOrAlias.value?.toLowerCase()
      })
    }

    const onChange = () => {
      if (!yelpBusinessIdOrAlias.value) {
        toggleDisabled(true)
        return true
      }
      updateSlideData()
      updateSlide()
      toggleDisabled(false)
    }

    return {
      slide,
      yelpBusinessIdOrAlias,
      inputRef,
      onChange,
      onSubmit,
      updateSlide
    }
  }
})
</script>

<style lang="less">

</style>
