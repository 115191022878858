<script setup>
import { reactive, ref, toRaw, watch, watchEffect } from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const emit = defineEmits(['update:open', 'save'])
const dateFormat = 'YYYY-MM-DD';

const props = defineProps({
  open: Boolean,
  options: Object
})

const state = reactive({
  deleteAt: props.options?.deleteAt ? dayjs(props.options.deleteAt, dateFormat) : null,
  withMedia: props.options?.withMedia || false
})

const disabledDate = current => {
  // return current && current < dayjs().subtract(1, 'day')
  return current && current < dayjs().endOf('day')
}

const onCancel = () => {
  emit('update:open', false)
}

const onSave= () => {
  emit('save', {
    // autoDeleteOptions :{
    //   deleteAt: state.deleteAt
    //       ? state.deleteAt.hour(dayjs().hour())
    //         .minute(dayjs().minute())
    //         .second(dayjs().second())
    //         .millisecond(dayjs().millisecond())
    //         .add(1, 'hour')
    //         .toISOString()
    //       : null,
    //   force: true,
    //   withMedia: state.withMedia
    // }
    autoDeleteOptions :{
      deleteAt: state.deleteAt ? dayjs(state.deleteAt).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null,
      force: true,
      withMedia: state.withMedia
    }
  })
  emit('update:open', false)
}

const onRemove = () => {
  emit('save', {
    autoDeleteOptions :null
  })
  emit('update:open', false)
}

watchEffect(()=>{
  if (props.open) {
    state.deleteAt = props.options?.deleteAt ? dayjs(props.options.deleteAt, dateFormat) : null
    state.withMedia = props.options?.withMedia || false
  }
})

</script>

<template>
  <a-modal
    :title="$t('components.playlistExpirationModal.title')"
    :open="open"
    width="400px"
    destroy-on-close
    ok-text="Save"
    cancel-text="Cancel"
    :ok-button-props="{ disabled: !state.deleteAt }"
    @cancel="onCancel"
    @ok="onSave"
  >
    <a-space
      direction="vertical"
      size="middle"
    >
      <a-date-picker
        v-model:value="state.deleteAt"
        :disabled-date="disabledDate"
        style="width: 200px; margin-top: 8px;"
      />
      <a-checkbox v-model:checked="state.withMedia">
        {{ $t('components.playlistExpirationModal.deleteMedia') }}
      </a-checkbox>
    </a-space>
    <template #footer>
      <a-space>
        <a-button @click="onCancel">
          {{ $t('components.playlistExpirationModal.cancelButtonText') }}
        </a-button>
        <a-button
          type="default"
          :disabled="!options?.deleteAt"
          @click="onRemove"
        >
          {{ $t('components.playlistExpirationModal.removeButtonText') }}
        </a-button>
        <a-button
          type="primary"
          :disabled="!state.deleteAt"
          @click="onSave"
        >
          {{ $t('components.playlistExpirationModal.saveButtonText') }}
        </a-button>
      </a-space>
    </template>
  </a-modal>
</template>

<style scoped lang="less">

</style>
