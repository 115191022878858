<template>
  <div class="fixed-footer">
    <div
      v-if="$slots.left"
      class="left"
    >
      <slot name="left" />
    </div>
    <div class="default">
      <slot />
    </div>
    <div
      v-if="$slots.right"
      class="right"
    >
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FixedFooter'
})
</script>

<style scoped lang="less">
.fixed-footer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  background-color: #fff;
  border-top: solid 1px #f0f0f0;
  gap: 16px;
  padding: 16px;
  align-items: center;
  .default {
    flex: 1;
  }
  .right {
    justify-self: flex-end;
  }
}
</style>
