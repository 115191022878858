<template>
  <div
    class="widget-step"
    :class="{disabled}"
  >
    <div class="widget-step-heading">
      <div class="widget-step-heading-left">
        <div
          v-if="title"
          class="widget-step-heading-title"
        >
          {{ title }}
        </div>
        <div
          v-if="subTitle || $slots['sub-title']"
          class="widget-step-heading-subtitle"
          :class="{ multiline }"
        >
          <slot
            v-if="$slots['sub-title']"
            name="sub-title"
          />
          <span v-else-if="subTitle">{{ subTitle }}</span>
        </div>
      </div>
      <span class="widget-step-heading-extra">
        <slot name="extra" />
      </span>
    </div>
    <slot />
    <a-divider v-if="!noBorder" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SlideStep',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiline: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped lang="less">
.widget-step {
  .widget-step-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    .widget-step-heading-left {
      align-items: center;
      overflow: hidden;
      .widget-step-heading-title {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
      .widget-step-heading-subtitle {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        line-height: 1.5715;
        &:not(.multiline) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .widget-step-heading-extra {
      padding-left: 24px;
    }

  }
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

</style>
