<template>
  <div
    class="media-picker"
    @click="openSelectModal"
  >
    <div class="title">
      {{ $t('components.smartTemplateConstructor.mediaPicker.title') }}
    </div>
    <div
      :class="{active: isSelected}"
      class="media-picker-input"
    >
      <div class="thumbnail">
        <div class="thumbnail-wrapper">
          <img
            v-if="selected"
            :src="selected.thumbnail"
            alt=""
          >
        </div>
      </div>
      <span
        v-if="selected"
        class="file-name"
      >{{ selected?.name }}</span>
      <span v-else>{{ $t('components.smartTemplateConstructor.mediaPicker.mediaPickerPlaceholder') }}</span>
      <MediaSelectModal
        v-model:visible="showMediaSelectModal"
        :exclude-headings="['actions']"
        :types="mediaTypes"
        selection="single"
        @update="onSelectionUpdate"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import MediaSelectModal from '@/components/MediaSelectModal'

export default defineComponent({
  name: 'MediaPicker',
  components: { MediaSelectModal },
  props: {
    isSelected: {
      type: Boolean,
      default: () => false,
    },
    title: String,
    selected: Object,
    mediaTypes: {
      type: Array,
      default: () => ['IMAGE']
    }
  },
  emits: ['update'],
  setup (props, { emit }) {
    const showMediaSelectModal = ref(false)
    const selectedMedia = ref(null)

    const openSelectModal = () => {
      showMediaSelectModal.value = true
      if (selectedMedia.value) {
        setTimeout(() => {
          emit('update', selectedMedia.value)
        }, 300)
      }
    }

    const onSelectionUpdate = (selection = []) => {
      const [media] = selection
      selectedMedia.value = media
      emit('update', media)
    }

    return {
      selectedMedia,
      showMediaSelectModal,
      openSelectModal,
      onSelectionUpdate
    }
  }
})
</script>

<style lang="less" scoped>
.media-picker {
  .title {
    align-items: center;
    color: rgba(0, 0, 0, .85);
    display: inline-flex;
    font-size: 14px;
    height: 32px;
    max-width: 100%;
    position: relative;
  }

  .media-picker-input {
    font-feature-settings: "tnum";
    background-color: #fff;
    background-image: none;
    border: 2px solid #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    padding: 4px 11px 4px 9px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    min-width: 0;
    position: relative;
    transition: all .3s;
    width: 100%;
    cursor: pointer;
    height: 32px;
    display: flex;
    align-items: center;

    &.active, &:hover {
      border-color: var(--ant-primary-5);
    }

    .file-name {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .thumbnail {
      width: 28px;
      height: 22px;
      margin-right: 11px;
      display: flex;
      align-items: center;
      justify-content: center;

      .thumbnail-wrapper {
        background-color: #ccc;
        width: 22px;
        height: 22px;

        img {
          object-fit: cover;
          max-height: 22px;
          max-width: 22px;
        }
      }

    }
  }
}

</style>
