<template>
  <a-layout-sider
    style="background: #fff;"
    :width="INNER_SIDER_WIDTH"
    class="sider-menu"
  >
    <slot />
    <div>
      <slot name="footer" />
    </div>
  </a-layout-sider>
</template>

<script>
import { defineComponent } from 'vue'
import { INNER_SIDER_WIDTH } from '@/constants'

export default defineComponent({
  name: 'GeneralSider',
  setup () {
    return {
      INNER_SIDER_WIDTH
    }
  }
})
</script>

<style lang="less">
.sider-menu .ant-layout-sider-children {
  justify-content: space-between;
}
</style>
