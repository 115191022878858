<template>
  <a-modal
    :open="visible"
    :title="title"
    width="400px"
    @cancel="onClose"
    @ok="onOk"
  >
    <a-form
      v-if="!maxGroupsDepth || maxGroupsDepth - currentGroupDepth > 0"
      layout="vertical"
    >
      <a-form-item :label="$t('components.moveToOtherGroupModal.selectGroupLabel')">
        <a-tree-select
          v-if="showSelect"
          ref="treeSelectRef"
          v-model:value="selectedKey"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :placeholder="$t('components.moveToOtherGroupModal.selectGroupPlaceholder')"
          allow-clear
          tree-default-expand-all
          :tree-data="groupsTree"
        />
      </a-form-item>
    </a-form>
    <a-typography-paragraph
      v-else
      style="text-align: center; margin-top: 60px;"
    >
      {{ $t('components.moveToOtherGroupModal.tooManySubgroups') }}
    </a-typography-paragraph>
    <template #footer>
      <a-button
        key="submit"
        type="primary"
        :disabled="disabled"
        @click="onOk"
      >
        {{ $t('components.moveToOtherGroupModal.okButtonText') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, defineComponent, ref, toRaw, toRef, watch, watchEffect } from 'vue'
import { TreeSelect } from 'ant-design-vue'
import { useStore } from 'vuex'

const SHOW_CHILD = TreeSelect.SHOW_CHILD

export default defineComponent({
  name: 'MoveToOtherGroupModal',
  props: {
    visible: Boolean,
    title: String,
    maxGroupsDepth: Number,
    currentGroupDepth: Number,
    includeRoot: Boolean,
    excludeCurrentGroup: Boolean,
    excludeGroupId: String,
    doNotExcludeBranch: Boolean,
    nonSelectableParentGroupId: String,
    ignoreSmartGroups: Boolean
  },
  emits: ['update:visible', 'select', 'close'],
  setup (props, { emit }) {
    const store = useStore()
    const showSelect = ref(true)
    const selectedKey = ref(null)
    const visible = toRef(props, 'visible')
    const treeSelectRef = ref()

    const currentGroupId = computed(() => store.getters['groups/currentGroupId'])
    const smartGroupsEnabled = computed(()=> store.getters['workspace/smartGroupsEnabled'])
    const standardGroupsTree = computed(() => store.getters['groups/availableStandardGroupsTree'](
        {
          nonSelectableParentGroupId: props.nonSelectableParentGroupId,
          includeRoot: props.includeRoot,
          maxGroupsDepth: props.maxGroupsDepth - props.currentGroupDepth,
          groupIdToExclude: props.excludeGroupId ? props.excludeGroupId : props.excludeCurrentGroup ? currentGroupId.value : null,
          excludeBranch: !props.doNotExcludeBranch
        }))
    const smartGroupsTree = computed(() => store.getters['groups/availableSmartGroupsTree'](
        {
          excludeBranch: true,
          groupIdToExclude: props.excludeGroupId
              ? props.excludeGroupId
              : props.excludeCurrentGroup
                  ? currentGroupId.value
                  : null
        }))

    const groupsTree = computed(() => {
      if (!props.ignoreSmartGroups && smartGroupsEnabled.value && smartGroupsTree.value.length) {
        if (standardGroupsTree.value?.length) {
          return [{
            title: 'Groups',
            key: 'groups',
            value: 'groups',
            selectable: false,
            children : standardGroupsTree.value
          },
          {
            title: 'Smart Groups',
            key: 'smart-groups',
            value: 'smart-groups',
            selectable: false,
            children : smartGroupsTree.value
          }]
        }
        else {
          return smartGroupsTree.value
        }
      }
      else {
        return standardGroupsTree.value
      }
    })
    const disabled = computed(() => !selectedKey.value)

    const onOk = () => {
      emit('update:visible', false)
      emit('select', toRaw(selectedKey.value))
      selectedKey.value = null
    }

    const onClose = () => {
      emit('update:visible', false)
      emit('close')
      selectedKey.value = null
    }

    watch(() => visible.value, (newValue) => {
      if (newValue) {
        setTimeout(() => {
          const treeSelect = treeSelectRef.value.$el.querySelector('.ant-select-selector');
          treeSelectRef.value.focus();
          const event = new MouseEvent('mousedown', {
            bubbles: true,
            cancelable: true,
            view: window,
          });
          treeSelect.dispatchEvent(event);
        }, 350)
      }
    })

    return {
      SHOW_CHILD,
      currentGroupId,
      treeSelectRef,
      groupsTree,
      selectedKey,
      showSelect,
      disabled,
      onOk,
      onClose
    }
  }
})
</script>

<style scoped>

</style>
