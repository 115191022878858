<template>
  <a-modal
    :open="showDevices"
    width="100%"
    :footer="null"
    wrap-class-name="full-modal devices-modal"
    @ok="closeModal"
    @cancel="closeModal"
  >
    <template #title>
      <ModalHeading
        :title="$t('components.devicesModal.title')"
        :subtitle="$t('components.devicesModal.subtitle')"
      />
    </template>
    <MoveToOtherGroupModal
      v-model:visible="showDevicesMoveModal"
      :title="$t('components.devicesTable.moveToGroupModalTitle')"
      :ignore-smart-groups="true"
      @select="onDevicesMoveSelect"
    />
    <a-layout-content style="overflow-x: auto; flex: 1;">
      <DevicesTable
        :devices="devicesList"
        selection="multiple"
        :show-groups="true"
      />
    </a-layout-content>
    <StickyFooter v-if="selectedDevicesIds?.length">
      <FixedFooter>
        <template #left>
          {{ selectedDevicesIds.length }} {{ selectedDevicesIds.length === 1 ? 'Screen' : 'Screens' }} Selected
        </template>
        <template
          #right
        >
          <a-space>
            <a-button
              size="small"
              @click="onDevicesBulkMove()"
            >
              {{ $t('components.storageView.moveButtonText') }}
            </a-button>
            <a-popconfirm
              :title="$t('components.storageView.popConfirmTitle')"
              :ok-text="$t('components.storageView.popConfirmOkText')"
              :cancel-text="$t('components.storageView.popConfirmCancelText')"
              placement="bottomRight"
              @confirm="onDevicesBulkDelete"
            >
              <a-button size="small">
                {{ $t('components.storageView.deleteButtonText') }}
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
        <a-button
          size="small"
          @click="deselectAll"
        >
          {{ $t('components.storageView.deselectAllButtonText') }}
        </a-button>
      </FixedFooter>
    </StickyFooter>
  </a-modal>
</template>

<script>

import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import DevicesTable from '@/components/tables/DevicesTable.vue'
import FixedFooter from '@/components/FixedFooter.vue'
import MoveToOtherGroupModal from '@/components/inputModals/MoveToOtherGroupModal.vue'
import { success } from '@/utils'
import ModalHeading from '@/components/ModalHeading.vue'
import StickyFooter from '@/components/StickyFooter.vue'

export default defineComponent({
  name: 'DevicesModal',
  components: { StickyFooter, ModalHeading, MoveToOtherGroupModal, FixedFooter, DevicesTable },
  setup () {
    const store = useStore()
    const showDevices = computed(() => store.getters.showDevices)
    const devicesList = computed(()=> store.getters['devices/allDevices'])

    const showDevicesMoveModal = ref(false)
    const selectedDevicesIds = computed(()=> store.getters['devices/selectedDevicesIds'])

    const closeModal = () => {
      store.dispatch('closeDevices')
      deselectAll()
    }

    const onDevicesBulkMove = () => {
      showDevicesMoveModal.value = true
    }

    const onDevicesBulkDelete = () => {
      store.dispatch('devices/deleteDevices', { ids: selectedDevicesIds.value }).then(()=>{
        deselectAll()
        success()
      })
    }

    const onDevicesMoveSelect = (groupId) => {
      showDevicesMoveModal.value = false
      store.dispatch('devices/moveDevices', { ids: selectedDevicesIds.value, groupId }).then(()=>{
        deselectAll()
        success()
      })
    }

    const deselectAll = () => {
      store.commit('devices/CLEAR_SELECTED_DEVICES')
    }

    onMounted(() => {
      store.dispatch('devices/getAllDevices')
      store.dispatch('devices/subscribeToDeviceUpdate')
    })

    watch(()=> showDevices.value, (show)=>{
      show && store.dispatch('devices/getAllDevices')
    })


    return {
      showDevices,
      devicesList,
      selectedDevicesIds,
      showDevicesMoveModal,
      onDevicesBulkMove,
      onDevicesBulkDelete,
      onDevicesMoveSelect,
      deselectAll,
      closeModal
    }
  }
})
</script>

<style lang="less">
.devices-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

</style>
