<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.socialTwitter.slideStep1.subTitle')"
    :title="$t('components.slides.socialTwitter.slideStep1.title')"
  >
    <template #extra>
      <AddSocialAccountButton :socials="['twitter']" />
    </template>
    <a-select
      v-model:value="accounts"
      :placeholder="$t('components.slides.socialTwitter.slideStep1.accountSelectPlaceholder')"
      mode="multiple"
      style="width: 100%"
      @change="handleSocialAccountsChange"
    >
      <a-select-opt-group v-if="twitterAccounts.length > 0">
        <template #label>
          <span>
            <TwitterCircleFilled />
            Twitter
          </span>
        </template>
        <a-select-option
          v-for="account in twitterAccounts"
          :key="account.id"
          :value="account.id"
        >
          <div>
            @ {{ account.name }}
          </div>
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </SlideStep>
  <SlideStep
    :sub-title="$t('components.slides.socialTwitter.slideStep2.subTitle')"
    :title="$t('components.slides.socialTwitter.slideStep2.title')"
  >
    <a-select
      :placeholder="$t('components.slides.socialTwitter.slideStep2.tagsInputPlaceholder')"
      :value="hashtags"
      mode="tags"
      style="width: 100%"
      @change="onHashtagsChange"
    />
  </SlideStep>
  <SlideStep :title="$t('components.slides.socialTwitter.slideStep3.title')">
    <a-radio-group v-model:value="feedType">
      <a-radio value="TOP">
        {{ $t('components.slides.socialTwitter.slideStep3.popularFirst') }}
      </a-radio>
      <a-radio value="RECENT">
        {{ $t('components.slides.socialTwitter.slideStep3.recentFirst') }}
      </a-radio>
    </a-radio-group>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialTwitter.slideStep4.title')"
    no-border
  >
    <a-checkbox v-model:checked="includeOwnTweets">
      {{ $t('components.slides.socialTwitter.slideStep4.includeOwnPosts') }}
    </a-checkbox>
  </SlideStep>
</template>

<script>
import { TwitterCircleFilled } from '@ant-design/icons-vue'
import { computed, defineComponent, reactive, toRaw, toRefs, watch } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { getSocialHashtagsOrMentionsOnChange, SocialTwitterSlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import { toStandard } from '@/helpers/SocialAccount'
import { MAX_SOCIAL_ACCOUNTS_IN_SLIDE } from '@/constants'
import AddSocialAccountButton from '@/components/AddSocialAccountButton'
import { useI18n } from 'vue-i18n'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

export default defineComponent({
  name: 'SocialTwitterSlide',
  components: {
    DurationSlideStep,
    AddSocialAccountButton,
    SlideStep,
    TwitterCircleFilled
  },
  props: {
    title: String,
    slideObj: SocialTwitterSlide,
    groupId: String,
    disabled: Boolean,
    zoning: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const store = useStore()
    const { t } = useI18n()
    const slide = props.slideObj || new SocialTwitterSlide()
    const { muted } = slide.slideData
    const isMuted = props.zoning || muted
    const {
      hashtags,
      feedType,
      includeOwnTweets,
      socialTwitterAccountIds
    } = slide.slideData
    const state = reactive({
      accounts: [...socialTwitterAccountIds || []],
      hashtags: hashtags.map(h => h.replace('#', '')),
      feedType,
      includeOwnTweets
    })
    const twitterAccounts = computed(() => store.getters['social/socialTwitterAccounts'].map(toStandard))
    const isValid = computed(() => (state.hashtags?.length > 0 || state.includeOwnTweets) && state.accounts?.length > 0)

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })
    emit('update:disabled', !isValid.value)

    const onHashtagsChange = (value) => {
      state.hashtags = getSocialHashtagsOrMentionsOnChange(value)
    }

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      const {
        feedType,
        hashtags,
        includeOwnTweets = false
      } = toRaw(state)
      const twitterAccountsIds = twitterAccounts.value.filter(a => state.accounts.includes(a.id)).map(a => a.id)

      slide.updateSlideData({
        hashtags: hashtags.map(h => `#${h.replace(/#/g, '')}`),
        includeOwnTweets,
        feedType,
        socialTwitterAccountIds: twitterAccountsIds.length ? twitterAccountsIds : null
      })
      slide.setMute(isMuted)
    }

    const handleSocialAccountsChange = (socialAccounts) => {
      if (socialAccounts.length >= MAX_SOCIAL_ACCOUNTS_IN_SLIDE) {
        state.accounts = state.accounts.slice(0, MAX_SOCIAL_ACCOUNTS_IN_SLIDE)
      }
    }

    watch(() => state, () => {
      toggleDisabled(!isValid.value)
      if (isValid.value) {
        updateSlideData()
        updateSlide()
        toggleDisabled(false)
      }
    }, { deep: true })

    watch(() => twitterAccounts.value, (value, oldValue) => {
      if (value?.length - oldValue?.length === 1) {
        const newSocialAccount = value?.find(({ id }) => !oldValue.some(({ id: oldId }) => oldId === id))
        newSocialAccount && state.accounts?.push(newSocialAccount.id)
      }
    })

    return {
      slide,
      ...toRefs(state),
      isValid,
      twitterAccounts,
      onHashtagsChange,
      handleSocialAccountsChange,
      updateSlide
    }
  }
})
</script>

<style scoped>

</style>
