<template>
  <icon>
    <template #component>
      <svg
        width="9px"
        height="14px"
        viewBox="0 0 9 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <!-- Generator: Sketch 44 (41411) - http://www.bohemiancoding.com/sketch -->
        <title>Group</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Editor"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Step-1"
            transform="translate(-319.000000, -121.000000)"
          >
            <g
              id="Group-Copy-2"
              transform="translate(32.000000, 80.000000)"
            >
              <g
                id="Icons/Back"
                transform="translate(292.000000, 48.000000) scale(-1, 1) translate(-292.000000, -48.000000) translate(280.000000, 36.000000)"
              >
                <g id="Group">
                  <g>
                    <polygon
                      id="Bounds"
                      fill-opacity="0.2"
                      fill="#FF0000"
                      opacity="0"
                      points="0 0 24 0 24 24 0 24"
                    />
                    <polygon
                      id="Shape"
                      fill="#000000"
                      points="16.41 6.41 15 5 8 12 15 19 16.41 17.59 10.82 12"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'ForwardArrow',
  components: {
    Icon
  }
})
</script>
