<template>
  <div>
    <a-typography-title :level="5">
      {{ $t('components.generalSettings.title') }}
    </a-typography-title>
    <a-divider />
    <a-descriptions
      bordered
      class="general-settings-info"
    >
      <template #title>
        <a-typography-paragraph strong>
          {{ $t('components.generalSettings.generalSettings') }}
        </a-typography-paragraph>
      </template>
      <a-descriptions-item
        :label="$t('components.generalSettings.workspaceName')"
        :span="3"
      >
        <div style="display: flex; gap:8px; height: 32px; align-items: center; justify-content: space-between;">
          <a-typography-paragraph
            ref="workspaceNameRef"
            v-model:content="newWorkspaceName"
            :disabled="saving"
            class="inline-editable"
            style="margin: 0; display: inline; flex: 1;"
            :editable="{
              maxlength: 30,
              triggerType: ['icon','text'],
              onEnd: handleNameChangeEnd
            }"
          />
          <a-button
            v-if="newWorkspaceName !== workspaceName && newWorkspaceName"
            :loading="saving"
            type="primary"
            @click="handleNameChangeEnd"
          >
            {{ $t('components.generalSettings.saveWorkspaceName') }}
          </a-button>
        </div>
      </a-descriptions-item>
      <a-descriptions-item
        :label="$t('components.generalSettings.workspaceId')"
        :span="3"
      >
        {{ workspaceShortId }}
        <a-tooltip :title="$t('components.generalSettings.copyWorkspaceIdTooltipTitle')">
          <a-button
            size="small"
            type="link"
            @click="copyWorkspaceId"
          >
            <template #icon>
              <copy-outlined />
            </template>
          </a-button>
        </a-tooltip>
      </a-descriptions-item>
      <a-descriptions-item
        :label="$t('components.generalSettings.workspaceCreatedAt')"
        :span="3"
      >
        <span v-format-date="{date: workspaceCreatedAt, format: 'MM/DD/YYYY'}" />
      </a-descriptions-item>
      <a-descriptions-item
        :label="$t('components.generalSettings.screensInUse')"
        :span="3"
      >
        {{ currentDevicesNumber }}/{{ totalDevices }}
      </a-descriptions-item>
      <a-descriptions-item
        v-if="owner"
        :label="$t('components.generalSettings.owner')"
        :span="3"
      >
        {{ owner.name }} ({{ owner.email }})
      </a-descriptions-item>
      <a-descriptions-item
        :label="$t('components.generalSettings.jamfToken')"
        :span="3"
      >
        {{ deviceInitializationToken }}
        <a-tooltip :title="$t('components.generalSettings.copyConfigTooltipTitle')">
          <a-button
            size="small"
            type="link"
            @click="copyJamfConfig"
          >
            <template #icon>
              <copy-outlined />
            </template>
          </a-button>
        </a-tooltip>
      </a-descriptions-item>
    </a-descriptions>

    <template v-if="canUseSSO">
      <a-divider />
      <a-typography-paragraph strong>
        {{ $t('components.generalSettings.oauthSettings') }}
      </a-typography-paragraph>
      <a-form
        :model="ssoFormState"
        :rules="ssoRules"
        layout="vertical"
        @finish="handleSSOFormSubmit"
      >
        <a-row :gutter="0">
          <a-col :span="11">
            <a-form-item
              :label="$t('components.generalSettings.clientIdLabel')"
              name="clientId"
            >
              <a-input
                v-model:value="ssoFormState.clientId"
                :placeholder="$t('components.generalSettings.clientIdPlaceholder')"
              />
            </a-form-item>
          </a-col>
          <a-col
            :span="11"
            :offset="1"
          >
            <a-form-item
              :label="$t('components.generalSettings.clientSecretLabel')"
              name="clientSecret"
            >
              <a-input
                v-model:value="ssoFormState.clientSecret"
                :placeholder="$t('components.generalSettings.clientSecretPlaceholder')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="0">
          <a-col :span="11">
            <a-form-item
              :label="$t('components.generalSettings.issuerLabel')"
              name="issuer"
            >
              <a-input
                v-model:value="ssoFormState.issuer"
                :placeholder="$t('components.generalSettings.issuerPlaceholder')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item>
          <a-space>
            <a-button
              type="primary"
              html-type="submit"
              :loading="saving"
              :disabled="saveDisabled"
            >
              {{ $t('components.generalSettings.saveButtonText') }}
            </a-button>

            <a-popconfirm
              :title="$t('components.generalSettings.unsetSSOPopConfirmTitle')"
              :ok-text="$t('components.generalSettings.unsetSSOPopConfirmOkText')"
              :cancel-text="$t('components.generalSettings.unsetSSOPopConfirmCancelText')"
              @confirm="clearSSOProvider"
            >
              <a-button
                danger
                :disabled="clearDisabled"
                :loading="clearing"
              >
                {{ $t('components.generalSettings.clearButtonText') }}
              </a-button>
            </a-popconfirm>
          </a-space>
        </a-form-item>
      </a-form>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, reactive, ref, toRaw, watch } from 'vue'
import { CopyOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { copyText } from 'vue3-clipboard'
import { error, success } from '@/utils'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'GeneralSettings',
  components: {
    CopyOutlined
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const workspaceNameRef = ref(false)
    const saving = ref(false)
    const clearing = ref(false)
    const isOwner = computed(() => store.getters['workspace/isOwner'])
    const isAdmin = computed(() => store.getters['workspace/isAdmin'])
    const ssoEnabled = computed(() => store.getters['workspace/ssoEnabled'])
    const currentDevicesNumber = computed(() => store.getters['devices/allDevicesNumber'])
    const workspaceSSOIdentityProvider = computed(() => store.getters['workspace/workspaceSSOIdentityProvider'])
    const purchasedDevices = computed(() => store.getters['workspace/billingDevicesQuantity'])
    const freeDevicesQuantity = computed(() => store.getters['workspace/freeDevicesQuantity'])
    const totalDevices = computed(() => purchasedDevices.value + freeDevicesQuantity.value)
    const workspace = computed(() => store.getters['workspace/workspace'])
    const workspaceName = computed(() => workspace.value?.name)
    const newWorkspaceName = ref(workspaceName.value)
    const workspaceShortId = computed(() =>  store.getters['workspace/workspaceShortId'])
    const deviceInitializationToken = computed(() =>  store.getters['workspace/deviceInitializationToken'])
    const workspaceCreatedAt = computed(() => workspace.value?.createdAt)
    const owner = computed(() => store.getters['workspace/workspaceMembers'].filter(member=>{
      return member.role === 'OWNER'
    }).map(member => {
      return {
        name: `${member.user?.firstName} ${member.user?.lastName}`,
        email: member.user?.email,
        avatar: member.user?.avatarMedia?.generatedMedia?.[0]?.url
      }
    })?.[0])

    const ssoFormState = reactive({
      clientId: '',
      clientSecret: '',
      issuer: ''
    })

    const saveDisabled = computed(()=> {
      return (ssoFormState.clientId === workspaceSSOIdentityProvider.value?.clientId
              && ssoFormState.clientSecret === workspaceSSOIdentityProvider.value?.clientSecret
              && ssoFormState.issuer === workspaceSSOIdentityProvider.value?.issuer) ||
          !ssoFormState.clientId || !ssoFormState.clientSecret || ! ssoFormState.issuer || clearing.value
    })
    const clearDisabled = computed(()=> !workspaceSSOIdentityProvider.value || saving.value)

    const ssoRules = computed(()=>{
      return {
        clientId: [{
          required: true,
          message: t('components.generalSettings.clientIdInvalidError'),
        }],
        clientSecret: [{
          required: true,
          message: t('components.generalSettings.clientSecretInvalidError'),
        }],
        issuer: [{
          required: true,
          message: t('components.generalSettings.issuerInvalidError'),
        }]
      }
    })

    const JamfConfigTemplate = `
      <dict>
      <key>secret</key>
      <string>%TOKEN%</string>
      <key>externalDeviceId</key>
      <string>$UDID</string>
      <key>deviceName</key>
      <string>$DEVICENAME</string>
      <key>externalGroupId</key>
      <string>$SITEID</string>
      <key>groupName</key>
      <string>$SITENAME</string>
      </dict>
    `

    const canUseSSO = computed(()=> ssoEnabled.value && (isAdmin.value || isOwner.value))

    onMounted(async ()=>{
      if (canUseSSO.value) {
        await store.dispatch('workspace/getWorkspaceSSOIdentityProvider')
      }
    })

    const copyWorkspaceId = () => {
      copyText(workspaceShortId.value, undefined, (error, event) => {
        if (error) {
        } else {
          success(t('components.generalSettings.copiedSuccessfully'))
        }
      })

    }

    const copyJamfConfig = () => {
      copyText(JamfConfigTemplate.replace('%TOKEN%', deviceInitializationToken.value), undefined, (error, event) => {
        if (error) {
        } else {
          success(t('components.generalSettings.copiedSuccessfully'))
        }
      })
    }

    const handleSSOFormSubmit = () => {
      saving.value = true
      const {issuer, clientSecret, clientId} = toRaw(ssoFormState)
      store.dispatch('workspace/setWorkspaceSSOIdentityProvider', {issuer, clientSecret, clientId}).then(()=>{
        success()
      }).catch((e)=>{
        error(e.message)
      }).finally(()=>{
        saving.value = false
      })
    }

    const clearSSOProvider = () => {
      clearing.value = true
      store.dispatch('workspace/unsetWorkspaceSSOIdentityProvider').finally(()=>{
        clearing.value=false
      })
    }

    const handleNameChangeEnd = () => {
      if (!newWorkspaceName.value) {
        nextTick(()=>{
          newWorkspaceName.value = workspaceName.value
        })
        return
      }
      saving.value = true
      store.dispatch('workspace/updateWorkspace', { name: newWorkspaceName.value }).then(()=>{
        success()
      }).catch((e)=>{
        error(e.message)
      }).finally(()=>{
        saving.value = false
      })
    }

    watch(() => workspaceSSOIdentityProvider.value, (value) => {
      if (value) {
        const { issuer, clientSecret, clientId } = value
        ssoFormState.issuer = issuer
        ssoFormState.clientSecret = clientSecret
        ssoFormState.clientId = clientId
      }
      else {
        ssoFormState.issuer = ''
        ssoFormState.clientSecret = ''
        ssoFormState.clientId = ''
      }
    })

    return {
      saving,
      clearing,
      owner,
      canUseSSO,
      ssoRules,
      currentDevicesNumber,
      totalDevices,
      workspaceName,
      newWorkspaceName,
      workspaceShortId,
      workspaceCreatedAt,
      deviceInitializationToken,
      workspaceSSOIdentityProvider,
      ssoFormState,
      saveDisabled,
      workspaceNameRef,
      clearDisabled,
      handleNameChangeEnd,
      clearSSOProvider,
      copyJamfConfig,
      copyWorkspaceId,
      handleSSOFormSubmit
    }
  }
})
</script>

<style lang="less">
.general-settings-info {
  .ant-descriptions-header {
    margin-bottom: 0;
  }
}
.inline-editable {
  max-width: 320px;
  flex: 1;
}

</style>
