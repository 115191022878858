import ApolloClient from '@/apollo'
import {
  GET_DEVICE_BY_ID,
  GET_DEVICE_PREVIEW_WEBRTC_ICE_SERVERS,
  GET_DEVICES_BY_MATCHING_RULE,
  LIST_DEVICES
} from '@/graphql/queries'
import {
  ATTACH_DEVICE,
  DELETE_DEVICE_BY_ID,
  DELETE_DEVICES_BY_IDS,
  INITIALIZE_DEVICE_PREVIEW,
  MOVE_DEVICE_BY_ID,
  MOVE_DEVICES_BY_IDS,
  SEND_DEVICE_PREVIEW_LOCAL_CANDIDATES,
  SEND_DEVICE_PREVIEW_WEBRTC_OFFER,
  SET_DEVICE_CUSTOM_TAGS_BY_DEVICE_ID,
  UPDATE_DEVICE_BY_ID,
} from '@/graphql/mutations'
import { updateEntityById } from '@/utils'
import { ON_DEVICE_UPDATED } from '@/graphql/subscriptions'
import { handleError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    allDevices: [],
    deviceUpdateSubscription: null,
    currentGroupDevices: [],
    selectedDevices: [],
    devicesMatchingRules: [],
    devicePreviewObject: null
  },
  actions: {
    async getAllDevices ({ commit }) {
      try {
        const { data: { listDevices } } = await ApolloClient.query({ query: LIST_DEVICES, variables: { filters: {} }, fetchPolicy: 'no-cache' })
        commit('SET_ALL_DEVICES', listDevices)
      } catch (e) {
        handleError(e)
      }
    },
    async subscribeToDeviceUpdate ({ state, commit }) {
      if (state.deviceUpdateSubscription) return
      state.deviceUpdateSubscription = ApolloClient.subscribe({ query: ON_DEVICE_UPDATED })
        .subscribe(({ data: { onDeviceUpdated }}) => {
          commit('UPDATE_DEVICE', onDeviceUpdated)
          commit('UPDATE_CURRENT_GROUP_DEVICE', onDeviceUpdated)
        })
    },

    async unsubscribeFromDeviceUpdate ({ commit }) {
      commit('UNSUBSCRIBE_FROM_DEVICE_UPDATE')
    },
    async getCurrentGroupDevices ({ rootGetters, commit }) {
      const groupId = rootGetters['groups/currentGroupId']
      if (!groupId) return
      try {
        const { data: { listDevices } } = await ApolloClient.query({ query: LIST_DEVICES, variables: { filters: { groupIds: [groupId] } }, fetchPolicy: 'no-cache' })
        commit('SET_CURRENT_GROUP_DEVICES', listDevices)
      } catch (e) {
        handleError(e)
      }
    },
    async updateDevice ({ rootGetters, commit }, { id, groupId, input, isCurrent }) {
      const currentGroupId = rootGetters['groups/currentGroupId']
      try {
        const { data: { updateDeviceById } } = await ApolloClient.query({ query: UPDATE_DEVICE_BY_ID, variables: { id, input }, fetchPolicy: 'no-cache' })
        if (isCurrent || groupId === currentGroupId) {
          commit('UPDATE_CURRENT_GROUP_DEVICE', updateDeviceById)
        }
        if (rootGetters.showDevices) {
          commit('UPDATE_DEVICE', updateDeviceById)
        }
      } catch (e) {
        handleError(e)
      }
    },
    async moveDevice ({ rootGetters, commit }, { id, groupId, input, isCurrent }) {
      const moveToGroupId = input.groupId
      const currentGroupId = rootGetters['groups/currentGroupId']
      try {
        const { data: { moveDeviceById } } = await ApolloClient.mutate({ mutation: MOVE_DEVICE_BY_ID, variables: { id, input }, fetchPolicy: 'no-cache' })
        if (isCurrent || groupId === currentGroupId) {
          commit('REMOVE_CURRENT_GROUP_DEVICE', id)
        }
        else if (moveToGroupId === currentGroupId) {
          commit('ADD_CURRENT_GROUP_DEVICE', moveDeviceById)
        }
        else if (rootGetters.showDevices) {
          commit('UPDATE_DEVICE', moveDeviceById)
        }
      } catch (e) {
        handleError(e)
      }
    },
    async moveDevices ({ rootGetters, commit, dispatch }, { ids, groupId }) {
      try {
        const currentGroupId = rootGetters['groups/currentGroupId']
        const { data: { moveDevicesByIds } } = await ApolloClient.mutate({ mutation: MOVE_DEVICES_BY_IDS, variables: { ids, input: { groupId } } })
        for (let device in moveDevicesByIds) {
          const moveToGroupId = moveDevicesByIds[device].groupId
          if (groupId === currentGroupId) {
            commit('REMOVE_CURRENT_GROUP_DEVICE', device.id)
          }
          else if (moveToGroupId === currentGroupId) {
            commit('ADD_CURRENT_GROUP_DEVICE', moveDevicesByIds[device])
          }
          else if (rootGetters.showDevices) {
            commit('UPDATE_DEVICE', moveDevicesByIds[device])
          }
        }
      } catch (e) {
        handleError(e)
      }
    },
    async attachDeviceToCurrentGroup ({ rootGetters, commit, dispatch }, payload) {
      const groupId = rootGetters['groups/currentGroupId']
      try {
        const { data: { attachDevice } } = await ApolloClient.mutate({ mutation: ATTACH_DEVICE, variables: { input: { groupId, ...payload } }, fetchPolicy: 'no-cache' })
        commit('ADD_CURRENT_GROUP_DEVICE', attachDevice)
        dispatch('getAllDevices')
        return attachDevice
      } catch (e) {
        handleError(e)
      }
    },
    async deleteDevice ({ rootGetters, commit, dispatch }, { id, groupId, isCurrent }) {
      const currentGroupId = rootGetters['groups/currentGroupId']
      try {
        await ApolloClient.mutate({ mutation: DELETE_DEVICE_BY_ID, variables: { id } })
        if (isCurrent || groupId === currentGroupId) {
          commit('REMOVE_CURRENT_GROUP_DEVICE', id)
          dispatch('getAllDevices')
        }
        if (rootGetters.showDevices) {
          commit('REMOVE_DEVICE', id)
        }
      } catch (e) {
        handleError(e)
      }
    },
    async deleteDevices ({ dispatch }, { ids }) {
      try {
        await ApolloClient.mutate({ mutation: DELETE_DEVICES_BY_IDS, variables: { ids } })
        return Promise.all([dispatch('getCurrentGroupDevices'), dispatch('getAllDevices')])
      } catch (e) {
        handleError(e)
      }
    },
    async getDevicesMatchingRules ({ commit }, input) {
      try {
        const { data: { getDevicesBySmartGroupMatchingRule } } = await ApolloClient.query({ query: GET_DEVICES_BY_MATCHING_RULE, variables: { input }, fetchPolicy: 'no-cache' })
        commit('SET_DEVICES_MATCHING_RULES', getDevicesBySmartGroupMatchingRule)
      } catch (e) {
        handleError(e)
      }
    },
    async setDeviceCustomTagsById ({ commit, dispatch }, { id, groupId, input }) {
      try {
        await ApolloClient.mutate({ mutation: SET_DEVICE_CUSTOM_TAGS_BY_DEVICE_ID, variables: { id, input }})
        dispatch('getUpdatedDeviceTagsByDeviceId', { id, groupId })
      } catch (e) {
        handleError(e)
      }
    },
    async getUpdatedDeviceTagsByDeviceId ({ commit, rootGetters }, { id, groupId }) {
      const currentGroupId = rootGetters['groups/currentGroupId']
      try {
        const { data: { getDeviceById } } = await ApolloClient.query({ query: GET_DEVICE_BY_ID, variables: { id }, fetchPolicy: 'no-cache' })
        if (groupId === currentGroupId) {
          commit('UPDATE_CURRENT_GROUP_DEVICE', getDeviceById)
        }
        if (rootGetters.showDevices) {
          commit('UPDATE_DEVICE', getDeviceById)
        }
      } catch (e) {
        handleError(e)
      }
    },
    async initializeDevicePreview ({ commit, rootGetters }, { deviceId }) {
      try {
        const { data: { initializeDevicePreview } } = await ApolloClient.mutate({ mutation: INITIALIZE_DEVICE_PREVIEW, variables: { deviceId } })
        return initializeDevicePreview
      } catch (e) {
        const error = e.graphQLErrors?.[0]?.extensions?.code || e.message
        throw new Error(error)
      }
    },
    async getDevicePreviewWebrtcIceServers ({ commit, rootGetters }, { deviceId }) {
      try {
        const { data: { getDevicePreviewWebrtcIceServers } } = await ApolloClient.query({ query: GET_DEVICE_PREVIEW_WEBRTC_ICE_SERVERS, variables: { deviceId }, fetchPolicy: 'no-cache'  })
        return getDevicePreviewWebrtcIceServers
      } catch (e) {
        const error = e.graphQLErrors?.[0]?.extensions?.code || e.message
        throw new Error(error)
      }
    },
    async sendDevicePreviewWebrtcOffer ({ commit, rootGetters }, { deviceId, offerSdp }) {
      try {
        const { data: { sendDevicePreviewWebrtcOffer } } = await ApolloClient.mutate({ mutation: SEND_DEVICE_PREVIEW_WEBRTC_OFFER, variables: { deviceId, offerSdp }})
        return sendDevicePreviewWebrtcOffer
      } catch (e) {
        const error = e.graphQLErrors?.[0]?.extensions?.code || e.message
        throw new Error(error)
      }
    },
    async sendDevicePreviewWebrtcLocalCandidates ({ commit, rootGetters }, { deviceId, sdpFragment, eTag }) {
      try {
        const { data: { sendDevicePreviewWebrtcLocalCandidates } } = await ApolloClient.mutate({ mutation: SEND_DEVICE_PREVIEW_LOCAL_CANDIDATES, variables: { deviceId, sdpFragment, eTag }})
        return sendDevicePreviewWebrtcLocalCandidates
      } catch (e) {
        const error = e.graphQLErrors?.[0]?.extensions?.code || e.message
        throw new Error(error)
      }
    },
  },
  getters: {
    currentGroupDevices: state => state.currentGroupDevices,
    devicesMatchingRules: state => state.devicesMatchingRules,
    devicesMatchingRulesCount: state => state.devicesMatchingRules?.length,
    allDevices: state => state.allDevices,
    allDevicesIdsMap: state => {
      return (state.allDevices || []).reduce((acc, g) => {
        acc[g.id] = g.name
        return acc
      }, {})
    },
    allDevicesNumber: state => state.allDevices?.length,
    selectedDevices: state => state.selectedDevices,
    selectedDevicesIds: state => state.selectedDevices.map(d => d.id),
    devicePreviewObject: state => state.devicePreviewObject,
    devicePreviewObjectIsSet: state => state.devicePreviewObject !== null,
    devicePreviewDeviceId: state => state.devicePreviewObject?.deviceId,
    devicePreviewDeviceName: state => state.devicePreviewObject?.deviceName,
  },
  mutations: {
    SET_ALL_DEVICES (state, devices) {
      state.allDevices = devices
    },
    SET_DEVICES_MATCHING_RULES (state, devices) {
      state.devicesMatchingRules = devices
    },
    ADD_CURRENT_GROUP_DEVICE (state, device) {
      state.currentGroupDevices = [device, ...state.currentGroupDevices]
    },
    SET_CURRENT_GROUP_DEVICES (state, devices) {
      state.currentGroupDevices = devices
    },
    REMOVE_CURRENT_GROUP_DEVICE (state, deviceId) {
      state.currentGroupDevices = [...state.currentGroupDevices.filter(d => deviceId !== d.id)]
    },
    UPDATE_CURRENT_GROUP_DEVICE (state, device) {
      if (!state.currentGroupDevices?.some(d => d.id === device.id)) {
        return
      }
      state.currentGroupDevices = updateEntityById(state, 'currentGroupDevices', device)
    },
    UPDATE_DEVICE (state, device) {
      if (!state.allDevices?.some(d => d.id === device.id)) {
        return
      }
      state.allDevices = updateEntityById(state, 'allDevices', device)
    },
    REMOVE_DEVICE (state, deviceId) {
      state.allDevices = [...state.allDevices.filter(d => deviceId !== d.id)]
    },
    UNSUBSCRIBE_FROM_DEVICE_UPDATE (state) {
      state.deviceUpdateSubscription?.unsubscribe()
      state.deviceUpdateSubscription = null
    },
    SET_DEVICE_PREVIEW_OBJECT (state, devicePreviewObject) {
      state.devicePreviewObject = devicePreviewObject
    },
    SET_SELECTED_DEVICES (state, selectedDevices) {
      state.selectedDevices = selectedDevices
    },
    CLEAR_SELECTED_DEVICES (state) {
      state.selectedDevices = []
    },
    CLEAR_DEVICE_PREVIEW_OBJECT (state) {
      state.devicePreviewObject = null
    },
    CLEAR_DEVICES_DATA (state) {
      state.allDevices = []
      state.selectedDevices = []
      state.devicesMatchingRules = []
      state.currentGroupDevices = []
      state.deviceUpdateSubscription?.unsubscribe()
      state.deviceUpdateSubscription = null
    }
  }
}
