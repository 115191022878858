<template>
  <a-spin
    tip="Redirecting..."
    class="spinner"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'LoginAsPage',
  props: {
    token: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()

    store.dispatch('auth/authorize', props.token).then(() => {
      router.push({ name: 'Home' })
    })

    return {}
  }
})
</script>

<style scoped>
.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
